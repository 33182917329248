import classNames from 'classnames'
import React, { FC, useEffect, useMemo, useState } from 'react'

import { updateUserQuizAnswersInnerRoute } from '../../../api/user-prefs'
import { useCurrentUser } from '../../../context/CurrentUserContext'
import useSizes from '../../../hooks/useSizes'
import { trackPixelEvent } from '../../../utils/pixelUtils'
import SignupFlowModal from '../common/SignupFlowModal/SignupFlowModal'
import GetStartedScreen from './GetStartedScreen/GetStartedScreen'
import styles from './WelcomeModal.module.scss'
import {
  answersExample,
  occupationStepValues,
  sourceStepValues,
  TStepData,
  TWelcomeModalAnswers,
  TWelcomeModalSteps,
  welcomeModalConfig,
  WelcomeModalSteps,
} from './data'

type Props = {
  opened: boolean
  onClose: () => void
}

const WelcomeModal: FC<Props> = ({ opened, onClose }) => {
  const { currentUser } = useCurrentUser()
  const { isSmallTablet } = useSizes()

  const [step, setStep] = useState<TWelcomeModalSteps>(WelcomeModalSteps.GET_STARTED)
  const [selectedOptions, setSelectedOptions] = useState<number[]>([])
  const [_, setAnswers] = useState<TWelcomeModalAnswers>(answersExample)
  const [isDetailedInputOpened, setIsDetailedInputOpened] = useState(false)
  const [detailedInputValue, setDetailedInputValue] = useState('')

  const welcomeModalStepConfig = useMemo(() => welcomeModalConfig[step], [step])

  useEffect(() => {
    if (!opened) return

    const startStep = currentUser?.prefs?.afterRegModalShown
      ? WelcomeModalSteps.OCCUPATION
      : WelcomeModalSteps.GET_STARTED
    setStep(startStep)
  }, [opened])

  const handleMoveBack = () => {
    if (isDetailedInputOpened) {
      setIsDetailedInputOpened(false)
      setDetailedInputValue('')
      return
    }

    const newStep = welcomeModalStepConfig.prevStep as TWelcomeModalSteps
    newStep && setStep(newStep)
  }
  const handleMoveForward = async (answers?: TWelcomeModalAnswers) => {
    const newStep = welcomeModalStepConfig.nextStep as TWelcomeModalSteps

    if (newStep) {
      setStep(newStep)

      setIsDetailedInputOpened(false)
      setDetailedInputValue('')
    } else {
      if (answers) {
        if (!occupationStepValues.includes(answers.OCCUPATION as string)) {
          answers.OCCUPATION_DETAILED = answers.OCCUPATION as string
          answers.OCCUPATION = 'other'
        }
        if (!sourceStepValues.includes(answers.SOURCE as string)) {
          answers.SOURCE_DETAILED = answers.SOURCE as string
          answers.SOURCE = 'other'
        }

        await updateUserQuizAnswersInnerRoute(answers)
      }

      onClose()
    }
  }

  const handleContinueSingle = (index: number, data: TStepData, detailedValue?: string) => {
    if (!detailedValue && data.content[index].value === 'other') {
      setIsDetailedInputOpened(true)
      return
    }

    setAnswers((v) => {
      const stepAnswer = detailedValue || data.content[index].value
      const newV = { ...v, [step]: stepAnswer }
      handleMoveForward(newV)
      return newV
    })
  }
  const handleContinueMultiple = (data: TStepData) => {
    setAnswers((v) => {
      const stepAnswers = data.content
        .filter((_, index) => selectedOptions.includes(index))
        .map((option) => option.value)
      const newV = { ...v, [step]: stepAnswers }

      handleMoveForward(newV)
      return newV
    })
  }

  const handleClickCell = (index: number, data: TStepData) => {
    if (data.isMultiple) {
      setSelectedOptions((v) => (v.includes(index) ? v.filter((v) => v !== index) : [...v, index]))
    } else {
      handleContinueSingle(index, data)
    }
  }

  const getLabel = () => {
    if (step === WelcomeModalSteps.OCCUPATION) return 'How would you describe yourself?'
    if (step === WelcomeModalSteps.SOURCE) return 'How did you hear about us?'
    return ''
  }
  const getTitle = () => {
    if (!isDetailedInputOpened) return ''
    if (step === WelcomeModalSteps.OCCUPATION) return 'Tell us about yourself'
    if (step === WelcomeModalSteps.SOURCE) return 'How did you hear about us?'
    return ''
  }
  const getActionType = (): any => {
    if (isDetailedInputOpened) return 'back'
    return welcomeModalStepConfig.actionType
  }
  const getActionsDisabled = () => {
    if (isDetailedInputOpened) return false
    return welcomeModalStepConfig.actionsDisabled
  }

  const renderContainer = (data: TStepData) => {
    const isGridLayout = data.layout === 'grid'
    const isListLayout = data.layout === 'list'

    return (
      <div className={styles.welcomeModalContainer}>
        <div className={styles.welcomeModalHeader}>
          {data.title && <div className={styles.welcomeModalTitle}>{data.title}</div>}
          {data.subtitle && <div className={styles.welcomeModalSubtitle}>{data.subtitle}</div>}
        </div>

        {isGridLayout && renderGridLayout(data)}
        {isListLayout && renderListLayout(data)}

        {data.isMultiple && (
          <button
            disabled={!selectedOptions.length}
            className={styles.welcomeModalButton}
            onClick={() => handleContinueMultiple(data)}
          >
            Continue
          </button>
        )}
      </div>
    )
  }
  const renderGridLayout = (data: TStepData) => {
    const numOfCells = data.content.length
    const columnsRaw = Math.min(numOfCells, 4)
    const columns = isSmallTablet ? columnsRaw - 2 : columnsRaw

    return (
      <div className={styles.welcomeModalGrid} style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
        {data.content.map((cell, index) => (
          <div
            key={`${data.title}_${index}`}
            className={classNames(styles.welcomeModalGridCell, {
              [styles.welcomeModalGridCellActive]: data.isMultiple && selectedOptions.includes(index),
            })}
            onClick={() => handleClickCell(index, data)}
          >
            {cell.icon && <div className={styles.welcomeModalGridCellIcon}>{cell.icon}</div>}

            <div className={styles.welcomeModalGridCellContent}>
              {cell.title && <div className={styles.welcomeModalGridCellTitle}>{cell.title}</div>}
              {cell.text && <div className={styles.welcomeModalGridCellText}>{cell.text}</div>}
            </div>
          </div>
        ))}
      </div>
    )
  }
  const renderListLayout = (data: TStepData) => {
    return (
      <div className={styles.welcomeModalList}>
        {data.content.map((cell, index) => (
          <div
            key={`${data.title}_${index}`}
            className={classNames(styles.welcomeModalListCell, {
              [styles.welcomeModalListCellActive]: data.isMultiple && selectedOptions.includes(index),
            })}
            onClick={() => handleClickCell(index, data)}
          >
            {cell.icon && <div className={styles.welcomeModalListCellIcon}>{cell.icon}</div>}
            {cell.title && <div className={styles.welcomeModalListCellTitle}>{cell.title}</div>}
          </div>
        ))}
      </div>
    )
  }

  const renderGetStartedContent = () => {
    return (
      <GetStartedScreen
        handleNext={() => {
          trackPixelEvent('CompleteRegistration')
          if (currentUser?.prefs?.quizTaken) {
            onClose()
            return
          }
          handleMoveForward()
        }}
      />
    )
  }
  const renderDetailedInputContent = () => {
    return (
      <div className={styles.detailedFieldContainer}>
        <div className={styles.detailedFieldContent}>
          <div className={styles.detailedFieldTitle}>{getLabel()}</div>

          <textarea
            rows={4}
            name='detailedInput'
            placeholder='...'
            onKeyDown={(e) => e.stopPropagation()}
            value={detailedInputValue}
            onChange={(e) => setDetailedInputValue(e.target.value || '')}
            className={styles.detailedFieldInput}
          />
        </div>

        <div className={styles.detailedFooter}>
          <button
            className={styles.confirmButton}
            disabled={!detailedInputValue}
            onClick={() => handleContinueSingle(-1, welcomeModalStepConfig.data as any, detailedInputValue)}
          >
            Continue
          </button>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (step === WelcomeModalSteps.GET_STARTED) return renderGetStartedContent()

    if (isDetailedInputOpened) return renderDetailedInputContent()

    const { data } = welcomeModalStepConfig
    if (!data) return null

    return renderContainer(data)
  }

  return (
    <SignupFlowModal
      opened={opened}
      onBack={handleMoveBack}
      actionType={getActionType()}
      actionsDisabled={getActionsDisabled()}
      title={getTitle()}
    >
      {renderContent()}
    </SignupFlowModal>
  )
}

export default WelcomeModal
