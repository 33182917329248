import { InteractivePianoRollContextType } from '../types'
import { PianoRollNote } from '../types'
import { getMonoNoteOverlaps, removeOverlapsFromNotes } from './overlapHelper'

export const moveTempNotesToNormal = (
  context: InteractivePianoRollContextType,
  tempNoteList: PianoRollNote[],
  tempNoteIds: string[],
) => {
  const { getTempNotesByIds, addNotes, noteOpacity, isMonophonic } = context
  const tempNotes = getTempNotesByIds(tempNoteIds)
  if (isMonophonic) {
    const overlaps = getMonoNoteOverlaps(tempNotes, tempNoteList)
    if (overlaps) {
      const newTempNotes = removeOverlapsFromNotes(tempNotes, overlaps)
      addNotes(newTempNotes.map((note) => ({ ...note, opacity: noteOpacity })))
    }
  } else {
    addNotes(tempNotes.map((note) => ({ ...note, opacity: noteOpacity })))
  }
}
