import { DragType, PianoRollNote } from '../types'
import { InteractivePianoRollContextType } from '../types'

// Adjust the note limits based on the drag type and delta values (when it's moving)
export const adjustNoteLimits = (
  context: InteractivePianoRollContextType,
  dragType: DragType,
  dragNote: PianoRollNote,
  draggingNoteIds: Set<string>,
  deltaTicks: number,
  deltaMidiValue: number,
) => {
  const noteList: PianoRollNote[] = []
  const { ppq, ppqSnapRef, getNoteById, midiRangeMin, midiRangeMax, isMonophonic } = context

  let adjustedDeltaTicks = deltaTicks
  let adjustedDeltaMidi = deltaMidiValue
  let snapAdjustment = 0

  // The shift in ticks when the position is not snapped to the grid
  if (dragNote) {
    let tickOffset = 0
    if (dragType === DragType.NOTE) {
      tickOffset = (adjustedDeltaTicks + dragNote.startTicks) % ppqSnapRef.current
    } else if (dragType === DragType.NOTE_LENGTH || dragType === DragType.PENCIL) {
      tickOffset = (adjustedDeltaTicks + dragNote.endTicks) % ppqSnapRef.current
    }

    if (tickOffset > ppqSnapRef.current / 2) {
      snapAdjustment = ppqSnapRef.current - tickOffset
    } else {
      snapAdjustment = -tickOffset
    }
  }

  adjustedDeltaTicks += snapAdjustment

  const maxTicksLength = ppq * 8
  let maxTicksMonophonic = ppq * 8
  // Simple monophonic mode: calculate the maximum ticks until bump if multiple notes are dragged
  if (isMonophonic && draggingNoteIds.size > 1) {
    maxTicksMonophonic = 0
    draggingNoteIds.forEach((noteId) => {
      const note = getNoteById(noteId)
      if (!note) return
      maxTicksMonophonic = Math.max(maxTicksMonophonic, note.endTicks - note.startTicks)
    })
  }

  draggingNoteIds.forEach((noteId) => {
    const note = getNoteById(noteId)
    if (!note) return
    noteList.push(note)

    if (dragType === DragType.NOTE) {
      const newStartTicks = note.startTicks + adjustedDeltaTicks
      // Calculate the lower bound of the grid, so we don't drag notes out of the grid
      if (newStartTicks < 0) {
        adjustedDeltaTicks -= newStartTicks
      }
      // Calculate the upper/lower bounds of the MIDI range, so we don't drag notes out of the MIDI range
      if (note.midiNote + adjustedDeltaMidi >= midiRangeMax) {
        adjustedDeltaMidi -= note.midiNote + adjustedDeltaMidi - (midiRangeMax - 1)
      } else if (note.midiNote + adjustedDeltaMidi < midiRangeMin) {
        adjustedDeltaMidi -= note.midiNote + adjustedDeltaMidi - midiRangeMin
      }
    } else if (dragType === DragType.NOTE_LENGTH || dragType === DragType.PENCIL) {
      const newWidth = note.endTicks - note.startTicks + adjustedDeltaTicks
      if (newWidth < Math.floor(ppq / 12)) {
        // Let's not make it less than PPQ / 12 ticks wide
        adjustedDeltaTicks -= newWidth - Math.floor(ppq / 12)
      } else if (newWidth > maxTicksLength) {
        // Let's not make it more than 8 * PPQ ticks wide
        adjustedDeltaTicks -= newWidth - maxTicksLength
      } else if (isMonophonic && newWidth > maxTicksMonophonic) {
        adjustedDeltaTicks -= newWidth - maxTicksMonophonic
      }
    }
  })

  return { noteList, adjustedDeltaTicks, adjustedDeltaMidiValue: adjustedDeltaMidi }
}
