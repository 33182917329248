import { InputMask } from '@react-input/mask'
import classNames from 'classnames'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'

import { loginEmailCallbackInnerRoute } from '../../../../api/auth'
import WarningIcon from '../../../../assets/icons/warning2.svg'
import { useCurrentUser } from '../../../../context/CurrentUserContext'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import { secondsToString, validateEmail, validateLoginCode } from '../../../../utils/stringUtils'
import CircleLoader from '../../../common/CircleLoader/CircleLoader'
import styles from './LoginEmailForm.module.scss'

export type LoginEmailStep = 'enter-email' | 'code-sent' | 'code-wrong'

type Props = {
  step: LoginEmailStep
  setStep: (v: LoginEmailStep) => void
}

const LoginEmailForm: React.FC<Props> = ({ step, setStep }) => {
  const { loginEmail } = useCurrentUser()
  const { getText } = useInternationalization()

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [code, setCode] = useState('_______')
  const [timer, setTimer] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  const emailNotValid = email && !validateEmail(email)

  const STRINGS = getText('LoginModal')

  useEffect(() => {
    if (error === STRINGS.emailError && !emailNotValid) setError('')
  }, [emailNotValid, error])
  useEffect(() => {
    const onKeyDown = async (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !loading && validateLoginCode(code)) {
        handleLogin()
      }
    }

    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [code, loading])
  useEffect(() => {
    const interval = setInterval(() => setTimer((timer) => (timer ? timer - 1 : timer)), 1000)
    return () => clearInterval(interval)
  }, [])

  const handleSendEmail = async () => {
    if (!email || emailNotValid) {
      setError(STRINGS.emailError)
      return
    }

    setLoading(true)
    setTimer(120)

    try {
      await loginEmail(email)
      setStep('code-sent')
    } catch (e) {
      setError('Something went wrong, please try again later')
    } finally {
      setLoading(false)
    }
  }
  const handleLogin = async () => {
    setLoading(true)

    try {
      await loginEmailCallbackInnerRoute(email, code)
      window.location.reload()
    } catch (e) {
      setError(STRINGS.codeError)
    } finally {
      setLoading(false)
    }
  }

  const renderEnterEmailStep = () => {
    return (
      <div className={styles.enterEmailContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.emailFieldTitle}>{STRINGS.emailLabel}</div>

          <input
            name='email'
            type='email'
            placeholder='john.smith@gmail.com'
            value={email}
            onChange={(e) => setEmail(e.target.value || '')}
            onBlur={() => {
              if (!email || emailNotValid) setError(STRINGS.emailError)
            }}
            className={styles.emailFieldInput}
          />

          <div className={classNames(styles.fieldError, { [styles.fieldErrorHidden]: !error })}>
            <WarningIcon />

            <span>{error}</span>
          </div>
        </div>

        <div className={styles.footer}>
          <button
            className={styles.confirmButton}
            disabled={emailNotValid || !email || loading}
            onClick={handleSendEmail}
          >
            <span>{loading ? STRINGS.codeSendingBtn : STRINGS.emailContinueBtn}</span>
          </button>
        </div>
      </div>
    )
  }
  const renderCodeSentStep = () => {
    return (
      <div className={styles.codeSentContainer}>
        <div className={styles.fieldContainer}>
          <Image layout={'fixed'} width={150} height={120} src='/static/images/login/letter.svg' alt='' />

          <div className={styles.codeSentTitle}>
            {STRINGS.codeText_1} <span>{email}</span> <br /> {STRINGS.codeText_2}
          </div>

          <InputMask
            mask='_______'
            pattern='\d'
            showMask
            replacement={{ _: /\d/ }}
            className={styles.codeFieldInput}
            modify={() => undefined}
            onMask={(e) => setCode(e.detail.input)}
          />

          <div className={classNames(styles.fieldError, { [styles.fieldErrorHidden]: !error })}>
            <WarningIcon />

            <span>{error}</span>
          </div>
        </div>

        <div className={styles.footer}>
          <button
            className={styles.confirmButton}
            disabled={loading || code === '_______' || code.length !== 7}
            onClick={handleLogin}
          >
            {loading ? <CircleLoader className={styles.loader} /> : null}

            <span>{STRINGS.codeLoginBtn}</span>
          </button>

          <button className={styles.resendCodeButton} disabled={(timer || 0) > 0 || loading} onClick={handleSendEmail}>
            <span>{STRINGS.codeResendBtn}</span> {timer ? `(${secondsToString(timer)})` : ''}
          </button>
        </div>
      </div>
    )
  }

  const renderFormContent = () => {
    if (step === 'enter-email') return renderEnterEmailStep()
    if (step === 'code-sent') return renderCodeSentStep()
    return <></>
  }

  return <div className={styles.container}>{renderFormContent()}</div>
}

export default LoginEmailForm
