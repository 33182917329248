import classNames from 'classnames'
import React from 'react'

import { useInstrumentsState } from '../../../../../context/InstrumentsStateContext'
import { useInternationalization } from '../../../../../context/InternationalizationContext'
import { INSTRUMENT_CATEGORY_TO_PLASTYLE_TYPES } from '../../../../../utils/instrumentsUtils'
import { Playstyle } from '../../../../../utils/types'
import { usePlayerConfigState } from '../../../hooks/usePlayerConfigState'
import styles from './InstrumentMenu.module.scss'
import Menu from './Menu'
import { SETTINGS_TYPES } from '../../../LayersOfInstruments/LayersOfInstruments'

type Props = {
  open: boolean
  onClose: () => void
}

const PlaystyleMenu: React.FC<Props> = ({ open, onClose }) => {
  const { text } = useInternationalization()
  const { playerConfig } = usePlayerConfigState()
  const { activeLayerId, editorMode, playstylesData, updateLayerById, activeLayer } = useInstrumentsState()

  const allowedTypes = INSTRUMENT_CATEGORY_TO_PLASTYLE_TYPES[activeLayer?.instrument.category || '']
  const rightMenuItems = playstylesData.filter((ps) => allowedTypes && ps.types.some((t) => allowedTypes.includes(t)))

  const activeRightMenuItem = activeLayer?.playstyle.name
  const setActiveRightMenuItem = (playstyle: Playstyle) => {
    if (!activeLayer) return
    updateLayerById(activeLayerId, { ...activeLayer, playstyle }, editorMode)
  }
  const STRINGS = text.footer.instrumentsEditor.playstyle

  if (!playerConfig.currentPart) return null

  const renderHeader = () => {
    return <div className={styles.header}>{STRINGS.title}</div>
  }

  const renderLeftMenu = () => {
    return <></>
  }

  const renderRightMenu = () => {
    const renderItem = (option: Playstyle) => {
      return (
        <div
          className={classNames(styles.rightMenuItem)}
          key={option.name}
          data-selected={option.name === activeRightMenuItem}
          onClick={() => setActiveRightMenuItem(option)}
        >
          <div className={styles.name}>{option.name}</div>
        </div>
      )
    }

    return <div className={styles.rightMenu}>{rightMenuItems.map((option) => renderItem(option))}</div>
  }

  const renderFooter = () => {
    return <div className={styles.footer} />
  }

  return (
    <Menu
      settingType={SETTINGS_TYPES.ARPEGGIO}
      open={open}
      onClose={onClose}
      renderHeader={renderHeader}
      renderLeftMenu={renderLeftMenu}
      renderRightMenu={renderRightMenu}
      renderFooter={renderFooter}
    />
  )
}

export default PlaystyleMenu
