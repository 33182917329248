import classNames from 'classnames'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import ArrowIcon from '../../assets/icons/arrow.svg'
import useClickOutsideAlerter from '../../hooks/clickOutsideAlerterHook'
import canvasStyles from '../canvas/ChordCanvas.module.scss'
import styles from './SimpleSelect.module.scss'

type Option = {
  label: string | ReactNode
  value: string
  icon?: ReactNode
  tooltipId?: string
}

type Props = {
  id?: string
  type?: 'value' | 'valueLabel'
  label?: string
  labelIcon?: ReactNode
  custom?: boolean
  placeholder?: string
  className?: string
  pickerClassName?: string
  optionClassName?: string
  dropdownClassName?: string
  value?: string
  position?: string
  valuePlaceholder?: string
  options: Array<Option>
  optionFormatter?: (label: any, index: number) => ReactNode | null
  iconPosition?: 'start' | 'end'
  onChange: (arg0: string) => void
  tooltipId?: string
  canvasMode?: boolean
  size?: 'small' | 'big'
  includePoints?: boolean
  hideSelected?: boolean
  clickOutsideDisabledIds?: string[]
}

const SimpleSelect: React.FC<Props> = ({
  id,
  type = 'valueLabel',
  options,
  onChange,
  value,
  label,
  labelIcon = null,
  placeholder,
  iconPosition = 'end',
  className,
  pickerClassName,
  optionClassName,
  dropdownClassName,
  custom,
  position,
  valuePlaceholder,
  optionFormatter = null,
  tooltipId,
  canvasMode = false,
  size = 'big',
  includePoints = false,
  hideSelected = false,
  clickOutsideDisabledIds,
}) => {
  const optionLabel = valuePlaceholder || options.find((opt) => opt.value === value)?.label || placeholder
  const optionIcon = options.find((opt) => opt.value === value)?.icon
  const [selectOpen, setSelectOpen] = useState(false)
  const [parentContainer, setParentContainer] = useState<HTMLDivElement | null>(null)
  const ref = useRef()
  const menuRef = useRef()
  useClickOutsideAlerter(menuRef, () => setSelectOpen(false)) // TODO: clickOutsideHookIds
  useEffect(() => {
    const el = document.createElement('div')
    setParentContainer(el)
    document.body.appendChild(el)
    return () => {
      document.body.removeChild(el)
    }
  }, [])
  const renderTrigger = (onClick?: () => void) => (
    <div
      className={classNames(pickerClassName, styles.selectPicker)}
      onClick={() => {
        if ((options?.length > 1 || !canvasMode) && onClick) onClick()
      }}
    >
      {iconPosition === 'start' && optionIcon ? <span className={styles.selectPickerIcon}>{optionIcon}</span> : null}
      <div className={styles.selectPickerContent} data-text-left={iconPosition === 'start' || custom}>
        {label && <label className={styles.selectLabel}>{label}</label>}
        {optionLabel ? <>{optionFormatter ? optionFormatter(optionLabel, -1) : optionLabel}</> : null}
      </div>
      {options?.length > 1 && (labelIcon || <ArrowIcon data-rotated={selectOpen} />)}
    </div>
  )
  if (custom) {
    // @ts-ignore
    const parentPosition = ref.current?.getBoundingClientRect() || {}
    return (
      <div
        id={id}
        className={classNames(
          className,
          styles.wrapper,
          { [styles.justValue]: type === 'value' },
          { [styles.canvasMode]: canvasMode },
          { [styles.includePoints]: includePoints },
          styles[size],
        )}
        // @ts-ignore
        ref={ref}
      >
        <div
          className={classNames(styles.selectContainer, styles.custom, {
            [styles.active]: selectOpen,
            [styles.top]: position === 'top',
          })}
          data-tooltip-id={tooltipId}
        >
          {!selectOpen && renderTrigger(() => setSelectOpen(!selectOpen))}
        </div>
        {selectOpen &&
          !!parentContainer &&
          createPortal(
            <div
              // @ts-ignore
              ref={menuRef}
              data-select={true}
              className={classNames(
                styles.selectContainer,
                styles.custom,
                {
                  [styles.active]: selectOpen,
                  [styles.top]: position === 'top',
                  [styles.justValue]: type === 'value',
                },
                { [styles.canvasMode]: canvasMode },
                { [styles.includePoints]: includePoints },
                styles[size],
              )}
              style={{
                width: parentPosition.width,
                left: parentPosition.x,
                top: position === 'top' ? undefined : parentPosition.y,
                bottom:
                  position !== 'top'
                    ? undefined
                    : ((window !== undefined ? window : {}).innerHeight || 0) - parentPosition.bottom,
              }}
              data-disable-ids-click-outside-hook={[clickOutsideDisabledIds]}
            >
              {position !== 'top' && renderTrigger(() => setSelectOpen(!selectOpen))}
              {selectOpen && (
                <div className={classNames(styles.menu, { [canvasStyles.noise]: canvasMode }, dropdownClassName)}>
                  {options
                    .filter((option) => option.value !== value || !hideSelected)
                    .map((opt, index) => (
                      <div
                        className={classNames(styles.option, optionClassName)}
                        data-selected={value === opt.value}
                        onClick={() => {
                          onChange(opt.value)
                          setSelectOpen(false)
                        }}
                        data-icon-start={iconPosition === 'start'}
                        key={opt.value}
                        data-tooltip-id={opt.tooltipId}
                      >
                        {iconPosition === 'start' && opt.icon}
                        <div className={styles.selectOptionLabel}>
                          {opt.label ? (optionFormatter ? optionFormatter(opt.value, index) : opt.label) : null}
                        </div>

                        {iconPosition === 'end' && opt.icon}
                      </div>
                    ))}
                </div>
              )}
              {position === 'top' && renderTrigger(() => setSelectOpen(!selectOpen))}
            </div>,
            parentContainer,
          )}
      </div>
    )
  }

  return (
    <div
      className={classNames(
        className,
        styles.wrapper,
        { [styles.justValue]: type === 'value' },
        { [styles.canvasMode]: canvasMode },
        { [styles.includePoints]: includePoints },
        styles[size],
      )}
      data-select={true}
      data-disable-ids-click-outside-hook={[clickOutsideDisabledIds]}
    >
      <div className={styles.selectContainer}>
        <select
          onChange={(e) => onChange(e.target.value)}
          value={value}
          disabled={options?.length < 2}
          className={styles.select}
          onKeyPress={(e) => {
            e.preventDefault()
          }}
        >
          {options
            .filter((option) => option.value !== value || !hideSelected)
            .map((opt, index) => (
              <option value={opt.value} key={opt.value}>
                {opt.label ? <>{optionFormatter ? optionFormatter(opt.label, index) : opt.label}</> : null}
              </option>
            ))}
        </select>
        {renderTrigger()}
      </div>
    </div>
  )
}

export default SimpleSelect
