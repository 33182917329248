import { Midi, MidiJSON, TrackJSON } from '@tonejs/midi'
import { NoteJSON } from '@tonejs/midi/dist/Note'
import axios from 'axios'
import midiFile, { MidiData } from 'midi-file'
import * as Tone from 'tone'

import { getLastIndexOfBit, getTicksByInd, TICKS_PER_BAR } from '../drumsUtils'
import { mapChordsToEvents } from '../playstyles'
import { getChordsFromAllPartsWithLoops } from '../progUtils'
import { GuitarStringValue, Prog, ProgPartPattern } from '../types'
import { getPartDuration, sixteenthsToTicks } from './audioUtils'

export const PLACEHOLDER_MIDI = {
  header: {
    keySignatures: [
      {
        key: 'C',
        scale: 'major',
        ticks: 0,
      },
    ],
    meta: [],
    name: '',
    ppq: 24,
    tempos: [],
    timeSignatures: [],
  },
  tracks: [
    {
      channel: 0,
      controlChanges: {},
      pitchBends: [],
      instrument: {
        family: 'piano',
        number: 0,
        name: 'acoustic grand piano',
      },
      name: 'kicks',
      notes: [
        {
          duration: 0.13274325,
          durationTicks: 120,
          midi: 55,
          name: 'G3',
          ticks: 0,
          time: 0,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.26548650000000007,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 120,
          time: 0.13274325,
          velocity: 0.4015748031496063,
        },
        {
          duration: 0.530973,
          durationTicks: 48,
          midi: 55,
          name: 'G3',
          ticks: 48,
          time: 0.530973,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.26548649999999996,
          durationTicks: 24,
          midi: 64,
          name: 'E4',
          ticks: 720,
          time: 0.7964595000000001,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.26548649999999996,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 960,
          time: 1.061946,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.1327432500000001,
          durationTicks: 120,
          midi: 55,
          name: 'G3',
          ticks: 1320,
          time: 1.46017575,
          velocity: 0.4330708661417323,
        },
        {
          duration: 0.26548649999999996,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 1560,
          time: 1.72566225,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 55,
          name: 'G3',
          ticks: 1920,
          time: 2.123892,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.2654865000000002,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 2040,
          time: 2.25663525,
          velocity: 0.4015748031496063,
        },
        {
          duration: 0.5309730000000004,
          durationTicks: 48,
          midi: 55,
          name: 'G3',
          ticks: 2400,
          time: 2.654865,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.2654865000000002,
          durationTicks: 24,
          midi: 64,
          name: 'E4',
          ticks: 2640,
          time: 2.9203515,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.26548649999999974,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 2880,
          time: 3.1858380000000004,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.1327432500000003,
          durationTicks: 120,
          midi: 55,
          name: 'G3',
          ticks: 3240,
          time: 3.58406775,
          velocity: 0.4330708661417323,
        },
        {
          duration: 0.2654865000000002,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 3480,
          time: 3.84955425,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 55,
          name: 'G3',
          ticks: 3840,
          time: 4.247784,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.26548649999999974,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 3960,
          time: 4.38052725,
          velocity: 0.4015748031496063,
        },
        {
          duration: 0.5309729999999995,
          durationTicks: 48,
          midi: 55,
          name: 'G3',
          ticks: 4320,
          time: 4.778757000000001,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.26548649999999974,
          durationTicks: 24,
          midi: 64,
          name: 'E4',
          ticks: 4560,
          time: 5.0442435,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.2654865000000006,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 4800,
          time: 5.30973,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 55,
          name: 'G3',
          ticks: 5160,
          time: 5.7079597500000006,
          velocity: 0.4330708661417323,
        },
        {
          duration: 0.26548649999999974,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 5400,
          time: 5.97344625,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 55,
          name: 'G3',
          ticks: 5760,
          time: 6.371676000000001,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.26548649999999974,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 5880,
          time: 6.504419250000001,
          velocity: 0.4015748031496063,
        },
        {
          duration: 0.5309730000000004,
          durationTicks: 48,
          midi: 55,
          name: 'G3',
          ticks: 6240,
          time: 6.902649,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.2654865000000006,
          durationTicks: 24,
          midi: 64,
          name: 'E4',
          ticks: 6480,
          time: 7.1681355,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.26548649999999974,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 6720,
          time: 7.433622000000001,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 55,
          name: 'G3',
          ticks: 7080,
          time: 7.83185175,
          velocity: 0.4330708661417323,
        },
        {
          duration: 0.26548649999999974,
          durationTicks: 24,
          midi: 55,
          name: 'G3',
          ticks: 7320,
          time: 8.09733825,
          velocity: 0.8031496062992126,
        },
      ],
      endOfTrackTicks: 7560,
    },
    {
      channel: 0,
      controlChanges: {},
      pitchBends: [],
      instrument: {
        family: 'piano',
        number: 0,
        name: 'acoustic grand piano',
      },
      name: 'snares',
      notes: [],
      endOfTrackTicks: 0,
    },
    {
      channel: 0,
      controlChanges: {},
      pitchBends: [],
      instrument: {
        family: 'piano',
        number: 0,
        name: 'acoustic grand piano',
      },
      name: 'claps',
      notes: [],
      endOfTrackTicks: 0,
    },
    {
      channel: 0,
      controlChanges: {},
      pitchBends: [],
      instrument: {
        family: 'piano',
        number: 0,
        name: 'acoustic grand piano',
      },
      name: 'hihats',
      notes: [
        {
          duration: 0.7964595000000001,
          durationTicks: 720,
          midi: 55,
          name: 'G3',
          ticks: 720,
          time: 0.7964595000000001,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.7964594999999999,
          durationTicks: 720,
          midi: 60,
          name: 'C4',
          ticks: 1680,
          time: 1.8584055000000002,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.7964595000000001,
          durationTicks: 720,
          midi: 55,
          name: 'G3',
          ticks: 2640,
          time: 2.9203515,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.7964595000000005,
          durationTicks: 720,
          midi: 60,
          name: 'C4',
          ticks: 3600,
          time: 3.9822975,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.7964595000000001,
          durationTicks: 720,
          midi: 55,
          name: 'G3',
          ticks: 4560,
          time: 5.0442435,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.7964595000000001,
          durationTicks: 720,
          midi: 60,
          name: 'C4',
          ticks: 5520,
          time: 6.1061895,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.7964595000000001,
          durationTicks: 720,
          midi: 55,
          name: 'G3',
          ticks: 6480,
          time: 7.1681355,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.26548649999999974,
          durationTicks: 24,
          midi: 60,
          name: 'C4',
          ticks: 7440,
          time: 8.2300815,
          velocity: 0.8031496062992126,
        },
      ],
      endOfTrackTicks: 7680,
    },
    {
      channel: 0,
      controlChanges: {},
      pitchBends: [],
      instrument: {
        family: 'piano',
        number: 0,
        name: 'acoustic grand piano',
      },
      name: 'percs',
      notes: [
        {
          duration: 0.13274324999999998,
          durationTicks: 120,
          midi: 62,
          name: 'D4',
          ticks: 48,
          time: 0.530973,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.1327432500000001,
          durationTicks: 120,
          midi: 62,
          name: 'D4',
          ticks: 600,
          time: 0.66371625,
          velocity: 0.6535433070866141,
        },
        {
          duration: 0.1327432500000003,
          durationTicks: 120,
          midi: 62,
          name: 'D4',
          ticks: 2400,
          time: 2.654865,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 62,
          name: 'D4',
          ticks: 2520,
          time: 2.7876082500000003,
          velocity: 0.6535433070866141,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 62,
          name: 'D4',
          ticks: 4320,
          time: 4.778757000000001,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 62,
          name: 'D4',
          ticks: 4440,
          time: 4.9115002500000005,
          velocity: 0.6535433070866141,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 62,
          name: 'D4',
          ticks: 6240,
          time: 6.902649,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.13274324999999987,
          durationTicks: 120,
          midi: 62,
          name: 'D4',
          ticks: 6360,
          time: 7.03539225,
          velocity: 0.6535433070866141,
        },
      ],
      endOfTrackTicks: 6480,
    },
    {
      channel: 0,
      controlChanges: {},
      pitchBends: [],
      instrument: {
        family: 'piano',
        number: 0,
        name: 'acoustic grand piano',
      },
      name: 'openhats',
      notes: [
        {
          duration: 0.530973,
          durationTicks: 48,
          midi: 60,
          name: 'C4',
          ticks: 48,
          time: 0.530973,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.5309729999999999,
          durationTicks: 48,
          midi: 60,
          name: 'C4',
          ticks: 1440,
          time: 1.5929190000000002,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.5309730000000004,
          durationTicks: 48,
          midi: 60,
          name: 'C4',
          ticks: 2400,
          time: 2.654865,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.5309729999999999,
          durationTicks: 48,
          midi: 60,
          name: 'C4',
          ticks: 3360,
          time: 3.7168110000000003,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.5309729999999995,
          durationTicks: 48,
          midi: 60,
          name: 'C4',
          ticks: 4320,
          time: 4.778757000000001,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.5309730000000004,
          durationTicks: 48,
          midi: 60,
          name: 'C4',
          ticks: 5280,
          time: 5.840703,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.5309730000000004,
          durationTicks: 48,
          midi: 60,
          name: 'C4',
          ticks: 6240,
          time: 6.902649,
          velocity: 0.8031496062992126,
        },
        {
          duration: 0.5309730000000004,
          durationTicks: 48,
          midi: 60,
          name: 'C4',
          ticks: 7200,
          time: 7.964595,
          velocity: 0.8031496062992126,
        },
      ],
      endOfTrackTicks: 7680,
    },
  ],
}

const PPQ = 24
const DEFAULT_MIDI_HEADER = { format: 1, numTracks: 3, ticksPerBeat: PPQ }

export const getBarsFromTicks = (ticks: number) => {
  return ticks / PPQ / 4
}

export const getTicksFromBars = (bars: number) => {
  return bars * PPQ * 4
}

export const getBarsFromSixteens = (barBitsSixteens: string) => {
  const [a, b, c] = barBitsSixteens.split(':')
  return +a + +b / 4 + +c / 16
}

export const getTicksFromSixteens = (barBitsSixteens: string) => {
  return getTicksFromBars(getBarsFromSixteens(barBitsSixteens))
}

const defaultMidiTempoTrack = (bpm: number) => {
  return [
    {
      deltaTime: 0,
      meta: true,
      type: 'timeSignature',
      numerator: 4,
      denominator: 4,
      metronome: 24,
      thirtyseconds: 8,
    },
    {
      deltaTime: 0,
      meta: true,
      type: 'setTempo',
      microsecondsPerBeat: bpmToMicrosecondsPerBeat(bpm),
    },
    { deltaTime: 0, meta: true, type: 'endOfTrack' },
  ]
}

const endOfTrackEvent = {
  deltaTime: 0,
  meta: true,
  type: 'endOfTrack',
}

const bpmToMicrosecondsPerBeat = (bpm: number) => {
  return Math.round((60 * 1000000) / bpm)
}

export const drumsMidiBufferHelper = (
  midi: Midi | undefined,
  bpm: number,
  drumsDuration: number,
  duration: number,
  offset: number,
) => {
  if (!midi) {
    return null
  }
  midi.header.setTempo(bpm)
  const drumsDurationTicks = drumsDuration
  const durationTicks = duration

  const offsetTicks = offset
  const endOfTrackTicks = getTicksByInd(getLastIndexOfBit({ midi, name: '' }))
  midi.tracks.forEach((track) => {
    const notes = track.notes
    track.notes = []
    let noteIndex = 0
    let loops = 0
    if (!notes.length) {
      return
    }
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const note = notes[noteIndex]
      const noteOffset = note.ticks
      const noteTicks = endOfTrackTicks * loops + noteOffset
      if (noteTicks >= durationTicks) {
        break
      }
      if (noteTicks <= drumsDurationTicks) {
        track.addNote({
          durationTicks: note.durationTicks,
          velocity: note.velocity,
          noteOffVelocity: note.noteOffVelocity,
          midi: note.midi,
          ticks: noteTicks + offsetTicks,
        })
      }
      noteIndex += 1
      if (noteIndex === notes.length) {
        noteIndex = 0
        loops += 1
      }
    }
    track.endOfTrackTicks = durationTicks
  })
  return midi
}

const chordsToCommands = (timings: any[], channel = 0) => {
  const notes = timings.map((timing) => ({
    ...timing,
    type: 'noteOn',
    channel,
    noteNumber: timing.note,
  }))

  const offNotes = notes.map((note) => ({
    ...note,
    type: 'noteOff',
    time: note.time + note.duration,
  }))

  const commandsRaw = [...notes, ...offNotes].sort((a, b) => a.time - b.time || a.type.localeCompare(b.type))
  const commands = commandsRaw.map((cmd, i) => ({
    ...cmd,
    deltaTime: i ? cmd.time - commandsRaw[i - 1].time : cmd.time,
  }))

  return commands
}

const clearEvents = (event: any[]) => {
  return event.map((e) => {
    if (e.type !== 'noteOn' && e.type !== 'noteOff') return e
    return {
      type: e.type,
      channel: e.channel,
      noteNumber: e.noteNumber,
      deltaTime: e.deltaTime,
      velocity: e.velocity || 100,
    }
  })
}

export const getMidiBuffer = async (prog: Prog, bpm: number) => {
  const events = []

  for (const part of prog.parts) {
    for (let channel = 0; channel < part.chordLayers.length; channel++) {
      const event = []

      const layer = part.chordLayers[channel]
      const playstyle = await axios
        .get(`${process.env.API_URL}/playstyles/${layer.playstyle.id}`)
        .then((res) => res.data)

      event.push({
        deltaTime: 0,
        type: 'trackName',
        text: `${part.name} - ${layer.instrument.key} / ${playstyle.name}`,
      })

      const timings = mapChordsToEvents(part.chords, playstyle, layer, '', true)
      const commands = chordsToCommands(timings, channel)

      event.push(...commands)
      event.push({
        deltaTime: 0,
        type: 'copyrightNotice',
        text: prog.name,
      })
      event.push(endOfTrackEvent)

      events.push(clearEvents(event))
    }
  }

  const data = {
    header: DEFAULT_MIDI_HEADER,
    tracks: [defaultMidiTempoTrack(bpm), ...events],
  } as MidiData

  return Buffer.from(midiFile.writeMidi(data))
}
export const getMidiBufferFromMelody = async (prog: Prog, bpm: number) => {
  const events = []

  for (const part of prog.parts) {
    const event = []

    const { melodyDuration } = getPartDuration(part)
    const melodyDurationInTicks = sixteenthsToTicks(melodyDuration)

    const timings = (part.melody?.notes || [])
      .map((note) => ({
        ...note,
        time: note.startTicks,
        note: note.midiNote,
        duration: Math.min(note.endTicks, melodyDurationInTicks) - note.startTicks,
      }))
      .filter((note) => note.time < melodyDurationInTicks)
    const commands = chordsToCommands(timings)

    event.push({ deltaTime: 0, type: 'trackName', text: prog.name })
    event.push(...commands)
    event.push({
      deltaTime: 0,
      type: 'copyrightNotice',
      text: prog.name,
    })
    event.push(endOfTrackEvent)

    events.push(clearEvents(event))
  }

  const data = {
    header: DEFAULT_MIDI_HEADER,
    tracks: [defaultMidiTempoTrack(bpm), ...events],
  } as MidiData

  return Buffer.from(midiFile.writeMidi(data))
}

export const getMidiBufferFromCanvas = (prog: Prog, bpm: number) => {
  const arpeggioTrack = []
  const chords = getChordsFromAllPartsWithLoops(prog)
  let ticksToNextChord = 0

  chords.forEach((chord) => {
    const notes = chord.midiTimings
      .map((mt, index) =>
        mt.map((item) => ({
          ...item,
          noteNumber: chord.midi[index] + (chord.octave || 0) * 12,
          type: 'noteOn',
          channel: 0,
        })),
      )
      .reduce((acc, item) => [...acc, ...item], [])

    const offNotes = notes.map((note) => ({
      ...note,
      type: 'noteOff',
      midiTiming: note.midiTiming + note.duration,
    }))

    const commands = [...notes, ...offNotes].sort((a, b) => a.midiTiming - b.midiTiming || a.type.localeCompare(b.type))

    commands.forEach((cm, index) => {
      arpeggioTrack.push({
        ...cm,
        deltaTime: index === 0 ? ticksToNextChord : cm.midiTiming - commands[index - 1].midiTiming,
      })
    })

    if (commands.length) {
      ticksToNextChord = getTicksFromBars(chord.duration || 1) - commands[commands.length - 1].midiTiming
    }
  })

  arpeggioTrack.push({
    deltaTime: 0,
    type: 'copyrightNotice',
    text: prog.name,
  })
  arpeggioTrack.push(endOfTrackEvent)

  const data = {
    header: DEFAULT_MIDI_HEADER,
    tracks: [defaultMidiTempoTrack(bpm), arpeggioTrack],
  } as MidiData

  return Buffer.from(midiFile.writeMidi(data))
}

export const getOctaveByGuitarNote = (str: number, fret: GuitarStringValue) => {
  if (fret === 'X') {
    return 0
  }
  if (fret === 'FREE') {
    fret = 0
  }
  const stringOffset = {
    1: { offset: 8, start: 4 },
    2: { offset: 1, start: 3 },
    3: { offset: 5, start: 3 },
    4: { offset: 10, start: 3 },
    5: { offset: 3, start: 2 },
    6: { offset: 8, start: 2 },
  }

  // @ts-ignore
  const config = stringOffset[str]
  if (fret < config.offset) {
    return config.start
  }
  return Math.ceil((fret - config.offset + 1) / 12) + config.start
}

export const patternToMidi = (pattern: { key: string; buffer: string }): MidiJSON => {
  if (!pattern.buffer) return { header: null, tracks: [] } as any

  const midiObj = new Midi(Buffer.from(pattern.buffer, 'base64'))
  midiObj.fromJSON(midiObj.toJSON())

  const tracksOrder = ['percs', 'openhats', 'hihats', 'snares', 'kicks', 'claps']
  midiObj.tracks.sort((a, b) => tracksOrder.indexOf(a.name) - tracksOrder.indexOf(b.name))

  return midiObj
}

export const drumsToMidi = (drums: ProgPartPattern, excludeMuted = false): MidiJSON => {
  const midi: any = {
    header: {},
    tracks: [],
  }
  if (!drums) return midi

  drums.groups.map((group) => {
    const groupMidi = patternToMidi(group.pattern)
    const { header, tracks } = groupMidi

    if (group.percTypes.length !== tracks.length) {
      group.percTypes.forEach(({ type }, index) => {
        const existingTrack = tracks.find((t) => t.name === type)
        if (existingTrack) return

        tracks.push({
          channel: index,
          controlChanges: {},
          pitchBends: [],
          instrument: {
            family: 'piano',
            number: 0,
            name: 'acoustic grand piano',
          },
          name: type,
          notes: [],
          endOfTrackTicks: 0,
        })
      })
    }

    midi.header = header || midi.header
    midi.tracks.push(
      ...tracks.map((track) => {
        const isActive = group.percTypes.find((pT) => pT.type === track.name)?.active

        if (!isActive && excludeMuted) {
          return { ...track, notes: track.notes.map((n) => ({ ...n, velocity: 0 })) }
        }
        return track
      }),
    )
  })

  return midi
}

export const drumsToMidiWithTempo = (drums: ProgPartPattern, excludeMuted = false): MidiJSON => {
  const midi = drumsToMidi(drums, excludeMuted)

  // TODO: remove once Tempo is needed once more
  return midi

  return {
    ...midi,
    tracks: midi.tracks.map((track) => {
      const halfTime = (track.endOfTrackTicks || 0) / 2
      let newNotes = track.notes

      if (drums?.tempo === -1) {
        newNotes = newNotes
          .filter((note) => note.ticks < halfTime)
          .map((note) => ({
            ...note,
            duration: note.duration * 2,
            durationTicks: note.durationTicks * 2,
            ticks: note.ticks * 2,
            time: note.time * 2,
          }))
      }

      if (drums?.tempo === 1) {
        newNotes = newNotes.flatMap((note) => [
          {
            ...note,
            duration: note.duration / 2,
            durationTicks: note.durationTicks / 2,
            ticks: note.ticks / 2,
            time: note.time / 2,
          },
          {
            ...note,
            duration: note.duration / 2 + halfTime,
            durationTicks: note.durationTicks / 2 + halfTime,
            ticks: note.ticks / 2 + halfTime,
            time: note.time / 2 + halfTime,
          },
        ])
      }

      return { ...track, notes: newNotes }
    }),
  }
}

export const getDrumsMidiBuffer = (midi: MidiJSON) => {
  const uint8ArrayToBase64 = (uint8Array: any) => {
    let binary = ''
    const len = uint8Array.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(uint8Array[i])
    }
    return btoa(binary)
  }
  const getNoteEvents = (track: TrackJSON) => {
    let prevTicks = 0

    const trackNotes = JSON.parse(JSON.stringify(track.notes)) as NoteJSON[]
    trackNotes.sort((a, b) => a.ticks - b.ticks)

    const midiTrackEvents = trackNotes.flatMap((note) => {
      const common = {
        channel: track.channel,
        noteNumber: Tone.Frequency(note.name).toMidi() + 12,
        velocity: note.velocity * 127 || 100,
      }
      const deltaTime = note.ticks - prevTicks

      if (deltaTime < 0) return []

      const noteOnEvent = {
        ...common,
        type: 'noteOn',
        deltaTime,
      }

      const noteOffEvent = {
        ...common,
        type: 'noteOff',
        deltaTime: note.durationTicks,
      }

      prevTicks = note.ticks + note.durationTicks

      return [noteOnEvent, noteOffEvent]
    })

    return midiTrackEvents
  }

  const events = midi.tracks.map((track) =>
    clearEvents([
      {
        deltaTime: 0,
        type: 'trackName',
        text: track.name,
      },
      ...getNoteEvents(track),
      {
        deltaTime: 0,
        meta: true,
        type: 'endOfTrack',
      },
    ]),
  )

  const data = {
    header: DEFAULT_MIDI_HEADER,
    tracks: events,
  } as MidiData

  const buffer = Buffer.from(midiFile.writeMidi(data))

  return uint8ArrayToBase64(buffer)
}

export const getDrumsNote = (ticks: number) => ({
  ticks: ticks,
  velocity: 0.8,
  durationTicks: TICKS_PER_BAR / 4,
  midi: 60,
  time: 0,
  name: 'C4',
  duration: 0.5,
})
