import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useCurrentUser } from '../../../../context/CurrentUserContext'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import { formatNumber } from '../../../../utils/stringUtils'
import styles from './LimitProgress.module.scss'

type Props = {
  title: string
  limit: number
  current: number
  generatorComponent?: boolean
  isRecurrent?: boolean
}

const LimitProgress: React.FC<Props> = ({ title, limit, current, generatorComponent, isRecurrent }) => {
  const { getText } = useInternationalization()
  const { currentUser } = useCurrentUser()
  const isFree = !currentUser || currentUser?.subscriptionPlan === 'free'
  const isFull = limit !== -1 && limit <= current && !(limit === 0 && !isFree)
  const STRINGS = getText('LimitProgress')
  const [init, setInit] = useState(false)
  useEffect(() => setInit(true), [])
  return (
    <div
      className={classNames(styles.container, {
        [styles.generator]: generatorComponent,
      })}
    >
      <div className={styles.text}>
        <p>{title}</p>
        {limit === -1 ? (
          <span data-full={isFull}>
            {formatNumber(current)} / <b>{STRINGS.unlimited}</b>
          </span>
        ) : limit === 0 ? (
          <span data-full={isFull}>{isFree ? STRINGS.unavailable : STRINGS.available}</span>
        ) : (
          <span data-full={isFull}>
            {formatNumber(current)} / {formatNumber(limit)}{' '}
            {isRecurrent ? <span className={styles.verySmall}>{isFree ? STRINGS.perDay : STRINGS.perMonth}</span> : ''}
          </span>
        )}
      </div>
      <div className={styles.slider}>
        <div
          data-full={isFull}
          style={{
            width: !init ? 0 : `${limit === -1 || limit === 0 ? 100 : (current / limit) * 100}%`,
          }}
        />
      </div>
    </div>
  )
}

export default LimitProgress
