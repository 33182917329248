import { getApiChordProg, getApiMelody } from '../utils/api'
import { Chord, GenerationsTrackConfig, Prog } from '../utils/types'
import api from './index'

export type progressionConfig = {
  genre?: string | string[] | null
  tonalityKey?: string | string[] | null
  tonalityScale?: string | string[] | null
  size?: string | string[] | null
  length?: string | string[] | null
  prog?: Prog
  currentPartId?: number
  lockedIndexes?: string[]
  generateDrums?: boolean
  options?: GenerationsTrackConfig
}

export type TRagConfig = {
  chords: Chord[]
  chordLayers: { instrument: string; playstyle: string; volume: number }[]
  drums: {
    tempo: number
    groups: {
      instruments: { type: string; active: boolean }[]
      pattern: { key: string; buffer: string }
    }[]
  }
  drumLayers: { instrument: string; volume: number }[]
  melody: {
    tempo: number
    notes: any[]
  }
  melodyLayers: { instrument: string; volume: number }[]
  generatorSettings: {
    chordGenreKey: string
    drumGenreKey: string
  }
  bpm: number
  key: string
  scale: 'minoj' | 'major'
  error?: string
}

// PROG

export const generateProgressionCanvas = ({ genre, tonalityKey, tonalityScale }: progressionConfig) => {
  const data: any = { extension: genre, tonalityKey, tonalityScale }
  return api.get(`${process.env.CANVAS_API_URL}/canvas/prog`, {
    params: data,
  })
}
export const generateProgressionInnerRoute = async ({
  genre,
  tonalityKey,
  tonalityScale,
  size,
  length,
  generateDrums,
  currentPartId,
  prog,
}: progressionConfig) => {
  // const chords = getActivePart(prog as Prog, currentPartId || 0)?.chords || []
  const melody = getApiMelody(prog as Prog, currentPartId)

  const lockedPositions = [] as any
  // chords.map((ch: Chord, index: number) => ({
  //   index,
  //   locked: ch.locked,
  //   position: chords.slice(0, index).reduce((acc: number, ch: Chord) => acc + (ch.duration || 0), 0),
  // }))

  const data = await api.post('/api/generate', {
    genre,
    tonalityKey,
    tonalityScale,
    size,
    length,
    melody,
    generateDrums: generateDrums || '',
  })
  const newProg = data.data.data

  return {
    drumsData: data.data.drumsData,
    data: {
      ...newProg,
      chords: newProg.chords.map((ch: Chord, index: number) => {
        const chordPosition = newProg.chords
          .slice(0, index)
          .reduce((acc: number, ch: Chord) => acc + (ch.duration || 0), 0)
        return {
          ...ch,
          locked: lockedPositions.find((l: any) => l.position === chordPosition)?.locked,
        }
      }),
    },
  }
}

//

export const generateFirstChordOfProgressionInnerRoute =
  ({ genre, tonalityKey, tonalityScale }: progressionConfig) =>
  async () => {
    const data = await api.get('/api/generate/first-chord', {
      params: { genre, tonalityKey, tonalityScale, size: 1 },
    })
    return data.data
  }

// TRANSPOSE

export const transposeProgression = ({
  prog,
  melody,
  tonalityKey,
  tonalityScale,
}: {
  prog: string
  melody: string
  tonalityKey: string
  tonalityScale: string
}) => {
  return api.post(`${process.env.API_URL}/chord-track/prog/transpose`, {
    tonalityKey,
    tonalityScale,
    prog,
    melody,
  })
}
export const transposeProgressionCanvas = ({
  prog,
  tonalityKey,
}: {
  prog?: string | string[]
  tonalityKey?: string | string[]
}) => {
  return api.post(`${process.env.CANVAS_API_URL}/canvas/prog/transpose`, {
    tonalityKey,
    prog,
  })
}
export const transposeProgressionInnerRoute = async ({
  prog,
  tonalityKey,
  tonalityScale,
}: {
  prog: Prog
  tonalityKey: string
  tonalityScale: string
}): Promise<Prog> => {
  const data = await api.post('/api/transpose', {
    prog: getApiChordProg(prog),
    tonalityKey,
    tonalityScale,
    melody: getApiMelody(prog),
  })
  const newProg = data.data.prog
  const newMelody = data.data.melody

  let chIndex = 0
  return {
    ...prog,
    key: newProg.key,
    scale: newProg.scale,
    parts: prog.parts.map((part) => ({
      ...part,
      chords: part.chords.map((ch) => {
        return {
          ...newProg.chords[chIndex++],
          id: ch.id,
        }
      }),
      melody: {
        ...(part.melody as any),
        notes: newMelody.filter((note: any) => note.id === part.id),
      },
    })),
  }
}

// RAG

export const checkRagLimitsInnerRoute = async () => {
  await api.post('/api/generate/rag/limits')
}
export const generateRagProgressionInnerRoute = async (query: string) => {
  const data = await api.post('/api/generate/rag', { query })

  return data.data as TRagConfig
}
