import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import ReactSlider from 'react-slider'

import VolumeMutedIcon from '../../../assets/icons/volume-muted.svg'
import VolumeIcon from '../../../assets/icons/volume.svg'
import styles from './NewVolumeControls.module.scss'

type Props = {
  mode?: 'small' | 'thin' | 'bpm' | 'thin-enlarged'
  color?: 'white' | 'blue' | 'purple' | 'green' | 'blue-marketing' | 'yellow' | 'gray' | 'pink' | 'cyan' | 'lagoon'
  volume: number
  onVolumeChange: (newValue: number, delta: number) => void
  onAfterVolumeChange?: (newValue: number) => void
  className?: string
  hide?: boolean
  muted?: boolean
  setMuted?: (a: boolean) => void
  hideControls?: boolean
  minValue?: number
  maxValue?: number
  disabled?: boolean
}

const NewVolumeControls: React.FC<Props> = ({
  color,
  volume: defaultVolume,
  onVolumeChange,
  onAfterVolumeChange,
  className,
  hide,
  muted,
  setMuted = () => {},
  hideControls,
  minValue,
  maxValue,
  mode,
  disabled,
}) => {
  const initialVolumeRef = useRef<number>(0)

  const [volume, setVolume] = useState(defaultVolume)

  useEffect(() => {
    setVolume(defaultVolume)
  }, [defaultVolume])

  return (
    <div
      className={classNames(styles.sliderWithText, className || '', {
        [styles.hidden]: hide,
        [styles.hideControls]: hideControls,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={classNames(
          styles.sliderContainer,
          styles[color || ''],
          { [styles.small]: mode === 'small' },
          { [styles.thin]: mode === 'thin' || mode === 'thin-enlarged' },
          { [styles.thinEnlarged]: mode === 'thin-enlarged' },
          { [styles.bpm]: mode === 'bpm' },
          { [styles.disabled]: muted },
        )}
      >
        <ReactSlider
          disabled={disabled}
          min={minValue || 0}
          max={maxValue || 100}
          value={muted ? 0 : volume}
          defaultValue={volume}
          onBeforeChange={(value) => {
            initialVolumeRef.current = value
          }}
          onChange={(value) => {
            setVolume(value)
            onVolumeChange(value, value - initialVolumeRef.current)
            setMuted(false)

            initialVolumeRef.current = value
          }}
          onAfterChange={onAfterVolumeChange}
          className={classNames(styles.slider)}
          thumbClassName={classNames(styles.thumb)}
          trackClassName={classNames(styles.track)}
          ariaLabel='Volume control'
        />
      </div>

      <div onClick={() => setMuted(!muted)} className={styles.muteButton}>
        {muted ? <VolumeMutedIcon /> : <VolumeIcon />}
      </div>
    </div>
  )
}

NewVolumeControls.defaultProps = {
  color: 'white',
}

export default NewVolumeControls
