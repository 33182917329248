import { HistoryState } from '../types'

const checkStateSetsEquality = (setA: Set<any>, setB: Set<any>): boolean => {
  if (setA.size !== setB.size) return false
  for (const item of Array.from(setA)) {
    if (!setB.has(item)) return false
  }
  return true
}

const checkHistoryStateEquality = (stateA: HistoryState, stateB: HistoryState) => {
  if (stateA.pianoRollNotes.length !== stateB.pianoRollNotes.length) {
    return false
  }

  for (let i = 0; i < stateA.pianoRollNotes.length; i++) {
    if (
      !stateB.pianoRollNotes[i] ||
      JSON.stringify(stateA.pianoRollNotes[i]) !== JSON.stringify(stateB.pianoRollNotes[i])
    ) {
      return false
    }
  }

  // Check if the state sets are equal
  if (!checkStateSetsEquality(stateA.selectedNoteIds, stateB.selectedNoteIds)) {
    return false
  }

  return true
}

export class HistoryManager {
  history: HistoryState[] = []
  currentIndex = -1
  limit: number

  constructor(limit = 1000) {
    this.limit = limit
  }

  // Push a new state to the history
  push = (state: HistoryState) => {
    if (this.currentIndex >= 0 && checkHistoryStateEquality(this.history[this.currentIndex], state)) {
      return // Skip adding the state
    }
    // If we are not at the latest state, discard all future states
    this.history = this.history.slice(0, this.currentIndex + 1)

    // Add the new state
    this.history.push(state)

    // Trim history if it exceeds the limit
    if (this.history.length > this.limit) {
      this.history.shift()
    } else {
      this.currentIndex += 1
    }
  }

  // Undo the last action
  undo = (): HistoryState | null => {
    let state: HistoryState | null = null
    if (this.currentIndex > 0) {
      this.currentIndex -= 1
      state = this.history[this.currentIndex]
    }
    return state
  }

  // Redo the last undone action
  redo = () => {
    if (this.currentIndex < this.history.length - 1) {
      this.currentIndex += 1
      return this.history[this.currentIndex]
    }
  }

  // Check if undo is possible
  canUndo = (): boolean => {
    return this.currentIndex > -1
  }

  // Check if redo is possible
  canRedo = (): boolean => {
    return this.currentIndex < this.history.length - 1
  }
}
