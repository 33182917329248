import * as Tone from 'tone'

import {
  barsBeatsSixteenthsToBars,
  normalizeVolume,
  tileLengthToNoteDuration,
} from '../../../../utils/audio/audioUtils'
import { S3_URL } from '../../../../utils/constants'
import { SingleInstrumentKey, defaultInstrument, defaultLayer } from '../../../../utils/instrumentsUtils'
import {
  getChordsFromAllParts,
  getMaxPartsAvailable,
  getProgLengthInBars,
  getSingleLayerFromProg,
} from '../../../../utils/progUtils'
import { InstrumentLayer, Prog } from '../../../../utils/types'
import ChordPlayer from './ChordPlayer'
import DrumsPlayer from './DrumsPlayer'
import { TInstrumentData } from './Layers'
import MelodyPlayer from './MelodyPlayer'
import MetronomePlayer from './MetronomePlayer'
import { MidiOutputPlayer } from './PlayerCommon'

const PPQ = 24
const SAMPLE_RATE = 44100

class Player extends MidiOutputPlayer {
  chordPlayer = new ChordPlayer(this.getMidiOutput, 100) // 90
  melodyPlayer = new MelodyPlayer(this.getMidiOutput, 100) // 100
  drumsPlayer = new DrumsPlayer(this.getMidiOutput, 100) // 80
  metronomePlayer = new MetronomePlayer()
  previewPlayer: Tone.Player | null = null

  volume: Tone.Volume | null = null
  onRepeat = false

  constructor(
    public onPlaying: (a: boolean) => void,
    public handleChordChange?: (a: number | null, prog?: Prog | null) => void,
    public handleMelodyChange?: (a: number | null, prog?: Prog | null) => void,
    public handleDrumChange?: (partId: number | null, prog?: Prog | null) => void,
    public setWebMidiPlayingFunc?: (a: number, add?: boolean) => void,
    public onPreviewPlaying: (a: boolean) => void = () => {},
  ) {
    super()

    if (setWebMidiPlayingFunc) this.setWebMidiPlaying = setWebMidiPlayingFunc

    if (handleChordChange) this.chordPlayer.handleChange = handleChordChange
    if (handleMelodyChange) this.melodyPlayer.handleChange = handleMelodyChange
    if (handleDrumChange) this.drumsPlayer.handleChange = handleDrumChange

    this.onPlaying = (isPlaying) => {
      this.chordPlayer.isPlaying = isPlaying
      this.melodyPlayer.isPlaying = isPlaying
      this.drumsPlayer.isPlaying = isPlaying

      onPlaying(isPlaying)
    }
    this.onPreviewPlaying = onPreviewPlaying
  }

  // SERVE FUNCTIONS

  getTransportPosition = (offset?: number) => {
    return barsBeatsSixteenthsToBars((Tone.Transport.position || '').toString()) - (offset || 0)
  }
  getTransportPositionTicks = (ppqResolution: number = PPQ) => {
    return (Tone.Transport.ticks / Tone.Transport.PPQ) * ppqResolution
  }
  getTransportPositionBars = (ppqResolution: number = PPQ) => {
    return ((Tone.Transport.ticks / Tone.Transport.PPQ) * ppqResolution) / (ppqResolution * 4)
  }
  getActionsOnSetupProg = () => {
    return {
      onStop: () => {
        if (!this.onRepeat) {
          this.chordPlayer.handleChange(null)
          this.stopTransport()
          this.onPlaying(false)
        }
      },
      onLoad: (duration: string, initMetronome = true) => {
        if (initMetronome) {
          this.metronomePlayer.initMetronomePart(duration)
        } else {
          this.metronomePlayer.updatePart(duration)
        }
        Tone.Transport.loop = this.onRepeat
        Tone.Transport.loopStart = '0:0:0'
        Tone.Transport.loopEnd = duration
      },
    }
  }
  getTestInstrument = (() => {
    let player: Tone.Player | null = null
    return {
      start: async (instrument: string, isDrums: boolean, onPlay: () => void): Promise<void> => {
        return new Promise(async (resolve) => {
          if (player) {
            player.stop()
          }
          setTimeout(
            () => {
              let instrumentDemoMp3 = `${S3_URL}/${instrument}preview.mp3`
              if (isDrums) {
                instrumentDemoMp3 = `${S3_URL}${instrument}`
              }
              player = new Tone.Player(instrumentDemoMp3, () => onPlay())
              player.connect(this.volume?.toDestination() as Tone.Volume)
              player.autostart = true
              player.onstop = () => {
                resolve()
                player?.dispose()
                player = null
              }
              // need to wait if previous player was playing
            },
            player ? 150 : 0,
          )
        })
      },
      stop: () => {
        if (!player) {
          return
        }
        player.stop()
      },
    }
  })()
  clearProg = (progRaw: Prog | null | undefined, layerKey?: string): Prog | null => {
    if (!progRaw) return progRaw || null

    const maxPartsAvailable = getMaxPartsAvailable(navigator)
    const progRestrictedByParts = { ...progRaw, parts: progRaw.parts.slice(0, maxPartsAvailable) }
    const progWithNeededLayers = getSingleLayerFromProg(progRestrictedByParts, layerKey)

    return progWithNeededLayers
  }

  // MAIN FUNCTIONS

  // SETUP INSTRUMENTS
  init = (volume: number, prog: Prog) => {
    this.volume = new Tone.Volume()
    this.volume.volume.value = normalizeVolume(volume)

    this.chordPlayer.init(prog, this.volume)
    this.melodyPlayer.init(prog, this.volume)
    this.drumsPlayer.init(prog, this.volume)
    this.metronomePlayer.init(this.volume)

    this.initWebMidi(this.playMidiAttack, this.playMidiRelease)
  }
  setupChordSynth = async (
    prog: Prog | null,
    chordLayers: InstrumentLayer[],
    instrumentMidiOut: { [key: string]: string },
    chordInstrumentsData: TInstrumentData[] = [],
  ) => {
    this.chordPlayer.instrumentsData = chordInstrumentsData

    this.outputActive = !!Object.values(instrumentMidiOut).length
    const shouldResetup = await this.chordPlayer.setupSynth(this.clearProg(prog), chordLayers, instrumentMidiOut)

    return shouldResetup
  }
  setupChordSynthSingle = async (
    prog: Prog | null,
    instrumentPath: string,
    instrumentMidiOut: { [key: string]: string },
    chordInstrumentsData: TInstrumentData[] = [],
  ) => {
    this.chordPlayer.instrumentsData = chordInstrumentsData

    const shouldResetup = await this.chordPlayer.setupSynth(
      this.clearProg(prog),
      [{ ...defaultLayer, instrument: { ...defaultInstrument, key: SingleInstrumentKey, path: instrumentPath } }],
      instrumentMidiOut,
    )

    return shouldResetup
  }
  setupMelodySynth = async (
    prog: Prog | null,
    melodyLayers: InstrumentLayer[],
    melodyInstrumentsData: TInstrumentData[] = [],
  ) => {
    this.melodyPlayer.instrumentsData = melodyInstrumentsData

    const shouldResetup = await this.melodyPlayer.setupSynth(this.clearProg(prog), melodyLayers)

    return shouldResetup
  }
  setupDrumsSynth = async (prog: Prog | null, drumLayers: InstrumentLayer[]) => {
    const shouldResetup = await this.drumsPlayer.setupSynth(this.clearProg(prog), drumLayers)

    return shouldResetup
  }

  // SETUP PLAYBACK
  setupProgPlayback = (prog?: Prog | null, loopedPart?: number, mutedDrumTracks?: string[]) => {
    const clearProg = this.clearProg(prog)
    if (!clearProg) return

    const { onLoad, onStop } = this.getActionsOnSetupProg()
    this.chordPlayer.setupProgPlayback(clearProg, onStop, onLoad, loopedPart)
    this.melodyPlayer.setupProgPlayback(clearProg, loopedPart)
    this.drumsPlayer?.setupProgPlayback(clearProg, loopedPart, mutedDrumTracks)
  }
  exportBuffer = async (prog: Prog, bpm: number, loops: number, layerKey?: string) => {
    const clearProg = this.clearProg(prog, layerKey) as Prog

    return new Promise<Tone.ToneAudioBuffer>((resolve) => {
      const duration = tileLengthToNoteDuration(getProgLengthInBars(clearProg))

      const { onFinish: onChordFinish, onPlay: onChordPlay } = this.chordPlayer.prepareExportPlay(
        clearProg,
        loops,
        layerKey,
      )
      const { onFinish: onMelodyFinish, onPlay: onMelodyPlay } = this.melodyPlayer.prepareExportPlay(
        clearProg,
        loops,
        layerKey,
      )
      const { onFinish: onDrumsFinish, onPlay: onDrumsPlay } = this.drumsPlayer.prepareExportPlay(clearProg, layerKey)

      Tone.Offline(
        async ({ transport }) => {
          transport.PPQ = PPQ
          transport.bpm.value = bpm

          onChordPlay()
          onMelodyPlay()
          onDrumsPlay && onDrumsPlay()

          transport.start()
        },
        // @ts-ignore
        new Tone.Time(new Tone.Time(duration).toSeconds() * loops, 's'),
        2,
        SAMPLE_RATE,
      ).then((buffer) => {
        onChordFinish()
        onMelodyFinish()
        onDrumsFinish && onDrumsFinish()
        resolve(buffer)
      })
    })
  }
  updateChordPart = async (prog: Prog, loopedPart?: number) => {
    const clearProg = this.clearProg(prog)
    if (!clearProg) return

    const { onLoad, onStop } = this.getActionsOnSetupProg()

    if (!this.chordPlayer.part || (this.chordPlayer.part.length === 1 && getChordsFromAllParts(clearProg).length)) {
      this.chordPlayer.setupProgPlayback(clearProg, onStop, onLoad, loopedPart)
    } else {
      await this.chordPlayer.updatePart(clearProg, onLoad, loopedPart)
    }
  }
  updateMelodyPart = async (prog: Prog, loopedPart?: number) => {
    const clearProg = this.clearProg(prog)
    if (!clearProg) return

    if (!this.melodyPlayer.part) {
      this.melodyPlayer?.setupProgPlayback(clearProg, loopedPart)
    } else {
      await this.melodyPlayer.updatePart(clearProg, loopedPart)
    }
  }
  updateDrumPart = async (prog: Prog, loopedPart?: number) => {
    const clearProg = this.clearProg(prog)
    if (!clearProg) return

    if (!this.drumsPlayer.part) {
      this.drumsPlayer?.setupProgPlayback(clearProg, loopedPart)
    } else {
      await this.drumsPlayer.updatePart(clearProg, loopedPart)
    }
  }
  updatePart = async (prog: Prog, loopedPart?: number) => {
    await this.updateChordPart(prog, loopedPart)
    await this.updateMelodyPart(prog, loopedPart)
    await this.updateDrumPart(prog, loopedPart)
  }

  // SETTERS
  setBpm = (bpm: number) => {
    Tone.Transport.bpm.value = bpm
  }
  setVolume = (volume: number) => {
    if (!this.volume) {
      return
    }
    this.volume.volume.value = normalizeVolume(volume)
  }
  setChordPartVolume = this.chordPlayer.setPartVolume
  setChordLayerVolume = this.chordPlayer.setLayerVolume
  setMelodyPartVolume = this.melodyPlayer.setPartVolume
  setMelodyLayerVolume = this.melodyPlayer.setLayerVolume
  setDrumsPartVolume = this.drumsPlayer.setPartVolume
  setDrumLayerVolume = this.drumsPlayer.setLayerVolume
  enableMetronome = (enabled: boolean) => {
    this.metronomePlayer.enableMetronome(enabled)
  }

  // PLAYBACK CONTROLLERS
  play = () => {
    this.metronomePlayer.play()
    this.chordPlayer.play()
    this.melodyPlayer.play()
    this.drumsPlayer?.play()
    Tone.Transport.start()
    this.onPlaying(true)
    if (this.midiOutput) {
      this.midiOutput.executeOutputs((output) => output.send(new Uint8Array([0xfa])))
    }

    this.chordPlayer.playChordFromAnyPosition(PPQ)
  }
  pause = () => {
    Tone.Transport.pause()
    this.onPlaying(false)
    if (this.midiOutput) {
      this.midiOutput.executeOutputs((output) => output.send(new Uint8Array([0xfc])))
    }
  }
  reset = () => {
    Tone.Transport.stop()
    this.chordPlayer.handleChange(null)
    this.chordPlayer.reset()

    this.melodyPlayer.reset()

    this.drumsPlayer?.reset()

    this.metronomePlayer.reset()

    this.onPlaying(false)
  }
  repeat = (onRepeat: boolean) => {
    Tone.Transport.loop = onRepeat
    this.onRepeat = onRepeat
  }
  setTime = (time: number | string) => {
    if (Tone.Transport.state !== 'started') {
      Tone.Transport.start()
      Tone.Transport.pause()
      this.metronomePlayer.play()
      this.chordPlayer.play()
      this.melodyPlayer.play()
      this.drumsPlayer?.play()
    }

    const newPositionSixteenths = typeof time === 'string' ? time : tileLengthToNoteDuration(time)

    Tone.Transport.position = newPositionSixteenths

    return () => this.chordPlayer.playChordFromAnyPosition(PPQ, newPositionSixteenths)
  }
  stopTransport = () => {
    if (Tone.Transport.state === 'started') {
      Tone.Transport.stop()
    }
  }
  dispose = () => {
    this.chordPlayer.dispose()
    this.melodyPlayer.dispose()
    this.metronomePlayer.dispose()
    this.drumsPlayer?.dispose()
    this.stopTransport()
    this.onPlaying(false)
  }

  // PLAYING
  playChord = this.chordPlayer.playChord
  playChords = this.chordPlayer.playChords
  playMidi = this.chordPlayer.playMidi
  playMidiAttack = this.chordPlayer.playMidiAttack
  playMidiRelease = this.chordPlayer.playMidiRelease
  playPreview = async (prog: Prog, bpm: number, layerKey?: string) => {
    const onStop = () => this.onPreviewPlaying(false)

    const buffer = await this.exportBuffer(this.clearProg(prog) as Prog, bpm, 1, layerKey)

    this.previewPlayer = new Tone.Player(buffer).toDestination()
    this.previewPlayer.onstop = onStop

    if (this.previewPlayer.loaded) {
      this.previewPlayer.start()
    } else {
      this.previewPlayer.autostart = true
    }

    this.onPreviewPlaying(true)
  }
  pausePreview = () => {
    this.previewPlayer?.stop()
  }
  playMelodyNote = this.melodyPlayer.playNote
}

export default Player
