// utils/zoomHandler.ts
import { InteractivePianoRollContextType, PianoRollPlayheadContextType } from '../types'
import { zoomLevelToZoomFactor, ticksToWidth, zoomLevelToPpqSnap } from '../utils/pianoRollUtils'
import { getRelativeMousePositionInDiv, getRelativeScrollCenterPosition } from '../utils/positionHelper'
import { handleScrollX } from './scrollHandler'

let isAnimating = false

export const handleZoomInButton = (
  context: InteractivePianoRollContextType,
  playheadContext: PianoRollPlayheadContextType,
  refs: any,
) => {
  const zoomBounded = Math.max(
    context.scaleBounds.min,
    Math.min(context.scaleBounds.max, context.zoomLevelRef.current * context.zoomControlsFactor),
  )
  handleZoom(context, playheadContext, zoomBounded, getRelativeScrollCenterPosition(refs.horizontalScrollRef), refs)
}

export const handleZoomOutButton = (
  context: InteractivePianoRollContextType,
  playheadContext: PianoRollPlayheadContextType,
  refs: any,
) => {
  const zoomBounded = Math.max(
    context.scaleBounds.min,
    Math.min(context.scaleBounds.max, context.zoomLevelRef.current / context.zoomControlsFactor),
  )
  handleZoom(context, playheadContext, zoomBounded, getRelativeScrollCenterPosition(refs.horizontalScrollRef), refs)
}

export const handleZoom = (
  context: InteractivePianoRollContextType,
  playheadContext: PianoRollPlayheadContextType,
  zoomLevel: number,
  mouseX: number,
  refs: any,
) => {
  if (!isAnimating) {
    isAnimating = true
    requestAnimationFrame(() => {
      updateDOM(context, playheadContext, zoomLevel, mouseX, refs)
      isAnimating = false
    })
  }
}

const updateDOM = (
  context: InteractivePianoRollContextType,
  playheadContext: PianoRollPlayheadContextType,
  zoomLevel: number,
  mouseX: number,
  refs: any,
) => {
  const {
    pianoRollNotes,
    ghostNotes,
    smartScaleNotes,
    autocompleteNotes,
    tempNotes,
    ppq,
    baseGridWidth,
    pianoRollWidth,
    zoomLevelRef,
    renderStripesDict,
    ppqSnapRef,
    ppqSnapStages,
    inactiveZoneStartTicks,
    rulerLabels,
  } = context
  const { playheadPositionTicks } = playheadContext

  const {
    horizontalScrollRef,
    scrollBarXRef,
    pianoRollNotesRef,
    gridPatternRef,
    pianoRollRowsRef,
    svgGridRef,
    svgRulerGridRef,
    rulerGridPatternRef,
    rulerNumbersRef,
    rulerLabelsRef,
    playheadRef,
    inactiveZoneRef,
    rulerInnerContainerRef,
    containerRef,
  } = refs

  if (!horizontalScrollRef.current) return

  const prevZoomLevel = zoomLevelRef.current
  zoomLevelRef.current = zoomLevel
  ppqSnapRef.current = zoomLevelToPpqSnap(zoomLevel, ppqSnapStages)

  const prevZoomFactor = zoomLevelToZoomFactor(prevZoomLevel)
  const newZoomFactor = zoomLevelToZoomFactor(zoomLevel)

  const cursorPosRelativeToContent = getRelativeMousePositionInDiv(mouseX, 0, containerRef).x

  const prevDistanceFromLeft = cursorPosRelativeToContent
  const newDistanceFromLeft = (prevDistanceFromLeft / prevZoomFactor) * newZoomFactor
  const deltaToMove = newDistanceFromLeft - prevDistanceFromLeft

  if (gridPatternRef.current && rulerGridPatternRef.current) {
    const patternWidth = ((4 * baseGridWidth * newZoomFactor) / 6) * ppqSnapRef.current
    gridPatternRef.current.setAttribute('width', `${patternWidth}`)
    rulerGridPatternRef.current.setAttribute('width', `${patternWidth}`)

    // Update the x positions of the grid pattern lines
    const rects = gridPatternRef.current.querySelectorAll('rect')
    rects.forEach((rect: any, index: number) => {
      const xPosition = ((index * 25) / 100) * patternWidth
      rect.setAttribute('x', `${xPosition}`)
    })
  }

  if (pianoRollRowsRef.current && svgGridRef.current && svgRulerGridRef.current) {
    const newWidth = `${pianoRollWidth * newZoomFactor}px`
    pianoRollRowsRef.current.style.width = newWidth
    svgGridRef.current.style.width = newWidth
    svgRulerGridRef.current.style.width = newWidth
    for (let i = 0; i < rulerNumbersRef.current.children.length; i++) {
      // change the visibility of the ruler numbers depending on the zoom level
      if (ppqSnapRef.current === ppqSnapStages[0].snap && i % 4 !== 0) {
        rulerNumbersRef.current.children[i].style.visibility = 'hidden'
      } else {
        rulerNumbersRef.current.children[i].style.visibility = 'visible'
      }
      const child = rulerNumbersRef.current.children[i] as HTMLElement
      child.style.left = `${i * (baseGridWidth * 4 * newZoomFactor)}px`
    }
  }

  if (rulerLabelsRef.current && rulerLabels.length === rulerLabelsRef.current.children.length) {
    const labels = rulerLabelsRef.current.children
    for (let i = 0; i < labels.length; i++) {
      const labelDiv = labels[i] as HTMLElement
      labelDiv.style.left = `${ticksToWidth(rulerLabels[i].startTicks, newZoomFactor, baseGridWidth, ppq)}px`
      labelDiv.style.width = `${ticksToWidth(
        rulerLabels[i].endTicks - rulerLabels[i].startTicks,
        newZoomFactor,
        baseGridWidth,
        ppq,
      )}px`
    }
  }

  const allNotes = [...pianoRollNotes, ...ghostNotes, ...tempNotes, ...smartScaleNotes, ...autocompleteNotes]
  if (pianoRollNotesRef.current) {
    const notes = pianoRollNotesRef.current.children
    for (let i = 0; i < notes.length; i++) {
      const noteDiv = notes[i] as HTMLElement
      const note = allNotes.find((note) => note.id === noteDiv.id)

      if (!note) continue

      const noteWidth = ticksToWidth(
        note.endTicks - note.startTicks,
        zoomLevelToZoomFactor(zoomLevelRef.current),
        baseGridWidth,
        ppq,
      )

      noteDiv.style.width = `${noteWidth}px`
      noteDiv.style.left = `${ticksToWidth(note.startTicks, newZoomFactor, baseGridWidth, ppq)}px`

      if (!renderStripesDict[note.id]) continue
      for (const stripe of renderStripesDict[note.id]) {
        const stripeElement = noteDiv.querySelector(`#${stripe.id}`) as HTMLElement
        stripeElement.style.width = `${ticksToWidth(
          stripe.endTicks - stripe.startTicks,
          newZoomFactor,
          baseGridWidth,
          ppq,
        )}px`
        stripeElement.style.left = `${ticksToWidth(stripe.startTicks, newZoomFactor, baseGridWidth, ppq)}px`
      }
    }
  }

  playheadRef.current.style.left = `${ticksToWidth(playheadPositionTicks, newZoomFactor, baseGridWidth, ppq)}px`

  horizontalScrollRef.current.scrollLeft += deltaToMove

  if (inactiveZoneRef.current) {
    const inactiveZoneLeft = ticksToWidth(inactiveZoneStartTicks, newZoomFactor, baseGridWidth, ppq)
    inactiveZoneRef.current.style.left = `${inactiveZoneLeft}px`
    inactiveZoneRef.current.style.width = `${pianoRollWidth - inactiveZoneLeft}px`
  }

  handleScrollX(context, horizontalScrollRef.current.scrollLeft, {
    horizontalScrollRef,
    scrollBarXRef,
    rulerInnerContainerRef,
    containerRef,
  })
}
