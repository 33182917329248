export enum LimitType {
  suggestions = 'AI Chord Suggestions',
  generations = 'AI Generations',
  exports = 'MIDI, MP3, WAV, PDF exports',
  voicings = 'Chord Voicings',
  lyricsSuggestions = 'AI Lyrics',
  projects = 'Projects',
  shares = 'Preview Shares',
  midiOutput = 'Midi Output',
  guitarChords = 'Guitar Chords',
  rag = 'AI Prompt Generations',
  aiDemo = 'AI Demo Generations',
}

export enum LimitTypesLabel {
  suggestions = 'AI Chord Suggestions',
  generations = 'AI Generations',
  exportsMP3 = 'MP3 exports',
  exportsMidi = 'MIDI exports',
  exportsPDF = 'PDF exports',
  exportsWAV = 'WAV exports',
  voicings = 'Chord Voicings',
  lyricsSuggestions = 'AI Lyrics',
  projects = 'Projects',
  shares = 'Preview Shares',
  midiOutput = 'Midi Output',
  guitarChords = 'Guitar Chords',
  rag = 'AI Prompt Generations',
  aiDemo = 'AI Demo Generations',
}

export const getLimitTypeLabelToLimit = (limitTypeLabel: LimitTypesLabel) => {
  switch (limitTypeLabel) {
    case LimitTypesLabel.exportsMidi:
    case LimitTypesLabel.exportsPDF:
    case LimitTypesLabel.exportsWAV:
    case LimitTypesLabel.exportsMP3:
      return LimitType.exports
    case LimitTypesLabel.generations:
      return LimitType.generations
    case LimitTypesLabel.rag:
      return LimitType.rag
    case LimitTypesLabel.aiDemo:
      return LimitType.aiDemo
    case LimitTypesLabel.projects:
      return LimitType.projects
    case LimitTypesLabel.lyricsSuggestions:
      return LimitType.lyricsSuggestions
    case LimitTypesLabel.shares:
      return LimitType.shares
    case LimitTypesLabel.midiOutput:
      return LimitType.midiOutput
    case LimitTypesLabel.voicings:
      return LimitType.voicings
    case LimitTypesLabel.suggestions:
      return LimitType.suggestions
    case LimitTypesLabel.guitarChords:
      return LimitType.guitarChords
  }
}

export const LIMITS_LIST = [
  {
    title: LimitType.generations,
    isRecurrent: true,
  },
  {
    title: LimitType.suggestions,
    isRecurrent: true,
  },
  {
    title: LimitType.exports,
    isRecurrent: true,
  },
  {
    title: LimitType.voicings,
    isRecurrent: true,
  },
  {
    title: LimitType.lyricsSuggestions,
    isRecurrent: true,
  },
  {
    title: LimitType.projects,
    isRecurrent: false,
  },
  {
    title: LimitType.shares,
    isRecurrent: true,
  },
  {
    title: LimitType.midiOutput,
    isRecurrent: false,
  },
  {
    title: LimitType.guitarChords,
    isRecurrent: false,
  },
  {
    title: LimitType.rag,
    isRecurrent: true,
  },
]
