import classNames from 'classnames'
import React, { FC } from 'react'

import BrushIcon from '../../../../assets/icons/brush.svg'
import CursorIcon from '../../../../assets/icons/cursor.svg'
import PencilIcon from '../../../../assets/icons/pencil.svg'
import { CursorMode } from '../../../ai-playground/InteractivePianoRoll/types'
import { InstrumentType } from '../../LayersOfInstruments/LayersOfInstruments'
import styles from './EditorSettings.module.scss'

type Props = {
  cursorMode: CursorMode
  setCursorMode: (v: CursorMode) => void
  mode?: InstrumentType
  className?: string
}

const cursorOptions: any = {
  [CursorMode.SELECTION]: <CursorIcon />,
  [CursorMode.PENCIL]: <PencilIcon />,
  [CursorMode.BRUSH]: <BrushIcon />,
}

const CursorSetting: FC<Props> = ({ cursorMode, setCursorMode, mode = '', className }) => {
  return (
    <div className={classNames(styles.cursorSetting, className)}>
      {Object.values(CursorMode)
        .filter((cM) => cM !== 'velocity')
        .map((cM) => (
          <div
            key={cM}
            className={classNames(styles.cursorSelectorOption, styles[mode], {
              [styles.cursorSelectorSelected]: cursorMode === cM,
            })}
            onClick={() => setCursorMode(cM)}
          >
            {cursorOptions[cM]}
          </div>
        ))}
    </div>
  )
}

export default CursorSetting
