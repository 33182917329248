import { PianoRollNote, StripeSegment } from '../types'
import { generateUniqueId } from './pianoRollUtils'

const mergeIndexRanges = (indexes: { startTicks: number; endTicks: number }[]) => {
  const mergedIndexRanges: { startTicks: number; endTicks: number }[] = []
  for (const indexRange of indexes) {
    if (
      mergedIndexRanges.length === 0 ||
      mergedIndexRanges[mergedIndexRanges.length - 1].endTicks < indexRange.startTicks
    ) {
      // No overlap, add the current index
      mergedIndexRanges.push(indexRange)
    } else {
      // Overlap, merge with the last index
      mergedIndexRanges[mergedIndexRanges.length - 1].endTicks = Math.max(
        mergedIndexRanges[mergedIndexRanges.length - 1].endTicks,
        indexRange.endTicks,
      )
    }
  }
  return mergedIndexRanges
}

export const getMonoNoteIntersection = (noteA: PianoRollNote, range: { startTicks: number; endTicks: number }) => {
  const start = Math.max(noteA.startTicks, range.startTicks)
  const end = Math.min(noteA.endTicks, range.endTicks)
  return start < end ? { startTicks: start, endTicks: end } : null
}

export const isMonoNoteIntersection = (noteA: PianoRollNote, noteB: PianoRollNote) => {
  return getMonoNoteIntersection(noteA, noteB) !== null
}

export const getMonoNotesNonOverlappingSegments = (
  targetNote: PianoRollNote,
  ranges: { startTicks: number; endTicks: number }[],
) => {
  let remainingSegments = [targetNote]

  for (const range of ranges) {
    const newSegments = []
    for (const segment of remainingSegments) {
      const intersection = getMonoNoteIntersection(segment, range)
      if (!intersection) {
        newSegments.push(segment) // No intersection, keep the whole segment
      } else {
        // Add non-overlapping parts
        if (segment.startTicks < intersection.startTicks) {
          newSegments.push({
            ...segment,
            id: generateUniqueId(),
            startTicks: segment.startTicks,
            endTicks: intersection.startTicks,
          })
        }
        if (segment.endTicks > intersection.endTicks) {
          newSegments.push({
            ...segment,
            id: generateUniqueId(),
            startTicks: intersection.endTicks,
            endTicks: segment.endTicks,
          })
        }
      }
    }
    remainingSegments = newSegments
  }

  return remainingSegments
}

export const removeOverlapsFromNotes = (
  notes: PianoRollNote[],
  overlapNoteRangesDict: Record<string, { startTicks: number; endTicks: number }[]>,
) => {
  const updatedNotes: PianoRollNote[] = []
  for (const note of notes) {
    const stripes = overlapNoteRangesDict[note.id]
    if (stripes) {
      updatedNotes.push(...getMonoNotesNonOverlappingSegments(note, stripes))
    } else {
      updatedNotes.push(note)
    }
  }
  return updatedNotes
}

export const createStripeOverlapsDict = (
  notes: PianoRollNote[],
  overlapNoteRangesDict: Record<string, { startTicks: number; endTicks: number }[]>,
) => {
  /**
   * Adds stripe overlaps to each note based on the provided overlap ranges.
   *
   * This function iterates over the given notes and checks if there are any
   * overlap ranges associated with each note's ID in the overlapNoteRangesDict.
   * If overlap ranges exist, it adds them as stripes to the note, each with a
   * unique ID. If no overlap ranges exist, it adds an empty stripes array to the note.
   *
   * @param {PianoRollNote[]} notes - The array of notes to which stripe overlaps will be added.
   * @param {Record<string, { startTicks: number; endTicks: number }[]>} overlapNoteRangesDict - A dictionary mapping note IDs to their overlap ranges.
   * @returns {PianoRollNote[]} An array of notes with added stripe overlaps.
   */
  const noteStripesDict: Record<string, StripeSegment[]> = {}
  for (const note of notes) {
    const stripes = overlapNoteRangesDict[note.id]
    if (stripes && stripes.length > 0) {
      noteStripesDict[note.id] = stripes.map((stripe, index) => ({
        ...stripe,
        id: `stripe-${note.id}-${index}`,
      }))
    }
  }
  return noteStripesDict
}

// Compare only the number of stripes per note
export const hasSameNumberOfStripes = (
  oldDict: Record<string, StripeSegment[]>,
  newDict: Record<string, StripeSegment[]>,
) => {
  if (Object.keys(oldDict).length !== Object.keys(newDict).length) return false
  for (const noteId in oldDict) {
    if (!(noteId in newDict) || oldDict[noteId].length !== newDict[noteId].length) return false
  }
  return true
}

export const getMonoNoteOverlaps = (
  oldNotes: PianoRollNote[],
  newNotes: PianoRollNote[],
) => {
  /**
   * Identifies overlapping segments between old and new notes.
   *
   * This function sorts the old and new notes by their start and end ticks,
   * then identifies overlapping segments between them. It returns a dictionary
   * where each key is the ID of an old note, and the value is an array of
   * overlapping ranges with the new notes.
   *
   * @param {PianoRollNote[]} oldNotes - The array of old notes to check for overlaps.
   * @param {PianoRollNote[]} newNotes - The array of new notes to check against.
   * @param {Set<string>} ghostNoteIds - A set of note IDs that should be ignored in overlap calculations.
   * @returns {Record<string, { startTicks: number; endTicks: number }[]>} A dictionary mapping old note IDs to their overlapping ranges.
   */
  // sort ascending by startTicks, if startTicks are the same, sort descending by endTicks
  const sortedOldNotes = [...oldNotes].sort((a, b) => {
    if (a.startTicks === b.startTicks) return b.endTicks - a.endTicks
    return a.startTicks - b.startTicks
  })
  // sort ascending by startTicks, if startTicks are the same, sort ascending by endTicks
  const sortedNewNotes = [...newNotes].sort((a, b) => {
    if (a.startTicks === b.startTicks) return a.endTicks - b.endTicks
    return a.startTicks - b.startTicks
  })
  const overlapNoteRangesDict: Record<string, { startTicks: number; endTicks: number }[]> = {}

  // merge all new notes into linear index ranged for efficient processing
  let lastStartTicks = -1
  const indexRanges: { startTicks: number; endTicks: number }[] = []
  for (const newNote of sortedNewNotes) {
    if (lastStartTicks == newNote.startTicks) continue
    indexRanges.push({ startTicks: newNote.startTicks, endTicks: newNote.endTicks })
    lastStartTicks = newNote.startTicks
  }
  const mergedIndexRanges = mergeIndexRanges(indexRanges)

  let i = 0
  for (const oldNote of sortedOldNotes) {
    // move i to the first range that could overlap with the current old note
    while (i < mergedIndexRanges.length && mergedIndexRanges[i].endTicks < oldNote.startTicks) i++

    let temp_i = i
    while (temp_i < mergedIndexRanges.length && mergedIndexRanges[temp_i].startTicks < oldNote.endTicks) {
      const intersection = getMonoNoteIntersection(oldNote, mergedIndexRanges[temp_i])
      if (intersection) {
        overlapNoteRangesDict[oldNote.id] = [...(overlapNoteRangesDict[oldNote.id] || []), intersection]
      }
      temp_i += 1
    }
  }

  return overlapNoteRangesDict
}
