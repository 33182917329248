import { InteractivePianoRollContextType } from '../types'
import { getRelativeMousePositionInDiv } from '../utils/positionHelper'

const detectKeyIndexByPosition = (
  context: InteractivePianoRollContextType,
  clientX: number,
  clientY: number,
  refs: any,
): number => {
  const { midiRangeMin, midiRangeMax, baseGridHeight } = context
  const { leftSideKeysContentRef } = refs
  const leftSideKeysDiv = leftSideKeysContentRef.current

  if (!leftSideKeysDiv) return -1

  const { x, y } = getRelativeMousePositionInDiv(clientX, clientY, leftSideKeysContentRef)
  const contentHeight = (midiRangeMax - midiRangeMin) * baseGridHeight
  const bottomBasedY = contentHeight - y

  const keyIndex = Math.floor(bottomBasedY / baseGridHeight) + midiRangeMin
  return keyIndex
}

export const handleLeftSideKeysMouseDrag = (
  context: InteractivePianoRollContextType,
  event: MouseEvent | PointerEvent,
  memo: any,
  first: boolean,
  last: boolean,
  movementX: number,
  movementY: number,
  refs: any,
) => {
  const { leftSideHeldKeys, setLeftSideHeldKeys, onNoteSoundCallbackRef } = context

  const keyIndex = detectKeyIndexByPosition(context, event.clientX, event.clientY, refs)
  if (keyIndex !== -1) {
    if (!leftSideHeldKeys.has(keyIndex)) {
      setLeftSideHeldKeys(new Set([keyIndex]))
      if (onNoteSoundCallbackRef.current)
        onNoteSoundCallbackRef.current({
          midiNote: keyIndex,
          startTicks: 0,
          endTicks: 0,
          velocity: 100,
          opacity: 1,
          color: 'red',
          id: 'test',
        })
    }
  }

  if (last) {
    setLeftSideHeldKeys(new Set())
  }
}
