import { InteractivePianoRollContextType } from '../types'
import { handleMouseMove } from './mouseMoveHandler'

const renderScrollXByPercentage = (
  context: InteractivePianoRollContextType,
  percentage: number,
  refs: any,
  withHorizontalScroll = false,
) => {
  const { leftSideKeysWidth } = context
  const { horizontalScrollRef, scrollBarXRef, rulerInnerContainerRef } = refs
  const width = parseFloat(getComputedStyle(horizontalScrollRef.current).width)
  const totalWidth = horizontalScrollRef.current.scrollWidth
  const scrollBarWidth = width * (width / totalWidth)
  const left = (percentage * (width - scrollBarWidth - leftSideKeysWidth)) / 100
  scrollBarXRef.current.style.width = `${scrollBarWidth}px`
  scrollBarXRef.current.style.left = `${leftSideKeysWidth + left}px`

  if (withHorizontalScroll) {
    horizontalScrollRef.current.scrollLeft = (percentage / 100) * (totalWidth - width)
  }

  rulerInnerContainerRef.current.style.left = `${-horizontalScrollRef.current.scrollLeft + leftSideKeysWidth}px`
}

const renderScrollYByPercentage = (context: InteractivePianoRollContextType, percentage: number, refs: any) => {
  const { headerHeight } = context
  const { scrollBarYRef, contentDivRef, leftSideKeysScrollRef } = refs

  const height = parseFloat(getComputedStyle(contentDivRef.current).height) - headerHeight
  const totalContentHeight = contentDivRef.current.scrollHeight - headerHeight

  if (totalContentHeight <= height) {
    scrollBarYRef.current.style.display = 'none'
    return
  }
  scrollBarYRef.current.style.display = 'block'

  const scrollBarHeight = height * (height / totalContentHeight)
  const top = (percentage * (height - scrollBarHeight)) / 100

  scrollBarYRef.current.style.height = `${scrollBarHeight}px`
  scrollBarYRef.current.style.top = `${top + headerHeight}px`

  const percentageInPx = (percentage / 100) * (totalContentHeight - height)

  contentDivRef.current.scrollTop = percentageInPx

  if (!leftSideKeysScrollRef.current) return
  leftSideKeysScrollRef.current.scrollTop = percentageInPx
}

export const handleDragScrollX = (
  context: InteractivePianoRollContextType,
  initial: number,
  movementX: number,
  refs: { horizontalScrollRef: React.RefObject<HTMLElement>; scrollBarXRef: React.RefObject<HTMLElement> },
) => {
  const { leftSideKeysWidth } = context
  const { horizontalScrollRef, scrollBarXRef } = refs
  if (!horizontalScrollRef.current || !scrollBarXRef.current) return

  // first update the scroll bar position
  const width = parseFloat(getComputedStyle(horizontalScrollRef.current).width)
  const scrollBarWidth = parseFloat(getComputedStyle(scrollBarXRef.current).width)
  const max = width - scrollBarWidth
  const min = leftSideKeysWidth
  const newLeft = Math.min(Math.max(initial + movementX, min), max)
  const percentage = ((newLeft - min) / (max - min)) * 100
  renderScrollXByPercentage(context, percentage, refs, true)
}

export const handleDragScrollY = (
  context: InteractivePianoRollContextType,
  initial: number,
  movementY: number,
  refs: {
    scrollBarYRef: React.RefObject<HTMLElement>
    contentDivRef: React.RefObject<HTMLElement>
    leftSideKeysScrollRef: React.RefObject<HTMLElement>
  },
) => {
  const { headerHeight } = context
  const { scrollBarYRef, contentDivRef } = refs
  if (!scrollBarYRef.current || !contentDivRef.current) return

  // first update the scroll bar position
  // const adjustedInitial = initial - headerHeight
  const height = parseFloat(getComputedStyle(contentDivRef.current).height)
  const scrollBarHeight = parseFloat(getComputedStyle(scrollBarYRef.current).height)
  const max = height - scrollBarHeight
  const min = headerHeight
  const newTop = Math.min(Math.max(initial + movementY, min), max)
  const percentage = ((newTop - min) / (max - min)) * 100

  renderScrollYByPercentage(context, percentage, refs)
}

export const handleScrollX = (context: InteractivePianoRollContextType, x: number, refs: any) => {
  const { horizontalScrollRef } = refs
  const { mousePositionRef } = context
  const width = parseFloat(getComputedStyle(horizontalScrollRef.current).width)
  const totalWidth = horizontalScrollRef.current.scrollWidth
  const percentage = (x / (totalWidth - width)) * 100

  renderScrollXByPercentage(context, percentage, refs, false)

  handleMouseMove(context, mousePositionRef.current.x, mousePositionRef.current.y, refs)
}

export const handleScrollY = (context: InteractivePianoRollContextType, y: number, refs: any) => {
  const { headerHeight } = context
  const { contentDivRef } = refs

  const height = parseFloat(getComputedStyle(contentDivRef.current).height) - headerHeight
  const totalContentHeight = contentDivRef.current.scrollHeight - headerHeight

  const percentage = (y / (totalContentHeight - height)) * 100

  renderScrollYByPercentage(context, percentage, refs)
}
