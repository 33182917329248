import React, { FC, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'

import { useGeneratorModals } from '../../../../context/GeneratorModalsContext'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../hooks/useSizes'
import { getDrumsMidiBuffer, patternToMidi } from '../../../../utils/audio/midiUtils'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import DrumsInstrument from '../DrumsPattern/DrumsInstrument'
import DrumsPattern, { patternMenuHookId } from '../DrumsPattern/DrumsPattern'
import styles from './DrumsDefaultEditor.module.scss'

type Props = {
  mini?: boolean
}

const DrumsDefaultEditor: FC<Props> = ({ mini }) => {
  const { isMobile } = useSizes()
  const {
    playerConfig: {
      currentPart: { drums },
      drumGenre: genre,
    },
  } = usePlayerConfigState()
  const { drumsMenuOpenRow, setDrumsMenuOpenRow } = useGeneratorModals()

  const menuRef = useRef<HTMLDivElement>(null)
  const menuOpen = drumsMenuOpenRow !== -1
  const isManualEnabled = !isMobile && !mini

  useClickOutsideAlerter(menuRef, () => setDrumsMenuOpenRow(-1), clickOutsideHookIds.drumsDefaultEditor)

  const { left, top, width } = useMemo(() => {
    if (drumsMenuOpenRow === -1 || !genre) return { left: -Infinity, top: -Infinity }

    const spaceX = 40
    const spaceY = 10
    const height = genre.percGroups[drumsMenuOpenRow].percTypes.length * 60 + 48 + 24 * 2
    // all patterns + manual + paddings

    const hook = document.getElementById(patternMenuHookId + drumsMenuOpenRow)

    const bcr = hook?.getBoundingClientRect()
    const windowWidth = window.innerWidth

    if (!bcr) return { left: 0, top: 0, width: 0 }

    let left = bcr.x + spaceX
    let top = bcr.y - spaceY
    let width = bcr.width - spaceX

    if (top < height) {
      left = bcr.x + bcr.width + spaceY
      top = height + spaceY
      width = 284 + 48 + 2
    }

    if (left > windowWidth - width) {
      left = windowWidth - width - spaceY
    }

    return { left, top, width }
  }, [drumsMenuOpenRow])

  if (!drums) return null

  const renderPatternMenu = () => {
    if (!menuOpen) return null

    const patterns = genre.percGroups[drumsMenuOpenRow].patterns
    let manualPattern = drums.groups[drumsMenuOpenRow].pattern

    if (!manualPattern.key.startsWith('manual')) {
      const midiObj = patternToMidi(patterns[0])
      midiObj.tracks.forEach((track) => (track.notes = []))

      manualPattern = { key: `manual-${drumsMenuOpenRow}`, buffer: getDrumsMidiBuffer(midiObj) }
    }

    const midiObj = patternToMidi(patterns[0])
    midiObj.tracks.forEach((track) => (track.notes = []))

    return createPortal(
      <div ref={menuRef} className={styles.patternMenu} style={{ left, top, width }}>
        {patterns.map((pattern) => (
          <DrumsPattern key={pattern.key} pattern={pattern} />
        ))}

        {isManualEnabled && <DrumsPattern pattern={manualPattern} />}
      </div>,
      document.getElementById('__next') as HTMLElement,
    )
  }

  return (
    <div className={styles.defaultEditorContainer}>
      <div className={styles.instruments}>
        {genre.percGroups.map((group, index) => (
          <>
            {index ? <div className={styles.divider} /> : null}

            <div key={`group_${index}`} className={styles.instrumentRow}>
              {group.percTypes.map((type) => (
                <DrumsInstrument key={type.type} type={type.type} />
              ))}
            </div>
          </>
        ))}
      </div>

      <div className={styles.patterns}>
        {drums.groups.map((group, index) => (
          <DrumsPattern key={`group_${index}`} pattern={group.pattern} index={index} />
        ))}
      </div>
      {renderPatternMenu()}
    </div>
  )
}

export default DrumsDefaultEditor
