import classNames from 'classnames'
import React, { FC } from 'react'

import ArrowIcon from '../../../../assets/icons/arrow.svg'
import PencilIcon from '../../../../assets/icons/pencil.svg'
import { useGeneratorModals } from '../../../../context/GeneratorModalsContext'
import useSizes from '../../../../hooks/useSizes'
import { getBarsFromSixteens, patternToMidi } from '../../../../utils/audio/midiUtils'
import { trackMixpanelEvent_drumsSimpleChangePattern } from '../../../../utils/tracking'
import { ProgPartPattern } from '../../../../utils/types'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './DrumsPattern.module.scss'

export const isInstrumentActive = (drums: ProgPartPattern, type: string) => {
  return (
    drums?.groups
      .map((group) => group.percTypes)
      .flat()
      .find((i) => i.type === type)?.active || false
  )
}

export const isPatternActive = (drums: ProgPartPattern, patternId: string) => {
  return drums?.groups
    .map((group) => group.pattern)
    .flat()
    .some((pattern) => pattern.key === patternId)
}

export const patternMenuHookId = 'pattern-menu-hook-'

type Props = {
  pattern: { key: string; buffer: string }
  index?: number
}

const DrumsPattern: FC<Props> = ({ pattern, index }) => {
  const { isMobile } = useSizes()
  const {
    playerConfig: {
      currentPart: { drums },
      drumGenre: genre,
      player: { drumsPlayer },
    },
    playerConfigSetter: { setPattern },
  } = usePlayerConfigState()
  const { drumsMenuOpenRow, setDrumsMenuOpenRow, setDrumsManualOpen } = useGeneratorModals()

  if (!drums || !genre.key) return null

  const drumsDuration = '4:0:0'
  const durationBars = Math.max(isMobile ? 2 : 1, getBarsFromSixteens(drumsDuration))

  const midi = patternToMidi(pattern)
  const { allNotes } = drumsPlayer?.convertPattern({ midi, name: '' }, drumsDuration) || {}

  const isInMenu = index === undefined
  const isManualMode = pattern.key.startsWith('manual')
  const isEmptyManualMode = isManualMode && midi.tracks.every((t) => t.notes.length === 0)

  const groupIndex = isInMenu ? drumsMenuOpenRow : index
  const groupInstruments = (genre.percGroups[groupIndex]?.percTypes || []).map((v) => v.type)
  const patternIndex = genre.percGroups[groupIndex].patterns.findIndex((p) => p.key === pattern.key) + 1

  const tracks = midi.tracks
    .filter((track) => groupInstruments.includes(track.name))
    .map((t) => ({ name: t.name, notes: allNotes.filter((n) => n.track === t.name) }))

  const handleOpenPatternDropdown = () => {
    if (isInMenu) {
      const newGroups = JSON.parse(JSON.stringify(drums.groups))
      newGroups[drumsMenuOpenRow].pattern = pattern

      setPattern({ ...drums, groups: newGroups })

      trackMixpanelEvent_drumsSimpleChangePattern()
    } else {
      setDrumsMenuOpenRow(groupIndex)
    }
  }
  const handleOpenManualEditor = (e: React.MouseEvent) => {
    if (!isManualMode || isMobile) return

    if (!isInMenu) {
      e.preventDefault()
      e.stopPropagation()
    }

    setDrumsManualOpen(true)
    setDrumsMenuOpenRow(-1)
  }

  return (
    <div
      id={patternMenuHookId + groupIndex}
      className={classNames(
        styles.pattern,
        { [styles.active]: isPatternActive(drums, pattern.key) },
        { [styles.menuOpen]: index === drumsMenuOpenRow },
        { [styles.inMenu]: isInMenu },
      )}
      onClick={handleOpenPatternDropdown}
    >
      <div className={styles.left} onClick={handleOpenManualEditor}>
        {isManualMode ? <PencilIcon /> : patternIndex}
      </div>

      <div className={styles.center}>
        {isEmptyManualMode ? (
          <span>Manual</span>
        ) : (
          <>
            {tracks.map(({ name: instrumentName, notes }) => {
              const instrumentActive = isInstrumentActive(drums, instrumentName)

              return (
                <div
                  key={`track_${instrumentName}`}
                  className={classNames(
                    styles.track,
                    {
                      [styles.activeTrack]:
                        (isInMenu && instrumentActive && isPatternActive(drums, pattern.key)) ||
                        (!isInMenu && instrumentActive),
                    },
                    {
                      [styles.muttedTrack]: !instrumentActive,
                    },
                  )}
                >
                  {notes.map((note, i) => {
                    const left = (getBarsFromSixteens(note.time.toString()) / durationBars) * 100
                    const width = (getBarsFromSixteens(note.duration) / durationBars) * 100

                    return (
                      <div
                        key={note.time + i}
                        className={styles.note}
                        style={{
                          left: `${left}%`,
                          width: `calc(${Math.min(100 - left, width)}% - 1px)`,
                        }}
                      ></div>
                    )
                  })}
                </div>
              )
            })}
          </>
        )}
      </div>

      {isInMenu ? null : (
        <div className={styles.right}>
          <ArrowIcon />
        </div>
      )}
    </div>
  )
}

export default DrumsPattern
