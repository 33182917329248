import deepEqual from 'deep-equal'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import * as wm from 'webmidi'

import {
  getDrumGenreInnerRoute,
  getDrumsInnerRoute,
  getInstrumentsInnerRoute,
  getPlaystylesInnerRoute,
} from '../api/constants'
import { generateRagProgressionInnerRoute, TRagConfig } from '../api/progresssions'
import DrumsInstrumentIcon from '../assets/icons/drums-instrument.svg'
import InstrumentCategoryBassesIcon from '../assets/icons/instrument-category-basses.svg'
import InstrumentCategoryGuitarsIcon from '../assets/icons/instrument-category-guitars.svg'
import InstrumentCategoryKeysIcon from '../assets/icons/instrument-category-keys.svg'
import InstrumentCategoryOtherIcon from '../assets/icons/instrument-category-other.svg'
import InstrumentCategoryPadsIcon from '../assets/icons/instrument-category-pads.svg'
import InstrumentCategoryStringsIcon from '../assets/icons/instrument-category-strings.svg'
import InstrumentCategorySynthsIcon from '../assets/icons/instrument-category-synths.svg'
import notification from '../components/common/Notification/notification'
import { INSTRUMENT_TYPES, InstrumentType, TabType } from '../components/editor/LayersOfInstruments/LayersOfInstruments'
import {
  DRUMS_CATEGORIES_NEW,
  INSTRUMENT_CATEGORIES_NEW,
  INSTRUMENT_CATEGORY,
} from '../components/editor/TimelineWrapper/InstrumentMenu/constants'
import { usePlayerConfigState } from '../components/editor/hooks/usePlayerConfigState'
import { getRandomFromArr } from '../utils/array'
import {
  createLayer,
  getInstrumentKey,
  getPlaystyleIdByInstrumentKey,
  setupLayers,
  INSTRUMENT_CATEGORY_TO_PLASTYLE_TYPES,
  defaultLayer,
} from '../utils/instrumentsUtils'
import { getActivePart, updateProgPartDataById } from '../utils/progUtils'
import { trackMixpanelEvent_changeInstrument } from '../utils/tracking'
import { Instrument, InstrumentLayer, Playstyle, Prog, ProgPart, TGenre } from '../utils/types'
import { useInternationalization } from './InternationalizationContext'
import { useLimits } from './LimitsContext'
import { useProjectState } from './ProjectStateContext'

type FetchType = {
  chordLayers: InstrumentLayer[]
  melodyLayers: InstrumentLayer[]
  drumLayers: InstrumentLayer[]
  layers: InstrumentLayer[]
  activeLayer: InstrumentLayer | undefined
  activeLayerId: number
  setActiveLayerId: (v: number) => void
  editorMode: InstrumentType
  isChordMode: boolean
  isMelodyMode: boolean
  isDrumMode: boolean

  chordsInstrumentName: string
  melodyInstrumentName: string
  drumsInstrumentName: string

  testInstrument?: {
    start: (a: string, drums: boolean, b: () => void) => Promise<void>
    stop: () => void
  }
  navigator: {
    nextInstrument: (genre?: string, update?: boolean) => string
    prevInstrument: (genre?: string, update?: boolean) => string
    randomInstrument: (genre?: string, update?: boolean) => string
    //
    nextMelody: (genre?: string, update?: boolean) => string
    prevMelody: (genre?: string, update?: boolean) => string
    randomMelody: (genre?: string, update?: boolean) => string
    //
    nextDrums: (genre?: string, update?: boolean) => string
    prevDrums: (genre?: string, update?: boolean) => string
    randomDrums: (genre?: string, update?: boolean) => string
    //
    nextPlaystyle: (update?: boolean) => string
    prevPlaystyle: (update?: boolean) => string
    randomPlaystyle: (update?: boolean) => string
  }

  chordInstrumentsData: Instrument[]
  drumInstrumentsData: Instrument[]
  playstylesData: Playstyle[]

  addLayer: () => void
  deleteLayerById: (layerId: number) => void
  updateLayerById: (layerId: number, data: InstrumentLayer, type: InstrumentType) => void

  outputs: wm.Output[]

  resetLayers: (instrumentKey: string, type: INSTRUMENT_TYPES) => Prog | null
  setupGeneratedLayers: (query?: string, config?: TRagConfig) => Promise<Prog | null>
  handlePickRandom: () => void

  updateVoicing: (
    type: 'bass' | 'chord' | null,
    data: {
      enabled?: boolean
      startMidi?: number
      spread?: number
      density?: boolean
    },
  ) => void
  silentlyUpdateLayers: (layers: InstrumentLayer[], type: InstrumentType) => void

  getTabProps: (v: TabType, type?: InstrumentType) => { tabColor: string; tabIcon: ReactNode }
  handleChangeGenre: (chordGenreKey?: string, drumGenreKey?: string, replaceInstrument?: boolean) => void
}

export const InstrumentsStateContext = React.createContext<FetchType>({
  chordLayers: [],
  melodyLayers: [],
  drumLayers: [],
  layers: [],
  activeLayer: undefined,
  activeLayerId: 0,
  setActiveLayerId: () => {},
  editorMode: 'CHORDS',
  isChordMode: false,
  isMelodyMode: false,
  isDrumMode: false,

  chordsInstrumentName: '',
  melodyInstrumentName: '',
  drumsInstrumentName: '',

  testInstrument: undefined,
  navigator: {
    nextInstrument: () => '',
    prevInstrument: () => '',
    randomInstrument: () => '',
    nextMelody: () => '',
    prevMelody: () => '',
    randomMelody: () => '',
    nextDrums: () => '',
    prevDrums: () => '',
    randomDrums: () => '',
    nextPlaystyle: () => '',
    prevPlaystyle: () => '',
    randomPlaystyle: () => '',
  },

  chordInstrumentsData: [],
  drumInstrumentsData: [],
  playstylesData: [],

  addLayer: () => {},
  deleteLayerById: () => {},
  updateLayerById: () => {},

  outputs: [],

  resetLayers: () => null,
  setupGeneratedLayers: () => new Promise(() => {}),
  handlePickRandom: () => {},

  updateVoicing: () => {},
  silentlyUpdateLayers: () => {},

  getTabProps: () => ({ tabColor: '', tabIcon: <></> }),
  handleChangeGenre: () => {},
})

export const InstrumentsStateProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient()
  const {
    playerConfig: {
      view,
      player,
      instrumentMidiOut,
      prog,
      currentPartId,
      currentPart,
      isOnRepeat,
      drumGroups,
      chordGroups,
      chordGenre,
      drumGenre,
    },
    playerConfigSetter: {
      setInstrumentLoaded,
      setMelodyInstrumentLoaded,
      setDrumsInstrumentLoaded,
      setInstrumentMidiOut,
      setIsProgLoading,
      setIsMelodyLoading,
      setIsDrumsPatternLoading,
      setPattern,
      setProg,
    },
  } = usePlayerConfigState()
  const { isProjectSetuped } = useProjectState()
  const { text } = useInternationalization()
  const { isFreeUser, isInstrumentLimited } = useLimits()

  const [chordLayers, setChordLayers] = useState<InstrumentLayer[]>([])
  const [melodyLayers, setMelodyLayers] = useState<InstrumentLayer[]>([])
  const [drumLayers, setDrumLayers] = useState<InstrumentLayer[]>([])

  const [activeLayerId, setActiveLayerId] = useState(0)
  const [outputs, setOutputs] = useState(wm.WebMidi.outputs)

  const [startAfterSetup, setStartAfterSetup] = useState(false)

  const { data: chordInstrumentsDataRaw } = useQuery({
    queryKey: ['instruments-list'],
    queryFn: getInstrumentsInnerRoute,
  })
  const { data: drumInstrumentsDataRaw } = useQuery({
    queryKey: ['drums-list'],
    queryFn: getDrumsInnerRoute,
  })
  const { data: playstylesDataRaw } = useQuery({
    queryKey: ['playstyles-list'],
    queryFn: getPlaystylesInnerRoute,
  })

  const STRINGS = text.timeline || {}

  const chordInstrumentsData = useMemo(() => chordInstrumentsDataRaw || [], [chordInstrumentsDataRaw])
  const drumInstrumentsData = useMemo(() => drumInstrumentsDataRaw || [], [drumInstrumentsDataRaw])
  const playstylesData = useMemo(() => playstylesDataRaw || [], [playstylesDataRaw])

  const { progChordLayers, progMelodyLayers, progDrumLayers } = useMemo(() => {
    if (!chordInstrumentsData.length || !drumInstrumentsData.length) {
      return { progChordLayers: [], progMelodyLayers: [], progDrumLayers: [] }
    }

    const progChordLayers: InstrumentLayer[] = []
    const progMelodyLayers: InstrumentLayer[] = []
    const progDrumLayers: InstrumentLayer[] = []

    const setupPartLayers = (
      part: ProgPart,
      partLayers: InstrumentLayer[],
      instrumentsData: any,
      playstylesData: any,
      layers: InstrumentLayer[],
    ) => {
      partLayers?.forEach((layer, index) => {
        const key = getInstrumentKey(layer.instrument, index, part.id)
        const setupedLayer = JSON.parse(JSON.stringify(setupLayers([layer], instrumentsData, playstylesData)[0]))

        setupedLayer.instrument.key = key

        layers.push(setupedLayer)
      })
    }

    prog?.parts.forEach((part) => {
      setupPartLayers(part, part.chordLayers, chordInstrumentsData, playstylesData, progChordLayers)
      setupPartLayers(part, part.melodyLayers, chordInstrumentsData, undefined, progMelodyLayers)
      setupPartLayers(part, part.drumLayers, drumInstrumentsData, undefined, progDrumLayers)
    })

    return { progChordLayers, progMelodyLayers, progDrumLayers }
  }, [JSON.stringify(prog), chordInstrumentsData, drumInstrumentsData, playstylesData])

  const editorMode = useMemo(() => {
    if (view.layersOpen) return view.layersOpen
    if (view.drumsEditorOpen) return INSTRUMENT_TYPES.DRUMS
    if (view.melodyEditorOpen) return INSTRUMENT_TYPES.MELODY
    return INSTRUMENT_TYPES.CHORDS
  }, [view.layersOpen, view.drumsEditorOpen, view.melodyEditorOpen])
  const isChordMode = editorMode === INSTRUMENT_TYPES.CHORDS
  const isMelodyMode = editorMode === INSTRUMENT_TYPES.MELODY
  const isDrumMode = editorMode === INSTRUMENT_TYPES.DRUMS

  const layers = useMemo(() => {
    if (isMelodyMode) return melodyLayers
    if (isDrumMode) return drumLayers
    return chordLayers
  }, [chordLayers, melodyLayers, drumLayers, isChordMode, isMelodyMode, isDrumMode])
  const activeLayer = useMemo(() => layers[activeLayerId], [layers, activeLayerId])

  const chordsInstrumentName =
    chordLayers.length === 1 ? chordLayers[0]?.instrument.name : `${chordLayers.length} ${STRINGS.layers}`
  const melodyInstrumentName = melodyLayers[0]?.instrument.name
  const drumsInstrumentName = drumLayers[0]?.instrument.name

  const testInstrument = player.getTestInstrument

  useEffect(() => {
    ;(async () => {
      try {
        const shouldResetupChords = await player.setupChordSynth(
          prog,
          progChordLayers,
          instrumentMidiOut,
          chordInstrumentsData,
        )
        const shouldResetupMelody = await player.setupMelodySynth(prog, progMelodyLayers, chordInstrumentsData)
        const shouldResetupDrums = await player.setupDrumsSynth(prog, progDrumLayers)

        if (progChordLayers.length) setInstrumentLoaded(true)
        if (progMelodyLayers.length) setMelodyInstrumentLoaded(true)
        if (progDrumLayers.length) setDrumsInstrumentLoaded(true)

        const partLoopedId = typeof isOnRepeat === 'number' ? isOnRepeat : undefined

        if (shouldResetupChords && prog) player.updateChordPart(prog, partLoopedId)
        if (shouldResetupMelody && prog) player.updateMelodyPart(prog, partLoopedId)
        if (shouldResetupDrums && prog) player.updateDrumPart(prog, partLoopedId)

        if (startAfterSetup) {
          setTimeout(() => player.play(), 0)
          setStartAfterSetup(false)
        }
      } catch (e) {
        console.log(e)
        setInstrumentLoaded(true)
        setMelodyInstrumentLoaded(true)
        setDrumsInstrumentLoaded(true)
      }
    })()
  }, [isOnRepeat, progChordLayers, progMelodyLayers, progDrumLayers, instrumentMidiOut])

  useEffect(() => {
    const currentPart = getActivePart(prog, currentPartId)

    if (
      !chordInstrumentsData.length ||
      !drumInstrumentsData.length ||
      !playstylesData.length ||
      !currentPart ||
      !isProjectSetuped ||
      !chordGenre.key ||
      !drumGenre.key
    )
      return

    const { chordLayers: rawChordLayers, melodyLayers: rawMelodyLayers, drumLayers: rawDrumLayers } = currentPart

    const newChordLayers = setupLayers(
      rawChordLayers,
      chordInstrumentsData,
      playstylesData,
      chordGenre.defaultInstrumentKey,
    )
    const newMelodyLayers = setupLayers(rawMelodyLayers, chordInstrumentsData)
    const newDrumLayers = setupLayers(rawDrumLayers, drumInstrumentsData, undefined, drumGenre.defaultInstrumentKey)

    if (!deepEqual(newChordLayers, chordLayers)) setChordLayers(newChordLayers)
    if (!deepEqual(newMelodyLayers, melodyLayers)) setMelodyLayers(newMelodyLayers)
    if (!deepEqual(newDrumLayers, drumLayers)) setDrumLayers(newDrumLayers)

    if (prog && rawChordLayers.length === 0 && rawMelodyLayers.length === 0 && rawDrumLayers.length === 0) {
      const newProg = updateProgPartDataById(prog, currentPartId, {
        chordLayers: newChordLayers,
        melodyLayers: newMelodyLayers,
        drumLayers: newDrumLayers,
      })
      setProg(newProg)
    }
  }, [
    JSON.stringify(prog),
    currentPartId,
    isProjectSetuped,
    chordInstrumentsData,
    drumInstrumentsData,
    playstylesData,
    chordGenre.key,
    drumGenre.key,
  ])

  useEffect(() => {
    const updateOutputs = () => {
      setOutputs([...wm.WebMidi.outputs])
      if (!wm.WebMidi.outputs?.length) {
        setInstrumentMidiOut({})
      }
    }
    wm.WebMidi.addListener('connected', updateOutputs)
    wm.WebMidi.addListener('disconnected', updateOutputs)
    return () => {
      wm.WebMidi.removeListener('connected', updateOutputs)
      wm.WebMidi.removeListener('disconnected', updateOutputs)
    }
  }, [])

  useEffect(() => {
    if (activeLayerId > layers.length - 1) setActiveLayerId(0)
  }, [layers])

  const updateLayer = (newLayers: InstrumentLayer[], type?: InstrumentType, save = true) => {
    if (!prog) return prog

    let newProg = null as any
    let setLayers = null as any

    switch (type || editorMode) {
      case INSTRUMENT_TYPES.CHORDS: {
        newProg = updateProgPartDataById(prog, currentPartId, { chordLayers: newLayers })
        setLayers = setChordLayers

        break
      }
      case INSTRUMENT_TYPES.MELODY: {
        newProg = updateProgPartDataById(prog, currentPartId, { melodyLayers: newLayers })
        setLayers = setMelodyLayers

        break
      }
      case INSTRUMENT_TYPES.DRUMS: {
        newProg = updateProgPartDataById(prog, currentPartId, { drumLayers: newLayers })
        setLayers = setDrumLayers

        break
      }
    }

    if (!save) return newProg

    setProg(newProg)
    setLayers(newLayers)
  }

  const addLayer = () => {
    const data = isChordMode || isMelodyMode ? chordInstrumentsData : drumInstrumentsData

    const validData = data.filter((o) => !o.premium || !isFreeUser)

    const newInstrument = getRandomFromArr(validData)
    const newPlaystyle = newInstrument.category === 'basses' ? 'simple-bass' : 'basic'

    const newLayers = [...layers, createLayer(newInstrument.key, data, newPlaystyle, playstylesData)]

    updateLayer(newLayers)
  }

  const deleteLayerById = (layerId: number) => {
    const newLayers = [...layers.slice(0, layerId), ...layers.slice(layerId + 1)]

    updateLayer(newLayers)
    setActiveLayerId(layerId ? layerId - 1 : layerId)
  }

  const updateLayerById = (layerId: number, data: InstrumentLayer, type: InstrumentType) => {
    let layers = [] as any[]

    if (type === 'CHORDS') layers = chordLayers
    if (type === 'MELODY') layers = melodyLayers
    if (type === 'DRUMS') layers = drumLayers

    const newLayers = [...layers.slice(0, layerId), data, ...layers.slice(layerId + 1)]

    updateLayer(newLayers, type)
  }

  const switchInstrument = (
    direction: 'prev' | 'next' | 'random',
    type: InstrumentType,
    genre?: string,
    update = true,
  ): string => {
    const isChordMode = type === INSTRUMENT_TYPES.CHORDS
    const isMelodyMode = type === INSTRUMENT_TYPES.MELODY
    const isDrumMode = type === INSTRUMENT_TYPES.DRUMS

    const { chordGenreKey, drumGenreKey } = currentPart.generatorSettings

    const data = {
      [INSTRUMENT_TYPES.CHORDS]: {
        layers: chordLayers,
        options: chordInstrumentsData,
        categories: INSTRUMENT_CATEGORIES_NEW,
        selectedGenre: chordGenreKey,
      },
      [INSTRUMENT_TYPES.MELODY]: {
        layers: melodyLayers,
        options: chordInstrumentsData,
        categories: INSTRUMENT_CATEGORIES_NEW,
        selectedGenre: chordGenreKey,
      },
      [INSTRUMENT_TYPES.DRUMS]: {
        layers: drumLayers,
        options: drumInstrumentsData,
        categories: DRUMS_CATEGORIES_NEW,
        selectedGenre: drumGenreKey,
      },
    }

    const current = data[type].layers[activeLayerId]?.instrument
    if (!current) return ''

    const options = data[type].options.filter((o) => !o.premium || !isFreeUser)
    const categories = data[type].categories
    const selectedGenre = data[type].selectedGenre

    const sorted = categories
      .reduce((acc: any, c) => {
        const cateryInstruments = options.filter((o) => o.category === c)

        const bestOptions = cateryInstruments.filter((option) => option.genres.includes(selectedGenre))
        const otherOptions = cateryInstruments.filter((option) => !option.genres.includes(selectedGenre))

        return [...acc, ...bestOptions, ...otherOptions]
      }, [])
      .filter((s: any) => !isInstrumentLimited(s))

    const currIndex = sorted.indexOf(current)
    const lastIndex = sorted.length - 1

    let next
    if (direction === 'random') {
      const forRandom = sorted
        .filter((s: any) => s.key !== current.key)
        .filter((s: any) => {
          if (!genre) return true
          if (isMelodyMode) return s.genre_melody_keys.includes(genre)
          return s.genre_keys.includes(genre)
        })
      next = getRandomFromArr(forRandom) || current
    } else if (direction === 'prev') {
      next = sorted[currIndex - 1] || sorted[lastIndex]
    } else {
      next = sorted[currIndex + 1] || sorted[0]
    }
    if (direction !== 'random' && update) {
      // @ts-ignore
      trackMixpanelEvent_changeInstrument(type.toLowerCase(), next.key)
    }
    if (update) {
      if (isChordMode) {
        const playstyleId = getPlaystyleIdByInstrumentKey(
          next.key,
          chordInstrumentsData,
          chordLayers[activeLayerId].playstyle.id,
          playstylesData,
        )

        updateLayerById(
          activeLayerId,
          createLayer(next.key, chordInstrumentsData, playstyleId, playstylesData, activeLayer),
          type,
        )
      }
      if (isMelodyMode) {
        updateLayerById(
          activeLayerId,
          createLayer(next.key, chordInstrumentsData, undefined, undefined, activeLayer),
          type,
        )
      }
      if (isDrumMode) {
        updateLayerById(
          activeLayerId,
          createLayer(next.key, drumInstrumentsData, undefined, undefined, activeLayer),
          type,
        )
      }
    }
    return next.key
  }

  const switchPlaystyle = (direction: 'prev' | 'next' | 'random', update = true): string => {
    const { playstyle: currentPlaystyle, instrument: currentInstrument } = activeLayer
    const allowedTypes = INSTRUMENT_CATEGORY_TO_PLASTYLE_TYPES[currentInstrument.category]
    const playstyles = (playstylesData as Playstyle[]).filter(
      (ps) => allowedTypes && ps.types.some((t) => allowedTypes.includes(t)),
    )

    const currIndex = playstyles.findIndex((ps) => ps.id === currentPlaystyle.id)

    if (currIndex === -1) return ''
    if (playstyles.length === 1 && currIndex === 0) {
      setInstrumentLoaded(true)
      return playstyles[0].id
    }

    let next
    if (direction === 'random') {
      const forRandom = playstyles.filter((ps: any) => ps.id !== currentPlaystyle.id)
      next = getRandomFromArr(forRandom)
    } else if (direction === 'prev') {
      next = playstyles[currIndex - 1] || playstyles[playstyles.length - 1]
    } else {
      next = playstyles[currIndex + 1] || playstyles[0]
    }
    if (update) updateLayerById(activeLayerId, { ...activeLayer, playstyle: next }, INSTRUMENT_TYPES.CHORDS)
    return next.id
  }

  const navigator = {
    nextInstrument: (genre?: string, update?: boolean) =>
      switchInstrument('next', INSTRUMENT_TYPES.CHORDS, genre, update),
    prevInstrument: (genre?: string, update?: boolean) =>
      switchInstrument('prev', INSTRUMENT_TYPES.CHORDS, genre, update),
    randomInstrument: (genre?: string, update?: boolean) =>
      switchInstrument('random', INSTRUMENT_TYPES.CHORDS, genre, update),

    nextMelody: (genre?: string, update?: boolean) => switchInstrument('next', INSTRUMENT_TYPES.MELODY, genre, update),
    prevMelody: (genre?: string, update?: boolean) => switchInstrument('prev', INSTRUMENT_TYPES.MELODY, genre, update),
    randomMelody: (genre?: string, update?: boolean) =>
      switchInstrument('random', INSTRUMENT_TYPES.MELODY, genre, update),

    nextDrums: (genre?: string, update?: boolean) => switchInstrument('next', INSTRUMENT_TYPES.DRUMS, genre, update),
    prevDrums: (genre?: string, update?: boolean) => switchInstrument('prev', INSTRUMENT_TYPES.DRUMS, genre, update),
    randomDrums: (genre?: string, update?: boolean) =>
      switchInstrument('random', INSTRUMENT_TYPES.DRUMS, genre, update),

    nextPlaystyle: (update?: boolean) => switchPlaystyle('next', update),
    prevPlaystyle: (update?: boolean) => switchPlaystyle('prev', update),
    randomPlaystyle: (update?: boolean) => switchPlaystyle('random', update),
  }

  const resetLayers = (instrumentKey: string, type: INSTRUMENT_TYPES): Prog | null => {
    const isChordMode = type === INSTRUMENT_TYPES.CHORDS
    const isMelodyMode = type === INSTRUMENT_TYPES.MELODY
    const isDrumMode = type === INSTRUMENT_TYPES.DRUMS

    const data = isChordMode || isMelodyMode ? chordInstrumentsData : drumInstrumentsData
    if (!data || !data.length) return null

    if (isChordMode) setInstrumentLoaded(false)
    if (isMelodyMode) setMelodyInstrumentLoaded(false)
    if (isDrumMode) setDrumsInstrumentLoaded(false)

    const newProg = silentlyUpdateLayers(
      [createLayer(instrumentKey, data, 'basic', isChordMode ? playstylesData : [])],
      type,
    )

    setActiveLayerId(0)

    return newProg
  }

  const silentlyUpdateLayers = (layers: InstrumentLayer[], type: InstrumentType) => {
    return updateLayer(layers, type, false)
  }

  const updateVoicing = (
    type: 'bass' | 'chord' | null,
    data: {
      enabled?: boolean
      startMidi?: number
      spread?: number
      density?: boolean
    },
  ) => {
    if (!type) return

    const { enabled, startMidi, spread, density } = data
    const newLayer = { ...activeLayer }
    const isBass = type === 'bass'

    if (enabled !== undefined) newLayer[isBass ? 'bassEnabled' : 'chordEnabled'] = enabled
    if (startMidi !== undefined) newLayer[isBass ? 'bassStartMidi' : 'chordStartMidi'] = startMidi
    if (spread !== undefined) newLayer[isBass ? 'bassSpread' : 'chordSpread'] = spread
    if (density !== undefined) newLayer[isBass ? 'bassDensity' : 'chordDensity'] = density

    updateLayerById(activeLayerId, newLayer, editorMode)
  }

  const getTabProps = (tabType: TabType, type?: InstrumentType) => {
    if (type === 'MELODY' || isMelodyMode) return { tabColor: 'blue', tabIcon: <InstrumentCategoryKeysIcon /> }

    if (type === 'DRUMS' || isDrumMode) return { tabColor: 'green', tabIcon: <DrumsInstrumentIcon /> }

    switch (tabType) {
      case INSTRUMENT_CATEGORY.keys:
        return { tabColor: 'blue', tabIcon: <InstrumentCategoryKeysIcon /> }
      case INSTRUMENT_CATEGORY.guitars:
        return { tabColor: 'yellow', tabIcon: <InstrumentCategoryGuitarsIcon /> }
      case INSTRUMENT_CATEGORY.synths:
        return { tabColor: 'purple', tabIcon: <InstrumentCategorySynthsIcon /> }
      case INSTRUMENT_CATEGORY.pads:
        return { tabColor: 'pink', tabIcon: <InstrumentCategoryPadsIcon /> }
      case INSTRUMENT_CATEGORY.other:
        return { tabColor: 'cyan', tabIcon: <InstrumentCategoryOtherIcon /> }
      case INSTRUMENT_CATEGORY.strings:
        return { tabColor: 'green', tabIcon: <InstrumentCategoryStringsIcon /> }
      case INSTRUMENT_CATEGORY.basses:
        return { tabColor: 'lagoon', tabIcon: <InstrumentCategoryBassesIcon /> }
      default:
        return { tabColor: '', tabIcon: <></> }
    }
  }

  const setupGeneratedLayers = async (query = '', config?: TRagConfig) => {
    if (
      !prog ||
      !chordInstrumentsData ||
      !chordInstrumentsData.length ||
      !drumInstrumentsData ||
      !drumInstrumentsData.length ||
      (!query && !config)
    )
      return null

    player.pause()

    setInstrumentLoaded(false)
    setMelodyInstrumentLoaded(false)
    setDrumsInstrumentLoaded(false)

    setIsProgLoading(true)
    setIsMelodyLoading(true)
    setIsDrumsPatternLoading(true)

    setActiveLayerId(0)
    setStartAfterSetup(true)

    const {
      chords,
      chordLayers: generatedChordLayers,
      drums,
      drumLayers: generatedDrumLayers,
      melody,
      melodyLayers: generatedMelodyLayers,
      generatorSettings,
      bpm,
      key,
      scale,
      error,
    } = config || (await generateRagProgressionInnerRoute(query))

    if (error) {
      notification.error(error)

      setInstrumentLoaded(true)
      setMelodyInstrumentLoaded(true)
      setDrumsInstrumentLoaded(true)

      setIsProgLoading(false)
      setIsMelodyLoading(false)
      setIsDrumsPatternLoading(false)

      return null
    }

    player.reset()

    const changes: any = {
      generatorSettings,
    }

    if (generatedChordLayers && generatedChordLayers.length) {
      const newChordLayers = generatedChordLayers.map((layer) =>
        createLayer(layer.instrument, chordInstrumentsData, layer.playstyle, playstylesData, {
          ...defaultLayer,
          volume: layer.volume,
        }),
      )

      setChordLayers(newChordLayers)
      changes.chordLayers = newChordLayers
    }

    if (generatedDrumLayers && generatedDrumLayers.length) {
      const newDrumLayers = generatedDrumLayers.map((layer) =>
        createLayer(layer.instrument, drumInstrumentsData, undefined, undefined, {
          ...defaultLayer,
          volume: layer.volume,
        }),
      )

      setDrumLayers(newDrumLayers)
      changes.drumLayers = newDrumLayers
    }

    if (chords && chords.length) {
      changes.chords = chords.map((chord, i) => ({ ...chord, id: currentPartId * 1000 + 1 + i }))
    } else {
      changes.chords = []
    }

    if (melody) {
      changes.melodyShown = true
      changes.melody = {
        ...currentPart?.melody,
        ...melody,
      }
    } else {
      changes.melodyShown = false
      changes.melody = null
    }

    if (drums) {
      changes.drumsShown = true
      changes.drums = {
        ...currentPart?.drums,
        ...drums,
      }
    } else {
      changes.drumsShown = false
      changes.drums = null
    }

    let newProg = updateProgPartDataById(prog, currentPartId, changes)
    newProg = { ...newProg, key, scale, bpm }
    setProg(newProg)

    setIsProgLoading(false)
    setIsMelodyLoading(false)
    setIsDrumsPatternLoading(false)

    return newProg
  }

  const handlePickRandom = () => {
    if (isChordMode) {
      setInstrumentLoaded(false)

      const instument = switchInstrument('random', INSTRUMENT_TYPES.CHORDS, undefined, false)

      const playstyle = switchPlaystyle('random', false)
      const playstyleId = getPlaystyleIdByInstrumentKey(instument, chordInstrumentsData, playstyle, playstylesData)

      updateLayerById(
        activeLayerId,
        createLayer(instument, chordInstrumentsData, playstyleId, playstylesData, activeLayer),
        INSTRUMENT_TYPES.CHORDS,
      )
    }
    if (isMelodyMode) {
      setMelodyInstrumentLoaded(false)

      navigator.randomMelody()
    }
    if (isDrumMode) {
      setDrumsInstrumentLoaded(false)

      navigator.randomDrums()
    }
  }

  const handleChangeGenre = async (chordGenreKey?: string, drumGenreKey?: string, replaceInstrument = true) => {
    if (!prog) return

    const prevGeneratorSettings = currentPart.generatorSettings
    let newDrumGenre = undefined

    const updates: any = {
      generatorSettings: {
        chordGenreKey: chordGenreKey || prevGeneratorSettings.chordGenreKey,
        drumGenreKey: drumGenreKey || prevGeneratorSettings.drumGenreKey,
        emptyGenre: false,
      },
    }

    if (chordGenreKey) {
      const chordGenres = chordGroups.map((group) => group.genres).flat()
      const newChordGenre = chordGenres.find((genre) => genre.key === chordGenreKey) || chordGenres[0]

      if (replaceInstrument) {
        updates.chordLayers = [
          createLayer(newChordGenre.defaultInstrumentKey, chordInstrumentsData, 'basic', playstylesData),
        ]
      }
    }

    if (drumGenreKey) {
      newDrumGenre = await queryClient.fetchQuery(
        [drumGroups, currentPartId, drumGenreKey],
        (): Promise<TGenre> => getDrumGenreInnerRoute(drumGenreKey),
      )

      if (replaceInstrument) {
        updates.drumLayers = [createLayer(newDrumGenre.defaultInstrumentKey, drumInstrumentsData)]
      }
    }

    const newProg = updateProgPartDataById(prog, currentPartId, updates)

    if (drumGenreKey && drumGenreKey !== prevGeneratorSettings.drumGenreKey) {
      setPattern(undefined, newProg, undefined, newDrumGenre)
    } else {
      setProg(newProg)
    }
  }

  return (
    <InstrumentsStateContext.Provider
      value={{
        chordLayers,
        melodyLayers,
        drumLayers,
        layers,
        activeLayer,
        activeLayerId,
        setActiveLayerId,
        editorMode,
        isChordMode,
        isMelodyMode,
        isDrumMode,

        chordsInstrumentName,
        melodyInstrumentName,
        drumsInstrumentName,

        testInstrument,
        navigator,

        chordInstrumentsData,
        drumInstrumentsData,
        playstylesData,

        addLayer,
        deleteLayerById,
        updateLayerById,

        outputs,

        resetLayers,
        silentlyUpdateLayers,
        setupGeneratedLayers,
        handlePickRandom,

        updateVoicing,

        getTabProps,
        handleChangeGenre,
      }}
    >
      {children}
    </InstrumentsStateContext.Provider>
  )
}

export const useInstrumentsState = () => React.useContext<FetchType>(InstrumentsStateContext)
