import { InteractivePianoRollContextType, DragType, PianoRollNote, SnapType } from '../types'
import { zoomLevelToZoomFactor, widthToTicks, ticksToWidth } from './pianoRollUtils'

export const heightToMidiValue = (bottomBasedY: number, baseGridHeight: number, midiRangeMin: number) => {
  return Math.floor(bottomBasedY / baseGridHeight) + midiRangeMin
}

export const snapTicks = (ticks: number, ppqSnap: number, snapType: SnapType = SnapType.FLOOR) => {
  if (snapType === SnapType.FLOOR) return Math.floor(ticks / ppqSnap) * ppqSnap
  if (snapType === SnapType.ROUND) {
    return Math.round(ticks / ppqSnap) * ppqSnap
  }
  return ticks
}

// Helper function to calculate note position
export const positionToNoteCoordinates = (
  context: InteractivePianoRollContextType,
  relativeX: number,
  relativeY: number,
  ppqSnap: number = context.ppqSnapRef.current,
  snapType: SnapType = SnapType.FLOOR,
) => {
  const { zoomLevelRef, contentHeight, baseGridHeight, baseGridWidth, ppq, midiRangeMin } = context

  const zoomFactor = zoomLevelToZoomFactor(zoomLevelRef.current)
  const bottomBasedY = contentHeight - relativeY
  const rawTicks = widthToTicks(relativeX, zoomFactor, baseGridWidth, ppq)
  const snappedTicks = snapTicks(rawTicks, ppqSnap, snapType)

  return {
    startTicks: snappedTicks,
    midiNote: heightToMidiValue(bottomBasedY, baseGridHeight, midiRangeMin),
  }
}

export const detectNoteAndDragForPos = (
  context: InteractivePianoRollContextType,
  relativeX: number,
  relativeY: number,
  closestTolerance = true,
  notes: PianoRollNote[],
) => {
  const { zoomLevelRef, contentHeight, baseGridHeight, baseGridWidth, ppq, midiRangeMin } = context

  const MAX_DISTANCE = 5 // 5 pixels tolerance
  const HANDLE_SIZE = 4 + 3 // 3 pixels border and 4 pixels handle

  const zoomFactor = zoomLevelToZoomFactor(zoomLevelRef.current)
  const bottomBasedY = contentHeight - relativeY

  let lastInBoundsNote: PianoRollNote | undefined = undefined // Variable to store the last found note
  let closestNote: PianoRollNote | undefined = undefined // Variable to store the closest note
  let closestDistance = MAX_DISTANCE // Initialize closest distance

  for (const note of notes) {
    const noteLeft = ticksToWidth(note.startTicks, zoomFactor, baseGridWidth, ppq)
    const noteWidth = ticksToWidth(note.endTicks - note.startTicks, zoomFactor, baseGridWidth, ppq)
    const noteBottom = note.midiNote * baseGridHeight - midiRangeMin * baseGridHeight
    const noteRight = noteLeft + noteWidth
    const noteTop = noteBottom + baseGridHeight

    // Check if the position is inside the note's bounding box
    if (relativeX >= noteLeft && relativeX <= noteRight && bottomBasedY >= noteBottom && bottomBasedY <= noteTop) {
      lastInBoundsNote = note
    } else {
      // quick optimization if we've already found a note in bounds
      if (lastInBoundsNote) continue
      // no tolerance - skip the rest of the loop
      if (!closestTolerance) continue

      const distance = Math.sqrt(
        Math.pow(Math.max(0, noteLeft - relativeX), 2) +
          Math.pow(Math.max(0, relativeX - noteRight), 2) +
          Math.pow(Math.max(0, noteBottom - bottomBasedY), 2) +
          Math.pow(Math.max(0, bottomBasedY - noteTop), 2),
      )

      if (distance < closestDistance) {
        closestDistance = distance
        closestNote = note
      }
    }
  }

  const note = lastInBoundsNote || closestNote || undefined
  if (!note) return undefined
  const noteRight = ticksToWidth(note.endTicks, zoomFactor, baseGridWidth, ppq)
  if (relativeX >= noteRight - HANDLE_SIZE) {
    return { note, type: DragType.NOTE_LENGTH }
  } else {
    return { note, type: DragType.NOTE }
  }
}

export const detectPianoRollNoteAndDrag = (
  context: InteractivePianoRollContextType,
  relativeX: number,
  relativeY: number,
  closestTolerance = true,
): { note: PianoRollNote; type: DragType } | undefined => {
  const notes = context.pianoRollNotes
  return detectNoteAndDragForPos(context, relativeX, relativeY, closestTolerance, notes)
}

export const detectScrollBarForPosition = (
  context: InteractivePianoRollContextType,
  refs: any,
  relativeX: number,
  relativeY: number,
): DragType | null => {
  const { scrollBarXRef, scrollBarYRef } = refs

  const scrollBarX = scrollBarXRef.current.getBoundingClientRect()
  const scrollBarY = scrollBarYRef.current.getBoundingClientRect()

  if (
    relativeX >= scrollBarX.left &&
    relativeX <= scrollBarX.right &&
    relativeY >= scrollBarX.top &&
    relativeY <= scrollBarX.bottom
  ) {
    return DragType.SCROLL_BAR_X
  } else if (
    relativeX >= scrollBarY.left &&
    relativeX <= scrollBarY.right &&
    relativeY >= scrollBarY.top &&
    relativeY <= scrollBarY.bottom
  ) {
    return DragType.SCROLL_BAR_Y
  }
  return null
}

export const getRelativeMousePositionInDiv = (
  clientX: number,
  clientY: number,
  containerRef: React.RefObject<HTMLDivElement>,
) => {
  if (!containerRef.current) return { x: 0, y: 0 }

  const rect = containerRef.current.getBoundingClientRect()
  const scrollLeft = containerRef.current.scrollLeft || 0
  const scrollTop = containerRef.current.scrollTop || 0

  const relativeX = clientX - rect.left + scrollLeft
  const relativeY = clientY - rect.top + scrollTop

  return { x: relativeX, y: relativeY }
}

export const getRelativeScrollCenterPosition = (horizontalScrollRef: React.RefObject<HTMLDivElement>) => {
  if (!horizontalScrollRef.current) return 0

  const scrollLeft = horizontalScrollRef.current.scrollLeft || 0
  const clientWidth = horizontalScrollRef.current.clientWidth

  return scrollLeft + clientWidth / 2
}
