import classNames from 'classnames'
import Image from 'next/image'
import React, { useEffect, useRef, useState } from 'react'

import PlatinumIcon from '../../../../../assets/icons/lock-instrument.svg'
import PauseIcon from '../../../../../assets/icons/pause.svg'
import PlayIcon from '../../../../../assets/icons/play-small.svg'
import { useInstrumentsState } from '../../../../../context/InstrumentsStateContext'
import { useInternationalization } from '../../../../../context/InternationalizationContext'
import { useLimits } from '../../../../../context/LimitsContext'
import { createLayer, getPlaystyleIdByInstrumentKey } from '../../../../../utils/instrumentsUtils'
import { capitalize } from '../../../../../utils/stringUtils'
import { trackMixpanelEvent_changeInstrument } from '../../../../../utils/tracking'
import { Instrument } from '../../../../../utils/types'
import CircleLoader from '../../../../common/CircleLoader/CircleLoader'
import { INSTRUMENT_TYPES, SETTINGS_TYPES } from '../../../LayersOfInstruments/LayersOfInstruments'
import { DRUMS_CATEGORIES_NEW, INSTRUMENT_CATEGORIES_NEW } from '../../../TimelineWrapper/InstrumentMenu/constants'
import { usePlayerConfigState } from '../../../hooks/usePlayerConfigState'
import styles from './InstrumentMenu.module.scss'
import Menu from './Menu'

type Props = {
  open: boolean
  onClose: () => void
}

const InstrumentMenu: React.FC<Props> = ({ open, onClose }) => {
  const { text } = useInternationalization()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const {
    activeLayerId,
    editorMode,
    testInstrument,
    chordInstrumentsData,
    drumInstrumentsData,
    playstylesData,
    updateLayerById,
    activeLayer,
  } = useInstrumentsState()
  const { isInstrumentLimited, triggerLimitCallback } = useLimits()

  const playingRef = useRef<string>()

  const [loadingName, setLoadingName] = useState<string | null>(null)
  const [playingName, setPlayingName] = useState<string | null>(null)
  const [activeLeftMenuItem, setActiveLeftMenuItem] = useState('')

  const isChordMode = editorMode === INSTRUMENT_TYPES.CHORDS
  const isMelodyMode = editorMode === INSTRUMENT_TYPES.MELODY
  const isDrumMode = editorMode === INSTRUMENT_TYPES.DRUMS

  const leftMenuItems = isChordMode || isMelodyMode ? INSTRUMENT_CATEGORIES_NEW : DRUMS_CATEGORIES_NEW
  const rightMenuItems = (isChordMode || isMelodyMode ? chordInstrumentsData : drumInstrumentsData).sort((a, b) =>
    a.name.localeCompare(b.name, 'en'),
  )
  const STRINGS = text.footer.instrumentsEditor.instruments

  const activeRightMenuItem = activeLayer?.instrument.name
  const setActiveRightMenuItem = (instrument: Instrument) => {
    if (!activeLayer) return

    // @ts-ignore
    trackMixpanelEvent_changeInstrument(editorMode.toLowerCase(), instrument.key)

    switch (editorMode) {
      case 'CHORDS': {
        const playstyleId = getPlaystyleIdByInstrumentKey(
          instrument.key,
          chordInstrumentsData,
          activeLayer.playstyle.id,
          playstylesData,
        )

        updateLayerById(
          activeLayerId,
          createLayer(instrument.key, chordInstrumentsData, playstyleId, playstylesData, activeLayer),
          editorMode,
        )

        break
      }
      case 'MELODY': {
        updateLayerById(
          activeLayerId,
          createLayer(instrument.key, chordInstrumentsData, undefined, undefined, activeLayer),
          editorMode,
        )

        break
      }
      case 'DRUMS': {
        updateLayerById(
          activeLayerId,
          createLayer(instrument.key, drumInstrumentsData, undefined, undefined, activeLayer),
          editorMode,
        )

        break
      }
    }
  }

  useEffect(() => {
    testInstrument?.stop()
  }, [open])

  useEffect(() => {
    if (!rightMenuItems.length || !activeRightMenuItem) return

    const activeRightMenuItemInstrument = rightMenuItems.find((item) => item.name === activeRightMenuItem)

    if (!activeRightMenuItemInstrument) return

    setActiveLeftMenuItem(activeRightMenuItemInstrument.category)
  }, [open, activeRightMenuItem, rightMenuItems])

  if (!playerConfig.currentPart) return null

  const handlePreviewPlay = async (option: any) => {
    playerConfig.player.pause()

    setLoadingName(option.name)
    setPlayingName(null)
    playingRef.current = option.name
    testInstrument &&
      (await testInstrument.start(
        editorMode === INSTRUMENT_TYPES.DRUMS ? option.preview : option.path,
        editorMode === INSTRUMENT_TYPES.DRUMS,
        () => setPlayingName(option.name),
      ))
    if (playingRef.current !== option.name) {
      return
    }
    setPlayingName(null)
    setLoadingName(null)
  }

  const renderHeader = () => {
    return <div className={styles.header}>{STRINGS.title}</div>
  }

  const renderLeftMenu = () => {
    return (
      <div className={styles.leftMenu}>
        {leftMenuItems.map((opt) => {
          return (
            <div
              key={opt}
              className={styles.leftMenuItem}
              data-selected={opt === activeLeftMenuItem}
              onClick={() => setActiveLeftMenuItem(opt)}
            >
              {capitalize(opt)}
            </div>
          )
        })}
      </div>
    )
  }

  const renderRightMenu = () => {
    const renderItem = (option: Instrument) => {
      const isPlaying = playingName === option.name || loadingName === option.name
      const isLoading = loadingName === option.name && !playingName
      const isLimited = isInstrumentLimited(option)

      const renderPlayBtn = () => {
        if (!option.hasPreview)
          return (
            <span className={classNames(styles.playIcon, styles.noPreview)}>
              <PlayIcon />
            </span>
          )

        return (
          <>
            {isLoading ? (
              <span className={styles.loadingIcon}>
                <CircleLoader />
              </span>
            ) : isPlaying ? (
              <span
                onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                  e.stopPropagation()
                  testInstrument && testInstrument.stop()
                }}
                className={styles.playIcon}
              >
                <PauseIcon />
              </span>
            ) : (
              <span
                className={styles.playIcon}
                onClick={async (e: React.MouseEvent<HTMLSpanElement>) => {
                  e.stopPropagation()
                  if (loadingName && !playingName) {
                    return
                  }
                  await handlePreviewPlay(option)
                }}
              >
                <PlayIcon />
              </span>
            )}
          </>
        )
      }
      return (
        <div
          className={classNames(styles.rightMenuItem)}
          key={option.name + isPlaying}
          data-selected={option.name === activeRightMenuItem}
          data-loading={isPlaying}
          onClick={async () => {
            if (isLimited) {
              triggerLimitCallback(isLimited)
              onClose()
              return
            }

            if (isChordMode) playerConfigSetter.setInstrumentLoaded(false)
            if (isMelodyMode) playerConfigSetter.setMelodyInstrumentLoaded(false)
            if (isDrumMode) playerConfigSetter.setDrumsInstrumentLoaded(false)

            setActiveRightMenuItem(option)
            testInstrument?.stop()
          }}
        >
          {renderPlayBtn()}
          <div className={styles.name}>
            {option.name}
            {/* TODO: uncomment once needed */}
            {/* <span>
              {option.premium
                ? !currentUser || currentUser?.subscriptionPlan === 'free'
                  ? 'Upgrade to Platinum'
                  : 'Platinum'
                : 'Free'}
            </span> */}
          </div>
          {/* TODO: uncomment once needed */}
          {isLimited && <PlatinumIcon className={styles.platinumIcon} />}
        </div>
      )
    }

    const genre = (isChordMode ? playerConfig.chordGenre : playerConfig.drumGenre) || ''
    const image = playerConfig.drumGenre.imageUrl

    const filteredRightMenuItems = rightMenuItems.filter((opt) => opt.category === activeLeftMenuItem)

    const bestOptions = filteredRightMenuItems.filter((option) => option.genres.includes(genre.key))
    const otherOptions = filteredRightMenuItems.filter((option) => !option.genres.includes(genre.key))

    return (
      <div className={styles.rightMenu}>
        {bestOptions.length ? (
          <>
            <div className={styles.bestWith}>
              <div className={styles.image}>
                {image ? <Image src={image} layout='fill' alt='Image' objectFit='cover' /> : null}
              </div>
              <div className={styles.text}>
                {STRINGS.bestWith} {genre.name}:
              </div>
            </div>
            {bestOptions.map((option) => renderItem(option))}
          </>
        ) : null}

        {otherOptions.length ? (
          <>
            {bestOptions.length ? (
              <div className={styles.bestWith}>
                <div className={styles.text}>{STRINGS.other}</div>
              </div>
            ) : null}
            {otherOptions.map((option) => renderItem(option))}
          </>
        ) : null}
      </div>
    )
  }

  const renderFooter = () => {
    return <div className={styles.footer} />
  }

  return (
    <Menu
      settingType={SETTINGS_TYPES.INSTRUMENT}
      open={open}
      onClose={onClose}
      renderHeader={renderHeader}
      renderLeftMenu={renderLeftMenu}
      renderRightMenu={renderRightMenu}
      renderFooter={renderFooter}
    />
  )
}

export default InstrumentMenu
