import classNames from 'classnames'
import React, { ReactNode } from 'react'

import CheckIcon from '../../../assets/icons/check.svg'
import styles from './Checkbox.module.scss'

type Props = {
  label?: string | ReactNode
  checked: boolean
  secondary?: boolean
  classNameContainer?: string
  className?: string
  classNameChecked?: string
  onChange?: (a: boolean) => void
}

const Checkbox: React.FC<Props> = ({
  label,
  checked,
  onChange,
  classNameContainer,
  className,
  classNameChecked,
  secondary,
}) => {
  if (!onChange || !label) {
    return (
      <div
        className={classNames(styles.box, className, {
          [styles.secondary]: secondary,
          [styles.checked]: checked,
          [classNameChecked || '']: checked,
        })}
      >
        <CheckIcon />
      </div>
    )
  }
  return (
    <div className={classNames(styles.container, classNameContainer)} onClick={() => onChange(!checked)}>
      <div
        className={classNames(styles.box, className, {
          [styles.secondary]: secondary,
          [styles.checked]: checked,
          [classNameChecked || '']: checked,
        })}
      >
        <CheckIcon />
      </div>
      {typeof label === 'string' ? <span>{label}</span> : <div className={styles.labelContainer}>{label}</div>}
    </div>
  )
}

export default Checkbox
