import { useQuery } from 'react-query'

import { getTonalityKeysInnerRoute, getTonalityScalesInnerRoute } from '../../../api/constants'

type Props = {
  setTonalityScale: (a: string) => void
  tonalityScale?: string
}

const useConstantsFetcherState = ({ tonalityScale }: Props) => {
  const { data: tonalityScales } = useQuery({
    queryKey: ['tonality-scales-list'],
    queryFn: getTonalityScalesInnerRoute,
  })
  const { data: tonalityKeys } = useQuery({
    queryKey: ['tonality-keys-list', tonalityScale],
    queryFn: getTonalityKeysInnerRoute(tonalityScale || ''),
    enabled: !!tonalityScale,
  })

  return [
    {
      tonalityKeys,
      tonalityScales,
    },
  ]
}

export default useConstantsFetcherState
