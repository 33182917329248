import { MidiJSON, TrackJSON, Midi } from '@tonejs/midi'

import { PianoRollNote, SimplePianoRollNote } from '../components/ai-playground/InteractivePianoRoll/types'
import { convertNoteToToneJS } from './audio/audioUtils'
import { drumsToMidi, getDrumsMidiBuffer } from './audio/midiUtils'
import { PartPattern, PatternNote, ProgPartPattern } from './types'

export const PPQ = 24
export const TICKS_PER_BAR = PPQ * 4
export const DEFAULT_MIDI_NOTE = {
  ticks: 0,
  velocity: 0.8,
  durationTicks: PPQ,
  midi: 60,
  time: 0,
  name: '',
  duration: 0.5,
}

const cloneMidi = (midi: MidiJSON): MidiJSON => {
  return JSON.parse(JSON.stringify(midi))
}

export const getTrack = (pattern: PartPattern, trackName: string) => {
  const track = pattern.midi?.tracks.find((t) => t.name === trackName)
  if (track) {
    return track
  }
}

export const addTrack = (pattern: PartPattern, trackName: string) => {
  const newPattern = pattern.midi
    ? {
        ...pattern,
        midi: cloneMidi(pattern.midi),
      }
    : {
        midi: new Midi().toJSON(),
      }
  newPattern.midi.tracks.push({
    channel: 0,
    controlChanges: {},
    pitchBends: [],
    instrument: {
      family: 'piano',
      number: 0,
      name: 'acoustic grand piano',
    },
    notes: [],
    name: 'percs',
  })
  newPattern.midi.tracks[newPattern.midi.tracks.length - 1].name = trackName
  return newPattern
}

export const editTrackName = (pattern: PartPattern, trackName: string, newTrackName: string) => {
  if (!pattern.midi) {
    return pattern
  }
  const newPattern = {
    ...pattern,
    midi: cloneMidi(pattern.midi),
  }
  newPattern.midi.tracks = pattern.midi?.tracks.map((t) => {
    if (t.name === trackName) {
      t.name = newTrackName
    }
    return t
  })
  return newPattern
}

export const editTrackScale = (pattern: PartPattern, trackName: string, ind: number, scale: number) => {
  if (!pattern.midi) {
    return pattern
  }
  const newPattern = {
    ...pattern,
    midi: cloneMidi(pattern.midi),
  }
  newPattern.midi.tracks = pattern.midi?.tracks.map((t) => {
    if (t.name === trackName) {
      const ticks = getTicksByInd(ind)
      const nextBitTicks = getTicksByInd(ind + 1)
      const step = TICKS_PER_BAR / 4 / scale
      t.notes = t.notes.filter((note) => note.ticks < ticks || note.ticks >= nextBitTicks || note.ticks % step === 0)
      // @ts-ignore
      t.scaleObj = {
        // @ts-ignore
        ...(t.scaleObj || {}),
        [ind]: scale,
      }
    }
    return t
  })
  return newPattern
}

export const removeTrack = (pattern: PartPattern, trackName: string) => {
  if (!pattern.midi) {
    return pattern
  }
  const newPattern = {
    ...pattern,
    midi: cloneMidi(pattern.midi),
  }
  newPattern.midi.tracks = pattern.midi?.tracks.filter((t) => t.name !== trackName)
  return newPattern
}

export const toggleBitInTrack = (track: TrackJSON, ticks: number): TrackJSON => {
  const bit = track.notes.find((n) => n.ticks === ticks)
  return {
    ...track,
    notes: bit
      ? track.notes.filter((n) => n.ticks !== bit.ticks)
      : [...track.notes, convertNoteToMidiNote({ ticks })].sort((a, b) => a.ticks - b.ticks),
  }
}

export const getIsBitEnabled = (pattern: PartPattern, trackName: string, bitIndRaw: number, strict = false) => {
  const lastIndex = getLastIndexOfBit(pattern)
  const bitInd = strict ? bitIndRaw : bitIndRaw % lastIndex
  const track = pattern.midi?.tracks.find((t) => t.name === trackName)
  if (!track) {
    return { isActive: false, scale: 1, config: [] }
  }
  const notes: number[] = []
  for (let i = 0; i < 16; i++) {
    const sixteenth = track.notes.some((note) => getTicksByInd(bitInd, i) === note.ticks)
    if (sixteenth) {
      notes.push(i)
    }
  }
  const getScale = () => {
    // @ts-ignore
    if ((track.scaleObj || {})[bitInd]) {
      // @ts-ignore
      return track.scaleObj[bitInd]
    }
    if (!notes.length || (notes.length === 1 && notes[0] === 0)) {
      return 1
    }
    if (notes.every((item) => item % 4 === 0)) {
      return 4
    }
    return 16
  }
  return {
    isActive: notes.length > 0,
    scale: getScale(),
    config: notes,
  }
}

export const getTicksByInd = (ind: number, sixteenth = 0) => {
  return (ind * TICKS_PER_BAR) / 4 + (TICKS_PER_BAR / 4 / 16) * sixteenth
}

export const getIndByTicks = (ticks: number) => {
  return Math.floor((ticks / TICKS_PER_BAR) * 4)
}

export const clearNotesFromPattern = (pattern: PartPattern) => {
  const newPattern = JSON.parse(JSON.stringify(pattern)) as PartPattern
  const newMidi = {
    ...newPattern.midi,
    tracks:
      newPattern.midi?.tracks.map((t: TrackJSON) => {
        t.notes = []
        return t
      }) || [],
  } as MidiJSON
  newMidi.tracks.forEach((track, ind) => {
    // @ts-ignore
    track.scaleObj = pattern.midi?.tracks[ind]?.scaleObj
  })
  return {
    ...newPattern,
    midi: newMidi,
  }
}

export const toggleBit = (pattern: PartPattern, trackName: string, bitInd: number, sixteenth = 0): PartPattern => {
  const newPattern = JSON.parse(JSON.stringify(pattern)) as PartPattern
  const track = getTrack(newPattern, trackName)
  if (!track) {
    return pattern
  }
  const newTrack = toggleBitInTrack(track, getTicksByInd(bitInd, sixteenth))
  const newMidi = {
    ...newPattern.midi,
    tracks: newPattern.midi?.tracks.map((t: TrackJSON) => (t.name === track.name ? newTrack : t)) || [],
  } as MidiJSON
  newMidi.tracks.forEach((track, ind) => {
    // @ts-ignore
    track.scaleObj = pattern.midi?.tracks[ind]?.scaleObj
  })
  return {
    ...newPattern,
    midi: newMidi,
  }
}

export const convertNoteToMidiNote = ({ ticks, duration }: PatternNote) => {
  return {
    ticks: ticks,
    velocity: 0.8,
    durationTicks: duration || TICKS_PER_BAR / 4,
    midi: 60,
    time: 0,
    name: '',
    duration: 0.5,
  }
}

export const getLastIndexOfBit = (pattern: PartPattern | { midi: MidiJSON }) => {
  const lastTrack = pattern?.midi?.tracks.map((t) => t.notes[t.notes.length - 1]?.ticks).filter(Boolean) || []
  const ind = (getIndByTicks(Math.max(...lastTrack, 0)) || 3) + 1
  return Math.ceil(ind / 4) * 4
}

export const getPathForCustomDrumPattern = (id?: string) => {
  if (!id) {
    return null
  }
  return `custom:${id}`
}
export const getIdFromCustomDrumPatternPath = (path: string) => path?.replace('custom:', '')
export const isDrumPatternCustom = (path?: string) => path?.startsWith('custom:')

export const closestToSixteenthTicks = (ticks: number) =>
  Math.round(ticks / (TICKS_PER_BAR / 4 / 16)) * (TICKS_PER_BAR / 4 / 16)

export const quantizePattern = (midi: MidiJSON) => {
  return {
    ...midi,
    tracks: midi.tracks.map((track) => ({
      ...track,
      notes: track.notes.map((note) => convertNoteToMidiNote({ ticks: closestToSixteenthTicks(note.ticks) })),
    })),
  }
}

export const TEMP_PRESET = 'New Preset'
export const TEMP_GENERATED_PRESET = 'AI Generated'

const SAMPLER_NAMES: Record<number, string> = {
  0: 'kicks',
  1: 'snares',
  2: 'hihats',
  3: 'claps',
  4: 'openhats',
  5: 'percs',
}

export const getSamplerNames = (drums: ProgPartPattern) => {
  const tracks = drums?.groups.flatMap((group) => group.percTypes.map((pt) => pt.type)) || []

  return Object.fromEntries(
    Object.values(SAMPLER_NAMES)
      .filter((trackName) => tracks.includes(trackName))
      .map((trackName, index) => [index, trackName]),
  )
}
export const getSamplerNamesReverse = (drums: ProgPartPattern) => {
  const samplerNames = getSamplerNames(drums)
  return Object.fromEntries(Object.entries(samplerNames).map(([trackMidi, trackName]) => [trackName, +trackMidi]))
}
export const getMaxSamplerMidi = (drums: ProgPartPattern) => {
  const samplerNames = getSamplerNames(drums)

  return Object.keys(samplerNames).length
}

export const drumsToPianoRollNotes = (drums: ProgPartPattern): SimplePianoRollNote[] => {
  const drumsMidi = drumsToMidi(drums)
  const SAMPLER_NAMES_REVERSE = getSamplerNamesReverse(drums)

  const pianoRollNotes = drumsMidi.tracks.flatMap((track) => {
    return track.notes.map((note) => ({
      midiNote: SAMPLER_NAMES_REVERSE[track.name],
      startTicks: note.ticks,
      endTicks: note.ticks + note.durationTicks,
      velocity: note.velocity * 127,
    }))
  })

  return pianoRollNotes
}
export const pianoRollNotesToDrums = (pianoRollNotes: PianoRollNote[], drums: ProgPartPattern): ProgPartPattern => {
  if (!drums) return drums

  const oldDrumsMidi = drumsToMidi(drums)
  const SAMPLER_NAMES_REVERSE = getSamplerNamesReverse(drums)

  const newGroups = drums.groups.map((group, index) => {
    const groupPercTypes = group.percTypes.map((pt) => pt.type)

    const newMidi = JSON.parse(
      JSON.stringify({ ...oldDrumsMidi, tracks: oldDrumsMidi.tracks.filter((t) => groupPercTypes.includes(t.name)) }),
    ) as MidiJSON

    newMidi.tracks = newMidi.tracks.map((track) => ({
      ...track,
      notes: pianoRollNotes
        .filter((note) => note.midiNote === SAMPLER_NAMES_REVERSE[track.name])
        .map((note) => ({
          ...DEFAULT_MIDI_NOTE,
          name: convertNoteToToneJS(60).toNote(),
          velocity: note.velocity / 127,
          ticks: note.startTicks,
          durationTicks: note.endTicks - note.startTicks,
        })),
    }))

    return {
      ...group,
      pattern: { key: `manual-${index}`, buffer: getDrumsMidiBuffer(newMidi) },
    }
  })

  return { ...drums, groups: newGroups }
}
