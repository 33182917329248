import React, { ReactNode } from 'react'

import LimitIcon from '../assets/icons/limit.svg'
import { Chord, FrontendUserDetailed, Instrument, InstrumentLayer, ProgPart } from '../utils/types'
import { useCommonModals } from './CommonModalsContext'
import { useCurrentUser } from './CurrentUserContext'

export enum FRONTEND_LIMIT_TYPES {
  parts = 'parts',
  layers = 'layers',
  chords = 'chords',
  drums_autocomplete = 'drums_autocomplete',
  chord_palette = 'chord_palette',
}

type TFrontendLimit = keyof typeof FRONTEND_LIMIT_TYPES
type TFrontendLimits = { [key in TFrontendLimit]: number }
type TFrontendModes = { anonymous: TFrontendLimits; free: TFrontendLimits; paid: TFrontendLimits }

type FetchType = {
  isAnonymousUser: boolean
  isFreeUser: boolean
  isNotSubscribed: boolean

  isPartsLimited: (parts: ProgPart[]) => boolean
  isLayersLimited: (layers: InstrumentLayer[]) => boolean
  isChordsLimited: (oldChords: Chord[], newChords?: Chord[]) => boolean
  isDrumsAutocompleteLimited: (autocomplete: number) => boolean
  isChordEditorLimited: () => boolean
  isInstrumentLimited: (instrument: Instrument) => boolean

  renderLimitIcon: (isLimited: boolean, className?: string) => ReactNode
  triggerLimitCallback: (isLimited?: boolean, limit?: TFrontendLimit) => void
}

export const LimitsContext = React.createContext<FetchType>({
  isAnonymousUser: false,
  isFreeUser: false,
  isNotSubscribed: false,

  isPartsLimited: () => false,
  isLayersLimited: () => false,
  isChordsLimited: () => false,
  isDrumsAutocompleteLimited: () => false,
  isChordEditorLimited: () => false,
  isInstrumentLimited: () => false,

  renderLimitIcon: () => <></>,
  triggerLimitCallback: () => {},
})

const frontendLimits: TFrontendModes = {
  anonymous: { parts: 2, layers: 2, chords: 6, drums_autocomplete: 2, chord_palette: -1 },
  free: { parts: 2, layers: 2, chords: 6, drums_autocomplete: 10, chord_palette: -1 },
  paid: { parts: -1, layers: -1, chords: -1, drums_autocomplete: -1, chord_palette: -1 },
}
const limitIconStyles = {
  position: 'absolute',
  right: 10,
  top: 10,

  width: 20,
  height: 20,

  color: '#F4F4F8',
}

export const LimitsProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useCurrentUser() as {
    currentUser: FrontendUserDetailed | null
  }
  const { setLoginOpen, setLoginLimitType, setPricingOpen, setPricingLimited, setTryPlatinumOpen } = useCommonModals()

  const isAnonymousUser = currentUser === null
  const isFreeUser = !!currentUser && currentUser.subscriptionPlan === 'free'
  const isNotSubscribed = isAnonymousUser || isFreeUser

  const limits = (() => {
    if (isAnonymousUser) return frontendLimits.anonymous
    if (isFreeUser) return frontendLimits.free
    return frontendLimits.paid
  })()

  const checkIfLimited = (currentUsage: number, expectedUsage: number, limit: number) => {
    return limit !== -1 && (currentUsage >= limit || expectedUsage > limit)
  }

  const isPartsLimited = (parts: ProgPart[]) => {
    return checkIfLimited(parts.length, 0, limits.parts)
  }
  const isLayersLimited = (layers: InstrumentLayer[]) => {
    return checkIfLimited(layers.length, 0, limits.layers)
  }
  const isChordsLimited = (oldChords: Chord[], newChords?: Chord[]) => {
    return checkIfLimited(oldChords.length, newChords?.length || 0, limits.chords)
  }
  const isDrumsAutocompleteLimited = (autocomplete: number) => {
    return checkIfLimited(autocomplete, 0, limits.drums_autocomplete)
  }
  const isChordEditorLimited = () => isAnonymousUser || isFreeUser
  const isInstrumentLimited = (instrument: Instrument) => {
    return instrument.premium && isNotSubscribed
  }

  const renderLimitIcon = (isLimited: boolean, className?: string) => {
    if (!isLimited) return null
    return <LimitIcon style={limitIconStyles} className={className} />
  }
  const triggerLimitCallback = (isLimited?: boolean, limit?: TFrontendLimit) => {
    if (isLimited === false) return

    if (isAnonymousUser) {
      setLoginOpen(true)
      // setLoginLimitType('chords')

      return
    }

    setTryPlatinumOpen('default', limit)
    setPricingOpen(true)
    setPricingLimited(true)
  }

  return (
    <LimitsContext.Provider
      value={{
        isAnonymousUser,
        isFreeUser,
        isNotSubscribed,

        isPartsLimited,
        isLayersLimited,
        isChordsLimited,
        isDrumsAutocompleteLimited,
        isChordEditorLimited,
        isInstrumentLimited,

        renderLimitIcon,
        triggerLimitCallback,
      }}
    >
      {children}
    </LimitsContext.Provider>
  )
}

export const useLimits = () => React.useContext<FetchType>(LimitsContext)
