import {
  CursorMode,
  InteractivePianoRollContextType,
  PianoRollMode,
  PianoRollPlayheadContextType,
  SnapType,
} from '../types'
import {
  detectNoteAndDragForPos,
  getRelativeMousePositionInDiv,
  positionToNoteCoordinates,
} from '../utils/positionHelper'
import { detectPianoRollNoteAndDrag } from '../utils/positionHelper'

export const handleRulerClick = (
  event: React.MouseEvent<HTMLDivElement>,
  refs: any,
  context: InteractivePianoRollContextType,
  playheadContext: PianoRollPlayheadContextType,
) => {
  const { ppqSnapRef, onPositionUpdateCallbackRef } = context
  const { setPlayheadPositionTicks } = playheadContext
  const { x, y } = getRelativeMousePositionInDiv(event.clientX, event.clientY, refs.containerRef)
  const playheadCoordinates = positionToNoteCoordinates(context, x, y, ppqSnapRef.current, SnapType.ROUND)
  setPlayheadPositionTicks(playheadCoordinates.startTicks)
  onPositionUpdateCallbackRef.current?.(playheadCoordinates.startTicks, true)
}

// export const handleVerticalPianoClick = (
//   event: React.MouseEvent<HTMLDivElement>,
//   refs: any,
//   context: InteractivePianoRollContextType,
//   midiNote: number,
// ) => {
//   const { onNoteSoundCallbackRef } = context

//   if (onNoteSoundCallbackRef.current)
//     // TODO: Improve this feature to have the highlighting of the note that is played.
//     onNoteSoundCallbackRef.current({
//       midiNote,
//       startTicks: 0,
//       endTicks: 0,
//       velocity: 100,
//       opacity: 1,
//       color: 'red',
//       id: 'test',
//     })
// }

export const handleClick = (
  event: React.MouseEvent<HTMLDivElement>,
  refs: any,
  context: InteractivePianoRollContextType,
  playheadContext: PianoRollPlayheadContextType,
) => {
  const {
    cursorMode,
    setSelectedNoteIds,
    clearSelectedNoteIds,
    selectNote,
    removeNote,
    addNote,
    noteColor,
    noteLengthTicks,
    noteOpacity,
    noteVelocity,
    onPositionUpdateCallbackRef,
    ppqSnapRef,
    autocompleteNotes,
    acceptAutocompleteNotes,
    removeAllAutocompleteNotes,
    isContextMenuOpen,
    setIsContextMenuOpen,
  } = context

  if (isContextMenuOpen) {
    setIsContextMenuOpen(false)
    return
  }

  const { setPlayheadPositionTicks } = playheadContext

  if (!refs.containerRef.current) return

  const { x, y } = getRelativeMousePositionInDiv(event.clientX, event.clientY, refs.containerRef)

  // Handle autocomplete notes click
  const autocompleteNote = detectNoteAndDragForPos(context, x, y, true, autocompleteNotes)
  if (autocompleteNote) {
    acceptAutocompleteNotes()
    return
  } else {
    removeAllAutocompleteNotes()
  }

  // Handle normal pianoroll click

  const { note } = detectPianoRollNoteAndDrag(context, x, y) || {}
  const { note: preciseNote } = detectPianoRollNoteAndDrag(context, x, y, false) || {}

  const isShiftPressed = event.shiftKey
  // const isCmdOrCtrlPressed = event.metaKey || event.ctrlKey

  switch (cursorMode) {
    case CursorMode.SELECTION:
      if (note && isShiftPressed) {
        selectNote(note.id)
      } else if (note && !isShiftPressed) {
        setSelectedNoteIds(new Set([note.id]))
      } else {
        const playheadCoordinates = positionToNoteCoordinates(context, x, y, ppqSnapRef.current, SnapType.ROUND)
        setPlayheadPositionTicks(playheadCoordinates.startTicks)
        onPositionUpdateCallbackRef.current?.(playheadCoordinates.startTicks, false)
        clearSelectedNoteIds()
      }
      break
    case CursorMode.PENCIL:
      if (!note) {
        const noteCoordinates = positionToNoteCoordinates(context, x, y, noteLengthTicks)
        addNote({
          startTicks: noteCoordinates.startTicks,
          midiNote: noteCoordinates.midiNote,
          velocity: noteVelocity,
          opacity: noteOpacity,
          color: noteColor,
          endTicks: noteCoordinates.startTicks + noteLengthTicks,
        })
      }
      break
    case CursorMode.BRUSH:
      if (preciseNote) {
        clearSelectedNoteIds()
        removeNote(preciseNote.id)
      } else {
        const noteCoordinates = positionToNoteCoordinates(context, x, y, noteLengthTicks)
        addNote({
          startTicks: noteCoordinates.startTicks,
          midiNote: noteCoordinates.midiNote,
          velocity: noteVelocity,
          opacity: noteOpacity,
          color: noteColor,
          endTicks: noteCoordinates.startTicks + noteLengthTicks,
        })
      }
      break
    case CursorMode.VELOCITY:
      break
  }
}

export const handleDoubleClick = (
  event: React.MouseEvent<HTMLDivElement>,
  refs: any,
  context: InteractivePianoRollContextType,
) => {
  const { cursorMode, removeNote, addNote, noteColor, noteLengthTicks, noteOpacity, noteVelocity } = context

  if (cursorMode !== CursorMode.SELECTION && cursorMode !== CursorMode.PENCIL) return
  if (!refs.containerRef.current) return

  const { x, y } = getRelativeMousePositionInDiv(event.clientX, event.clientY, refs.containerRef)
  const { note } = detectPianoRollNoteAndDrag(context, x, y) || {}

  if (note) {
    removeNote(note.id)
  } else if (cursorMode === CursorMode.SELECTION) {
    const noteCoordinates = positionToNoteCoordinates(context, x, y)

    addNote({
      startTicks: noteCoordinates.startTicks,
      midiNote: noteCoordinates.midiNote,
      velocity: noteVelocity,
      opacity: noteOpacity,
      color: noteColor,
      endTicks: noteCoordinates.startTicks + noteLengthTicks,
    })
  }
}

export const handleRightClick = (
  event: React.MouseEvent<HTMLDivElement>,
  refs: any,
  context: InteractivePianoRollContextType,
) => {
  event.preventDefault() // Prevent the default context menu from appearing
  const {
    pianoRollMode,
    setIsContextMenuOpen,
    setContextMenuPosition,
    selectedNoteIds,
    setSelectedNoteIds,
  } = context

  if (pianoRollMode !== PianoRollMode.PIANO_ROLL) return

  const { x: insideX, y: insideY } = getRelativeMousePositionInDiv(event.clientX, event.clientY, refs.containerRef)
  const { note } = detectPianoRollNoteAndDrag(context, insideX, insideY) || {}
  if (note && !selectedNoteIds.has(note.id)) {
    setSelectedNoteIds(new Set([note.id]))
  }
  const { x, y } = getRelativeMousePositionInDiv(event.clientX, event.clientY, refs.mainContainerRef)
  setIsContextMenuOpen(true)
  setContextMenuPosition({ top: y, left: x })
}
