import { getApiChordProg } from '../utils/api'
import { Prog } from '../utils/types'
import api from './index'

export const generateMelodyPattern = async (
  prog: Prog,
  tonalityKey?: string | string[] | null,
  tonalityScale?: string | string[] | null,
) => {
  const data = await api.post('/api/melody-track/melody/generate', {
    prog: getApiChordProg(prog),
    tonalityKey,
    tonalityScale,
  })
  return data.data.pianoRollNotes
}
