import * as Tone from 'tone'
import * as wm from 'webmidi'

import { getPartIdFromInstrumentKey } from '../../../../utils/instrumentsUtils'
import { Layers, TVolumeNormalizator } from './Layers'
import MOPlayer from './MidiOutputPlayer'

type TTiming = {
  time: string
  velocity: number
  note: any
  duration: any
  id: number
  instrumentKey: string
}

export class Player extends Layers {
  part: Tone.Part | null = null
  progVolume: Tone.Volume | null = null
  timings: TTiming[] = []
  isPlaying = false

  constructor(normalizeVolume: TVolumeNormalizator, denormalizeVolume: TVolumeNormalizator, equalizerVolume = 100) {
    super(normalizeVolume, denormalizeVolume, equalizerVolume)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleChange = (a: number | null) => {
    void 0
  }

  setPartVolume = (volume: number, partId: number) => {
    const voolumeRef = this.partsVolume[partId]
    if (!voolumeRef) return
    voolumeRef.volume.value = this.normalizeEquilizeVolume(volume)
  }

  play = () => {
    try {
      this.part?.start()
    } catch (e) {
      console.error(e)
    }
  }
  reset = () => {
    this.part?.stop()
  }
  dispose = () => {
    this.part?.clear()
    this.part?.dispose()
  }

  setBpm = (bpm: number) => {
    Tone.Transport.bpm.value = bpm
  }

  initExportVolume = (instrumentKey: string) => {
    const partId = getPartIdFromInstrumentKey(instrumentKey)

    const progVolumeValue = this.progVolume?.volume?.value
    const partVolumeValue = this.partsVolume[partId]?.volume?.value
    const layerVolumeValue = this.layersVolume[instrumentKey]?.volume?.value

    if (progVolumeValue === undefined || partVolumeValue === undefined || layerVolumeValue === undefined) {
      return { volume: null, refs: [] }
    }

    const progVolume = new Tone.Volume(progVolumeValue)
    const partVolume = this.initVolume(this.denormalizeDeequilizeVolume(partVolumeValue), progVolume)
    const layerVolume = this.initVolume(this.denormalizeVolume(layerVolumeValue), partVolume, false, false)

    return { volume: layerVolume, refs: [progVolume, partVolume, layerVolume] }
  }
}

export class MidiOutputPlayer {
  midiOutput?: MOPlayer
  outputActive = false

  setWebMidiPlaying: (a: number, add?: boolean) => void = () => {}

  getMidiOutput = (port: string, key = 'default') => {
    if (this.midiOutput) {
      this.midiOutput.updatePort(key, port)
    }
    return this.midiOutput || null
  }
  initWebMidi(playMidiAttack: (midi: number, velocity: number) => void, playMidiRelease: (midi: number) => void) {
    try {
      const onNotePressed = (e: any) => {
        if (this.outputActive) {
          return
        }
        const midi = e.note.number + 12
        playMidiAttack(midi, e.note.rawAttack * 1.08)
        this.setWebMidiPlaying(midi, true)
      }
      const onNoteLeft = (e: any) => {
        if (this.outputActive) {
          return
        }
        const midi = e.note.number + 12
        this.setWebMidiPlaying(midi, false)
        playMidiRelease(midi)
      }
      if (navigator.requestMIDIAccess !== undefined) {
        wm.WebMidi.enable({ sysex: true }).then(() => {
          if (wm.WebMidi.inputs.length) {
            wm.WebMidi.inputs[0].addListener('start', () => console.log('midiInout start'))
            wm.WebMidi.inputs[0].addListener('stop', () => console.log('midiInout stop'))
            wm.WebMidi.inputs[0].addListener('noteon', onNotePressed)
            wm.WebMidi.inputs[0].addListener('noteoff', onNoteLeft)
          }
          wm.WebMidi.addListener('connected', () => {
            try {
              wm.WebMidi.inputs[0].addListener('noteon', onNotePressed)
              wm.WebMidi.inputs[0].addListener('noteoff', onNoteLeft)
            } catch (e) {
              console.error(e)
            }
          })
          this.midiOutput = new MOPlayer(wm.WebMidi.outputs)
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  constructor() {}
}
