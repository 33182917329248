import classNames from 'classnames'
import React, { FC, useMemo } from 'react'

import MutedInstrumentIcon from '../../../../assets/icons/muted-instrument.svg'
import NotMutedInstrumentIcon from '../../../../assets/icons/not-muted-instrument.svg'
import { updateProgPartDataById } from '../../../../utils/progUtils'
import NewVolumeControls from '../../Footer/NewVolumeControls'
import { INSTRUMENT_TYPES, InstrumentType } from '../../LayersOfInstruments/LayersOfInstruments'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './EditorSettings.module.scss'

type Props = {
  mode: InstrumentType
  className?: string
}

const STRINGS = {
  volume: 'Volume',
}

const VolumeSetting: FC<Props> = ({ mode, className }) => {
  const {
    playerConfig: { player, prog, currentPartId, currentPart },
    playerConfigSetter: { setProg },
  } = usePlayerConfigState()

  const isMelodyMode = mode === INSTRUMENT_TYPES.MELODY
  const isDrumsMode = mode === INSTRUMENT_TYPES.DRUMS

  const volume = useMemo(() => {
    if (isMelodyMode) return currentPart.melodyVolume
    if (isDrumsMode) return currentPart.drumsVolume
    return 0
  }, [mode, currentPartId, currentPart])
  const isMuted = useMemo(() => {
    if (isMelodyMode) return currentPart.melodyMuted
    if (isDrumsMode) return currentPart.drumsMuted
    return false
  }, [mode, currentPartId, currentPart])

  const handleChangeVolume = (newVolumeRaw: number) => {
    const newVolume = isMuted ? 0 : newVolumeRaw

    if (isMelodyMode) player.setMelodyPartVolume(newVolume, currentPartId)
    if (isDrumsMode) player.setDrumsPartVolume(newVolume, currentPartId)
  }
  const handleAfterChangeVolume = (newVolume: number) => {
    if (!prog) return

    const updates: any = {}

    if (isMelodyMode) {
      updates.melodyVolume = newVolume
      updates.melodyMuted = newVolume === 0
    }
    if (isDrumsMode) {
      updates.drumsVolume = newVolume
      updates.drumsMuted = newVolume === 0
    }

    const newProg = updateProgPartDataById(prog, currentPartId, updates)
    setProg(newProg)
  }
  const handleMuteMelody = (e: any) => {
    if (!prog) return
    e.stopPropagation()

    const newMuted = !isMuted
    const newVolume = newMuted ? 0 : volume
    const updates: any = {}

    if (isMelodyMode) {
      updates.melodyMuted = newMuted
      player.setMelodyPartVolume(newVolume, currentPartId)
    }
    if (isDrumsMode) {
      updates.drumsMuted = newMuted
      player.setDrumsPartVolume(newVolume, currentPartId)
    }

    const newProg = updateProgPartDataById(prog, currentPartId, updates)
    setProg(newProg)
  }

  return (
    <div className={classNames(styles.volumeSetting, className)}>
      <div className={styles.volumeSettingContent}>
        <div className={styles.volumeSettingTitle}>{STRINGS.volume}:</div>

        <NewVolumeControls
          data-block-swipe='true'
          color={isMelodyMode ? 'blue' : 'green'}
          volume={volume}
          onVolumeChange={handleChangeVolume}
          onAfterVolumeChange={handleAfterChangeVolume}
          mode='thin-enlarged'
          hideControls
          className={styles.volumeControl}
          muted={isMuted}
        />
      </div>

      <div className={classNames(styles.volumeBtn, { [styles.melodyMuted]: isMuted })} onClick={handleMuteMelody}>
        {isMuted ? <MutedInstrumentIcon /> : <NotMutedInstrumentIcon />}
      </div>
    </div>
  )
}

export default VolumeSetting
