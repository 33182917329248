import { trackMixpanelEvent } from '../api/tracking'

export type TTrack = 'chords' | 'melody' | 'drums'
export type TChordPaletteMode = 'AI Suggestions' | 'Diatonic Chords' | 'All Chords (Custom)'

// Tracks
export const trackMixpanelEvent_addNewSongPart = async (type: 'empty' | 'duplicate' | 'AI variation') => {
  await trackMixpanelEvent('add_new_song_part', { type })
}
export const trackMixpanelEvent_addTrack = async (type: TTrack) => {
  await trackMixpanelEvent('add_track', { type })
}
export const trackMixpanelEvent_addChordPlusClick = async () => {
  await trackMixpanelEvent('add_chord_plus_click', {})
}
export const trackMixpanelEvent_addMelodyPlusClick = async () => {
  await trackMixpanelEvent('add_melody_plus_click', {})
}
export const trackMixpanelEvent_addDrumsPlusClick = async () => {
  await trackMixpanelEvent('add_drums_plus_click', {})
}

// Instruments
export const trackMixpanelEvent_changeInstrument = async (type: TTrack, instrument_name: string) => {
  await trackMixpanelEvent('change_instrument', { type, instrument_name })
}
export const trackMixpanelEvent_addInstrumentLayer = async () => {
  await trackMixpanelEvent('add_instrument_layer', {})
}
export const trackMixpanelEvent_clickRandomInstrument = async (type: TTrack) => {
  await trackMixpanelEvent('click_random_instrument', { type })
}

// Editors view
export const trackMixpanelEvent_openChordEditor = async () => {
  await trackMixpanelEvent('open_chord_editor', {})
}
export const trackMixpanelEvent_selectChordPaletteMode = async (type: TChordPaletteMode) => {
  await trackMixpanelEvent('select_chord_palette_mode', { type })
}
export const trackMixpanelEvent_openMelodyPianoRoll = async () => {
  await trackMixpanelEvent('open_melody_piano_roll', {})
}
export const trackMixpanelEvent_openDrumsSimpleMode = async () => {
  await trackMixpanelEvent('open_drums_simple_mode', {})
}
export const trackMixpanelEvent_openDrumsManualMode = async () => {
  await trackMixpanelEvent('open_drums_manual_mode', {})
}

//
export const trackMixpanelEvent_drumsSimpleChangePattern = async () => {
  await trackMixpanelEvent('drums_simple_change_pattern', {})
}
export const trackMixpanelEvent_pickChordFromPalette = async (type: TChordPaletteMode) => {
  await trackMixpanelEvent('pick_chord_from_palette', { type })
}
export const trackMixpanelEvent_acceptDrumsAutocomplete = async () => { // TODO: implement once autocomplete callback is ready
  await trackMixpanelEvent('accept_drums_autocomplete', {})
}
