import dynamic from 'next/dynamic'
import React, { ReactNode, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { updateUndoRedoShownInnerRoute } from '../api/user-prefs'
import { INSTRUMENT_TYPES, InstrumentType } from '../components/editor/LayersOfInstruments/LayersOfInstruments'
import { usePlayerConfigState } from '../components/editor/hooks/usePlayerConfigState'
import useStorageState from '../hooks/useStorageState'
import { getPathForCustomDrumPattern } from '../utils/drumsUtils'
import { hjEvent } from '../utils/hotjar'
import { FrontendUserDetailed, ProgPart } from '../utils/types'
import { useCommonModals } from './CommonModalsContext'
import { useCurrentUser } from './CurrentUserContext'
import { useInstrumentsState } from './InstrumentsStateContext'
import { useInternationalization } from './InternationalizationContext'

const EditorPortal = dynamic(() =>
  import('../components/editor/EditorPortal/EditorPortal').then((module) => module.EditorPortal),
)
const ProjectEditNameModal = dynamic(
  () => import('../components/editor/Header/ProjectEditNameModal/ProjectEditNameModal'),
)
const ClearLyricsModal = dynamic(
  () => import('../components/editor/LyricsEditor/LyricsModals/ClearLyricsModal/ClearLyricsModal'),
)
const AILyricsModal = dynamic(
  () => import('../components/editor/LyricsEditor/LyricsModals/AILyricsModal/AILyricsModal'),
)
const UndoRedoHelpModal = dynamic(
  () => import('../components/editor/TimelineWrapper/UndoRedoHelpModal/UndoRedoHelpModal'),
)

export type PortalConfig = {
  randomInstrument: boolean
  randomMelodyInstrument: boolean
  randomDrumInstrument: boolean
  complexity?: string
  mode?: string
  tempo?: string
  customTempo: number
  chordsComplexity?: number
  isChordsLoading: boolean
  setIsChordsLoading: (a: boolean) => void
  setRandomInstrument: (a: boolean | ((v: boolean) => boolean)) => void
  setRandomMelodyInstrument: (a: boolean | ((v: boolean) => boolean)) => void
  setRandomDrumInstrument: (a: boolean | ((v: boolean) => boolean)) => void
  setComplexity: (a: string) => void
  setMode: (a: string) => void
  setTempo: (a: string) => void
  setCustomTempo: (a: number) => void
  setChordsComplexity: (a: 1 | 2 | 3) => void
}

export type SelectionPosition = { line: number; offset: number } | null

type FetchType = {
  isChordsSettingsOpen: boolean
  setIsChordsSettingsOpen: any
  isMelodySettingsOpen: boolean
  setIsMelodySettingsOpen: any
  isDrumsSettingsOpen: boolean
  setIsDrumsSettingsOpen: any
  drumsSettingsPosition: 'timeline' | 'drums-editor'
  setDrumsSettingsPosition: (a: 'timeline' | 'drums-editor') => void
  portalsConfig: PortalConfig
  isSongBootsrapOpen: boolean
  setIsSongBootstrapOpen: any
  currentCustomPatternPath?: string | null
  setProjectNameModalOpen: (a: boolean) => void
  isClearLyricsOpen: boolean
  setIsClearLyricsOpen: (a: boolean) => void
  isAILyricsOpen: boolean
  setIsAILyricsOpen: (a: boolean) => void
  isFooterHidden: boolean
  setIsFooterHidden: (a: boolean) => void
  currentWord: string
  setCurrentWord: (v: string) => void
  currentWordPosition: SelectionPosition
  setCurrentWordPosition: (v: SelectionPosition) => void
  editNameModalOpen: ProgPart | null
  setEditNameModalOpen: (v: ProgPart | null) => void
  undoRedoShown: boolean
  undoRedoHelpModalOpen: boolean
  setUndoRedoShown: (a: boolean) => void
  setUndoRedoHelpModalOpen: (a: boolean) => void
  quizOpen: boolean
  drumsMenuOpenRow: number
  drumsManualOpen: boolean
  setDrumsMenuOpenRow: (a: number) => void
  setDrumsManualOpen: (a: boolean) => void
  showRomanNumerals: boolean
  setShowRomanNumerals: (a: boolean | ((v: boolean) => boolean)) => void
}

export const GeneratorModalsContext = React.createContext<FetchType>({
  isChordsSettingsOpen: false,
  setIsChordsSettingsOpen: () => {},
  isMelodySettingsOpen: false,
  setIsMelodySettingsOpen: () => {},
  isDrumsSettingsOpen: false,
  setIsDrumsSettingsOpen: () => {},
  drumsSettingsPosition: 'timeline',
  setDrumsSettingsPosition: () => {},
  portalsConfig: {} as PortalConfig,
  isSongBootsrapOpen: false,
  setIsSongBootstrapOpen: () => {},
  setProjectNameModalOpen: () => {},
  isClearLyricsOpen: false,
  setIsClearLyricsOpen: () => {},
  isAILyricsOpen: false,
  setIsAILyricsOpen: () => {},
  isFooterHidden: false,
  setIsFooterHidden: () => {},
  currentWord: '',
  setCurrentWord: () => {},
  currentWordPosition: null,
  setCurrentWordPosition: () => {},
  editNameModalOpen: null,
  setEditNameModalOpen: () => {},
  undoRedoShown: false,
  undoRedoHelpModalOpen: false,
  setUndoRedoShown: () => {},
  setUndoRedoHelpModalOpen: () => {},
  quizOpen: false,
  drumsMenuOpenRow: -1,
  drumsManualOpen: false,
  setDrumsMenuOpenRow: () => {},
  setDrumsManualOpen: () => {},
  showRomanNumerals: false,
  setShowRomanNumerals: () => {},
})

export const GeneratorModalsProvider = ({ children }: { children: ReactNode }) => {
  const commonModalsConfig = useCommonModals()
  const { addComponentText } = useInternationalization()
  const { playerConfig } = usePlayerConfigState()
  const { chordsInstrumentName, melodyInstrumentName, drumsInstrumentName } = useInstrumentsState()
  const queryClient = useQueryClient()
  const { currentUser } = useCurrentUser() as {
    currentUser: FrontendUserDetailed
  }

  const [activeModal, setActiveModal] = useState<InstrumentType | null>(null)
  const [projectNameModalOpen, setProjectNameModalOpen] = useState(false)
  const [isChordsSettingsOpen, setIsChordsSettingsOpen] = useState(false)
  const [isMelodySettingsOpen, setIsMelodySettingsOpen] = useState(false)
  const [isDrumsSettingsOpen, setIsDrumsSettingsOpen] = useState(false)
  const [drumsSettingsPosition, setDrumsSettingsPosition] = useState<'timeline' | 'drums-editor'>('timeline')
  const [tempo, setTempo] = useState<string | undefined>('Current')
  const [customTempo, setCustomTempo] = useState(180)
  const [quizOpen, setQuizOpen] = useState<boolean>(false)
  const [isSongBootsrapOpen, setIsSongBootstrapOpen] = useState(false)
  const [isClearLyricsOpen, setIsClearLyricsOpen] = useState(false)
  const [isAILyricsOpen, setIsAILyricsOpen] = useState(false)
  const [isChordsLoading, setIsChordsLoading] = useState(false)
  const [isFooterHidden, setIsFooterHidden] = useState(false)
  const [currentWord, setCurrentWord] = useState('')
  const [currentWordPosition, setCurrentWordPosition] = useState<SelectionPosition>(null)
  const [editNameModalOpen, setEditNameModalOpen] = useState<ProgPart | null>(null)
  const [undoRedoShown, setUndoRedoShown] = useState(false)
  const [undoRedoHelpModalOpen, setUndoRedoHelpModalOpen] = useState(false)

  const [showRomanNumerals, setShowRomanNumerals] = useStorageState(false, 'show-roman-numerals')
  const [randomInstrument, setRandomInstrument] = useStorageState(false, 'portal-random-instrument')
  const [randomMelodyInstrument, setRandomMelodyInstrument] = useStorageState(true, 'portal-random-melody-instrument')
  const [randomDrumInstrument, setRandomDrumInstrument] = useStorageState(true, 'portal-random-drum-instrument')
  const [complexity, setComplexity] = useStorageState<string | undefined>(undefined, 'portal-complexity')
  const [mode, setMode] = useStorageState<string | undefined>(undefined, 'portal-mode')
  const [chordsComplexity, setChordsComplexity] = useStorageState<1 | 2 | 3>(2, 'portal-chords-complexity')

  const [drumsMenuOpenRow, setDrumsMenuOpenRow] = useState(-1)
  const [drumsManualOpen, setDrumsManualOpen] = useState(false)

  useEffect(() => {
    addComponentText('GeneratorModalsContext')
  }, [])
  useEffect(() => {
    if (commonModalsConfig.loginOpen || commonModalsConfig.pricingOpen) {
      setIsChordsSettingsOpen(false)
      setIsMelodySettingsOpen(false)
      setIsDrumsSettingsOpen(false)
    }
  }, [commonModalsConfig.loginOpen])

  // @ts-ignore
  const currCustomPreset = getPathForCustomDrumPattern(playerConfig.currentPart?.drums?.customId)

  const handleOpenQuizCheck = () => {
    if (currentUser && (!currentUser.prefs?.quizTaken || !currentUser.prefs?.afterRegModalShown)) {
      hjEvent('event', 'quiz_open')
      setQuizOpen(true)
    }
  }
  useEffect(() => {
    if (currentUser) {
      handleOpenQuizCheck()
    }
  }, [])
  return (
    <GeneratorModalsContext.Provider
      value={{
        isChordsSettingsOpen,
        setIsChordsSettingsOpen,
        isMelodySettingsOpen,
        setIsMelodySettingsOpen,
        isDrumsSettingsOpen,
        setIsDrumsSettingsOpen,
        drumsSettingsPosition,
        setDrumsSettingsPosition,
        portalsConfig: {
          randomInstrument,
          randomMelodyInstrument,
          randomDrumInstrument,
          tempo,
          chordsComplexity,
          customTempo,
          mode,
          complexity,
          isChordsLoading,
          setIsChordsLoading,
          setRandomInstrument,
          setRandomMelodyInstrument,
          setRandomDrumInstrument,
          setComplexity,
          setMode,
          setTempo,
          setCustomTempo,
          setChordsComplexity,
        },
        isSongBootsrapOpen,
        setIsSongBootstrapOpen,
        setProjectNameModalOpen,
        currentCustomPatternPath: currCustomPreset,
        isClearLyricsOpen,
        setIsClearLyricsOpen,
        isAILyricsOpen,
        setIsAILyricsOpen,
        isFooterHidden,
        setIsFooterHidden,
        currentWord,
        setCurrentWord,
        currentWordPosition,
        setCurrentWordPosition,
        editNameModalOpen,
        setEditNameModalOpen,
        undoRedoShown,
        undoRedoHelpModalOpen,
        setUndoRedoShown,
        setUndoRedoHelpModalOpen,
        quizOpen,
        drumsMenuOpenRow,
        drumsManualOpen,
        setDrumsMenuOpenRow,
        setDrumsManualOpen,
        showRomanNumerals,
        setShowRomanNumerals,
      }}
    >
      <EditorPortal
        type={INSTRUMENT_TYPES.CHORDS}
        isOpen={isChordsSettingsOpen}
        instrumentName={chordsInstrumentName}
        onClose={() => setIsChordsSettingsOpen(false)}
        isActive={activeModal === INSTRUMENT_TYPES.CHORDS}
        setActive={() => setActiveModal(INSTRUMENT_TYPES.CHORDS)}
      />
      <EditorPortal
        type={INSTRUMENT_TYPES.MELODY}
        isOpen={isMelodySettingsOpen}
        instrumentName={melodyInstrumentName}
        onClose={() => setIsMelodySettingsOpen(false)}
        isActive={activeModal === INSTRUMENT_TYPES.MELODY}
        setActive={() => setActiveModal(INSTRUMENT_TYPES.MELODY)}
      />
      <EditorPortal
        type={INSTRUMENT_TYPES.DRUMS}
        isOpen={isDrumsSettingsOpen}
        instrumentName={drumsInstrumentName}
        onClose={() => setIsDrumsSettingsOpen(false)}
        isActive={activeModal === INSTRUMENT_TYPES.DRUMS}
        setActive={() => setActiveModal(INSTRUMENT_TYPES.DRUMS)}
        position={drumsSettingsPosition}
      />
      <ProjectEditNameModal open={projectNameModalOpen} onClose={() => setProjectNameModalOpen(false)} />
      <ClearLyricsModal isOpen={isClearLyricsOpen} onClose={() => setIsClearLyricsOpen(false)} />
      <AILyricsModal isOpen={isAILyricsOpen} onClose={() => setIsAILyricsOpen(false)} />
      <UndoRedoHelpModal
        open={undoRedoHelpModalOpen}
        onClose={async () => {
          setUndoRedoHelpModalOpen(false)
          await updateUndoRedoShownInnerRoute()
          queryClient.invalidateQueries(['user-prefs'])
        }}
      />
      {children}
    </GeneratorModalsContext.Provider>
  )
}

export const useGeneratorModals = () => React.useContext<FetchType>(GeneratorModalsContext)
