import classNames from 'classnames'
import React, { ReactNode, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Transition } from 'react-transition-group'

import BackIcon from '../../../../assets/icons/signup/back.svg'
import CloseIcon from '../../../../assets/icons/signup/close.svg'
import useSizes from '../../../../hooks/useSizes'
import MobileSwipeableModal from '../../../common/MobileSwipeableModal/MobileSwipeableModal'
import styles from './SignupFlowModal.module.scss'

type Props = {
  opened: boolean
  actionType?: 'close' | 'back'
  onClose?: () => void
  onBack?: () => void
  actionsDisabled?: boolean
  actionsHidden?: boolean
  children?: ReactNode
  title?: string
}

const SignupFlowModal: React.FC<Props> = ({
  opened,
  actionType = 'close',
  onClose = () => {},
  onBack = () => {},
  actionsDisabled = false,
  actionsHidden = false,
  children,
  title,
}) => {
  const { isMobile } = useSizes()

  const modalRef = useRef<HTMLDivElement>(null)

  const isCloseMode = actionType === 'close'
  const isBackMode = actionType === 'back'

  useEffect(() => {
    if (modalRef && modalRef.current && opened) modalRef.current.focus()
  }, [opened])
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') handleClose()
    }

    if (opened) document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [opened, actionsDisabled])

  const handleClose = () => {
    if (actionsDisabled) return
    onClose()
  }
  const handleBack = () => {
    if (actionsDisabled) return
    onBack()
  }
  const handleCompleteAction = () => {
    if (isCloseMode) handleClose()
    if (isBackMode) handleBack()
  }

  const renderActions = () => {
    if (actionsDisabled || actionsHidden) return null
    return (
      <div className={styles.modalAction} onClick={handleCompleteAction}>
        {isCloseMode ? <CloseIcon /> : <BackIcon />}
      </div>
    )
  }
  const renderHeader = () => {
    if (!title) return renderActions()
    return (
      <div className={styles.modalHeader}>
        {renderActions()}

        <div className={styles.modalTitle}>{title}</div>
      </div>
    )
  }

  if (isMobile) {
    return (
      <MobileSwipeableModal
        className={styles.fitContentInner}
        controlsClassName={styles.fitContentOuter}
        onClose={handleClose}
        open={opened}
        blockingModal
      >
        {children}
      </MobileSwipeableModal>
    )
  }
  return (
    <Transition in={opened} timeout={100} unmountOnExit nodeRef={modalRef}>
      {(status) => (
        <>
          {createPortal(
            <div
              ref={modalRef}
              className={classNames(styles.modalWrap, {
                [styles.modalActive]: status === 'entering',
                [styles.modalDone]: status === 'entered',
                [styles.modalExitActive]: status === 'exiting',
                [styles.modalExitDone]: status === 'exited',
              })}
              onClick={handleClose}
              data-totally-disable-click-outside-hook={true}
            >
              <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                {renderHeader()}

                {children}
              </div>
            </div>,
            document.getElementById('__next') as HTMLElement,
          )}
        </>
      )}
    </Transition>
  )
}

export default SignupFlowModal
