import * as Tone from 'tone'

import { SingleInstrumentKey, getPartIdFromInstrumentKey } from '../../../../utils/instrumentsUtils'
import { ExtendedPlaystyle } from '../../../../utils/playstylesTypes'
import { InstrumentLayer } from '../../../../utils/types'
import MidiOutputPlayer from './MidiOutputPlayer'

export type TSampler = Tone.Sampler | MidiOutputPlayer | null
export type TSamplers = { [key: string]: TSampler }
export type TSamplerData = { [key: string]: { config: any; buffer: any } }
export type TSamplersVolume = { [key: string]: Tone.Volume | null }
export type TPlaystyles = { [key: string]: ExtendedPlaystyle }

export type TVolumeNormalizator = (v: number) => number
type TSetupLayersVolumeData = { [key: string]: number }

export type TInstrumentData = {
  category: string
  config: { envelope: any; volume: number; octaveShift: number; limiter: number }
  genres: string[]
  hasPreview: boolean
  key: string
  name: string
  path: string
  premium: boolean
  published: boolean
  _id: string
}

export class Layers {
  instrumentsData: TInstrumentData[] = []
  layersVolume: TSamplersVolume = {}
  playstyles: TPlaystyles = {}
  partsVolume: TSamplersVolume = {}

  layersVolumeEqualizer: TSetupLayersVolumeData = {}

  shouldResetup = false

  normalizeEquilizeVolume: TVolumeNormalizator = (v: number) => v
  denormalizeDeequilizeVolume: TVolumeNormalizator = (v: number) => v

  normalizeVolume: TVolumeNormalizator = (v: number) => v
  denormalizeVolume: TVolumeNormalizator = (v: number) => v

  constructor(normalizeVolume: TVolumeNormalizator, denormalizeVolume: TVolumeNormalizator, partVolumeEqualizer = 100) {
    const partEqualizerCoef = partVolumeEqualizer / 100

    // for parts volume only
    this.normalizeEquilizeVolume = (volume) => (normalizeVolume(volume) + 100) * partEqualizerCoef - 100
    this.denormalizeDeequilizeVolume = (volume) => denormalizeVolume(volume) / partEqualizerCoef

    // for all volumes except part
    this.normalizeVolume = normalizeVolume
    this.denormalizeVolume = denormalizeVolume
  }

  updateData = (data: { [key: string]: any }, func: any) => {
    Object.keys(data).map((key) => func(data[key]))
  }

  initVolume = (newVolumeValue = 100, volume: Tone.Volume, isPart = true, toDestination = true): Tone.Volume => {
    let volumeRef: any = null

    volumeRef = new Tone.Volume()
    volumeRef.volume.value = isPart
      ? this.normalizeEquilizeVolume(newVolumeValue)
      : this.normalizeVolume(newVolumeValue)
    volumeRef.connect(toDestination ? volume.toDestination() : volume)

    return volumeRef
  }
  defineLayerToRemoveToSetup = (
    prevData: { [key: string]: any },
    newData: { obj?: { [key: string]: any }; arr?: InstrumentLayer[] },
    instrumentMidiOut?: { [key: string]: string },
  ) => {
    const prevKeys = Object.keys(prevData)
    const newKeys = newData.obj
      ? Object.keys(newData.obj)
      : newData.arr?.length
      ? newData.arr.map((layer) => layer.instrument?.key).filter(Boolean)
      : prevKeys
    const midiOutKeys = instrumentMidiOut ? Object.keys(instrumentMidiOut) : []

    const filterKeys = (filterKeys: string[], key: string) => {
      return (
        !filterKeys.includes(key) || (instrumentMidiOut && midiOutKeys.includes(key)) || key === SingleInstrumentKey
      )
    }
    const getOctaveChanged = (key: string) => {
      const prevOctave = prevData[key]?.config?.octaveShift
      const newConfig = newData.obj ? newData.obj[key] : newData.arr?.find((l) => l.instrument.key === key)
      const newOctave = newConfig?.octave || newConfig?.config?.octaveShift
      return +(prevOctave || 0) !== +(newOctave || 0)
    }
    const dataToSetup = newKeys.filter((key) => filterKeys(prevKeys, key) || getOctaveChanged(key))
    const dataToRemove = prevKeys.filter((key) => filterKeys(newKeys, key))

    return { dataToSetup, dataToRemove }
  }
  definePlaystylesToRemoveToSetup = (prevData: TPlaystyles, newData: InstrumentLayer[]) => {
    const prevKeys = Object.keys(prevData)
    const newKeys = newData.map((layer) => layer.playstyle?.id).filter(Boolean)

    const filterKeys = (filterKeys: string[], key: string) => {
      return !filterKeys.includes(key) || key === 'single-instrument'
    }

    const playstylesToSetup = Array.from(new Set(newKeys.filter((key) => filterKeys(prevKeys, key))))
    const playstylesToRemove = Array.from(new Set(prevKeys.filter((key) => filterKeys(newKeys, key))))

    return { playstylesToSetup, playstylesToRemove }
  }

  setupChordLayersVolume = (samplerData: TSamplerData) => {
    const data: TSetupLayersVolumeData = {}

    Object.entries(samplerData).forEach(([key, { config }]) => (data[key] = config.volume))

    this.setupLayersVolume(data)
  }
  setupDrumLayersVolume = (layers: InstrumentLayer[]) => {
    const data: TSetupLayersVolumeData = {}

    layers.forEach((layer) => (data[layer.instrument.key] = this.normalizeVolume(layer.muted ? 0 : layer.volume)))

    this.setupLayersVolume(data)
  }
  setupLayersVolume = (data: TSetupLayersVolumeData) => {
    const { dataToRemove, dataToSetup } = this.defineLayerToRemoveToSetup(this.layersVolume, { obj: data })

    dataToRemove.forEach((key) => {
      this.layersVolume[key]?.disconnect()
      this.layersVolume[key]?.dispose()
      delete this.layersVolume[key]
    })

    dataToSetup.forEach((key) => {
      const partId = getPartIdFromInstrumentKey(key)
      const voolumeRef = this.partsVolume[partId]
      if (!voolumeRef) return

      const prevLayerVolume = this.denormalizeVolume(this.layersVolume[key]?.volume.value || data[key])

      this.layersVolume[key] = this.initVolume(prevLayerVolume, voolumeRef, false, false)
    })
  }

  setupSamplersVolume = (samplers: TSamplers, volume?: Tone.Volume | null) => {
    Object.keys(samplers).map((key) => {
      if (volume === null) return

      const volumeToConnect = volume ? volume : this.layersVolume[key]
      if (!volumeToConnect) return

      samplers[key]?.connect(volumeToConnect)
    })
  }

  setLayerVolume = (key: string, volume: number) => {
    const layerVolume = this.layersVolume[key]
    if (!layerVolume) return

    const layerVolumeEqualizer = this.layersVolumeEqualizer[key] || 1
    layerVolume.volume.value = this.normalizeVolume(volume * layerVolumeEqualizer)
  }

  setShouldResetup = (flag = true, value = true) => {
    const shouldResetup = this.shouldResetup

    if (flag && !this.shouldResetup) this.shouldResetup = value

    return shouldResetup
  }
}
