import dynamic from 'next/dynamic'
import React, { useEffect, useState } from 'react'

import useSizes from '../../../../hooks/useSizes'
import { LimitTypesLabel } from '../../../../utils/limits'
import CenterModal from '../../../common/CenterModal'
import MobileSwipeableModal from '../../../common/MobileSwipeableModal/MobileSwipeableModal'
import styles from './TryPlatinumModal.module.scss'

// const StripeDefaultScreen = dynamic(() => import('./Stripe/DefaultScreen/DefaultScreen'))
// const StripePricingScreen = dynamic(() => import('./Stripe/PricingScreen/PricingScreen'))
// const StripePaymentScreen = dynamic(() => import('./Stripe/PaymentScreen/PaymentScreen'))

const PaddleDefaultScreen = dynamic(() => import('./Paddle/DefaultScreen/DefaultScreen'))
const PaddlePricingScreen = dynamic(() => import('./Paddle/PricingScreen/PricingScreen'))
const PaddlePaymentScreen = dynamic(() => import('./Paddle/PaymentScreen/PaymentScreen'))

const TAB_STATES = {
  DEFAULT: 'DEFAULT',
  PRICING: 'PRICING',
  PAYMENT: 'PAYMENT',
}

type Props = {
  onClose: () => void
  open: boolean
  paymentProvider: 'Stripe' | 'Paddle'
  upgradeMode?: boolean
  upgradeModeTitle?: LimitTypesLabel
  tabState?: string
}

const TryPlatinumModal: React.FC<Props> = ({
  onClose,
  open,
  upgradeMode,
  upgradeModeTitle,
  tabState,
  paymentProvider,
}) => {
  const { isSmallTablet } = useSizes()

  const [tab, setTab] = useState(tabState || TAB_STATES.DEFAULT)

  const isRenderArrowBack = [TAB_STATES.PRICING, TAB_STATES.PAYMENT].includes(tab)

  // const { priceIds } = useStripe()
  // const isStripe = paymentProvider === 'Stripe'
  // const priceId = {
  //   pro: {
  //     monthly: priceIds.pro.monthly.trial.price_1299_3,
  //     annual: priceIds.pro.yealy.trial.price_9900_7,
  //   },
  //   premium: {
  //     monthly: priceIds.premium.monthly.trial.price_1999_3,
  //     annual: priceIds.premium.yealy.trial.price_14700_7,
  //   },
  // }

  useEffect(() => {
    setTab(tabState || TAB_STATES.DEFAULT)
  }, [tabState])
  useEffect(() => {
    if (!open) setTimeout(() => setTab(TAB_STATES.DEFAULT), 500)
  }, [open])

  const renderDefault = () => {
    const props = {
      open,
      upgradeMode,
      upgradeModeTitle,
      onClose,
      onNextClick: () => setTab(TAB_STATES.PRICING),
    }

    // if (isStripe) return <StripeDefaultScreen {...props} />
    return <PaddleDefaultScreen {...props} />
  }
  const renderPricing = () => {
    const props = {
      // priceId,
      onNextClick: () => setTab(TAB_STATES.PAYMENT),
    }

    // if (isStripe) return <StripePricingScreen {...props} />
    return <PaddlePricingScreen {...props} />
  }
  const renderPayment = () => {
    const props = {
      // priceId,
      onBackClick: () => setTab(TAB_STATES.PRICING),
    }

    // if (isStripe) return <StripePaymentScreen {...props} />
    return <PaddlePaymentScreen {...props} />
  }

  const renderBody = () => {
    return (
      <div className={styles.animateContainer}>
        <div
          className={`${styles.container} ${styles.animateSlide}`}
          style={{
            opacity: tab === TAB_STATES.DEFAULT ? 1 : 0,
            zIndex: tab === TAB_STATES.DEFAULT ? 1 : 0,
            left: tab === TAB_STATES.DEFAULT ? 0 : -150,
          }}
        >
          {renderDefault()}
        </div>
        <div
          style={{
            opacity: tab === TAB_STATES.PRICING ? 1 : 0,
            zIndex: tab === TAB_STATES.PRICING ? 1 : 0,
            right: tab === TAB_STATES.PRICING ? 0 : tab === TAB_STATES.PAYMENT ? 150 : -150,
            left: tab === TAB_STATES.PRICING ? 0 : tab === TAB_STATES.PAYMENT ? -150 : 150,
          }}
          className={`${styles.tableContainer} ${styles.animateSlide}`}
        >
          {renderPricing()}
        </div>
        <div
          style={{
            opacity: tab === TAB_STATES.PAYMENT ? 1 : 0,
            zIndex: tab === TAB_STATES.PAYMENT ? 1 : 0,
            right: tab === TAB_STATES.PAYMENT ? 0 : -150,
            left: tab === TAB_STATES.PAYMENT ? 0 : 150,
          }}
          className={`${styles.tableContainer} ${styles.animateSlide}`}
        >
          {renderPayment()}
        </div>
      </div>
    )
  }

  if (isSmallTablet) {
    return (
      <MobileSwipeableModal open={open} onClose={onClose} blockingModal>
        <>
          {tab === TAB_STATES.DEFAULT && <div className={styles.container}>{renderDefault()}</div>}
          {tab === TAB_STATES.PRICING && <div className={styles.tableContainer}>{renderPricing()}</div>}
          {tab === TAB_STATES.PAYMENT && <div className={styles.tableContainer}>{renderPayment()}</div>}
        </>
      </MobileSwipeableModal>
    )
  }

  return (
    <CenterModal
      visible={open}
      onCancel={(iconClick) => {
        if (tab === TAB_STATES.PAYMENT && iconClick) {
          setTab(TAB_STATES.PRICING)
          return
        }
        if (tab === TAB_STATES.PRICING && iconClick) {
          setTab(TAB_STATES.DEFAULT)
          return
        }
        onClose()
      }}
      className={styles.modal}
      size='medium'
      closeIcon={isRenderArrowBack ? 'arrow-back' : undefined}
      closeIconClassName={styles.closeIcon}
    >
      {renderBody()}
    </CenterModal>
  )
}

export default TryPlatinumModal
