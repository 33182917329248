import { InteractivePianoRollContextType } from '../types'

export const handleKeyDown = (
  e: KeyboardEvent,
  contentDivRef: React.RefObject<HTMLDivElement>,
  context: InteractivePianoRollContextType,
) => {
  const {
    removeNotes,
    selectedNoteIds,
    copySelectedNotes,
    cutSelectedNotes,
    pasteNotes,
    undo,
    redo,
    setSelectedNoteIds,
    pianoRollNotes,
    autocompleteNotes,
    acceptAutocompleteNotes,
  } = context
  // if (e.metaKey || e.ctrlKey) {
  //   if (contentDivRef.current) {
  //     contentDivRef.current.style.cursor = 'pointer'
  //   }
  // }
  // if (e.altKey) {
  //   if (contentDivRef.current) {
  //     contentDivRef.current.style.cursor = 'copy'
  //   }
  // }

  // Handle delete and backspace keys

  // We have to figure out the best way to actually integrate it with the chordchord main code.

  if (e.key === 'Tab') {
    if (autocompleteNotes.length > 0) {
      e.preventDefault()
      acceptAutocompleteNotes()
    }
  }

  if (e.key === 'Delete' || e.key === 'Backspace') {
    removeNotes(Array.from(selectedNoteIds))
  }

  // Handle CMD + C or CTRL + C
  if ((e.metaKey || e.ctrlKey) && (e.key === 'c' || e.key == 'C')) {
    e.preventDefault()
    copySelectedNotes()
  }

  if ((e.metaKey || e.ctrlKey) && (e.key === 'x' || e.key == 'X')) {
    e.preventDefault()
    cutSelectedNotes()
  }

  if ((e.metaKey || e.ctrlKey) && (e.key === 'v' || e.key == 'V')) {
    e.preventDefault()
    pasteNotes()
  }

  if ((e.metaKey || e.ctrlKey) && e.shiftKey && (e.key === 'z' || e.key == 'Z')) {
    e.preventDefault()
    redo()
  } else if ((e.metaKey || e.ctrlKey) && (e.key === 'z' || e.key == 'Z')) {
    e.preventDefault()
    undo()
  }

  if ((e.metaKey || e.ctrlKey) && (e.key === 'A' || e.key === 'a')) {
    e.preventDefault()
    setSelectedNoteIds(new Set(pianoRollNotes.map((note) => note.id)))
  }
}

export const handleKeyUp = (e: KeyboardEvent, contentDivRef: React.RefObject<HTMLDivElement>) => {
  // if (!e.metaKey && !e.ctrlKey) {
  //   if (contentDivRef.current) {
  //     contentDivRef.current.style.cursor = 'default'
  //   }
  // }
}
