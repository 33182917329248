// context/InteractivePianoRollContext.tsx
import React, { createContext, useContext, useState } from 'react'

import { PianoRollPlayheadContextType, PianoRollPlayheadProviderProps } from '../types'

// Create a map to store multiple context instances
const PianoRollPlayheadContexts: { [key: string]: React.Context<PianoRollPlayheadContextType | undefined> } = {}

// Function to get or create a context for a specific ID
const getContext = (id: string) => {
  if (!PianoRollPlayheadContexts[id]) {
    PianoRollPlayheadContexts[id] = createContext<PianoRollPlayheadContextType | undefined>(undefined)
  }
  return PianoRollPlayheadContexts[id]
}

// Provider component
export const PianoRollPlayheadProvider = ({ children, id }: PianoRollPlayheadProviderProps) => {
  const [playheadPositionTicks, setPlayheadPositionTicks] = useState<number>(0)
  const Context = getContext(id)

  return (
    <Context.Provider
      value={{
        id,
        // Playhead
        playheadPositionTicks,
        setPlayheadPositionTicks,
      }}
    >
      {children}
    </Context.Provider>
  )
}

// Hook to use the Piano Roll Notes context
export const usePianoRollPlayhead = (id: string) => {
  const Context = getContext(id)
  const context = useContext(Context)

  if (!context) {
    throw new Error(`usePianoRollPlayhead must be used within a PianoRollPlayheadProvider with id: ${id}`)
  }

  return context
}
