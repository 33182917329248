import classNames from 'classnames'
import React, { FC } from 'react'

import { useInteractivePianoRoll } from '../../../ai-playground/InteractivePianoRoll/hooks/useInteractivePianoRoll'
import { drumsPianoRollId } from '../../DrumsEditor/DrumsEditor'
import { INSTRUMENT_TYPES, InstrumentType } from '../../LayersOfInstruments/LayersOfInstruments'
import { melodyPianoRollId } from '../../MelodyEditor/MelodyEditor'
import styles from './EditorSettings.module.scss'

type Props = {
  noteDuration: number
  setNoteDuration: (ticks: number) => void
  mode?: InstrumentType
  className?: string
}

const STRINGS = {
  duration: 'Duration',
}

const DurationSetting: FC<Props> = ({ noteDuration, setNoteDuration, mode = '', className }) => {
  const { ppq } = useInteractivePianoRoll(mode === INSTRUMENT_TYPES.MELODY ? melodyPianoRollId : drumsPianoRollId)

  const durationOptions: any = {
    [ppq / 4]: '1/4',
    [ppq / 2]: '1/2',
    [ppq]: '1',
    [ppq * 2]: '2',
    [ppq * 4]: '4',
  }

  return (
    <div className={classNames(styles.durationSetting, className)}>
      <div className={styles.durationTitle}>
        {STRINGS.duration}: {durationOptions[noteDuration]}
      </div>

      <div className={styles.durationContent}>
        {Object.keys(durationOptions).map((durationOption) => (
          <div
            key={durationOption}
            className={classNames(styles.durationOption, styles[mode], {
              [styles.durationSelected]: +durationOption <= noteDuration,
              [styles.durationSelectedLast]: +durationOption === noteDuration,
            })}
            onClick={() => setNoteDuration(+durationOption)}
          />
        ))}
      </div>
    </div>
  )
}

export default DurationSetting
