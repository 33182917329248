import React, { useEffect } from 'react'

import { useCurrentUser } from '../../../context/CurrentUserContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import { LIMITS_LIST, LimitType } from '../../../utils/limits'
import { FrontendUserDetailed } from '../../../utils/types'
import LimitProgress from './LimitProgress/LimitProgress'

const UserLimits = ({
  generatorComponent,
  userUsage,
  limitType,
}: {
  generatorComponent?: boolean
  userUsage?: any
  limitType?: LimitType
}) => {
  const { addComponentText, getText, isTextReady } = useInternationalization()
  const { currentUser } = useCurrentUser() as {
    currentUser: FrontendUserDetailed | null
  }

  useEffect(() => {
    addComponentText('UserLimits')
  }, [])

  const limits = userUsage || currentUser?.limits
  const STRINGS = getText('UserLimits')

  if (!limits || !isTextReady(STRINGS)) return null

  let LIMITS = [
    {
      title: LimitType.generations,
      limit: limits.generations.limit,
      current: limits.generations.current,
    },
    {
      title: LimitType.suggestions,
      limit: limits.suggestions.limit,
      current: limits.suggestions.current,
    },
    {
      title: LimitType.exports,
      limit: limits.exports.limit,
      current: limits.exports.current,
    },
    {
      title: LimitType.voicings,
      limit: limits.voicings.limit,
      current: limits.voicings.current,
    },
    {
      title: LimitType.lyricsSuggestions,
      limit: limits.lyrics_suggestions.limit,
      current: limits.lyrics_suggestions.current,
    },
    {
      title: LimitType.projects,
      limit: limits.projects.limit,
      current: limits.projects.current,
    },
    {
      title: LimitType.shares,
      limit: limits.shares.limit,
      current: limits.shares.current,
    },
    {
      title: LimitType.midiOutput,
      limit: 0,
      current: 0,
    },
    {
      title: LimitType.guitarChords,
      limit: 0,
      current: 0,
    },
    {
      title: LimitType.rag,
      limit: limits.rag.limit,
      current: limits.rag.current,
    },
    {
      title: LimitType.aiDemo,
      limit: limits.aiDemo.limit,
      current: limits.aiDemo.current,
    },
  ]
    .filter((l) => {
      const isPaidUser = currentUser?.subscriptionPlan && currentUser?.subscriptionPlan !== 'free'
      return l.title !== LimitType.midiOutput || !isPaidUser
    })
    .map((l) => {
      return {
        ...l,
        isRecurrent: LIMITS_LIST.find((ll) => ll.title === l.title)?.isRecurrent,
      }
    })
  if (limitType) {
    LIMITS = LIMITS.filter((a) => a.title === limitType)
  }
  return (
    <>
      {LIMITS.map((lim) => (
        <LimitProgress
          title={STRINGS.types[lim.title]}
          limit={lim.limit}
          current={lim.current}
          isRecurrent={lim.isRecurrent}
          generatorComponent={generatorComponent}
          key={lim.title}
        />
      ))}
    </>
  )
}

export default UserLimits
