import classNames from 'classnames'
import React, { FC } from 'react'

import RandomPickerIcon from '../../../../assets/icons/random-picker.svg'
import { pianoRollNotesToDrums } from '../../../../utils/drumsUtils'
import { updateProgPartDataById } from '../../../../utils/progUtils'
import { useInteractivePianoRoll } from '../../../ai-playground/InteractivePianoRoll/hooks/useInteractivePianoRoll'
import { PianoRollNote } from '../../../ai-playground/InteractivePianoRoll/types'
import { drumsPianoRollId } from '../../DrumsEditor/DrumsEditor'
import NewVolumeControls from '../../Footer/NewVolumeControls'
import { INSTRUMENT_TYPES, InstrumentType } from '../../LayersOfInstruments/LayersOfInstruments'
import { melodyPianoRollId } from '../../MelodyEditor/MelodyEditor'
import { defaultDrums, defaultMelody, usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './EditorSettings.module.scss'

type Props = {
  velocity: number
  setVelocity: (newVelocity: number, delta?: number) => void
  setSelectedNotes: (v: PianoRollNote[] | ((v: PianoRollNote[]) => PianoRollNote[])) => void

  disabled: boolean

  mode?: InstrumentType
  className?: string
}

const STRINGS = {
  velocity: 'Velocity',
}

export const velocityMinValue = 0
export const velocityMaxValue = 127

const VelocitySetting: FC<Props> = ({ velocity, setVelocity, setSelectedNotes, disabled, mode, className }) => {
  const isMelodyMode = mode === INSTRUMENT_TYPES.MELODY
  const isDrumsMode = mode === INSTRUMENT_TYPES.DRUMS

  const {
    playerConfig: {
      prog,
      currentPartId,
      currentPart: { melody, drums },
    },
    playerConfigSetter: { setProg },
  } = usePlayerConfigState()
  const { pianoRollNotes, updateNotes } = useInteractivePianoRoll(isMelodyMode ? melodyPianoRollId : drumsPianoRollId)

  const handleChangeVelocity = (newVelocity: number, delta?: number) => {
    if (disabled) return

    setVelocity(newVelocity)

    setSelectedNotes((selectedNotes) => {
      const newNotes = selectedNotes.map((note) => {
        const rawVelocity = delta !== undefined ? note.velocity + delta : newVelocity
        const velocity = Math.max(Math.min(rawVelocity, velocityMaxValue), velocityMinValue)

        return { ...note, velocity }
      })

      updateNotes(
        newNotes.map((note) => ({
          noteId: note.id,
          updatedFields: { velocity: note.velocity },
        })),
      )

      return newNotes
    })
  }
  const handleAfterChangeVelocity = () => {
    if (isMelodyMode && !melody) return
    if (isDrumsMode && !drums) return
    if (!prog) return

    const updates: any = {}

    if (isMelodyMode) {
      const newMelody = { notes: pianoRollNotes }

      updates.melodyShown = true
      updates.melody = { ...(melody || defaultMelody), ...newMelody }
    }
    if (isDrumsMode) {
      const newDrums = pianoRollNotesToDrums(pianoRollNotes, drums)

      updates.drumsShown = true
      updates.drums = { ...(drums || defaultDrums), ...newDrums }
    }

    setTimeout(() => {
      const newProg = updateProgPartDataById(prog, currentPartId, updates)
      setProg(newProg)
    }, 500)
  }
  const handlePickRandomVelocity = () => {
    if (disabled) return

    const randomVelocity = Math.floor(Math.random() * (velocityMaxValue + 1))

    handleChangeVelocity(randomVelocity)
    handleAfterChangeVelocity()
  }

  return (
    <div className={classNames(styles.velocitySetting, className, { [styles.velocityDisabled]: disabled })}>
      <div className={styles.velocityContent}>
        <div className={styles.velocityTitle}>
          {STRINGS.velocity}: {velocity}
        </div>

        <NewVolumeControls
          color={mode === INSTRUMENT_TYPES.DRUMS ? 'green' : 'blue'}
          volume={velocity}
          onVolumeChange={handleChangeVelocity}
          onAfterVolumeChange={handleAfterChangeVelocity}
          minValue={velocityMinValue}
          maxValue={velocityMaxValue}
          mode='thin-enlarged'
          hideControls
          className={styles.velocitySlider}
          disabled={disabled}
        />
      </div>

      <div className={styles.velocityRandom} onClick={handlePickRandomVelocity}>
        <RandomPickerIcon />
      </div>
    </div>
  )
}

export default VelocitySetting
