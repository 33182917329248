import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'

import GenresIcon from '../../../assets/icons/genres.svg'
import { useGeneratorModals } from '../../../context/GeneratorModalsContext'
import { useInstrumentsState } from '../../../context/InstrumentsStateContext'
import useSizes from '../../../hooks/useSizes'
import { useInteractivePianoRoll } from '../../ai-playground/InteractivePianoRoll/hooks/useInteractivePianoRoll'
import { PianoRollNote } from '../../ai-playground/InteractivePianoRoll/types'
import { INSTRUMENT_TYPES } from '../LayersOfInstruments/LayersOfInstruments'
import EditorPianoRollSetup from '../common/EditorPianoRollSetup/EditorPianoRollSetup'
import CursorSetting from '../common/EditorSettings/CursorSetting'
import DurationSetting from '../common/EditorSettings/DurationSetting'
import InstrumentSetting from '../common/EditorSettings/InstrumentSetting'
import StretchSetting from '../common/EditorSettings/StretchSetting'
import VelocitySetting from '../common/EditorSettings/VelocitySetting'
import VolumeSetting from '../common/EditorSettings/VolumeSetting'
import EditorTabs from '../common/EditorTabs/EditorTabs'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import DrumsDefaultEditor from './DrumsDefaultEditor/DrumsDefaultEditor'
import styles from './DrumsEditor.module.scss'
import DrumsManualEditor from './DrumsManualEditor/DrumsManualEditor'
import GenreDropdown from './GenreDropdown'
import { trackMixpanelEvent_openDrumsManualMode, trackMixpanelEvent_openDrumsSimpleMode } from '../../../utils/tracking'

type Props = {
  mini?: boolean
}

export const drumsPianoRollId = 'drums-piano-roll-id'

const DrumsEditor: React.FC<Props> = ({ mini }) => {
  const { isMobile } = useSizes()
  const {
    playerConfig: { drumGenre: genre },
  } = usePlayerConfigState()
  const { handleChangeGenre } = useInstrumentsState()
  const { drumsManualOpen, setDrumsManualOpen } = useGeneratorModals()
  const { selectedNoteIds, cursorMode, noteLengthTicks, setCursorMode, setNoteLengthTicks } =
    useInteractivePianoRoll(drumsPianoRollId)

  const [velocity, setVelocity] = useState(0)
  const [_, setSelectedNotes] = useState<PianoRollNote[]>([])

  const notesSelected = useMemo(() => Array.from(selectedNoteIds).length, [selectedNoteIds])
  const noSelectedNotes = !notesSelected

  useEffect(() => {
    if (drumsManualOpen) {
      trackMixpanelEvent_openDrumsManualMode()
    } else {
      trackMixpanelEvent_openDrumsSimpleMode()
    }
  }, [drumsManualOpen])

  const renderStyleSetting = () => (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownTitle}>Drummer Style:</div>

      <GenreDropdown
        type={'DRUMS'}
        handleChangeGenre={(genreKey, replaceInstrument) => handleChangeGenre(undefined, genreKey, replaceInstrument)}
        replaceText={'Replace Drum Sounds'}
        triggerContent={
          <>
            <img src={genre.imageUrl} />
            <span>{genre.name}</span>
            <div className={styles.selectArrow}>
              <GenresIcon className={styles.arrow} />
            </div>
          </>
        }
        yOffset={-10}
      />
    </div>
  )
  const renderSettings = () => {
    if (drumsManualOpen && !isMobile && !mini) {
      return (
        <>
          <InstrumentSetting mode={INSTRUMENT_TYPES.DRUMS} hideArrows small />

          <CursorSetting cursorMode={cursorMode} setCursorMode={setCursorMode} mode={INSTRUMENT_TYPES.DRUMS} />

          <DurationSetting
            noteDuration={noteLengthTicks}
            setNoteDuration={setNoteLengthTicks}
            mode={INSTRUMENT_TYPES.DRUMS}
          />

          <VelocitySetting
            velocity={velocity}
            setVelocity={setVelocity}
            setSelectedNotes={setSelectedNotes}
            mode={INSTRUMENT_TYPES.DRUMS}
            disabled={noSelectedNotes}
          />

          <StretchSetting mode={INSTRUMENT_TYPES.DRUMS} disabled={noSelectedNotes} />
        </>
      )
    }
    return (
      <>
        {isMobile && !mini && <VolumeSetting mode={INSTRUMENT_TYPES.DRUMS} />}

        <InstrumentSetting mode={INSTRUMENT_TYPES.DRUMS} hideArrows small />
      </>
    )
  }

  if (isMobile || mini) {
    return (
      <div className={classNames(styles.container, { [styles.mini]: mini })}>
        <div className={styles.settings}>
          {renderSettings()}

          {renderStyleSetting()}
        </div>

        <div className={styles.content}>
          <div className={styles.contentTabContent}>
            <DrumsDefaultEditor mini={mini} />
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <EditorPianoRollSetup
        mode={INSTRUMENT_TYPES.DRUMS}
        setVelocity={setVelocity}
        setSelectedNotes={setSelectedNotes}
      />

      <div className={classNames(styles.container, { [styles.mini]: mini })}>
        <div className={styles.settings}>{renderSettings()}</div>

        <div className={styles.divider} />

        <div className={styles.content}>
          <div className={styles.contentHeader}>{renderStyleSetting()}</div>

          <div className={styles.contentTabs}>
            <EditorTabs
              tabs={[
                {
                  text: 'Simple',
                },
                {
                  text: 'Manual',
                },
              ]}
              activeTabIndex={+drumsManualOpen}
              setActiveTabIndex={(v) => setDrumsManualOpen(!!v)}
            />
          </div>

          <div className={styles.contentTabContent}>
            {drumsManualOpen ? <DrumsManualEditor /> : <DrumsDefaultEditor />}
          </div>
        </div>
      </div>
    </>
  )
}

export default DrumsEditor
