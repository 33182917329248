import api from './index'

export const generateDrumGenrePattern = async (genre: string) => {
  const data = await api.get('/api/drum-track/drums/generate', { params: { genre } })
  return data.data
}
export const generateDrumGenreSingle = async (genre: string, type: string) => {
  const data = await api.get('/api/drum-track/drums/single', { params: { genre, type } })
  return data.data
}
