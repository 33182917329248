import React from 'react'

import { useInstrumentsState } from '../../../context/InstrumentsStateContext'
import { FRONTEND_LIMIT_TYPES, useLimits } from '../../../context/LimitsContext'
import { getInstrumentKey } from '../../../utils/instrumentsUtils'
import { trackMixpanelEvent_addInstrumentLayer } from '../../../utils/tracking'
import { InstrumentLayer } from '../../../utils/types'
import EditorTabMuteDeleteControll from '../common/EditorTabs/EditorTabMuteDeleteControll'
import EditorTabVolumeControl from '../common/EditorTabs/EditorTabVolumeControl'
import EditorTabs from '../common/EditorTabs/EditorTabs'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import { TabType } from './LayersOfInstruments'
import styles from './LayersOfInstruments.module.scss'

const layersToTabs = (layers: InstrumentLayer[], getTabProps: any) => {
  return layers.map((layer, index) => {
    const { tabColor, tabIcon } = getTabProps(layer.instrument.category as TabType)

    return {
      text: `${index + 1}. ${layer.instrument.name}`,
      icon: tabIcon,
      color: tabColor,
      volume: layer.volume,
      isMuted: layer.muted,
    }
  })
}

export const LayersVolumeControll = () => {
  const { playerConfig } = usePlayerConfigState()
  const { editorMode, layers, activeLayerId, getTabProps, updateLayerById } = useInstrumentsState()

  const tabs = layersToTabs(layers, getTabProps)

  const setLayerVolume = (volume: number, _: any, index: number) => {
    const instrumentKey = getInstrumentKey(layers[index].instrument, index, playerConfig.currentPartId)
    playerConfig.player.setChordLayerVolume(instrumentKey, volume)
  }

  return (
    <EditorTabVolumeControl
      tab={tabs[activeLayerId]}
      index={activeLayerId}
      onVolumeTabCallback={setLayerVolume}
      onAfterVolumeTabCallback={(volume, tab, index) => {
        updateLayerById(index, { ...layers[index], volume, muted: false }, editorMode)
        setLayerVolume(volume, tab, index)
      }}
    />
  )
}

export const LayersMuteDeleteControll = () => {
  const { playerConfig } = usePlayerConfigState()
  const { editorMode, layers, activeLayerId, getTabProps, updateLayerById, deleteLayerById } = useInstrumentsState()

  const tabs = layersToTabs(layers, getTabProps)

  const setLayerVolume = (volume: number, _: any, index: number) => {
    const instrumentKey = getInstrumentKey(layers[index].instrument, index, playerConfig.currentPartId)
    playerConfig.player.setChordLayerVolume(instrumentKey, volume)
  }

  return (
    <EditorTabMuteDeleteControll
      tabs={tabs}
      index={activeLayerId}
      activeTabIndex={activeLayerId}
      onMuteTabCallback={(_, index) => {
        const layer = layers[index]
        const muted = !layer.muted
        const volume = muted ? 0 : layer.volume
        updateLayerById(index, { ...layer, muted }, editorMode)
        setLayerVolume(volume, null, index)
      }}
      onDeleteTabCallback={(_, index) => {
        deleteLayerById(index)
      }}
    />
  )
}

const LayersTabs = () => {
  const {
    editorMode,
    layers,
    activeLayerId,
    setActiveLayerId,
    updateLayerById,
    getTabProps,
    addLayer,
    deleteLayerById,
  } = useInstrumentsState()
  const {
    playerConfig: { player, currentPartId },
    playerConfigSetter: { setInstrumentLoaded },
  } = usePlayerConfigState()
  const { isLayersLimited, renderLimitIcon, triggerLimitCallback } = useLimits()

  const isLimited = isLayersLimited(layers)

  const setLayerVolume = (volume: number, _: any, index: number) => {
    const instrumentKey = getInstrumentKey(layers[index].instrument, index, currentPartId)
    player.setChordLayerVolume(instrumentKey, volume)
  }

  return (
    <div className={styles.layersTabs}>
      <EditorTabs
        tabs={layersToTabs(layers, getTabProps)}
        addTabContent={() => renderLimitIcon(isLimited, styles.limitIcon)}
        //
        activeTabIndex={activeLayerId}
        setActiveTabIndex={setActiveLayerId}
        //
        isDeleteTabEnabled
        onDeleteTabCallback={(_, index) => {
          deleteLayerById(index)
        }}
        //
        isMuteTabEnabled
        onMuteTabCallback={(_, index) => {
          const layer = layers[index]
          const muted = !layer.muted
          const volume = muted ? 0 : layer.volume
          updateLayerById(index, { ...layer, muted }, editorMode)
          setLayerVolume(volume, null, index)
        }}
        //
        isVolumeTabEnabled
        onVolumeTabCallback={setLayerVolume}
        onAfterVolumeTabCallback={(volume, tab, index) => {
          updateLayerById(index, { ...layers[index], volume, muted: false }, editorMode)
          setLayerVolume(volume, tab, index)
        }}
        //
        isAddTabEnabled
        onAddTabCallback={() => {
          triggerLimitCallback(isLimited, FRONTEND_LIMIT_TYPES.layers)

          if (isLimited) return

          addLayer()
          setActiveLayerId(layers.length)
          setInstrumentLoaded(false)

          trackMixpanelEvent_addInstrumentLayer()
        }}
      />
    </div>
  )
}

export default LayersTabs
