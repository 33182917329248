import axios from 'axios'
import * as Tone from 'tone'
import * as wm from 'webmidi'

import { getPlaystyleInnerRoute } from '../../../../api/constants'
import {
  chordsToPartTimings,
  clearProgByChordId,
  convertNoteToToneJS,
  denormalizeVolume,
  normalizeVelocity,
  normalizeVolume,
  parseSampleBuffers,
  progToTimings,
  sumTwoBarsBeatsSixteenths,
  tileLengthToNoteDuration,
} from '../../../../utils/audio/audioUtils'
import { getOctaveByGuitarNote } from '../../../../utils/audio/midiUtils'
import { S3_URL } from '../../../../utils/constants'
import {
  SingleInstrumentKey,
  defaultInstrument,
  defaultLayer,
  getInstrumentKey,
  getInstrumentKeyFromLayerKey,
  getPartIdFromInstrumentKey,
  guitarChordSamplerInstrument,
  guitarInstrumentKeys,
} from '../../../../utils/instrumentsUtils'
import { getActivePart, getPartIdByChordId } from '../../../../utils/progUtils'
import { sleep } from '../../../../utils/stringUtils'
import { GuitarChord, InstrumentLayer, Prog } from '../../../../utils/types'
import { getMidiPort } from '../../TimelineWrapper/InstrumentMenu/constants'
import { TSampler, TSamplerData, TSamplers } from './Layers'
import MidiOutputPlayer from './MidiOutputPlayer'
import { Player } from './PlayerCommon'

class ChordPlayer extends Player {
  samplers: TSamplers = {}
  oneChordSamplers: TSamplers = {}
  guitarChordSamplers: TSamplers = {}

  synthData: TSamplerData = {}
  guitarSynthData: TSamplerData = {}

  chordsPreviewTimeouts: NodeJS.Timeout[] = []

  getMidiOutput: (port: string, key: string) => MidiOutputPlayer

  constructor(getMidiOutput: (port: string, key: string) => any, equalizerVolume: number) {
    super(normalizeVolume, denormalizeVolume, equalizerVolume)

    this.getMidiOutput = getMidiOutput
  }

  // SERVE FUNCTIONS

  updateSamplers = (samplers: TSamplers, func: any) => {
    Object.keys(samplers).map((samplerKey) => func(samplers[samplerKey]))
  }
  updateSamplersVolume = (samplers: TSamplers, volume: Tone.Volume | null) => {
    Object.entries(samplers).map(([key]) => {
      if (!volume) return
      samplers[key]?.connect(volume)
    })
  }
  generateSamplerData = async (
    layers: InstrumentLayer[],
    customConfig: any = null,
    initialData: TSamplerData = {},
    prog?: Prog | null,
  ) => {
    const data: TSamplerData = initialData
    const loader: { [key: string]: boolean } = {}

    const { dataToRemove, dataToSetup } = this.defineLayerToRemoveToSetup(data, { arr: layers })
    const layersToSetup = layers.filter((l) => dataToSetup.includes(l.instrument.key))

    dataToRemove.forEach((key) => {
      data[key].buffer.dispose()
      delete data[key]
    })

    for (const layer of layersToSetup) {
      this.setShouldResetup(!!prog)

      const key = layer.instrument.key
      loader[key] = false

      axios.get(`${S3_URL}/${layer.instrument.path}instrument.json`).then((configData) => {
        const config = {
          ...configData.data,
          ...(customConfig || {}),
          octaveShift: layer.octave,
          volume: this.normalizeVolume(layer.muted ? 0 : layer.volume),
        }

        const instrumentData = this.instrumentsData.find((iD) => iD.key === getInstrumentKeyFromLayerKey(key))
        if (instrumentData) {
          config.octaveShift += instrumentData.config.octaveShift || 0

          const layerVolumeEqualizer = (instrumentData.config.volume || 10) / 10
          const volume = this.normalizeVolume(this.denormalizeVolume(config.volume) * layerVolumeEqualizer)

          config.volume = volume
          this.layersVolumeEqualizer[key] = layerVolumeEqualizer
        }

        new Tone.Buffer(`${S3_URL}/${layer.instrument.path}instrument.mp3`, async (buffer) => {
          setTimeout(() => {
            data[key] = { config, buffer }
            loader[key] = true
          }, 100)
        })
      })
    }

    if (prog) {
      const { playstylesToSetup, playstylesToRemove } = this.definePlaystylesToRemoveToSetup(this.playstyles, layers)

      playstylesToRemove.forEach((key) => delete this.playstyles[key])

      for (const playstyle of playstylesToSetup) {
        this.shouldResetup = true
        loader[playstyle] = false

        getPlaystyleInnerRoute(playstyle).then((res) => {
          this.playstyles[playstyle] = res
          loader[playstyle] = true
        })
      }
    }

    do {
      await sleep(200)
    } while (Object.values(loader).some((v) => v === false))

    // START: setup parts volume
    if (prog) {
      const oldPartIds = Object.keys(this.partsVolume).map((id) => +id)
      const newPartIds = Array.from(new Set(Object.keys(data).map((key) => getPartIdFromInstrumentKey(key))))

      oldPartIds.filter((id) => id && !newPartIds.includes(id)).forEach((id) => delete this.partsVolume[id])
      newPartIds
        .filter((id) => id && !oldPartIds.includes(id))
        .forEach((id) => {
          const part = getActivePart(prog, id)

          if (this.progVolume && part) {
            this.partsVolume[id] = this.initVolume(part.chordsMuted ? 0 : part.chordsVolume, this.progVolume)
          }
        })
    }
    // END

    return data
  }
  triggerAttack = (samplers: TSamplers, instrumentKeys: string[], note: string, velocity?: number) => {
    Object.entries(samplers)
      .filter(([key]) => instrumentKeys.includes(key))
      .forEach(([key, sampler]) => {
        // @ts-ignore
        sampler?.triggerAttack(note, undefined, velocity)
      })
  }
  triggerRelease = (samplers: TSamplers, instrumentKeys: string[], note: string[] | string, a?: any) => {
    Object.entries(samplers)
      .filter(([key]) => instrumentKeys.includes(key))
      .forEach(([key, sampler]) => {
        sampler?.triggerRelease(note, a)
      })
  }
  triggerAttackRelease = (
    samplers: TSamplers,
    instrumentKeys: string[],
    note: string,
    duration: any,
    time?: any,
    velocity?: any,
  ) => {
    Object.entries(samplers)
      .filter(([key]) => instrumentKeys.includes(key))
      .forEach(([key, sampler]) => {
        sampler?.triggerAttackRelease(note, duration, time, velocity)
      })
  }

  // MAIN FUNCTIONS

  // SETUP INSTRUMENTS
  init = async (prog: Prog, volume: Tone.Volume = new Tone.Volume(normalizeVolume(100))) => {
    this.progVolume = volume

    prog.parts.forEach((part) => {
      if (this.progVolume) {
        this.partsVolume[part.id]?.dispose()
        this.partsVolume[part.id] = this.initVolume(part.chordsMuted ? 0 : part.chordsVolume, this.progVolume)
      }
    })
  }
  setupSynth = async (
    prog: Prog | null,
    chordLayers: InstrumentLayer[],
    instrumentMidiOut: { [key: string]: string },
    customConfig: any = null,
  ) => {
    const prevSynthData = JSON.parse(JSON.stringify(this.synthData))

    const data: TSamplerData = await this.generateSamplerData(chordLayers, customConfig, this.synthData, prog)
    const guitarData: TSamplerData = await this.generateSamplerData(
      [{ ...defaultLayer, instrument: { ...defaultInstrument, ...guitarChordSamplerInstrument } }],
      customConfig,
      this.guitarSynthData,
    )
    const oldOctaves = Object.keys(prevSynthData)
      .sort()
      .map((key) => prevSynthData[key].config.octaveShift)
      .join('-')
    const newOctaves = Object.keys(data)
      .sort()
      .map((key) => data[key].config.octaveShift)
      .join('')
    if (
      oldOctaves === newOctaves &&
      JSON.stringify(Object.keys(prevSynthData).sort()) === JSON.stringify(Object.keys(data).sort()) &&
      !Object.keys(instrumentMidiOut).length
    )
      return this.setShouldResetup(true, false)

    this.synthData = data
    this.guitarSynthData = guitarData

    this.createSynth(this.samplers, data, instrumentMidiOut)
    this.createSynth(this.oneChordSamplers, data, instrumentMidiOut)
    this.createSynth(this.guitarChordSamplers, guitarData)

    this.setupChordLayersVolume(data)

    this.setupSamplersVolume(this.samplers)
    this.setupSamplersVolume(this.oneChordSamplers)

    this.updateSamplersVolume(this.guitarChordSamplers, this.progVolume)

    return true
  }
  createSynth = (samplers: TSamplers, data: TSamplerData, instrumentMidiOut: { [key: string]: string } = {}) => {
    const { dataToRemove, dataToSetup } = this.defineLayerToRemoveToSetup(samplers, { obj: data }, instrumentMidiOut)
    const samplersToSetup = Object.entries(data).filter(([key]) => dataToSetup.includes(key))
    dataToRemove.forEach((key) => {
      samplers[key]?.releaseAll()
      samplers[key]?.disconnect()
      samplers[key]?.dispose()
      delete samplers[key]
    })

    samplersToSetup.map(([key, { config, buffer }]) => {
      if (key in instrumentMidiOut && instrumentMidiOut[key]) {
        wm.WebMidi.enable().then(() => {
          setTimeout(() => {
            samplers[key] = this.getMidiOutput(getMidiPort(instrumentMidiOut[key]), key)
          }, 100)
        })
      } else {
        const sampleBuffers = parseSampleBuffers(config, buffer)

        samplers[key] = new Tone.Sampler(sampleBuffers, {
          attack: config.envelope.attack,
          release: config.envelope.release,
        })
        // @ts-ignore
        samplers[key].config = { octaveShift: config.octaveShift }
      }
    })
  }

  // SETUP PLAYBACK
  setupProgPlayback = (prog: Prog, onStop: () => void, onLoad: (duration: string) => void, loopedPartId?: number) => {
    const { timings, duration } = progToTimings(prog, this.playstyles, loopedPartId)
    this.timings = timings

    this.dispose()
    this.part = new Tone.Part(
      (time, obj) => {
        if (obj.isEnd) {
          onStop()
          this.handleChange(-1)
          return
        }

        this.handleChange(obj.id)

        this.triggerAttackRelease(this.samplers, [obj.instrumentKey], obj.note, obj.duration, time, obj.velocity)
      },
      [...(timings as any), { time: duration, isEnd: true }],
    )
    onLoad(duration)
  }
  prepareExportPlay = (prog: Prog, loops: number, layerKey?: string) => {
    const offlineSynthSamplers: TSamplers = {}
    const volumeRefs: Tone.Volume[] = []

    return {
      onPlay: () => {
        const { timings, duration } = progToTimings(prog, this.playstyles)

        this.createSynth(offlineSynthSamplers, this.synthData)

        const chordsPart = new Tone.Part((time, obj) => {
          this.triggerAttackRelease(
            offlineSynthSamplers,
            [layerKey || obj.instrumentKey],
            obj.note,
            obj.duration,
            time,
            obj.velocity,
          )
        }, timings)
        chordsPart.loop = loops
        chordsPart.loopStart = '0:0:0'
        chordsPart.loopEnd = duration
        chordsPart.start()

        Object.keys(offlineSynthSamplers).forEach((key) => {
          const synthKey = layerKey || key

          const { volume, refs } = this.initExportVolume(synthKey)
          volumeRefs.push(...refs)

          if (volume) offlineSynthSamplers[key]?.connect(volume)
        })
      },
      onFinish: () => {
        this.updateSamplers(offlineSynthSamplers, (s: TSampler) => s?.dispose())

        volumeRefs.forEach((volumeRef) => {
          volumeRef.disconnect()
          volumeRef.dispose()
        })
      },
    }
  }
  updatePart = async (
    prog: Prog,
    onLoad: (duration: string, initMetronome?: boolean) => void,
    loopedPartId?: number,
  ) => {
    const progPlaystyles = prog.parts.flatMap((part) => part.chordLayers.map((cl) => cl.playstyle.id))
    while (!progPlaystyles.every((playstyle) => Object.keys(this.playstyles).includes(playstyle))) {
      await sleep(100)
    }

    const { timings, duration } = progToTimings(prog, this.playstyles, loopedPartId)
    this.timings = timings

    this.part?.clear()

    timings?.forEach((n: any) => {
      this.part?.add(n)
    })

    this.part?.add({ time: duration, isEnd: true })

    onLoad(duration, false)
  }

  // PLAYING
  playNote = async (note: string) => {
    if (this.isPlaying) return

    this.triggerAttackRelease(this.oneChordSamplers, [SingleInstrumentKey], note, '0:0:1')
  }
  playGuitarNote = async (note: string) => {
    if (this.isPlaying) return

    this.triggerAttackRelease(this.guitarChordSamplers, guitarInstrumentKeys, note, '0:0:1')
  }
  playGuitarChord = (chord: GuitarChord) => {
    if (this.isPlaying) return

    const notesToPlay = chord.notes.filter((_, index) => chord.strings[index] !== 'X')
    const timings = notesToPlay.map((note, index) => ({
      index: notesToPlay.length - 1 - index,
      note: note + '' + (getOctaveByGuitarNote(index + 1, chord.strings[index]) - 1),
      duration: '1:0:0',
    }))
    timings.forEach((timing) => {
      setTimeout(() => {
        this.triggerAttackRelease(this.guitarChordSamplers, guitarInstrumentKeys, timing.note, timing.duration)
      }, timing.index * 75)
    })
  }
  playMidi = (midi: number, pianoInstrumentKeys: string[] = [], playGuitar = false) => {
    if (this.isPlaying) return

    const samplers = playGuitar ? this.guitarChordSamplers : this.oneChordSamplers
    const instrumentKeys = playGuitar ? guitarInstrumentKeys : pianoInstrumentKeys
    this.triggerAttackRelease(samplers, instrumentKeys, convertNoteToToneJS(midi).toNote(), '0:4:0', undefined, 1)
  }
  playMidiAttack = (midi: number, velocity: number) => {
    if (this.isPlaying) return

    this.triggerAttack(
      this.oneChordSamplers,
      Object.keys(this.oneChordSamplers),
      convertNoteToToneJS(midi).toNote(),
      normalizeVelocity(velocity),
    )
  }
  playMidiRelease = (midi: number) => {
    if (this.isPlaying) return

    this.triggerRelease(this.oneChordSamplers, Object.keys(this.oneChordSamplers), convertNoteToToneJS(midi).toNote())
  }
  playChord = (prog: Prog | null, chordId: number, playGuitar = false) => {
    if (!prog || this.isPlaying) return

    const sampler = playGuitar ? this.guitarChordSamplers : this.oneChordSamplers

    const clearedProg = clearProgByChordId(prog, chordId)
    const instrumentKeys = playGuitar
      ? guitarInstrumentKeys
      : clearedProg.parts[0].chordLayers.map((l, i) => {
          if (l.instrument.key.includes('bass-') || l.instrument.category === 'basses') {
            return ''
          }
          return getInstrumentKey(l.instrument, i, getPartIdByChordId(chordId) || 1)
        })
    const { timings } = chordsToPartTimings(clearedProg)

    timings.forEach((timing) => {
      setTimeout(() => {
        this.triggerAttackRelease(sampler, instrumentKeys, timing.note, '1:0:0', undefined, timing.velocity)
      }, timing.offsetInSec * 1000)
    })
  }
  playChords = (chords: any[], callback?: (chordId: number | null) => void) => {
    const duration = '0:1:0'

    const newPlaybackTimeouts = chords.map((chord, chordIndex) => {
      return setTimeout(() => {
        callback && callback(chord.id)

        chord.midi.forEach((midi: number) => {
          this.triggerAttackRelease(this.samplers, chord.instrumentKeys, convertNoteToToneJS(midi).toNote(), duration)
        })
      }, Tone.Time(duration).toSeconds() * chordIndex * 1000)
    })
    const finalCallbackTimeout = setTimeout(() => {
      callback && callback(null)
    }, Tone.Time(duration).toSeconds() * chords.length * 1000)

    this.chordsPreviewTimeouts.map((timeout) => clearTimeout(timeout))
    this.chordsPreviewTimeouts = [...newPlaybackTimeouts, finalCallbackTimeout]
  }
  playChordFromAnyPosition = (ppqResolution: number, newPositionSixteenths?: string) => {
    if (!this.isPlaying) return

    const normalizeTicks = (ticks: number) => (ticks / Tone.Transport.PPQ) * ppqResolution

    const positionInTicks = newPositionSixteenths
      ? normalizeTicks(Tone.Time(newPositionSixteenths).toTicks())
      : normalizeTicks(Tone.Transport.ticks)
    const timings = this.timings

    const timingsToBePlayer = timings
      .map((timing) => {
        const timingStartTicks = normalizeTicks(Tone.Time(timing.time).toTicks())
        const timingEndTicks = normalizeTicks(
          Tone.Time(sumTwoBarsBeatsSixteenths(timing.time, timing.duration)).toTicks(),
        )

        const isCurrentTiming = timingStartTicks < positionInTicks && positionInTicks < timingEndTicks

        if (isCurrentTiming) return { ...timing, timingEndTicks }
        return null
      })
      .filter(Boolean)

    if (!timingsToBePlayer.length) return

    timingsToBePlayer.forEach((timing) => {
      if (!timing) return

      const timingDurationBars = (timing.timingEndTicks - positionInTicks) / ppqResolution / 4
      const timingDurationSixteenths = tileLengthToNoteDuration(timingDurationBars)

      this.triggerAttackRelease(
        this.samplers,
        [timing.instrumentKey],
        timing.note,
        timingDurationSixteenths,
        undefined,
        timing.velocity,
      )
    })
  }

  // DATA
  getTimings = (prog: Prog | null) => {
    if (!prog) return []

    const { timings } = progToTimings(prog, this.playstyles)
    return timings
  }
}

export default ChordPlayer
