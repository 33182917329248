import * as Tone from 'tone'

import { TICKS_PER_BAR } from '../drumsUtils'
import { SingleInstrumentKey, getInstrumentKey } from '../instrumentsUtils'
import { mapChordsToEvents } from '../playstyles'
import { ExtendedPlaystyle } from '../playstylesTypes'
import { getPartLengthInBars } from '../progUtils'
import { Chord, Prog, ProgPart } from '../types'
import { getBarsFromTicks } from './midiUtils'

export const barsBeatsSixteenthsToBars = (beats: string) => {
  const [a, b, c] = beats.split(':')
  return +a + +b / 4 + +c / 16
}

export const sixteenthsToTicks = (sixteenths: string) => {
  return barsBeatsSixteenthsToBars(sixteenths) * TICKS_PER_BAR
}

export const sumTwoBarsBeatsSixteenths = (a: string, b: string) => {
  return Tone.Time(Tone.Time(a).toSeconds() + Tone.Time(b).toSeconds(), 's').toBarsBeatsSixteenths()
}

export const sumTwoBarsBeatsSixteenthsNoTone = (a: string, b: string) => {
  // Function to parse the input string to bars, beats, sixteenths
  function parseTime(time: string) {
    const [bars, beats, sixteenths] = time.split(':').map(Number)
    return { bars, beats, sixteenths }
  }

  // Function to convert fractional beats to sixteenths
  function convertToSixteenths(beats: number, sixteenths: number) {
    const totalSixteenths = Math.round(beats * 4) + sixteenths
    return totalSixteenths
  }

  // Parse both input times
  const time1 = parseTime(a)
  const time2 = parseTime(b)

  // Convert beats and sixteenths to total sixteenths
  const totalSixteenths1 = convertToSixteenths(time1.beats, time1.sixteenths)
  const totalSixteenths2 = convertToSixteenths(time2.beats, time2.sixteenths)

  // Sum the sixteenths
  const totalSixteenths = totalSixteenths1 + totalSixteenths2

  // Convert back to bars, beats, sixteenths
  const totalBars = time1.bars + time2.bars + Math.floor(totalSixteenths / 16)
  const remainingSixteenths = totalSixteenths % 16
  const totalBeats = Math.floor(remainingSixteenths / 4)
  const finalSixteenths = remainingSixteenths % 4

  // Format the result as bars:beats:sixteenths
  return `${totalBars}:${totalBeats}:${finalSixteenths}`
}

export const tileLengthToNoteDuration = (length: number | string, offset = '0:0:0') => {
  if (typeof length === 'string') {
    return length
  }
  const measures = Math.floor(length)
  const quarters = (length - measures) * 4
  const time = `${measures}:${quarters}:0`
  return sumTwoBarsBeatsSixteenthsNoTone(time, offset)
}

export const convertNoteToToneJS = (midi: number) => {
  return Tone.Frequency(midi - 12, 'midi')
}
export const convertNoteToMidi = (note: string) => {
  return Tone.Frequency(note).toMidi()
}

/**
 * Separates note name and octave from note string
 * @param  {string} note like 'C4'
 * @return {object} like {noteName: 'C', octave: 4}
 */
export const separateNoteNameAndOctave = (note: string) => {
  const octave = note.match(/\d/g)?.join('') || '-1'
  const noteName = note.replace(/\d/g, '')
  return { noteName, octave: parseInt(octave, 10) }
}

export const clearProgByChordId = (prog: Prog, chordId: number): Prog => {
  const newProg = JSON.parse(JSON.stringify(prog)) as Prog

  newProg.parts = newProg.parts
    .map((part) => {
      const chordInPart = part.chords.find((chord) => chord.id === chordId)

      return chordInPart ? { ...part, chords: [chordInPart] } : null
    })
    .filter(Boolean) as ProgPart[]

  return newProg
}

export const progToTimings = (prog: Prog, playstyles: { [key: string]: ExtendedPlaystyle }, loopedPartId?: number) => {
  let duration = 0

  const timings = prog.parts
    .map((part) => {
      if (loopedPartId && part.id !== loopedPartId) return []

      const partTimings = []
      const partLoops = loopedPartId ? 1 : part.loops

      for (let i = 0; i < partLoops; i++) {
        const partLoopTimings = part.chordLayers
          .map((layer, index) => {
            const playstyle = playstyles[layer.playstyle?.id]

            if (!playstyle) return []

            return mapChordsToEvents(
              part.chords.map((c) => (c.draft ? { ...c, midi: [] } : c)),
              playstyle,
              null,
              getInstrumentKey(layer.instrument, index, part.id),
            )
          })
          .flat()
          .map((t) => {
            return { ...t, time: sumTwoBarsBeatsSixteenthsNoTone(t.time, tileLengthToNoteDuration(duration)) }
          })

        duration += getPartLengthInBars(prog, part.id)
        partTimings.push(...partLoopTimings)
      }

      return partTimings
    })
    .flat()

  return { timings, duration: tileLengthToNoteDuration(duration) }
}

export const getPartTracksStatus = (part: ProgPart) => {
  return {
    isChordsEmpty: !part.chords.length,
    isMelodyEmpty: !part.melody?.notes?.length,
    isDrumsEmpty: !part.drums?.groups?.length,
  }
}

export const getPartDuration = (part: ProgPart) => {
  const chordsDuration = part.chords.reduce((acc, c) => (acc += c.duration || 0), 0)
  const melodyDuration = part.melody?.length || chordsDuration
  const drumsDuration = part.drums?.length || chordsDuration

  const duration = Math.max(chordsDuration, melodyDuration, drumsDuration)

  return {
    duration: tileLengthToNoteDuration(duration),
    chordsDuration: tileLengthToNoteDuration(chordsDuration),
    melodyDuration: tileLengthToNoteDuration(melodyDuration),
    drumsDuration: tileLengthToNoteDuration(drumsDuration),
  }
}

export const chordsToPartTimings = (
  prog: Prog,
  loopedPartId?: number,
  drumsLengthRaw?: number | null,
  melodyLengthRaw?: number | null,
) => {
  const loopedPart = prog?.parts.find((p) => p.id === loopedPartId)
  const chords = loopedPart?.chords || []
  const drumsLength = drumsLengthRaw || loopedPart?.drums?.length
  const melodyLength = melodyLengthRaw || loopedPart?.melody?.length

  const timings: any[] = []
  let duration = 0

  const formatNote = (chord: Chord, index: number, value: number, instrumentKeys: string[], noteOffset?: number) => ({
    time: tileLengthToNoteDuration(duration + (noteOffset || 0)),
    velocity: (chord.settings?.velocity || 100) / 127,
    note: convertNoteToToneJS(value + (chord.octave || 0) * 12).toNote(),
    duration: tileLengthToNoteDuration(chord.duration || 0),
    id: chord.id,
    instrumentKeys,
  })
  const getInstrumentKeys = (part?: ProgPart) => {
    const instrumentKeys =
      part && part.chordLayers.length
        ? part.chordLayers.map((layer, index) => getInstrumentKey(layer.instrument, index, part.id))
        : [SingleInstrumentKey]

    return instrumentKeys
  }

  if (!loopedPartId) {
    prog.parts.forEach((part) => {
      for (let i = 0; i < part.loops; i++) {
        let noteOffset = 0

        part.chords.forEach((chord: Chord) => {
          const instrumentKeys = getInstrumentKeys(part)

          const notes = chord.midi.reduce((acc, value, index) => {
            if (chord.midiTimings && !!(chord.midiTimings[0] || [])[0]) {
              return [
                ...acc,
                ...chord.midiTimings[index].map((bit) => {
                  const delayInBars = getBarsFromTicks(bit.midiTiming)
                  return formatNote(
                    {
                      ...chord,
                      settings: { ...(chord.settings || {}), velocity: bit.velocity },
                      duration: getBarsFromTicks(bit.duration),
                    },
                    index,
                    value,
                    instrumentKeys,
                    noteOffset + delayInBars,
                  )
                }),
              ]
            }
            return [...acc, formatNote(chord, index, value, instrumentKeys, noteOffset)]
          }, [] as any[])

          timings.push(...notes)
          noteOffset += chord.duration || 0
        })

        duration += Math.max(noteOffset, part.drums?.length || 0, part.melody?.length || 0)
      }
    })
  } else {
    chords.forEach((chord) => {
      const notes = chord.midi.map((value, index) => formatNote(chord, index, value, getInstrumentKeys(loopedPart)))
      timings.push(...notes)
      duration += chord.duration || 0
    })
  }
  return {
    timings: timings,
    duration: tileLengthToNoteDuration(Math.max(duration, drumsLength || 0, melodyLength || 0)),
    drumsDuration: tileLengthToNoteDuration(drumsLength || duration),
    melodyDuration: tileLengthToNoteDuration(melodyLength || duration),
  }
}

export const shiftOctave = (note: string, octaveShift: number) => {
  const { noteName, octave } = separateNoteNameAndOctave(note)
  return noteName + (octave - octaveShift).toString()
}

export const parseSampleBuffers = (config: any, buffer: any) => {
  const sampleBuffers: { [index: string]: any } = {}
  if (!config.octaveShift) {
    config.octaveShift = 0
  }

  for (const { duration, offset, root } of config.samples) {
    const shiftedRoot = shiftOctave(root, config.octaveShift)
    const start = parseFloat(offset)
    const end = start + parseFloat(duration)
    sampleBuffers[shiftedRoot] = buffer.slice(start, end)
  }
  return sampleBuffers
}

// -33 -> 0
export const normalizeVolume = (volume: number) => (volume === 0 ? -100 : -(100 - volume) / 3)

export const denormalizeVolume = (volume: number) => (volume === -100 ? 0 : volume * 3 + 100)

export const normalizeVelocity = (velocity: number) => velocity / 127

// -50 -> 0
export const normalizeDrumsVolume = (volume: number) => (volume === 0 ? -100 : -(100 - volume) / 2)

export const denormalizeDrumsVolume = (volume: number) => (volume === -100 ? 0 : volume * 2 + 100)
