import { PianoRollNote } from '../components/ai-playground/InteractivePianoRoll/types'
import { getIndByTicks, TICKS_PER_BAR } from './drumsUtils'
import { copyObj } from './stringUtils'
import { MelodyPattern } from './types'

export const getLastIndexOfMelodyBit = (notes: PianoRollNote[]) => {
  const sortedNotes = (copyObj(notes) as PianoRollNote[]).sort((a, b) => a.startTicks - b.startTicks)

  const lastTrack = sortedNotes[sortedNotes.length - 1]?.startTicks || 0
  const ind = (getIndByTicks(lastTrack) || 3) + 1
  return Math.ceil(ind / 4) * 4
}

export const melodyToTempo = (melody: MelodyPattern): MelodyPattern => {
  if (!melody) return melody

  // TODO: remove once Tempo is needed once more. Remove ignores too
  return melody

  // @ts-ignore
  const halfTime = (getLastIndexOfMelodyBit(melody.notes) * (TICKS_PER_BAR / 4)) / 2
  // @ts-ignore
  let newNotes = melody.notes

  if (melody?.tempo === -1) {
    newNotes = newNotes
      .filter((note) => note.startTicks < halfTime)
      .map((note) => ({
        ...note,
        startTicks: note.startTicks * 2,
        endTicks: note.endTicks * 2,
      }))
  }

  if (melody?.tempo === 1) {
    newNotes = newNotes.flatMap((note) => [
      {
        ...note,
        startTicks: note.startTicks / 2,
        endTicks: note.endTicks / 2,
      },
      {
        ...note,
        startTicks: note.startTicks / 2 + halfTime,
        endTicks: note.endTicks / 2 + halfTime,
      },
    ])
  }

  // @ts-ignore
  return { ...melody, notes: newNotes }
}

export const stretchPianoRollNotes = (
  notes: PianoRollNote[],
  selectedNoteIdsSet: Set<string>,
  value: number,
): {
  noteId: string
  updatedFields: Partial<PianoRollNote>
}[] => {
  const selectedNoteIds = Array.from(selectedNoteIdsSet)

  const allNotesStartTicks = notes.filter((note) => selectedNoteIds.includes(note.id)).map((note) => note.startTicks)
  const minimalStartTicks = Math.min(...allNotesStartTicks)

  const isEnlarge = value > 1

  const startTicksSchift = minimalStartTicks * Math.abs(value - 1) * (isEnlarge ? 1 : -1)

  return selectedNoteIds.map((selectedNoteId) => {
    const note = notes.find((n) => n.id === selectedNoteId)
    return {
      noteId: selectedNoteId,
      updatedFields: note
        ? {
            startTicks: note.startTicks * value - startTicksSchift,
            endTicks: note.endTicks * value - startTicksSchift,
          }
        : {},
    }
  })
}
