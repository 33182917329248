import classNames from 'classnames'
import React, { FC, ReactNode, useEffect, useState } from 'react'

import { getUserUsageInnerRoute } from '../../../../api/auth'
import AppleIcon from '../../../../assets/icons/signup/apple.svg'
import EmailIcon from '../../../../assets/icons/signup/email.svg'
import GoogleIcon from '../../../../assets/icons/signup/google.svg'
import { useCurrentUser } from '../../../../context/CurrentUserContext'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import { LimitType } from '../../../../utils/limits'
import Routes from '../../../../utils/routes'
import CircleLoader from '../../../common/CircleLoader/CircleLoader'
import Link from '../../../common/Link/Link'
import UserLimits from '../../../common/UserLimits/UserLimits'
import SignupFlowModal from '../../common/SignupFlowModal/SignupFlowModal'
import LoginEmailForm, { LoginEmailStep } from '../LoginEmailForm/LoginEmailForm'
import styles from './LoginModal.module.scss'

export type limitTypeType =
  | 'drums'
  | 'chords'
  | 'melody'
  | 'voicing'
  | 'export'
  | 'share'
  | 'project'
  | 'rag'
  | undefined

type Props = {
  opened: boolean
  onClose: () => void
  limitName?: LimitType
  limitType?: limitTypeType
  raw?: boolean
}

const LoginModal: FC<Props> = ({ opened, onClose, limitName, limitType, raw }) => {
  const { getText } = useInternationalization()
  const { loginApple, loginGoogle } = useCurrentUser()

  const [userUsage, setUserUsage] = useState<object | undefined>(undefined)

  const [emailLoginStarted, setEmailLoginStarted] = useState(false)
  const [googleLoginStarted, setGoogleLoginStarted] = useState(false)
  const [appleLoginStarted, setAppleLoginStarted] = useState(false)

  const [emailLoginStep, setEmailLoginStep] = useState<LoginEmailStep>('enter-email')

  const STRINGS = getText('LoginModal')

  useEffect(() => {
    if (opened && limitType && !userUsage) {
      getUserUsageInnerRoute().then((data) => setUserUsage(data))
    }
  }, [opened, limitType])

  // Functionality
  const handleLoginWithEmail = () => {
    setEmailLoginStarted(true)
  }
  const handleLoginWithGoogle = () => {
    setGoogleLoginStarted(true)
    loginGoogle()
  }
  const handleLoginWithApple = () => {
    setAppleLoginStarted(true)
    loginApple()
  }

  const handleInteruptLoginWithEmail = () => {
    if (emailLoginStep !== 'enter-email') {
      setEmailLoginStep('enter-email')
      return
    }
    setEmailLoginStarted(false)
  }

  // Data
  const getModalTitle = () => {
    if (!emailLoginStarted) return ''

    if (emailLoginStep === 'enter-email') return STRINGS.emailTitle
    if (emailLoginStep === 'code-sent') return STRINGS.codeTitle
    return ''
  }
  const getModalAction = () => {
    if (!emailLoginStarted) return 'close'
    return 'back'
  }

  // Renders
  const renderLoginButton = (
    icon: ReactNode,
    text: string,
    onLogin: () => void,
    isLoading: boolean,
    className?: string,
  ) => {
    return (
      <button className={classNames(styles.loginFormButton, className)} onClick={onLogin}>
        {isLoading ? (
          <div className={styles.loader}>
            <CircleLoader />
          </div>
        ) : (
          icon
        )}

        {text}
      </button>
    )
  }
  const renderLoginForm = () => (
    <div className={styles.loginForm}>
      <div className={styles.loginFormButtons}>
        {renderLoginButton(<EmailIcon />, STRINGS.email, handleLoginWithEmail, emailLoginStarted, styles.email)}
        {renderLoginButton(<GoogleIcon />, STRINGS.google, handleLoginWithGoogle, googleLoginStarted, styles.google)}
        {renderLoginButton(<AppleIcon />, STRINGS.apple, handleLoginWithApple, appleLoginStarted, styles.apple)}
      </div>

      <div className={styles.loginFormAgreement}>
        {STRINGS.agreement}
        <br />
        <Link href={Routes.TERMS_OF_SERVICE} target='_blank' rel='noopener noreferrer'>
          {STRINGS.terms}
        </Link>{' '}
        {STRINGS.and}{' '}
        <Link href={Routes.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer'>
          {STRINGS.privacy}
        </Link>
      </div>
    </div>
  )

  const renderDefaultLoginContent = () => {
    return (
      <div className={styles.defaultLoginContent}>
        <div className={styles.defaultLoginContentTitle}>{STRINGS.title}</div>

        <div className={styles.defaultLoginContentSubtitle}>
          <div />
          <span>{STRINGS.subTitle}</span>
          <div />
        </div>

        {renderLoginForm()}
      </div>
    )
  }
  const renderLimitedLoginContent = () => {
    return (
      <div className={styles.limitedLoginContent}>
        <div className={styles.limitedLoginLeftSide}>
          <div className={styles.limitedLoginLeftSideHeader}>
            <div className={styles.limitedLoginContentTitle}>{STRINGS[`limitTitle_${limitType}`]}</div>
            <div className={styles.limitedLoginContentSubtitle}>{STRINGS.limitTitle}</div>

            <UserLimits generatorComponent userUsage={userUsage} limitType={limitName} />
          </div>

          {renderLoginForm()}
        </div>

        <div className={styles.limitedLoginRightSide}>
          <img src='/static/images/upgrade-plan.png' className={styles.limitedLoginRightSideImg} />
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (emailLoginStarted) return <LoginEmailForm step={emailLoginStep} setStep={setEmailLoginStep} />
    if (limitType) return renderLimitedLoginContent()
    return renderDefaultLoginContent()
  }

  if (raw) return renderContent()
  return (
    <SignupFlowModal
      opened={opened}
      onClose={onClose}
      onBack={handleInteruptLoginWithEmail}
      title={getModalTitle()}
      actionType={getModalAction()}
    >
      {renderContent()}
    </SignupFlowModal>
  )
}

export default LoginModal
