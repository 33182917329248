import classNames from 'classnames'
import Image from 'next/image'
import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import ArrowIcon from '../../../assets/icons/arrow-pricing.svg'
import { useGeneratorModals } from '../../../context/GeneratorModalsContext'
import { useLimits } from '../../../context/LimitsContext'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../hooks/clickOutsideAlerterHook'
import Switch from '../../common/Switch'
import { InstrumentType } from '../LayersOfInstruments/LayersOfInstruments'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import styles from './DrumsEditor.module.scss'

type Props = {
  type: InstrumentType
  handleChangeGenre: (genreKey: string, replaceInstrument: boolean) => void

  replaceText: string

  triggerContent: ReactNode
  triggerClassName?: string

  openClassName?: string
  className?: string
  dropdownClassName?: string

  xOffset?: number
  yOffset?: number
}

const GenreDropdown: FC<Props> = ({
  type,
  handleChangeGenre,
  replaceText,
  triggerContent,
  triggerClassName,
  openClassName = '',
  className,
  dropdownClassName,
  xOffset = 0,
  yOffset = 0,
}) => {
  const {
    playerConfig: { chordGenre, chordGroups, drumGenre, drumGroups },
  } = usePlayerConfigState()
  const {
    drumsMenuOpenRow,
    portalsConfig: {
      randomInstrument,
      setRandomInstrument,
      randomMelodyInstrument,
      setRandomMelodyInstrument,
      randomDrumInstrument,
      setRandomDrumInstrument,
    },
  } = useGeneratorModals()
  const { isNotSubscribed, renderLimitIcon, triggerLimitCallback } = useLimits()

  const triggerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useClickOutsideAlerter(dropdownRef, () => setIsDropdownOpen(false), clickOutsideHookIds.genreDropdown)

  const isChordsMode = type === 'CHORDS'
  const isMelodyMode = type === 'MELODY'
  const isDrumsMode = type === 'DRUMS'

  const genre = isChordsMode ? chordGenre : drumGenre
  const groups = isChordsMode ? chordGroups : drumGroups

  const replaceSound = (() => {
    if (isChordsMode) return randomInstrument
    if (isMelodyMode) return randomMelodyInstrument
    if (isDrumsMode) return randomDrumInstrument
    return false
  })()
  const setReplaceSound = (() => {
    if (isChordsMode) return setRandomInstrument
    if (isMelodyMode) return setRandomMelodyInstrument
    if (isDrumsMode) return setRandomDrumInstrument
    return () => {}
  })()

  const { left, top } = useMemo(() => {
    const totalGroupsNum = groups.length
    const totalStylesNum = groups.map((g) => g.genres).flat().length

    const space = 10
    const width = 270
    const height = Math.min(
      48 + totalGroupsNum * 28 + totalStylesNum * 48 + (totalGroupsNum + totalStylesNum) * 8 + 16 * 2 + 2,
      420,
    )

    const hook = triggerRef.current

    const bcr = hook?.getBoundingClientRect()
    const windowWidth = window.innerWidth

    if (!bcr) return { left: 10, top: 0 }

    let left = bcr.x + xOffset
    let top = bcr.y + yOffset

    if (top < height) {
      left = bcr.x + bcr.width
      top = bcr.y + height
    }

    if (left > windowWidth - width) {
      left = windowWidth - width - space
    }

    return { left, top }
  }, [groups, triggerRef.current])

  useEffect(() => {
    if (drumsMenuOpenRow !== -1) {
      setIsDropdownOpen(false)
    }
  }, [drumsMenuOpenRow])

  return (
    <>
      <div
        className={classNames(styles.dropdownMenu, { [styles.dropdownOpen]: isDropdownOpen }, className, {
          [openClassName]: isDropdownOpen,
        })}
        ref={triggerRef}
      >
        <div onClick={() => setIsDropdownOpen(true)} className={classNames(styles.dropdownTrigger, triggerClassName)}>
          {triggerContent}
        </div>
      </div>

      {createPortal(
        <div
          className={classNames(styles.dropdownBody, dropdownClassName)}
          style={{ left, top, display: isDropdownOpen ? undefined : 'none' }}
          ref={dropdownRef}
        >
          <div className={classNames(styles.style, styles.noHover)}>
            <span>{replaceText}</span>
            <Switch className={styles.switch} onChange={() => setReplaceSound((v) => !v)} value={replaceSound} />
          </div>

          {groups.map((group) => (
            <>
              <div className={styles.groupDivider}>
                {group.name} <div />
              </div>

              {group.genres.map((s) => {
                const isLocked = s.premium && isNotSubscribed

                return (
                  <div
                    key={s.key}
                    className={classNames(styles.style, { [styles.activeStyle]: s.key === genre.key })}
                    onClick={() => {
                      if (isLocked) {
                        triggerLimitCallback(isLocked)
                        setIsDropdownOpen(false)
                        return
                      }
                      handleChangeGenre(s.key, replaceSound)
                    }}
                  >
                    <Image src={s.imageUrl} width={48} height={48} alt='Style' priority />
                    <span>{s.name}</span>
                    <ArrowIcon className={styles.arrowIcon} />
                    {renderLimitIcon(isLocked, styles.limitIcon)}
                  </div>
                )
              })}
            </>
          ))}
        </div>,
        document.getElementById('__next') as HTMLElement,
      )}
    </>
  )
}

export default GenreDropdown
