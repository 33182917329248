import classNames from 'classnames'
import React, { FC } from 'react'

import { stretchPianoRollNotes } from '../../../../utils/melodyUtils'
import { useInteractivePianoRoll } from '../../../ai-playground/InteractivePianoRoll/hooks/useInteractivePianoRoll'
import { drumsPianoRollId } from '../../DrumsEditor/DrumsEditor'
import { INSTRUMENT_TYPES, InstrumentType } from '../../LayersOfInstruments/LayersOfInstruments'
import { melodyPianoRollId } from '../../MelodyEditor/MelodyEditor'
import styles from './EditorSettings.module.scss'

type Props = {
  mode: InstrumentType
  className?: string
  disabled?: boolean
}

const STRINGS = {
  stretch: 'Stretch',
}

const stretchOptions = {
  '/2': 1 / 2,
  '×2': 2,
}

const StretchSetting: FC<Props> = ({ mode, className, disabled }) => {
  const isMelodyMode = mode === INSTRUMENT_TYPES.MELODY
  const isDrumsMode = mode === INSTRUMENT_TYPES.DRUMS

  const { pianoRollNotes, selectedNoteIds, updateNotes } = useInteractivePianoRoll(
    isMelodyMode ? melodyPianoRollId : drumsPianoRollId,
  )

  const handleStretch = (value: number) => {
    const updates = stretchPianoRollNotes(pianoRollNotes, selectedNoteIds, value)
    updateNotes(updates)
  }

  return (
    <div className={classNames(styles.stretchSetting, className, { [styles.stretchDisabled]: disabled })}>
      <div className={styles.stretchTitle}>{STRINGS.stretch}:</div>

      <div className={styles.stretchContent}>
        {Object.entries(stretchOptions).map(([stretchOptionKey, stretchOptionValue]) => (
          <div
            key={stretchOptionKey}
            className={classNames(styles.stretchOption, styles[mode])}
            onClick={() => handleStretch(stretchOptionValue)}
          >
            {stretchOptionKey}
          </div>
        ))}
      </div>
    </div>
  )
}

export default StretchSetting
