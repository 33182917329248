export const TEMPO_LIST = ['Slow', 'Groovy', 'Rhythmic', 'Rapid', 'Current', 'Custom']

export const TEMPO_LIST_SHORT = ['Current', 'Random', 'Custom']

export const COMPLEXITY_LIST = ['Low', 'Medium', 'High', 'Random']

export const TEMPO_TO_RANGE = {
  Slow: [60, 80],
  Groovy: [80, 100],
  Rhythmic: [100, 130],
  Rapid: [130, 180],
}

export const GENRE_TO_RANGE = {
  Trap: [130, 150],
  RnB: [90, 120],
  'Hip-Hop': [85, 120],
  Ambient: [80, 115],
  EDM: [115, 140],
  House: [115, 130],
  Pop: [100, 130],
  Techno: [115, 140],
}

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const randBpmFromTempo = (tempo: string | number, genre: string) => {
  if (typeof tempo === 'number') {
    return tempo
  }
  // @ts-ignore
  if (!GENRE_TO_RANGE[genre]) {
    return 100
  }
  // @ts-ignore
  return randomIntFromInterval(GENRE_TO_RANGE[genre][0], GENRE_TO_RANGE[genre][1])
}
