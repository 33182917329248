import classNames from 'classnames'
import React, { FC } from 'react'

import DeleteInstrumentIcon from '../../../../assets/icons/delete-instrument.svg'
import MutedInstrumentIcon from '../../../../assets/icons/muted-instrument.svg'
import NotMutedInstrumentIcon from '../../../../assets/icons/not-muted-instrument.svg'
import { TTab } from './EditorTabs'
import styles from './EditorTabs.module.scss'

type Props = {
  tabs: TTab[]
  index: number
  activeTabIndex: number

  isDeleteTabEnabled?: boolean
  onDeleteTabCallback: (tab: TTab, tabIndex: number) => void

  isMuteTabEnabled?: boolean
  onMuteTabCallback: (tab: TTab, tabIndex: number) => void
}

const EditorTabMuteDeleteControll: FC<Props> = ({
  tabs,
  index,
  activeTabIndex,
  isDeleteTabEnabled = true,
  onDeleteTabCallback,
  isMuteTabEnabled = true,
  onMuteTabCallback,
}) => {
  const isActive = index === activeTabIndex

  const renderMuteControll = (tab: TTab, index: number) => {
    if (!isMuteTabEnabled) return null

    return (
      <div
        className={classNames(styles.showOnHower, { [styles.muted]: tab.isMuted })}
        onClick={(e) => {
          e.stopPropagation()
          onMuteTabCallback(tab, index)
        }}
      >
        {tab.isMuted ? <MutedInstrumentIcon /> : <NotMutedInstrumentIcon />}
      </div>
    )
  }
  const renderDeleteControll = (tab: TTab, index: number) => {
    if (!isDeleteTabEnabled) return null

    const isDeleteAvailable = tabs.length > 1

    return (
      <div
        className={classNames({ [styles.disabled]: !isDeleteAvailable })}
        onClick={(e) => {
          if (!isDeleteAvailable) return

          e.stopPropagation()
          onDeleteTabCallback(tab, index)
        }}
      >
        <DeleteInstrumentIcon />
      </div>
    )
  }

  if (!isMuteTabEnabled && !isDeleteTabEnabled) return null
  return (
    <div className={classNames(styles.instrumentBtns, { [styles.activeLayer]: isActive })}>
      {renderMuteControll(tabs[index], index)}

      {renderDeleteControll(tabs[index], index)}
    </div>
  )
}

export default EditorTabMuteDeleteControll
