import React, { FC } from 'react'

import InteractivePianoRoll from '../../../ai-playground/InteractivePianoRoll/InteractivePianoRoll'
import { drumsPianoRollId } from '../DrumsEditor'
import styles from './DrumsManualEditor.module.scss'

type Props = object

const DrumsManualEditor: FC<Props> = () => {
  return (
    <div className={styles.manualEditorContainer}>
      <InteractivePianoRoll id={drumsPianoRollId} />
    </div>
  )
}

export default DrumsManualEditor
