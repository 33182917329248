import { TWelcomeModalAnswers } from '../components/signup/welcome/data'
import { hjEvent } from '../utils/hotjar'
import { trackPixelCustomEvent } from '../utils/pixelUtils'
import { classifyQuizSource } from '../utils/quiz'
import { capitalize } from '../utils/stringUtils'
import { FrontendUserPrefs } from '../utils/types'
import api from './index'

export const getUserPrefsInnerRoute = async () => {
  const response = await api.get('/api/user-prefs/data')
  return response.data as FrontendUserPrefs
}

export const updateUndoRedoShownInnerRoute = async () => {
  await api.post('/api/user-prefs/undo-redo-pref')
}

export const updateFavouriteInstrumentInnerRoute = async (instrument: string) => {
  await api.post('/api/user-prefs/favourite-instrument-pref', null, { params: { instrument } })
}

export const updateLyricsPreviewShownInnerRoute = async () => {
  await api.post('/api/user-prefs/lyrics-preview-pref')
}

export const updateUserQuizAnswersInnerRoute = async (answers: TWelcomeModalAnswers) => {
  const {
    OCCUPATION: occupation,
    OCCUPATION_DETAILED: details,
    MUSIC_LEVEL: music_making_level,
    HELP_WITH: help_with,
    SOURCE: source_raw,
    SOURCE_DETAILED: source_detailed_raw,
  } = answers

  hjEvent('event', `user_segment_${occupation}`)

  const { source, source_detailed } = classifyQuizSource(source_raw as string, source_detailed_raw)

  await api.post('/api/user-prefs/quiz-answers', {
    who: occupation,
    details,
    music_making_level,
    help_with,
    source,
    source_detailed,
  })

  trackPixelCustomEvent('QuizAnswer', { occupation, details, music_making_level, help_with, source, source_detailed })
  trackPixelCustomEvent(`Quiz${capitalize(occupation as string)}`)
}

export const updateUserAfterRegAnswersInnerRoute = async (emailsSubscribed: boolean) => {
  await api.post('/api/user-prefs/quiz-answers/after-reg', {
    emailsSubscribed,
  })
}

export const updateLastBigFeatureShownInnerRoute = async (feature: string) => {
  await api.post('/api/user-prefs/last-big-feature-shown', {
    lastBigFeatureShown: feature,
  })
}
