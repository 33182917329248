import * as Tone from 'tone'
import { Output } from 'webmidi'

class MidiOutputPlayer {
  volume: any
  ports: { [key: string]: string } = {}
  outputs: Output[] = []

  constructor(outputs: any) {
    this.outputs = outputs
  }

  updatePort(key: string, port: string) {
    const ports = this.ports

    if (ports) ports[key] = port
  }

  getOutputs(key?: string): Output[] {
    if (!key) {
      return this.outputs.filter((o) => Object.values(this.ports).includes(o.name))
    }

    return this.outputs.filter((o) => o.name === this.ports[key])
  }

  executeOutputs(func: (output: Output) => void, outputs: Output[] = this.getOutputs()) {
    outputs.forEach((output) => func(output))
  }

  triggerRelease(note: string[] | string, a?: any) {
    const notes = typeof note !== 'string' ? note : [note]

    notes.forEach((n) => {
      const sendNoteOff = (output: Output) => {
        output.channels[1].sendNoteOff(Tone.Frequency(n).toMidi() + 12, {
          time: '+500',
        })
      }

      this.executeOutputs(sendNoteOff, this.getOutputs())
    })
  }

  triggerAttack(note: string) {
    const sendNoteOn = (output: Output) => {
      output.channels[1].sendNoteOn(Tone.Frequency(note).toMidi() + 12)
    }

    this.executeOutputs(sendNoteOn, this.getOutputs())
  }

  triggerAttackRelease(note: string, duration?: any, time?: any, velocity?: any, key?: string) {
    const playNote = (output: Output) => {
      output.channels[1].playNote(Tone.Frequency(note).toMidi() + 12, {
        duration: Tone.Time(duration || '1:0:0').toSeconds() * 1000,
        attack: velocity,
      })
    }

    this.executeOutputs(playNote, this.getOutputs(key))
  }

  releaseAll() {
    const sendAllNotesOff = (output: Output) => {
      output.channels[1].sendAllNotesOff()
    }

    this.executeOutputs(sendAllNotesOff, this.getOutputs())
  }

  disconnect() {}

  dispose() {}

  connect() {}
}

export default MidiOutputPlayer
