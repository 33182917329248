import { Prog, ProgPart } from './types'

// DEFAULT OPERATIONS

type T_DefaultOperation_ProgKeys =
  | 'rename-prog'
  | 'set-prog-key'
  | 'set-prog-scale'
  | 'set-prog-volume'
  | 'set-prog-bpm'
  | 'set-prog-metronome-enabled'
type T_DefaultOperation_PartKeys =
  | 'new-part'
  | 'set-part-loops'
  | 'rename-part'
  | 'duplicate-part'
  | 'delete-part'
  | 'drag-part'
type T_DefaultOperation_TrackConfigKeys = 'set-genre' | 'set-volume' | 'show-hide-track' | 'set-length' | 'set-tempo'

// NOT DEFAULT OPERATIONS

type T_NotDefaultOperation_TrackKeys = 'set-chords' | 'set-melody' | 'set-drums' | 'set-instruments'

// HISTORY

export type TOperationKey =
  | T_DefaultOperation_ProgKeys
  | T_DefaultOperation_PartKeys
  | T_DefaultOperation_TrackConfigKeys
  | T_NotDefaultOperation_TrackKeys
export type OperationEntity = {
  key: TOperationKey
  oldState: any
  newState: any
}
export type TMoveType = 'undo' | 'redo'

export type TCheckers = { [key in TOperationKey]: (oldState: Prog, newState: Prog) => OperationEntity | null }
export type TOperators = { [key in TOperationKey]: (key: TOperationKey, newValue: any, state: Prog) => Prog }
export type TOperationsDescriptions = { [key in TOperationKey]: string }

// CONSTANTS

export const historyUpdateDebounceTime = 500
export const operationsDescriptionsMap: TOperationsDescriptions = {
  'rename-prog': 'Project name',
  'set-prog-key': 'Project key',
  'set-prog-volume': 'Project volume',
  'set-prog-bpm': 'Project bpm',
  'set-prog-metronome-enabled': 'Project metronome',
  'set-prog-scale': 'Project scale',
  'new-part': 'New part added',
  'set-part-loops': 'Part loops changed',
  'rename-part': 'Part renamed',
  'duplicate-part': 'Part duplicated',
  'delete-part': 'Part deleted',
  'drag-part': 'Part order changed',
  'set-genre': 'Genre changed',
  'set-volume': 'Volume changed',
  'show-hide-track': 'Tracks changed',
  'set-length': 'Track length changed',
  'set-tempo': 'Track tempo changed',
  'set-chords': 'Chords changed',
  'set-melody': 'Melody changed',
  'set-drums': 'Drums changed',
  'set-instruments': 'Instruments changed',
}

// FUNCTIONS

//     =====     GENERAL     =====

export const getValueByPath = (obj: { [key: string]: any }, path: string) => {
  const [firstKey, secondKey] = path.split('.')

  if (!secondKey || !obj[firstKey]) return obj[firstKey]
  return obj[firstKey][secondKey]
}
export const moveElementInArray = (array: any[], fromIndex: number, toIndex: number) => {
  const element = array.splice(fromIndex, 1)[0]
  array.splice(toIndex, 0, element)

  return array
}
export const insertElementInArray = (array: any[], element: any, toIndex: number) => {
  array.splice(toIndex, 0, element)
  return array
}
export const checkIfPartsAreDuplicates = (oldPart: ProgPart, newPart: ProgPart) => {
  if (!oldPart || !newPart) return false

  const { id: idOld, name: nameOld, ...restOfOldPart } = oldPart
  const { id: idNew, name: nameNew, ...restOfNewPart } = newPart

  if (idOld === idNew || nameOld === nameNew || !nameNew.endsWith(nameOld)) return false
  return JSON.stringify(restOfOldPart) === JSON.stringify(restOfNewPart)
}
export const handleExternalUndoRedo = (isUndo: boolean, undo: () => any, redo: () => any) => {
  if (isUndo) {
    undo && undo()
    return
  }

  redo && redo()
}
export const getUndoRedoDescription = (operations: OperationEntity[]) => {
  const operationsKeys = operations.map((operation) => operation.key)
  const operationsDescriptions = operationsKeys.map((operationKey) => operationsDescriptionsMap[operationKey])

  let description = 'Changes on this step:'

  operationsDescriptions.forEach((operationDescription) => (description += `\n   - ${operationDescription}`))

  return description
}

//     =====     PROG     =====

type TChangeProgKey = 'name' | 'key' | 'scale' | 'volume' | 'bpm' | 'isMetronomeEnabled'

export const checkIfChangeProg = (
  operationKey: TOperationKey,
  key: TChangeProgKey,
  oldState: Prog,
  newState: Prog,
): OperationEntity | null => {
  const isChanged = oldState[key] !== newState[key]

  if (!isChanged) return null
  return { key: operationKey, oldState: oldState[key], newState: newState[key] }
}
export const handleChangeProg = (
  operationKey: TOperationKey,
  operationKeyProps: TOperationKey,
  key: TChangeProgKey,
  newValue: any,
  state: Prog | null,
): Prog => {
  if (operationKey !== operationKeyProps || !state) return state as Prog

  const newState = JSON.parse(JSON.stringify(state)) as Prog
  // @ts-ignore
  newState[key] = newValue

  return newState
}

//     =====     PART     =====

type TChangePartKey =
  | 'loops'
  | 'name'
  //
  | 'chordsVolume'
  //
  | 'melodyShown'
  | 'melody.length'
  | 'melody.tempo'
  | 'melodyVolume'
  //
  | 'drumsShown'
  | 'drums.length'
  | 'drums.tempo'
  | 'drumsVolume'
  //
  | 'generatorSettings.chordGenreKey'
  | 'generatorSettings.drumGenreKey'

export const checkIfChangePart = (
  operationKey: TOperationKey,
  keys: TChangePartKey[],
  oldState: Prog,
  newState: Prog,
): OperationEntity | null => {
  const changedPart = newState.parts.find((part) => {
    const oldPart = oldState.parts.find((oP) => oP.id === part.id)
    if (!oldPart) return false
    return keys.some((key) => getValueByPath(part, key) !== getValueByPath(oldPart, key))
  })

  const oldPart = oldState.parts.find((part) => part.id === changedPart?.id)
  const newPart = newState.parts.find((part) => part.id === changedPart?.id)

  if (!changedPart || !oldPart || !newPart) return null

  const oldOperationState = { partId: changedPart.id } as any
  const newOperationState = { partId: changedPart.id } as any

  keys.forEach((key) => {
    const oldPartValue = getValueByPath(oldPart, key)
    const newPartValue = getValueByPath(newPart, key)

    if (oldPartValue !== newPartValue) {
      oldOperationState[key] = oldPartValue
      newOperationState[key] = newPartValue
    }
  })

  return { key: operationKey, oldState: oldOperationState, newState: newOperationState }
}
export const handleChangePart = (
  operationKey: TOperationKey,
  operationKeyProps: TOperationKey,
  keys: TChangePartKey[],
  newValue: any,
  state: Prog | null,
): Prog => {
  if (operationKey !== operationKeyProps || !state) return state as Prog

  const newState = JSON.parse(JSON.stringify(state)) as Prog
  newState.parts = newState.parts.map((part) => {
    if (part.id !== newValue.partId) return part

    const newPart = JSON.parse(JSON.stringify(part))
    keys.forEach((key) => {
      if (!(key in newValue)) return
      const [firstKey, secondKey] = key.split('.')
      const value = newValue[key]

      if (!secondKey) return (newPart[firstKey] = value)
      if (!(firstKey in newPart) || !newPart[firstKey]) newPart[firstKey] = {}
      newPart[firstKey][secondKey] = value
    })

    return newPart
  })

  return newState
}

//     =====     TRACK CONTENT     =====

type TChangeTrackKey =
  | 'chords'
  | 'melody.notes'
  | 'drums.groups'
  //
  | 'chordLayers'
  | 'melodyLayers'
  | 'drumLayers'

export const checkIfChangeTrack = (
  operationKey: TOperationKey,
  keys: TChangeTrackKey[],
  oldState: Prog,
  newState: Prog,
): OperationEntity | null => {
  const changedPart = newState.parts.find((part) => {
    const oldPart = oldState.parts.find((oP) => oP.id === part.id)
    if (!oldPart) return false

    return keys.some((key) => {
      return JSON.stringify(getValueByPath(part, key)) !== JSON.stringify(getValueByPath(oldPart, key))
    })
  })

  const oldPart = oldState.parts.find((part) => part.id === changedPart?.id)
  const newPart = newState.parts.find((part) => part.id === changedPart?.id)

  if (!changedPart || !oldPart || !newPart) return null

  const oldOperationState = { partId: changedPart.id } as any
  const newOperationState = { partId: changedPart.id } as any

  keys.forEach((key) => {
    const oldPartValue = JSON.stringify(getValueByPath(oldPart, key))
    const newPartValue = JSON.stringify(getValueByPath(newPart, key))

    if (oldPartValue !== newPartValue) {
      oldOperationState[key] = oldPartValue
      newOperationState[key] = newPartValue
    }
  })

  return { key: operationKey, oldState: oldOperationState, newState: newOperationState }
}
export const handleChangeTrack = (
  operationKey: TOperationKey,
  operationKeyProps: TOperationKey,
  keys: TChangeTrackKey[],
  newValue: any,
  state: Prog | null,
): Prog => {
  if (operationKey !== operationKeyProps || !state) return state as Prog

  const newState = JSON.parse(JSON.stringify(state)) as Prog
  newState.parts = newState.parts.map((part) => {
    if (part.id !== newValue.partId) return part

    const newPart = JSON.parse(JSON.stringify(part))
    keys.forEach((key) => {
      if (!(key in newValue)) return

      const [firstKey, secondKey] = key.split('.')
      const value = newValue[key] ? JSON.parse(newValue[key]) : newValue[key]

      if (key === 'melody.notes') return (newPart.melody = value ? { ...newPart.melody, notes: value } : null)
      if (key === 'drums.groups') return (newPart.drums = value ? { ...newPart.drums, groups: value } : null)

      if (!secondKey || !newPart[firstKey]) return (newPart[firstKey] = value)
      newPart[firstKey][secondKey] = value
    })

    return newPart
  })

  return newState
}

export const validateProgAfterHistoryUpdate = (prog: Prog) => {
  const newProg = JSON.parse(JSON.stringify(prog)) as Prog

  newProg.parts.forEach((part, index) => {
    if (!part.drums?.groups) newProg.parts[index].drums = null
    if (!part.melody?.notes) newProg.parts[index].melody = null
  })

  return newProg
}
