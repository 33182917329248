import { isMobileDevice } from './deviceUtils'
import { getInstrumentKey, getPartIdFromInstrumentKey } from './instrumentsUtils'
import { Chord, Prog, ProgPart, TimelineState } from './types'

export const getChordByIdFromProg = (id: number, prog: Prog | null | undefined) => {
  if (!prog) {
    return null
  }
  const allChords = getChordsFromAllParts(prog)
  return allChords.find((ch) => ch.id === id)
}

export const getNextChordIdFromProg = (prog: Prog, partId: number) => {
  const allChords = getActivePart(prog, partId)?.chords
  if (!allChords?.length) {
    return partId * 1000 + 1
  }
  return (Math.max(...(allChords || []).map((ch) => ch.id)) || 0) + 1
}

export const duplicateChordByIdInProg = (id: number, position: number, prog: Prog, partId: number): Prog => {
  const part = getActivePart(prog, partId)
  const newChords = [...(part?.chords || [])]
  const chord = getChordByIdFromProg(id, prog)
  if (!chord) {
    return prog
  }
  const newId = getNextChordIdFromProg(prog, partId)
  const newChord = {
    ...chord,
    id: newId,
  }
  newChords.splice(position, 0, newChord)

  return updateProgPartDataById(prog, partId, { chords: newChords })
}

export const insertChordInProg = (chord: Chord, position: number, prog: Prog, partId: number): Prog => {
  const part = getActivePart(prog, partId)
  const newChords = [...(part?.chords || [])]

  const newId = getNextChordIdFromProg(prog, partId)
  const newChord = {
    ...chord,
    id: newId,
  }
  newChords.splice(position, 0, newChord)

  return updateProgPartDataById(prog, partId, { chords: newChords })
}

export const replaceChordByIdInProg = (id: number, prog: Prog, chord: Chord, partId: number): Prog => {
  const idExists = !!getChordByIdFromProg(id, prog)
  if (!idExists) {
    chord.duration = 1
  }
  const part = getActivePart(prog, partId)
  return updateProgPartDataById(prog, partId, {
    chords: idExists
      ? (part?.chords || []).map((ch) => (ch.id === id ? { ...ch, ...chord, duration: ch.duration, id } : ch)) || []
      : [...(part?.chords || []), { ...chord, id }],
  })
}

export const replaceProgWithNew = (prog: Prog, newProg: Prog) => {
  return {
    ...newProg,
    componentKey: prog.componentKey,
    parts: newProg.parts.map((part) => ({
      ...part,
      chords: part.chords.map((ch, index) => ({
        ...part.chords[index],
        ...ch,
      })),
    })),
  }
}

export const updateChordsFromTimeline = (s: TimelineState, prog: Prog, partId: number) => {
  const newChords: Array<Chord> = s.tiles
    .map(
      (id) =>
        getActivePart(prog, partId)?.chords.find((ch: Chord) => ch.id === id) || {
          midi: [],
          midiTimings: [],
          id,
          degree: '',
          duration: 0,
          name: '',
          octave: 0,
          settings: {
            velocity: 100,
          },
          voicings: [],
        },
    )
    .map((ch: Chord) => {
      const width = s.widths[ch.id] || 1
      return {
        ...ch,
        duration: width,
      }
    })
  return updateProgPartDataById(prog, partId, { chords: newChords })
}

export const updateProgPartDataById = (prog: Prog, partId: number, data: Partial<ProgPart>) => {
  return {
    ...prog,
    parts: prog.parts.map((p) => {
      if (p.id === partId) {
        return {
          ...p,
          ...data,
        }
      }
      return p
    }),
  }
}

export const incrementPartLoop = (prog: Prog | null, partId: number, value = 1) => {
  if (!prog) {
    return prog
  }
  return {
    ...prog,
    parts: prog.parts.map((p) => ({
      ...p,
      loops: partId === p.id ? p.loops + value : p.loops,
    })),
  }
}

export const getPartIdByChordId = (chordId?: number | null) => (chordId ? Math.floor(chordId / 1000) : null)
export const getFirstNonEmptyPart = (prog: Prog | null) => {
  const nonEmpty = prog?.parts.find((p) => !!p.chords.length || !!p.drums)
  return nonEmpty || prog?.parts[0]
}

export const getProgLengthInBars = (prog: Prog) => {
  let duration = 0
  prog.parts.forEach((p) => {
    duration += getPartLengthInBars(prog, p.id) * p.loops
  })
  return duration
}

export const getPartLengthInBars = (prog: Prog | null, partId: number) => {
  if (!prog) {
    return 0
  }
  let barsOffset = 0
  const part = prog.parts.find((p) => p.id === partId)

  part?.chords.forEach((ch) => {
    barsOffset += ch.duration || 0
  })
  return Math.max(barsOffset, part?.drums?.length || 0, part?.melody?.length || 0)
}

export const getPartOffsetInBars = (prog: Prog | null, partId: number, loop = 1) => {
  if (!prog) {
    return 0
  }
  let barsOffset = 0
  for (let i = 0; i < prog.parts.length; i++) {
    for (let j = 0; j < prog.parts[i].loops; j++) {
      const part = prog.parts[i]
      if (part.id === partId && j + 1 >= loop) {
        return barsOffset
      }
      barsOffset += getPartLengthInBars(prog, part.id)
    }
  }
  return barsOffset
}

export const getChordsFromAllParts = (prog: Prog | null) => {
  return prog?.parts?.reduce((acc: Array<Chord>, part) => [...acc, ...part.chords], []) || []
}
export const getMelodyNotesFromAllParts = (prog: Prog | null) => {
  return (
    prog?.parts?.reduce(
      (acc: Array<any>, part) => [...acc, ...(part.melody?.notes || []).map((note) => ({ ...note, id: part.id }))],
      [],
    ) || []
  )
}

// loop part not needed for export or preview
export const getChordsFromAllPartsWithLoops = (prog: Prog | null, loopedPart?: number) => {
  if (loopedPart) {
    return prog?.parts.find((p) => p.id === loopedPart)?.chords || []
  }
  return (
    prog?.parts.reduce((acc: Array<Chord>, part: ProgPart) => {
      const newAcc = [...acc]
      const duration = getPartLengthInBars(prog, part.id)
      let chordsDuration = 0
      part.chords.forEach((ch) => {
        chordsDuration += ch.duration || 0
      })
      const diff = duration - chordsDuration
      for (let i = 0; i < part.loops; i++) {
        newAcc.push(...part.chords)
        if (diff > 0) {
          // mock chord to add pauses if drums timeline is longer then chords timeline
          newAcc.push({
            duration: diff,
            midi: [0],
            id: 0,
            degree: '',
            name: '',
            settings: { velocity: 0 },
            midiTimings: [],
            octave: 0,
            voicings: [],
          })
        }
      }
      return newAcc
    }, []) || []
  )
}

export const getPartsForEachLoopArr = (prog: Prog | null, loopedPart?: number) => {
  if (loopedPart) {
    return [prog?.parts.find((p) => p.id === loopedPart)]
  }
  return (
    prog?.parts.reduce((acc: Array<ProgPart>, part: ProgPart) => {
      const newAcc = [...acc]
      for (let i = 0; i < part.loops; i++) {
        newAcc.push(part)
      }
      return newAcc
    }, []) || []
  )
}

export const getActivePart = (prog: Prog | null, partId: number) => prog?.parts.find((p) => p.id === partId)

export const isPartEmpty = (part: ProgPart) => !part.chords.length && !part.drums && !part.melody
export const isProgEmpty = (prog: Prog | null) =>
  !prog || prog.parts.length === 0 || prog.parts.every((p) => isPartEmpty(p))
export const convertProgToPlainArray = (prog: Prog, partId: number) => {
  return {
    ...prog,
    chords: getActivePart(prog, partId)?.chords || [],
  }
}

export const isPatternDraft = (part?: ProgPart) => {
  // if (typeof part?.drumsPattern === 'object' && !!part.drumsPattern) {
  //   return part.drumsPattern
  // }
  return null
}

export const isMelodyPatternClear = (part?: ProgPart) => {
  return !part || part.melody === null
}
export const isDrumsPatternClear = (part?: ProgPart) => {
  return !part || part.drums === null
}

export const isSilentProgUpdate = (prevProg: Prog, prog: Prog) => {
  const progToCompare = (prog: Prog) => ({
    ...(prog || {}),
    parts: prog?.parts.map((p) => {
      const {
        chords,
        chordLayers,
        chordsMuted,
        chordsVolume,

        drums,
        drumsShown,
        drumLayers,
        drumsMuted,
        drumsVolume,

        melody,
        melodyShown,
        melodyLayers,
        melodyMuted,
        melodyVolume,

        generatorSettings,
        ...compareP
      } = p
      return {
        ...compareP,
        chords: chords.map((ch) => ({ ...ch, locked: false })),
      }
    }),
  })
  const comparePrev = progToCompare(prevProg)
  const compareCurrent = progToCompare(prog)
  return JSON.stringify(comparePrev) === JSON.stringify(compareCurrent)
}

export const getMaxPartsAvailable = (navigator: Navigator) => {
  return isMobileDevice(navigator) ? 4 : 20
}

export const getSingleLayerFromProg = (prog: Prog, layerKey?: string) => {
  if (!layerKey) return prog

  const partId = getPartIdFromInstrumentKey(layerKey)

  const newProg = JSON.parse(JSON.stringify(prog)) as Prog
  newProg.parts = prog.parts
    .filter((part) => part.id === partId)
    .map((part) => {
      return {
        ...part,
        chordLayers: part.chordLayers.filter(
          (layer, index) => getInstrumentKey(layer.instrument, index, partId) === layerKey,
        ),
        melodyLayers: part.melodyLayers.filter(
          (layer, index) => getInstrumentKey(layer.instrument, index, partId) === layerKey,
        ),
        drumLayers: part.drumLayers.filter(
          (layer, index) => getInstrumentKey(layer.instrument, index, partId) === layerKey,
        ),
      }
    })

  return newProg
}
