import DrumsBasicIcon from '../../../../assets/icons/instruments/drums_basic.svg'
import DrumsHiphopIcon from '../../../../assets/icons/instruments/drums_hiphop.svg'
import DrumsLiveIcon from '../../../../assets/icons/instruments/drums_live.svg'
import DrumsOtherIcon from '../../../../assets/icons/instruments/drums_other.svg'
import KeysIcon from '../../../../assets/icons/instruments/piano_keys.svg'
import PianoOtherIcon from '../../../../assets/icons/instruments/piano_other.svg'
import PadsIcon from '../../../../assets/icons/instruments/piano_pads.svg'
import PlucksIcon from '../../../../assets/icons/instruments/piano_plucks.svg'
import MidiOutputIcon from '../../../../assets/icons/midi-output.svg'
import AmbientIcon from '../../../../assets/icons/patterns/ambient.svg'
import EDMIcon from '../../../../assets/icons/patterns/edm.svg'
import HipHopIcon from '../../../../assets/icons/patterns/hiphop.svg'
import HouseIcon from '../../../../assets/icons/patterns/house.svg'
import MyPatternIcon from '../../../../assets/icons/patterns/my-presets.svg'
import PopIcon from '../../../../assets/icons/patterns/pop.svg'
import RandBIcon from '../../../../assets/icons/patterns/randb.svg'
import TechnoIcon from '../../../../assets/icons/patterns/techno.svg'
import TrapIcon from '../../../../assets/icons/patterns/trap.svg'

export const MIDI_OUTPUT = 'midi-output'
export const getMidiPort = (midiOut?: string) => midiOut?.replace(`${MIDI_OUTPUT}:`, '') || ''

export const INSTRUMENT_CATEGORY = {
  keys: 'keys',
  guitars: 'guitars',
  synths: 'synths',
  pads: 'pads',
  strings: 'strings',
  basses: 'basses',
  other: 'other',
}

export const INSTRUMENT_CATEGORIES_NEW = Object.values(INSTRUMENT_CATEGORY)

export const DRUMS_CATEGORY = {
  basic: 'basic',
  // hiphop: 'hiphop',
  // live: 'live',
  // other: 'other',
}

export const DRUMS_CATEGORIES_NEW = Object.values(DRUMS_CATEGORY)

export const INSTRUMENT_CATEGORIES = {
  keys: { icon: KeysIcon, order: 0 },
  pads: { icon: PadsIcon, order: 1 },
  plucks: { icon: PlucksIcon, order: 2 },
  other: { icon: PianoOtherIcon, order: 3 },
  [MIDI_OUTPUT]: { icon: MidiOutputIcon, order: 4 },
}

export const DRUMS_CATEGORIES = {
  basic: { icon: DrumsBasicIcon, order: 0 },
  hiphop: { icon: DrumsHiphopIcon, order: 1 },
  live: { icon: DrumsLiveIcon, order: 2 },
  other: { icon: DrumsOtherIcon, order: 3 },
  [MIDI_OUTPUT]: { icon: MidiOutputIcon, order: 4 },
}

export const CUSTOM_CATEGORY_LABEl = 'My Presets'

export const PATTERN_GENRES = {
  custom: { icon: MyPatternIcon, order: 1 },
  'Hip-Hop': { icon: HipHopIcon, order: 7 },
  Pop: { icon: PopIcon, order: 5 },
  Trap: { icon: TrapIcon, order: 4 },
  RnB: { icon: RandBIcon, order: 3 },
  Techno: { icon: TechnoIcon, order: 9 },
  EDM: { icon: EDMIcon, order: 6 },
  Ambient: { icon: AmbientIcon, order: 2 },
  House: { icon: HouseIcon, order: 8 },
}

export const PATTERNS_ORDER = Object.entries(PATTERN_GENRES)
  .sort((a, b) => a[1].order - b[1].order)
  .map((a) => a[0])
