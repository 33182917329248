import React, { ReactNode } from 'react'

import OccupationComposerIcon from '../../../assets/icons/signup/occupation/composer.svg'
import OccupationGuitaristIcon from '../../../assets/icons/signup/occupation/guitarist.svg'
import OccupationOtherIcon from '../../../assets/icons/signup/occupation/other.svg'
import OccupationProducerIcon from '../../../assets/icons/signup/occupation/producer.svg'
import OccupationSingerIcon from '../../../assets/icons/signup/occupation/singer.svg'
import OccupationSongwriterIcon from '../../../assets/icons/signup/occupation/song-writer.svg'
import OccupationStudentIcon from '../../../assets/icons/signup/occupation/student.svg'
import OccupationTeacherIcon from '../../../assets/icons/signup/occupation/teacher.svg'
//
import SourceChatgptIcon from '../../../assets/icons/signup/source/chatgpt.svg'
import SourceFriendsIcon from '../../../assets/icons/signup/source/friends.svg'
import SourceGoogleIcon from '../../../assets/icons/signup/source/google.svg'
import SourceInstagramIcon from '../../../assets/icons/signup/source/instagram.svg'
import SourceOtherIcon from '../../../assets/icons/signup/source/other.svg'
import SourceSchoolIcon from '../../../assets/icons/signup/source/school.svg'
import SourceTiktokIcon from '../../../assets/icons/signup/source/tiktok.svg'
import SourceYoutubeIcon from '../../../assets/icons/signup/source/youtube.svg'

export enum WelcomeModalSteps {
  GET_STARTED = 'GET_STARTED',
  OCCUPATION = 'OCCUPATION',
  MUSIC_LEVEL = 'MUSIC_LEVEL',
  HELP_WITH = 'HELP_WITH',
  SOURCE = 'SOURCE',
}
export type TWelcomeModalSteps = keyof typeof WelcomeModalSteps

export type TStepDataContent = {
  icon: ReactNode
  title: string
  text?: string
  value: string
}
export type TStepData = {
  title: string
  subtitle?: string
  isMultiple: boolean
  layout: 'grid' | 'list'
  content: TStepDataContent[]
}

export type TWelcomeModalAnswers = { [key in TWelcomeModalSteps]: string | string[] } & {
  OCCUPATION_DETAILED: string
  SOURCE_DETAILED: string
}

export const occupationStepData: TStepData = {
  title: 'What are you using ChordChord for?',
  subtitle: "We'll use this to customize your experience",
  isMultiple: false,
  layout: 'grid',
  content: [
    {
      icon: <OccupationProducerIcon />,
      title: 'Music Producer',
      text: 'You make music in DAWs (Logic Pro X, Ableton, FL Studio, etc.)',
      value: 'producer',
    },
    {
      icon: <OccupationStudentIcon />,
      title: 'Student',
      text: 'You’re here to impress your teachers and classmates',
      value: 'student',
    },
    {
      icon: <OccupationSongwriterIcon />,
      title: 'Songwriter',
      text: 'You create songs and write lyrics',
      value: 'songwriter',
    },
    {
      icon: <OccupationGuitaristIcon />,
      title: 'Guitarist',
      text: 'You’re jamming with your guitar, use the backing tracks',
      value: 'guitarist',
    },
    {
      icon: <OccupationTeacherIcon />,
      title: 'Teacher',
      text: 'You educate students or give music lessons',
      value: 'teacher',
    },
    {
      icon: <OccupationSingerIcon />,
      title: 'Singer',
      text: 'You love to practice and play with your voice ',
      value: 'singer',
    },
    {
      icon: <OccupationComposerIcon />,
      title: 'Composer',
      text: 'You create, arrange, and orchestrate music',
      value: 'composer',
    },
    {
      icon: <OccupationOtherIcon />,
      title: 'Other',
      text: '',
      value: 'other',
    },
  ],
}
export const musicLevelStepData: TStepData = {
  title: 'What’s your music-making level?',
  isMultiple: false,
  layout: 'grid',
  content: [
    {
      icon: '🙋‍♂️',
      title: 'Complete Beginner',
      text: 'Don’t know how to make music or chords. Just starting out.',
      value: 'beginner',
    },
    {
      icon: '🎹',
      title: 'I Have Some Music Skills',
      text: 'Making some music, familiar with music theory, and using tools like FL Studio or Ableton.',
      value: 'intermediate',
    },
    {
      icon: '🎚️',
      title: "I'm a Pro",
      text: 'Experienced professional, expert in music production and confident with theory.',
      value: 'pro',
    },
  ],
}
export const helpWithStepData: TStepData = {
  title: 'What would you like ChordChord to help you with?',
  isMultiple: true,
  layout: 'list',
  content: [
    {
      icon: '🎹',
      title: 'Building chord progressions',
      value: 'building-chord-progressions',
    },
    {
      icon: '🪄',
      title: 'Composing without instruments',
      value: 'composing-without-instruments',
    },
    {
      icon: '🎶',
      title: 'Creating melodies',
      value: 'creating-melodies',
    },
    {
      icon: '🔉',
      title: 'Structuring & arranging my tracks',
      value: 'structuring-and-arranging-my-tracks',
    },
    {
      icon: '🔄',
      title: 'Generating musical ideas',
      value: 'generating-musical-ideas',
    },
    {
      icon: '🥁',
      title: 'Making drum patterns',
      value: 'making-drum-patterns',
    },
    {
      icon: '🎸',
      title: 'Practicing instrument',
      value: 'practicing-instrument',
    },
    {
      icon: '🎼',
      title: 'Learning theory concepts',
      value: 'learning-theory-concepts',
    },
  ],
}
export const sourceStepData: TStepData = {
  title: 'Finally, how did you hear about us?',
  isMultiple: false,
  layout: 'grid',
  content: [
    {
      icon: <SourceInstagramIcon />,
      title: 'Instagram',
      value: 'instagram',
    },
    {
      icon: <SourceYoutubeIcon />,
      title: 'YouTube',
      value: 'youtube',
    },
    {
      icon: <SourceTiktokIcon />,
      title: 'TikTok',
      value: 'tiktok',
    },
    {
      icon: <SourceGoogleIcon />,
      title: 'Google Search',
      value: 'google',
    },
    {
      icon: <SourceChatgptIcon />,
      title: 'ChatGPT',
      value: 'chatgpt',
    },
    {
      icon: <SourceSchoolIcon />,
      title: 'School / University',
      value: 'school',
    },
    {
      icon: <SourceFriendsIcon />,
      title: 'Friends',
      value: 'friends',
    },
    {
      icon: <SourceOtherIcon />,
      title: 'Other',
      value: 'other',
    },
  ],
}

export const welcomeModalConfig = {
  [WelcomeModalSteps.GET_STARTED]: {
    actionType: '',
    actionsDisabled: true,
    prevStep: '',
    nextStep: WelcomeModalSteps.OCCUPATION,
    data: null,
  },
  [WelcomeModalSteps.OCCUPATION]: {
    actionType: '',
    actionsDisabled: true,
    prevStep: WelcomeModalSteps.GET_STARTED,
    nextStep: WelcomeModalSteps.MUSIC_LEVEL,
    data: occupationStepData,
  },
  [WelcomeModalSteps.MUSIC_LEVEL]: {
    actionType: 'back',
    actionsDisabled: false,
    prevStep: WelcomeModalSteps.OCCUPATION,
    nextStep: WelcomeModalSteps.HELP_WITH,
    data: musicLevelStepData,
  },
  [WelcomeModalSteps.HELP_WITH]: {
    actionType: 'back',
    actionsDisabled: false,
    prevStep: WelcomeModalSteps.MUSIC_LEVEL,
    nextStep: WelcomeModalSteps.SOURCE,
    data: helpWithStepData,
  },
  [WelcomeModalSteps.SOURCE]: {
    actionType: 'back',
    actionsDisabled: false,
    prevStep: WelcomeModalSteps.HELP_WITH,
    nextStep: '',
    data: sourceStepData,
  },
}
export const answersExample: TWelcomeModalAnswers = {
  [WelcomeModalSteps.GET_STARTED]: '',
  [WelcomeModalSteps.OCCUPATION]: '',
  [WelcomeModalSteps.MUSIC_LEVEL]: '',
  [WelcomeModalSteps.HELP_WITH]: '',
  [WelcomeModalSteps.SOURCE]: '',
  OCCUPATION_DETAILED: '',
  SOURCE_DETAILED: '',
}

export const occupationStepValues = occupationStepData.content.map((option) => option.value)
export const sourceStepValues = sourceStepData.content.map((option) => option.value)
