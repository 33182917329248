import {
  InteractivePianoRollContextType,
  DragType,
  PianoRollNote,
  StripeSegment,
  CursorMode,
  BrushMode,
} from '../types'
import { adjustNoteLimits } from '../utils/noteMovementHelper'
import { moveTempNotesToNormal } from '../utils/noteStateUtils'
import { hasSameNumberOfStripes, createStripeOverlapsDict, getMonoNoteOverlaps } from '../utils/overlapHelper'
import { noteMidiToNoteName, ticksToWidth, widthToTicks, zoomLevelToZoomFactor } from '../utils/pianoRollUtils'
import {
  detectPianoRollNoteAndDrag,
  positionToNoteCoordinates,
  heightToMidiValue,
  getRelativeMousePositionInDiv,
  detectScrollBarForPosition,
} from '../utils/positionHelper'
import { handleDragScrollX, handleDragScrollY } from './scrollHandler'

const initDrag = (
  context: InteractivePianoRollContextType,
  event: MouseEvent | PointerEvent,
  initialX: number,
  initialY: number,
  movementX: number,
  movementY: number,
  refs: any,
) => {
  const {
    cursorMode,
    getSelectedNotes,
    isNoteSelected,
    setSelectedNoteIds,
    contentHeight,
    addTempNotes,
    removeAllAutocompleteNotes,
  } = context

  const { scrollBarXRef, scrollBarYRef, containerRef } = refs

  const scrollBarDragType = detectScrollBarForPosition(context, refs, initialX, initialY)

  if (scrollBarDragType === DragType.SCROLL_BAR_X || scrollBarDragType === DragType.SCROLL_BAR_Y) {
    return {
      dragType: scrollBarDragType,
      initial:
        scrollBarDragType === DragType.SCROLL_BAR_X
          ? parseFloat(scrollBarXRef.current.style.left)
          : parseFloat(scrollBarYRef.current.style.top),
    }
  }

  const { x, y } = getRelativeMousePositionInDiv(initialX, initialY, containerRef)
  const { note, type } = detectPianoRollNoteAndDrag(context, x, y, false) || {}

  const bottomBasedY = contentHeight - y

  const posData = {
    relX: x,
    relY: bottomBasedY,
  }

  // Remove autocomplete notes when dragging
  removeAllAutocompleteNotes()

  switch (cursorMode) {
    case CursorMode.SELECTION:
      if (note) {
        if (!isNoteSelected(note.id)) {
          setSelectedNoteIds(new Set([note.id]))
        }

        let tempNoteIds: string[] = []
        if (type === DragType.NOTE) {
          tempNoteIds = addTempNotes(
            Array.from(new Set([...getSelectedNotes(), note])).map(({ opacity, ...rest }) => rest),
          )
        }

        return {
          dragType: type,
          dragNote: note,
          dragTempNote: null,
          tempNoteIds,
        }
      } else {
        return {
          ...posData,
          dragType: DragType.SELECTION_BOX,
        }
      }
    case CursorMode.PENCIL:
      return {
        ...posData,
        dragType: DragType.PENCIL,
        dragNote: note,
      }
    case CursorMode.BRUSH:
      return {
        ...posData,
        dragType: DragType.BRUSH,
        brushMode: note ? BrushMode.BRUSH_REMOVE : BrushMode.BRUSH_ADD,
        lastBrushPosition: null,
      }
    case CursorMode.VELOCITY:
      return {
        ...posData,
        dragType: DragType.VELOCITY,
      }
  }
}

const drawStripeOverlapsDOM = (
  context: InteractivePianoRollContextType,
  noteList: PianoRollNote[],
  nStripesDict: Record<string, StripeSegment[]>,
  refs: any,
) => {
  const { baseGridWidth, ppq, zoomLevelRef } = context
  const { pianoRollNotesRef } = refs
  noteList.forEach((note) => {
    const noteDiv = pianoRollNotesRef.current?.querySelector(`#${note.id}`) as HTMLElement
    if (!note || !noteDiv) return
    const stripes = nStripesDict[note.id]
    if (!stripes || stripes.length === 0) return
    stripes.forEach((stripe) => {
      const stripeDiv = pianoRollNotesRef.current?.querySelector(`#${stripe.id}`) as HTMLElement
      if (!stripeDiv) return
      stripeDiv.style.left =
        ticksToWidth(
          stripe.startTicks - note.startTicks,
          zoomLevelToZoomFactor(zoomLevelRef.current),
          baseGridWidth,
          ppq,
        ) + 'px'
      stripeDiv.style.width =
        ticksToWidth(
          stripe.endTicks - stripe.startTicks,
          zoomLevelToZoomFactor(zoomLevelRef.current),
          baseGridWidth,
          ppq,
        ) + 'px'
    })
  })
}

const drawDragAlignersDOM = (
  context: InteractivePianoRollContextType,
  tempNote: PianoRollNote,
  left: boolean,
  right: boolean,
  refs: any,
) => {
  const { dragAlignerLeftRef, dragAlignerRightRef } = refs
  const { zoomLevelRef, baseGridWidth, ppq } = context
  if (left) {
    dragAlignerLeftRef.current.style.visibility = 'visible'
    dragAlignerLeftRef.current.style.left =
      ticksToWidth(tempNote.startTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq) + 'px'
  }
  if (right) {
    dragAlignerRightRef.current.style.visibility = 'visible'
    dragAlignerRightRef.current.style.left =
      ticksToWidth(tempNote.endTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq) + 'px'
  }
}

const clearDragAlignersDOM = (refs: any) => {
  const { dragAlignerLeftRef, dragAlignerRightRef } = refs
  dragAlignerLeftRef.current.style.visibility = 'hidden'
  dragAlignerRightRef.current.style.visibility = 'hidden'
}

const handleNoteDrag = (
  context: InteractivePianoRollContextType,
  event: MouseEvent | PointerEvent,
  memo: any,
  last: boolean,
  movementX: number,
  movementY: number,
  refs: any,
) => {
  const { pianoRollNotesRef } = refs
  const {
    isMonophonic,
    baseGridWidth,
    ppq,
    getSelectedNotes,
    getNoteById,
    updateNotes,
    baseGridHeight,
    pianoRollNotes,
    renderStripesDict,
    setRenderStripesDict,
    removeAllTempNotes,
    onNoteSoundCallbackRef,
    zoomLevelRef,
    midiRangeMin,
    midiRangeMax,
  } = context

  const memoNote = memo.dragNote
  if (!memoNote) return

  const deltaTicks = widthToTicks(movementX, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq)
  const deltaMidiValue = heightToMidiValue(-movementY, baseGridHeight, 0) // No padding as it's a delta

  let draggingNoteIds = new Set([memoNote.id])
  const selectedNotes = getSelectedNotes()
  if (selectedNotes.length > 0) {
    draggingNoteIds = new Set(selectedNotes.map((note) => note.id))
  }

  // Adjust the note limits before updating the notes.
  const { noteList, adjustedDeltaTicks, adjustedDeltaMidiValue } = adjustNoteLimits(
    context,
    memo.dragType,
    memo.dragNote,
    draggingNoteIds,
    deltaTicks,
    deltaMidiValue,
  )

  // console.log('adjustedDeltaMidiValue', adjustedDeltaMidiValue)

  const oldNotes = pianoRollNotes.filter((note) => !draggingNoteIds.has(note.id))
  const tempNoteList: PianoRollNote[] = []

  // Update the notes
  noteList.forEach((note) => {
    const noteDiv = pianoRollNotesRef.current?.querySelector(`#${note.id}`) as HTMLElement
    if (!note || !noteDiv) return

    if (memo.dragType === DragType.NOTE) {
      const newStartTicks = note.startTicks + adjustedDeltaTicks
      noteDiv.style.left =
        ticksToWidth(newStartTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq) + 'px'
      noteDiv.style.bottom = (note.midiNote + adjustedDeltaMidiValue - midiRangeMin) * baseGridHeight + 'px'

      const noteNameDiv = noteDiv.querySelector('.noteName')
      if (noteNameDiv) noteNameDiv.textContent = noteMidiToNoteName(note.midiNote + adjustedDeltaMidiValue)

      const tempNote = {
        ...note,
        midiNote: note.midiNote + adjustedDeltaMidiValue,
        startTicks: newStartTicks,
        endTicks: note.endTicks + adjustedDeltaTicks,
      }
      tempNoteList.push(tempNote)

      if (memoNote.id === note.id) {
        drawDragAlignersDOM(context, tempNote, true, draggingNoteIds.size === 1, refs)
      }
    } else if (memo.dragType === DragType.NOTE_LENGTH) {
      const newEndTicks = note.endTicks + adjustedDeltaTicks
      noteDiv.style.width =
        ticksToWidth(newEndTicks - note.startTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq) +
        'px'
      const tempNote = { ...note, endTicks: newEndTicks }
      tempNoteList.push(tempNote)
      if (memoNote.id === note.id && draggingNoteIds.size === 1) {
        drawDragAlignersDOM(context, tempNote, false, true, refs)
      }
    }
  })

  // small snippet to make the sound when dragging notes
  if (memo.dragType === DragType.NOTE) {
    const dragTempNote = tempNoteList.find((note) => note.id == memoNote.id) || null
    if (dragTempNote && memo.dragTempNote)
      if (memo.dragTempNote.midiNote !== dragTempNote.midiNote) {
        if (onNoteSoundCallbackRef.current) onNoteSoundCallbackRef.current(dragTempNote)
      }
    memo.dragTempNote = dragTempNote
  }

  if (isMonophonic) {
    let nStripesDict: Record<string, StripeSegment[]> = {}
    const overlaps = getMonoNoteOverlaps(oldNotes, tempNoteList)
    if (overlaps) {
      nStripesDict = createStripeOverlapsDict(oldNotes, overlaps)
    }
    const result = hasSameNumberOfStripes(renderStripesDict, nStripesDict)
    if (!result) {
      setRenderStripesDict(nStripesDict)
    }
    drawStripeOverlapsDOM(context, oldNotes, nStripesDict, refs)
  }

  if (last) {
    clearDragAlignersDOM(refs)

    const updateList: { noteId: string; updatedFields: Partial<PianoRollNote> }[] = []
    draggingNoteIds.forEach((noteId) => {
      const note = getNoteById(noteId)
      if (!note) return
      if (memo.dragType === DragType.NOTE) {
        updateList.push({
          noteId,
          updatedFields: {
            midiNote: note.midiNote + adjustedDeltaMidiValue,
            startTicks: note.startTicks + adjustedDeltaTicks,
            endTicks: note.endTicks + adjustedDeltaTicks,
          },
        })
      } else if (memo.dragType === DragType.NOTE_LENGTH) {
        updateList.push({
          noteId,
          updatedFields: {
            endTicks: note.endTicks + adjustedDeltaTicks,
          },
        })
      }
    })
    updateNotes(updateList)
    if (memo.dragType === DragType.NOTE) {
      if (event.altKey) {
        moveTempNotesToNormal(context, tempNoteList, memo.tempNoteIds)
      }
      removeAllTempNotes()
    }
  }
}

const handleSelectionBoxDrag = (
  context: InteractivePianoRollContextType,
  event: MouseEvent | PointerEvent,
  memo: any,
  last: boolean,
  initialX: number,
  initialY: number,
  movementX: number,
  movementY: number,
  refs: any,
) => {
  const { containerRef, selectionBoxRef } = refs
  const {
    pianoRollNotes,
    baseGridWidth,
    ppq,
    setSelectedNoteIds,
    baseGridHeight,
    contentHeight,
    zoomLevelRef,
    midiRangeMin,
  } = context

  const { x, y } = getRelativeMousePositionInDiv(initialX + movementX, initialY + movementY, containerRef)
  const bottomBasedY = contentHeight - y
  // Selection Box Logic
  const selectionBox = {
    left: Math.min(memo.relX, x),
    bottom: Math.min(memo.relY, bottomBasedY),
    width: Math.abs(x - memo.relX),
    height: Math.abs(bottomBasedY - memo.relY),
  }
  selectionBoxRef.current.style.visibility = 'visible'
  selectionBoxRef.current.style.left = selectionBox.left + 'px'
  selectionBoxRef.current.style.bottom = selectionBox.bottom + 'px'
  selectionBoxRef.current.style.width = selectionBox.width + 'px'
  selectionBoxRef.current.style.height = selectionBox.height + 'px'

  // Update selected notes based on the selection box
  const newSelectedNoteIds = new Set<string>()
  for (const note of pianoRollNotes) {
    const noteX = ticksToWidth(note.startTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq)
    const noteY = note.midiNote * baseGridHeight - midiRangeMin * baseGridHeight
    const noteWidth = ticksToWidth(
      note.endTicks - note.startTicks,
      zoomLevelToZoomFactor(zoomLevelRef.current),
      baseGridWidth,
      ppq,
    )
    const noteHeight = baseGridHeight

    // Check if the note overlaps with the selection box
    const isOverlap =
      noteX < selectionBox.left + selectionBox.width &&
      noteX + noteWidth > selectionBox.left &&
      noteY < selectionBox.bottom + selectionBox.height &&
      noteY + noteHeight > selectionBox.bottom

    if (isOverlap) {
      newSelectedNoteIds.add(note.id)
    }
  }

  setSelectedNoteIds(newSelectedNoteIds)

  if (last) {
    selectionBoxRef.current.style.left = '0px'
    selectionBoxRef.current.style.bottom = '0px'
    selectionBoxRef.current.style.width = '0px'
    selectionBoxRef.current.style.height = '0px'
    selectionBoxRef.current.style.visibility = 'hidden'
  }
}

const handleBrushDrag = (
  context: InteractivePianoRollContextType,
  event: MouseEvent | PointerEvent,
  memo: any,
  last: boolean,
  initialX: number,
  initialY: number,
  movementX: number,
  movementY: number,
  refs: any,
) => {
  const { containerRef } = refs
  const { addNote, removeNote, noteLengthTicks, noteOpacity, noteColor, noteVelocity } = context

  const { x, y } = getRelativeMousePositionInDiv(initialX + movementX, initialY + movementY, containerRef)
  const { note } = detectPianoRollNoteAndDrag(context, x, y, false) || {}
  // here we use note length ticks so logic is similar to brush tool of Logic Pro X
  const coordinates = positionToNoteCoordinates(context, x, y, noteLengthTicks)

  if (memo.brushMode === BrushMode.BRUSH_ADD) {
    if (note) return
    // State can update slower than dragging happening, FIX THIS.
    if (
      memo.lastBrushPosition &&
      memo.lastBrushPosition.startTicks === coordinates.startTicks &&
      memo.lastBrushPosition.midiNote === coordinates.midiNote
    ) {
      return
    }
    if (memo.brushMode !== BrushMode.BRUSH_ADD) return

    addNote({
      startTicks: coordinates.startTicks,
      endTicks: coordinates.startTicks + noteLengthTicks,
      midiNote: coordinates.midiNote,
      opacity: noteOpacity,
      velocity: noteVelocity,
      color: noteColor,
    })
    memo.lastBrushPosition = { startTicks: coordinates.startTicks, midiNote: coordinates.midiNote }
  } else if (memo.brushMode === BrushMode.BRUSH_REMOVE) {
    if (!note) return
    if (
      !memo.lastBrushPosition ||
      memo.lastBrushPosition.startTicks !== note.startTicks ||
      memo.lastBrushPosition.midiNote !== note.midiNote
    ) {
      removeNote(note.id)
      memo.lastBrushPosition = { startTicks: coordinates.startTicks, midiNote: coordinates.midiNote }
    }
  }
}

const handlePencilDrag = (
  context: InteractivePianoRollContextType,
  event: MouseEvent | PointerEvent,
  memo: any,
  last: boolean,
  initialX: number,
  initialY: number,
  movementX: number,
  movementY: number,
  refs: any,
) => {
  const { pianoRollNotesRef, containerRef } = refs
  const {
    isMonophonic,
    baseGridWidth,
    ppq,
    pianoRollNotes,
    renderStripesDict,
    setRenderStripesDict,
    zoomLevelRef,
    updateNote,
    addNote,
    noteOpacity,
    noteColor,
    noteVelocity,
    getNoteById,
  } = context

  const deltaTicks = widthToTicks(movementX, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq)

  const { x, y } = getRelativeMousePositionInDiv(initialX + movementX, initialY + movementY, containerRef)
  // here we use note length ticks so logic is similar to brush tool of Logic Pro X
  const coordinates = positionToNoteCoordinates(context, x, y)

  if (!memo.dragNote) {
    const newNote = {
      startTicks: coordinates.startTicks,
      endTicks: coordinates.startTicks, // 0 length trick to make the stripes visible in place where we draw the new note
      midiNote: coordinates.midiNote,
      opacity: noteOpacity,
      velocity: noteVelocity,
      color: noteColor,
    }
    const id = addNote(newNote, { saveInHistory: false })
    memo.dragNote = { ...newNote, id }
  }

  // Wait for the state to update with the new note
  if (!getNoteById(memo.dragNote.id)) {
    return
  }

  const draggingNoteIds = new Set([memo.dragNote.id])

  // Adjust the note limits before updating the notes.
  const { noteList, adjustedDeltaTicks } = adjustNoteLimits(
    context,
    memo.dragType,
    memo.dragNote,
    draggingNoteIds,
    deltaTicks,
    0,
  )

  const oldNotes = pianoRollNotes.filter((note) => !draggingNoteIds.has(note.id))

  const note = noteList[0]
  const noteDiv = pianoRollNotesRef.current?.querySelector(`#${note.id}`) as HTMLElement
  if (!note || !noteDiv) return
  const newEndTicks = note.endTicks + adjustedDeltaTicks
  noteDiv.style.width =
    ticksToWidth(newEndTicks - note.startTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq) + 'px'
  const tempNote = { ...note, endTicks: newEndTicks }

  drawDragAlignersDOM(context, tempNote, false, true, refs)

  if (isMonophonic) {
    // we draw each new stripe with react so we need to update the state, when a new stripe is added, or an old stripe is removed
    let nStripesDict: Record<string, StripeSegment[]> = {}
    const overlaps = getMonoNoteOverlaps(oldNotes, [tempNote])
    if (overlaps) {
      nStripesDict = createStripeOverlapsDict(oldNotes, overlaps)
    }
    const result = hasSameNumberOfStripes(renderStripesDict, nStripesDict)
    if (!result) {
      setRenderStripesDict(nStripesDict)
    }
    drawStripeOverlapsDOM(context, oldNotes, nStripesDict, refs)
  }

  if (last) {
    clearDragAlignersDOM(refs)
    updateNote(note.id, {
      endTicks: note.endTicks + adjustedDeltaTicks,
    })
  }
}

export const handleDrag = (
  context: InteractivePianoRollContextType,
  event: MouseEvent | PointerEvent,
  memo: any,
  first: boolean,
  last: boolean,
  initialX: number,
  initialY: number,
  movementX: number,
  movementY: number,
  refs: any,
) => {
  // First frame of dragging. Detect the dragging mode and the note being dragged.
  if (first) {
    return initDrag(context, event, initialX, initialY, movementX, movementY, refs)
  }

  if (!memo) return

  switch (memo.dragType) {
    case DragType.NOTE:
    case DragType.NOTE_LENGTH:
      handleNoteDrag(context, event, memo, last, movementX, movementY, refs)
      break
    case DragType.SELECTION_BOX:
      handleSelectionBoxDrag(context, event, memo, last, initialX, initialY, movementX, movementY, refs)
      break
    case DragType.PENCIL:
      handlePencilDrag(context, event, memo, last, initialX, initialY, movementX, movementY, refs)
      break
    case DragType.BRUSH:
      handleBrushDrag(context, event, memo, last, initialX, initialY, movementX, movementY, refs)
      break
    case DragType.VELOCITY:
      break
    case DragType.SCROLL_BAR_X:
      handleDragScrollX(context, memo.initial, movementX, refs)
      break
    case DragType.SCROLL_BAR_Y:
      handleDragScrollY(context, memo.initial, movementY, refs)
      break
  }

  if (last) {
    return null
  }

  // Return the memo for the next frame in any case
  return memo
}
