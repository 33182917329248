import classNames from 'classnames'
import React from 'react'
import { Output } from 'webmidi'

import { useCurrentUser } from '../../../../../context/CurrentUserContext'
import { useInternationalization } from '../../../../../context/InternationalizationContext'
import useSizes from '../../../../../hooks/useSizes'
import { LimitTypesLabel } from '../../../../../utils/limits'
import BlurModal from '../../../../common/BlurModal/BlurModal'
import Link from '../../../../common/Link/Link'
import SimpleSelect from '../../../../common/SimpleSelect'
import { MIDI_OUTPUT } from '../constants'
import styles from './MidiOutputMenu.module.scss'
import Illustration from './illustration.svg'

type Props = {
  port: string | null
  setPort: (p: string) => void
  onClose: () => void
  outputs: Output[]
}

const MidiOutputMenu: React.FC<Props> = ({ onClose, port, outputs, setPort }) => {
  const { text } = useInternationalization()
  const { isTablet } = useSizes()
  const { currentUser } = useCurrentUser()

  const STRINGS = text.footer.instrumentsEditor.midi

  const renderLink = () => (
    <p className={styles.description}>
      {STRINGS.learn.first}{' '}
      <Link
        href='https://help.ableton.com/hc/en-us/articles/209774225-Setting-up-a-virtual-MIDI-bus'
        target='_blank'
        rel='noreferrer noopener'
      >
        {STRINGS.learn.second}
      </Link>
    </p>
  )

  if (!outputs.length) {
    return (
      <div className={styles.container}>
        <h3 className={styles.title}>{STRINGS.emptyTitle}</h3>
        <p className={styles.description}>{STRINGS.notFound}</p>
        {renderLink()}
        <Illustration />
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{STRINGS.title}</h3>
      {!currentUser?.subscriptionPlan || currentUser?.subscriptionPlan === 'free' ? (
        <>
          <div className={styles.shadow} />
          <BlurModal
            title={LimitTypesLabel.midiOutput}
            upgradeText={STRINGS.toUseMidiOutput}
            upgradeOnly
            loginText=''
            onClick={() => onClose()}
          />
        </>
      ) : null}
      <SimpleSelect
        label={STRINGS.midiPort}
        className={classNames(styles.select)}
        value={port || ''}
        custom={!isTablet}
        options={[
          { label: 'None', value: '' },
          ...outputs.map((o) => ({
            label: o.name,
            value: `${MIDI_OUTPUT}:${o.name}`,
          })),
        ]}
        onChange={(value) => setPort(value)}
      />
      {renderLink()}
    </div>
  )
}

export default MidiOutputMenu
