import { MidiJSON } from '@tonejs/midi'
import axios from 'axios'
import * as Tone from 'tone'

import {
  convertNoteToToneJS,
  denormalizeDrumsVolume,
  getPartDuration,
  getPartTracksStatus,
  normalizeDrumsVolume,
  shiftOctave,
  sumTwoBarsBeatsSixteenthsNoTone,
  tileLengthToNoteDuration,
} from '../../../../utils/audio/audioUtils'
import { drumsToMidiWithTempo, getBarsFromTicks } from '../../../../utils/audio/midiUtils'
import { S3_URL } from '../../../../utils/constants'
import { getInstrumentKey, getPartIdFromInstrumentKey } from '../../../../utils/instrumentsUtils'
import { getActivePart, getPartsForEachLoopArr } from '../../../../utils/progUtils'
import { InstrumentLayer, PartPattern, Prog } from '../../../../utils/types'
import MidiOutputPlayer from './MidiOutputPlayer'
import { Player } from './PlayerCommon'

export type Bit = {
  track: string
  midi: Tone.FrequencyClass<number>
  duration: string
  velocity: number
  time: string
  partId?: number
  instrumentKeys: string[]
}

class DrumSoundConfig {
  layers: { [key: string]: Array<DrumSound> } = {}

  init(key: string, sounds: Array<DrumSound>) {
    this.layers[key] = sounds
  }

  remove(key: string) {
    this.layers[key].forEach((s) => s.disconnect())
    delete this.layers[key]
  }

  triggerAttackRelease(
    instrumentKeys: string[],
    name: string,
    note: string,
    duration: any,
    time: number | undefined,
    velocity: number,
    isOffline = false,
  ) {
    const playingLayers = Object.entries(this.layers).filter(([key, sounds]) => instrumentKeys.includes(key))
    const playingSounds = playingLayers
      .map(([key, sounds]) => sounds)
      .flat()
      .filter((s) => s.name === name)

    playingSounds.forEach((sound) => {
      const sampler = sound[isOffline ? 'offlineSampler' : 'sampler']
      sampler?.triggerAttackRelease(note, duration, time, velocity)
    })
  }

  apply(func: (s: DrumSound, key: string) => void) {
    Object.entries(this.layers).forEach(([key, sounds]) => sounds.forEach((sound) => func(sound, key)))

    return []
  }

  getSoundNames() {
    return Object.values(this.layers)[0]?.map((opt) => opt.name) || []
  }
}

export class DrumSound {
  constructor(name: string, path: string, volume: number, note: string) {
    this.name = name
    this.path = path
    this.volume = volume
    this.note = note
  }

  name = ''
  path = ''
  volume: number | null = null
  note = ''
  synthBuffer: Tone.ToneAudioBuffer | null = null
  sampler: Tone.Sampler | MidiOutputPlayer | null = null
  offlineSampler: Tone.Sampler | MidiOutputPlayer | null = null

  setupSynth = () => {
    return new Promise<void>(async (resolve) => {
      this.sampler?.releaseAll()
      this.synthBuffer?.dispose()
      this.synthBuffer = new Tone.Buffer(process.env.NEXT_PUBLIC_S3_URL + this.path, async () => {
        this.initSynthSampler(this.synthBuffer)
        resolve()
      })
    })
  }

  initSynthSampler = (buffer: any) => {
    this.sampler?.dispose()
    this.sampler = new Tone.Sampler({ [this.note]: buffer })
  }

  disconnect() {
    this.sampler?.disconnect()
  }

  connect(volume: Tone.Volume) {
    this.sampler?.connect(new Tone.Volume(normalizeDrumsVolume(this.volume as number)).connect(volume))
  }

  initOfflineSampler = () => {
    this.offlineSampler = new Tone.Sampler({
      [this.note]: this.synthBuffer as any,
    })
  }

  disposeOfflineSampler = () => {
    this.offlineSampler?.dispose()
  }
}

class DrumsPlayer extends Player {
  drumSoundConfig = new DrumSoundConfig()

  getMidiOutput: (key: string, port: string) => MidiOutputPlayer

  constructor(getMidiOutput: (key: string, port: string) => any, equalizerVolume: number) {
    super(normalizeDrumsVolume, denormalizeDrumsVolume, equalizerVolume)

    this.getMidiOutput = getMidiOutput
  }

  // SERVE FUNCTIONS

  getNotesFromMidi = (prog: Prog, loopedPart?: number) => {
    const parts = getPartsForEachLoopArr(prog, loopedPart)
    const allNotes: Array<any> = []
    let totalDuration = '0:0:0'

    for (const part of parts) {
      if (!part) continue

      const { isChordsEmpty, isMelodyEmpty, isDrumsEmpty } = getPartTracksStatus(part)

      const partId = isChordsEmpty && isMelodyEmpty && !isDrumsEmpty ? part.id : undefined

      const { duration, drumsDuration } = getPartDuration(part)
      const { allNotes: notes, totalDuration: dur } = this.convertPattern(
        { midi: drumsToMidiWithTempo(part.drums, true) },
        drumsDuration,
        totalDuration,
        duration,
      )
      const instrumentKeys = part.drumLayers.map((layer, index) => getInstrumentKey(layer.instrument, index, part.id))

      allNotes.push({ time: totalDuration, partId }) // timing to change part id
      allNotes.push(...notes.map((n) => ({ ...n, partId, instrumentKeys })))

      totalDuration = sumTwoBarsBeatsSixteenthsNoTone(totalDuration, dur)
    }
    return { allNotes, duration: totalDuration }
  }
  convertPattern = (
    pattern: { midi: MidiJSON } | PartPattern,
    drumsDuration: string,
    offset = '0:0:0',
    partDuration = '',
  ) => {
    const allNotes: Array<Bit> = []

    let totalDuration = '0:0:0'

    for (const track of pattern?.midi?.tracks || []) {
      if (!track.notes.length) continue

      track.notes.forEach((n) => {
        const noteTime = tileLengthToNoteDuration(getBarsFromTicks(n.ticks))
        const noteDuration = n.durationTicks ? tileLengthToNoteDuration(getBarsFromTicks(n.durationTicks)) : '0:0:0'

        const noteTimeWithOffset = sumTwoBarsBeatsSixteenthsNoTone(offset, noteTime)
        const drumsDurationWithOffset = sumTwoBarsBeatsSixteenthsNoTone(offset, drumsDuration)

        if (Tone.Time(noteTimeWithOffset).toSeconds() >= Tone.Time(drumsDurationWithOffset).toSeconds()) return

        allNotes.push({
          time: noteTimeWithOffset,
          midi: convertNoteToToneJS(n.midi),
          duration: noteDuration,
          velocity: n.velocity,
          track: track.name,
          instrumentKeys: [],
        })
      }, [])
    }

    totalDuration = sumTwoBarsBeatsSixteenthsNoTone(totalDuration, partDuration || drumsDuration)
    return { totalDuration, allNotes }
  }

  // MAIN FUNCTIONS

  // SETUP INSTRUMENTS
  init = (prog: Prog, volume: Tone.Volume) => {
    this.progVolume = volume

    prog.parts.forEach((part) => {
      if (this.progVolume) {
        this.partsVolume[part.id] = this.initVolume(part.drumsMuted ? 0 : part.drumsVolume, this.progVolume)
      }
    })
  }
  setupSynth = async (prog: Prog | null, drumLayers: InstrumentLayer[]) => {
    const shouldResetup = await this.createSynth(prog, drumLayers)

    return shouldResetup
  }
  createSynth = async (prog: Prog | null, drumLayers: InstrumentLayer[]) => {
    const { dataToRemove, dataToSetup } = this.defineLayerToRemoveToSetup(this.drumSoundConfig.layers, {
      arr: drumLayers,
    })
    const layersToSetup = drumLayers.filter((layer) => dataToSetup.includes(layer.instrument.key))

    dataToRemove.forEach((key) => this.drumSoundConfig.remove(key))

    for (const layer of layersToSetup) {
      const { key, path } = layer.instrument

      const config = await axios.get(S3_URL + path)
      const drumSounds = config.data.samples.map(
        (s: any) => new DrumSound(s.type, s.url, s.volume, shiftOctave(s.note, layer.octave || 0)),
      )

      this.drumSoundConfig.init(key, drumSounds)
    }

    // START: setup parts volume
    const newPartIds = Array.from(
      new Set(Object.keys(this.drumSoundConfig.layers).map((key) => getPartIdFromInstrumentKey(key))),
    )
    const oldPartIds = Object.keys(this.partsVolume).map((id) => +id)

    oldPartIds.filter((id) => id && !newPartIds.includes(id)).forEach((id) => delete this.partsVolume[id])
    newPartIds
      .filter((id) => id && !oldPartIds.includes(id))
      .forEach((id) => {
        const part = getActivePart(prog, id)

        if (this.progVolume && part) {
          this.partsVolume[id] = this.initVolume(part.drumsMuted ? 0 : part.drumsVolume, this.progVolume)
        }
      })
    // END

    this.setupDrumLayersVolume(drumLayers)

    const newLayersPaths = layersToSetup.map((layer) => layer.instrument.key)

    await Promise.all(
      this.drumSoundConfig.apply(async (s, key) => {
        if (!newLayersPaths.includes(key) || !this.layersVolume[key]) return
        await s.setupSynth()
        s.disconnect()
        s.connect(this.layersVolume[key] as Tone.Volume)
      }),
    )

    return !!layersToSetup.length
  }

  // SETUP PLAYBACK
  setupProgPlayback = (prog: Prog, loopedPart?: number, mutedTracks?: string[]) => {
    this.dispose()
    const { allNotes, duration } = this.getNotesFromMidi(prog, loopedPart)

    const filteredNotes = allNotes.filter((n) => !mutedTracks?.includes(n.track))
    this.part = new Tone.Part((time, obj: Bit) => {
      if (obj.partId) this.handleChange(obj.partId)
      if (!obj.track) return
      this.drumSoundConfig.triggerAttackRelease(
        obj.instrumentKeys,
        obj.track,
        obj.midi.toNote(),
        obj.duration,
        time,
        obj.velocity,
      )
    }, filteredNotes)
    this.part.loopStart = '0:0:0'
    this.part.loopEnd = duration
  }
  prepareExportPlay = (prog: Prog, layerKey?: string) => {
    const volumeRefs: Tone.Volume[] = []

    return {
      onPlay: () => {
        const { allNotes, duration } = this.getNotesFromMidi(prog)
        if (!duration) return

        this.drumSoundConfig.apply((s) => s.initOfflineSampler())
        const drumPart = new Tone.Part((time, obj: Bit) => {
          if (!obj.track) return

          this.drumSoundConfig.triggerAttackRelease(
            layerKey ? [layerKey] : obj.instrumentKeys,
            obj.track,
            obj.midi.toNote(),
            obj.duration,
            time,
            obj.velocity,
            true,
          )
        }, allNotes)
        drumPart.loop = true
        drumPart.loopEnd = duration
        drumPart.loopStart = '0:0:0'
        drumPart.start()

        this.drumSoundConfig.apply((s, key) => {
          const synthKey = layerKey || key

          const { volume, refs } = this.initExportVolume(synthKey)
          volumeRefs.push(...refs)

          if (volume) s.offlineSampler?.connect(volume)
        })
      },
      onFinish: () => {
        this.drumSoundConfig.apply((s) => s.disposeOfflineSampler())

        volumeRefs.forEach((volumeRef) => {
          volumeRef.disconnect()
          volumeRef.dispose()
        })
      },
    }
  }
  updatePart = (prog: Prog, loopedPart?: number) => {
    const { allNotes } = this.getNotesFromMidi(prog, loopedPart)
    this.part?.clear()
    allNotes?.forEach((n) => this.part?.add(n))
  }

  // PLAYING
  playBit(instrumentKeys: string[], track: string, velocity = 0.8) {
    this.drumSoundConfig.triggerAttackRelease(
      instrumentKeys,
      track,
      convertNoteToToneJS(60).toNote(),
      '0:2:0',
      undefined,
      velocity,
    )
  }
}

export default DrumsPlayer
