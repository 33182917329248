export enum PianoRollMode {
  PIANO_ROLL = 'piano-roll',
  SAMPLER = 'sampler',
}

export type PianoRollNote = {
  id: string
  midiNote: number
  startTicks: number
  endTicks: number
  velocity: number
  color: string
  opacity: number
}

export type RulerLabel = {
  text: string
  startTicks: number
  endTicks: number
}

export type SimplePianoRollNote = Omit<PianoRollNote, 'id' | 'opacity' | 'velocity' | 'color'> &
  Partial<Pick<PianoRollNote, 'opacity' | 'velocity' | 'color'>>

export enum PianoRollNoteType {
  NORMAL = 'normal',
  GHOST = 'ghost',
  SMART_SCALE = 'smart-scale',
  AUTOCOMPLETE = 'autocomplete',
  TEMP = 'temp',
}

export type StripeSegment = {
  id: string
  startTicks: number
  endTicks: number
}

export enum CursorMode {
  SELECTION = 'selection',
  PENCIL = 'pencil',
  BRUSH = 'brush',
  VELOCITY = 'velocity',
}

export enum DragType {
  SELECTION_BOX = 'selection-box',
  NOTE = 'note',
  NOTE_LENGTH = 'note-length',
  PENCIL = 'pencil',
  BRUSH = 'brush',
  VELOCITY = 'velocity',
  SCROLL_BAR_X = 'scroll-bar-x',
  SCROLL_BAR_Y = 'scroll-bar-y',
}

export enum BrushMode {
  BRUSH_ADD = 'brush-add',
  BRUSH_REMOVE = 'brush-remove',
}

export enum SnapType {
  FLOOR = 'floor',
  ROUND = 'round',
}

export type NoteUpdateParams = {
  saveInHistory?: boolean
  isSilent?: boolean
}

export type InteractivePianoRollProviderProps = {
  id: string
  children: React.ReactNode
  pianoRollMode?: PianoRollMode
  isMonophonic?: boolean

  initialLeftSideKeysVisible?: boolean
  // Active zone
  initialInactiveZoneStartTicks?: number

  // Dimensions
  width?: number | string
  height?: number | string
  leftSideKeysWidth?: number
  leftSideKeysGap?: number

  ppq?: number
  ppqSnapStages?: { maxZoom: number; snap: number }[]
  baseGridWidth?: number
  baseGridHeight?: number

  headerHeight?: number
  headerNumbersHeight?: number
  headerRulerHeight?: number

  initialMidiRangeMin?: number
  initialMidiRangeMax?: number

  pianoRollWidth?: number

  initialScale?: number
  initialScrollY?: number
  scaleBounds?: { min: number; max: number }
  showNoteNames?: boolean
  noteColor?: string
  noteOpacity?: number
  noteVelocity?: number
  tempNoteOpacity?: number

  // Zoom controls
  showZoomControls?: boolean
  zoomControlsFactor?: number

  colorAutocompleteHoveredStroke?: string
  colorAutocompleteHoveredBackground?: string
  colorHeldKeyStroke?: string
  colorHeldKeyBackground?: string

  // STRINGS
  TAB_TO_ADD_STRING?: string
  ADD_STRING?: string
}

export type InteractivePianoRollContextType = {
  id: string
  // Modes and overall state
  pianoRollMode: PianoRollMode
  isMonophonic: boolean

  cursorMode: CursorMode
  setCursorMode: (mode: CursorMode) => void

  // Note management
  pianoRollNotes: PianoRollNote[]

  addNote: (note: SimplePianoRollNote, params?: NoteUpdateParams) => string
  addNotes: (notes: SimplePianoRollNote[], params?: NoteUpdateParams) => string[]
  removeNote: (noteId: string, params?: NoteUpdateParams) => void
  removeNotes: (noteIds: string[], params?: NoteUpdateParams) => void
  removeAllNotes: (params?: NoteUpdateParams) => void
  updateNote: (noteId: string, updatedFields: Partial<PianoRollNote>, params?: NoteUpdateParams) => void
  updateNotes: (notes: { noteId: string; updatedFields: Partial<PianoRollNote> }[], params?: NoteUpdateParams) => void
  batchUpdateNotes: (noteIds: string[], updatedFields: Partial<PianoRollNote>, params?: NoteUpdateParams) => void
  getNotesByIds: (noteIds: string[]) => PianoRollNote[]
  getNoteById: (noteId: string) => PianoRollNote | undefined

  // Stripe management
  renderStripesDict: Record<string, StripeSegment[]>
  setRenderStripesDict: (renderStripesDict: Record<string, StripeSegment[]>) => void

  // Selection management
  selectedNoteIds: Set<string>
  selectNote: (noteId: string) => void
  selectNotes: (noteIds: string[]) => void
  unselectNote: (noteId: string) => void
  unselectNotes: (noteIds: string[]) => void
  clearSelectedNoteIds: () => void
  setSelectedNoteIds: (noteIds: Set<string>) => void
  getSelectedNotes: () => PianoRollNote[]
  isNoteSelected: (noteId: string) => boolean

  // Ghost notes
  ghostNotes: PianoRollNote[]
  addGhostNotes: (notes: SimplePianoRollNote[]) => string[]
  removeAllGhostNotes: () => void

  // Smart scale notes
  isSmartScaleEnabled: boolean
  setSmartScaleEnabled: (isSmartScaleEnabled: boolean | ((v: boolean) => boolean)) => void
  smartScaleNotes: PianoRollNote[]
  setSmartScaleNotes: (notes: PianoRollNote[] | ((prevNotes: PianoRollNote[]) => PianoRollNote[])) => void
  addSmartScaleNotes: (notes: SimplePianoRollNote[]) => string[]
  removeAllSmartScaleNotes: () => void

  // Autocomplete notes
  autocompleteNotes: PianoRollNote[]
  setAutocompleteNotes: (notes: PianoRollNote[]) => void
  addAutocompleteNotes: (notes: SimplePianoRollNote[]) => string[]
  removeAllAutocompleteNotes: () => void
  isAutocompleteNotesHovered: boolean
  setAutocompleteNotesHovered: (isHovered: boolean) => void
  acceptAutocompleteNotes: () => void

  // Context menu
  isContextMenuOpen: boolean
  setIsContextMenuOpen: (isOpen: boolean) => void
  contextMenuPosition: { top: number; left: number }
  setContextMenuPosition: (position: { top: number; left: number }) => void

  // Temp notes
  tempNotes: PianoRollNote[]
  addTempNotes: (notes: SimplePianoRollNote[]) => string[]
  getTempNotesByIds: (noteIds: string[]) => PianoRollNote[]
  removeAllTempNotes: () => void

  // Note movement from one type to another
  // changeNotesType: (noteIds: string[], from: PianoRollNoteType, to: PianoRollNoteType) => void

  // Note length
  noteLengthTicks: number
  setNoteLengthTicks: (ticks: number) => void

  // Configuration properties
  width: number | string
  height: number | string

  contentHeight: number
  headerHeight: number
  headerNumbersHeight: number
  headerRulerHeight: number
  baseGridWidth: number
  baseGridHeight: number

  // Ruler label
  rulerLabels: RulerLabel[]
  setRulerLabels: (labels: RulerLabel[]) => void
  isRulerLabelsVisible: boolean
  setIsRulerLabelsVisible: (isVisible: boolean) => void

  // Inactive zone
  inactiveZoneStartTicks: number
  setInactiveZoneStartTicks: (ticks: number) => void

  ppq: number
  ppqSnapStages: { maxZoom: number; snap: number }[]
  ppqSnapRef: React.MutableRefObject<number>
  zoomLevelRef: React.MutableRefObject<number>
  mousePositionRef: React.MutableRefObject<{ x: number; y: number }>

  // Midi range
  midiRangeMin: number
  midiRangeMax: number
  setMidiRangeMin: (midiRangeMin: number | ((v: number) => number)) => void
  setMidiRangeMax: (midiRangeMax: number | ((v: number) => number)) => void

  pianoRollWidth: number
  initialScale: number
  initialScrollY: number
  scaleBounds: { min: number; max: number }
  showNoteNames: boolean
  noteColor: string
  noteOpacity: number
  noteVelocity: number
  ghostNoteOpacity: number

  showZoomControls: boolean
  zoomControlsFactor: number

  // Callbacks
  onNoteSoundCallbackRef: React.MutableRefObject<((note: PianoRollNote) => void) | null>
  onNoteSoundEvent: (callback: (note: PianoRollNote) => void) => void

  onPositionUpdateCallbackRef: React.MutableRefObject<((ticks: number, fromRuler: boolean) => void) | null>
  onPositionUpdateEvent: (callback: (ticks: number, fromRuler: boolean) => void) => void

  onNotesUpdateCallbackRef: React.MutableRefObject<((notes: PianoRollNote[]) => void) | null>
  onNotesUpdateEvent: (callback: (notes: PianoRollNote[]) => void) => void

  onNotesRemoveCallbackRef: React.MutableRefObject<
    ((removedNoteIds: string[], notesLeft: PianoRollNote[]) => void) | null
  >
  onNotesRemoveEvent: (callback: (removedNoteIds: string[], notesLeft: PianoRollNote[]) => void) => void

  // Left side keys
  withLeftSideKeys: boolean
  leftSideKeysWidth: number
  leftSideKeysGap: number
  leftSideHeldKeys: Set<number>
  setLeftSideHeldKeys: (keys: Set<number>) => void

  samplerModeIcons: Record<string, React.ReactNode>
  setSamplerModeIcons: (icons: Record<string, React.ReactNode>) => void

  // History management
  undo: () => void
  redo: () => void

  // Clipboard management
  copySelectedNotes: () => void
  cutSelectedNotes: () => void
  pasteNotes: () => void

  // COLORS
  colorAutocompleteHoveredStroke: string
  colorAutocompleteHoveredBackground: string
  colorHeldKeyStroke: string
  colorHeldKeyBackground: string

  // STRINGS
  TAB_TO_ADD_STRING: string
  ADD_STRING: string
}

export type PianoRollPlayheadProviderProps = {
  id: string
  children: React.ReactNode
}

export type PianoRollPlayheadContextType = {
  id: string
  playheadPositionTicks: number
  setPlayheadPositionTicks: (ticks: number) => void
}

export type HistoryState = {
  pianoRollNotes: PianoRollNote[]
  selectedNoteIds: Set<string>
}

export type InteractivePianoRollProps = {
  id: string
}
