import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import PlusIcon from '../../../../assets/icons/plus.svg'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import useSizes from '../../../../hooks/useSizes'
import EditorTabMuteDeleteControll from './EditorTabMuteDeleteControll'
import EditorTabVolumeControl from './EditorTabVolumeControl'
import styles from './EditorTabs.module.scss'

export type TTab = {
  text: string
  icon?: ReactNode
  color?: string
  volume?: number
  isMuted?: boolean
}
type Props = {
  tabs: TTab[]
  maxNumberOfTabs?: number

  tabContent?: (tab: TTab) => ReactNode
  addTabContent?: () => ReactNode

  activeTabIndex: number
  setActiveTabIndex: (v: number) => void

  isDeleteTabEnabled?: boolean
  onDeleteTabCallback?: (tab: TTab, tabIndex: number) => void

  isMuteTabEnabled?: boolean
  onMuteTabCallback?: (tab: TTab, tabIndex: number) => void

  isVolumeTabEnabled?: boolean
  onVolumeTabCallback?: (newVolume: number, tab: TTab, tabIndex: number) => void
  onAfterVolumeTabCallback?: (newVolume: number, tab: TTab, tabIndex: number) => void

  isAddTabEnabled?: boolean
  onAddTabCallback?: () => void
}

const EditorTabs: FC<Props> = ({
  tabs,
  maxNumberOfTabs = 4,

  tabContent = () => null,
  addTabContent = () => null,

  activeTabIndex,
  setActiveTabIndex,

  isDeleteTabEnabled = false,
  onDeleteTabCallback = () => {},

  isMuteTabEnabled = false,
  onMuteTabCallback = () => {},

  isVolumeTabEnabled = false,
  onVolumeTabCallback = () => {},
  onAfterVolumeTabCallback = () => {},

  isAddTabEnabled = false,
  onAddTabCallback = () => {},
}) => {
  const { text } = useInternationalization()
  const { isMobile } = useSizes()

  const STRINGS = text.footer.instrumentsEditor

  const renderVolumeControll = (tab: TTab, index: number) => {
    return (
      <EditorTabVolumeControl
        tab={tab}
        index={index}
        isVolumeTabEnabled={isVolumeTabEnabled}
        onVolumeTabCallback={onVolumeTabCallback}
        onAfterVolumeTabCallback={onAfterVolumeTabCallback}
      />
    )
  }
  const renderMuteDeleteContainer = (tab: TTab, index: number) => {
    return (
      <EditorTabMuteDeleteControll
        tabs={tabs}
        index={index}
        activeTabIndex={activeTabIndex}
        isMuteTabEnabled={isMuteTabEnabled}
        onMuteTabCallback={onMuteTabCallback}
        isDeleteTabEnabled={isDeleteTabEnabled}
        onDeleteTabCallback={onDeleteTabCallback}
      />
    )
  }

  const renderTab = (tab: TTab, index: number) => {
    const isActive = index === activeTabIndex
    const { text: tabText, color: tabColor = '', icon: tabIcon = null, isMuted } = tab

    const handleClickInstrument = () => {
      if (isActive) return
      setActiveTabIndex(index)
    }

    return (
      <div
        key={`layer_${index}`}
        className={classNames(
          styles.instrumentTab,
          { [styles.active]: isActive },
          { [styles.muted]: isMuted },
          styles[tabColor],
        )}
        onClick={handleClickInstrument}
      >
        {isActive ? <div className={classNames(styles.roundedCorner, styles.left)} /> : null}

        {tabIcon}

        {isMobile ? null : (
          <div className={styles.content}>
            <div className={styles.main}>
              <div className={styles.name}>{tabText}</div>

              {renderMuteDeleteContainer(tab, index)}
            </div>

            {renderVolumeControll(tab, index)}
          </div>
        )}

        {isActive ? <div className={classNames(styles.roundedCorner, styles.right)} /> : null}

        {tabContent(tab)}
      </div>
    )
  }
  const renderTabAdd = () => {
    if (!isAddTabEnabled || tabs.length >= maxNumberOfTabs) return null

    return (
      <div className={classNames(styles.instrumentTab, styles.skeleton)} onClick={onAddTabCallback}>
        <PlusIcon /> {isMobile ? null : <div className={styles.text}>{STRINGS.add}</div>}
        {addTabContent()}
      </div>
    )
  }

  return (
    <div className={styles.instrumentsMenu}>
      {tabs.map((tab, index) => renderTab(tab, index))}

      {renderTabAdd()}
    </div>
  )
}

export default EditorTabs
