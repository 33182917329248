import { convertNoteToToneJS, normalizeVelocity, tileLengthToNoteDuration } from './audio/audioUtils'
import { getBarsFromTicks } from './audio/midiUtils'
import { Event, ExtendedPlaystyle } from './playstylesTypes'
import { Chord, InstrumentLayer } from './types'

export const getSourceMidi = (
  chord: Chord,
  source: number,
  transpose: number,
  fromBass = false,
  layer?: InstrumentLayer | null,
): number => {
  const bassMidi = chord.midi[0]
  const chordMidi = fromBass ? chord.midi : chord.midi.slice(1)
  if (source < 0) {
    return bassMidi + transpose
  } else {
    const chordMidiSpan = chordMidi[chordMidi.length - 1] - chordMidi[0]
    const chordMidiOctaveSpan = Math.ceil(chordMidiSpan / 12)
    const octaveExtension = Math.floor(source / chordMidi.length)
    const inChordIndex = source % chordMidi.length
    return (
      chordMidi[inChordIndex] +
      12 * octaveExtension * chordMidiOctaveSpan +
      transpose +
      (chord.octave || 0) * 12 +
      (layer?.octave || 0) * 12
    )
  }
}

export const adjustVelocity = (velocity: number, globalVelocity: number): number => {
  const velocityScaling = globalVelocity / 127
  return Math.floor(velocity * velocityScaling)
}

export const adjustVelocities = (velocities: number[], globalVelocity: number): number[] => {
  return velocities.map((v) => adjustVelocity(v, globalVelocity))
}

function gaussianRandom(mean = 0, stdev = 1) {
  const u = 1 - Math.random()
  const v = Math.random()
  const z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)
  return z * stdev + mean
}

function mapRange(value: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

function trimToRange(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(value, max))
}

const adjustEvents = (events: Event[], totalDuration: number) => {
  events.sort((a, b) => a.time - b.time)
  events.forEach((event) => {
    event.time = Math.round(event.time)
    event.duration = Math.round(event.duration)
  })
  // Get unique midi
  const uniqueNotes = Array.from(new Set(events.map((event) => event.note)))

  // Adjust durations
  const eventsAdjusted: Event[] = []
  uniqueNotes.forEach((note) => {
    const noteEvents = events.filter((event) => event.note === note)
    noteEvents.forEach((event, i) => {
      const adjustedEvent = { ...event }
      if (adjustedEvent.velocity === 0) {
        return
      }
      if (adjustedEvent.time + adjustedEvent.duration > totalDuration) {
        adjustedEvent.duration = totalDuration - adjustedEvent.time
      }
      if (i === noteEvents.length - 1) {
        eventsAdjusted.push(adjustedEvent)
        return
      }
      const nextEvent = noteEvents[i + 1]
      if (adjustedEvent.time + adjustedEvent.duration > nextEvent.time) {
        adjustedEvent.duration = nextEvent.time - adjustedEvent.time
      }
      eventsAdjusted.push(adjustedEvent)
    })
  })

  eventsAdjusted.sort((a, b) => a.time - b.time)

  return eventsAdjusted
}

export const mapChordsToEvents = (
  chords: Chord[],
  playstyle: ExtendedPlaystyle,
  layer: InstrumentLayer | null,
  instrumentKey: string,
  returnRaw = false,
  PPQ = 24,
  strumStd = 1.5,
  strumVelDecrease = 0.35,
): Event[] => {
  const events: Event[] = []
  let totalDuration = 0
  let playstyleHead = 0

  for (const chord of chords) {
    if (chord.draft) {
      events.push({
        time: totalDuration,
        velocity: 127,
        note: [],
        duration: 0,
        id: chord.id,
        instrumentKey: instrumentKey,
      })
    }

    if (!playstyle.continuous) {
      playstyleHead = 0
    }

    const repeatsPerChord = (chord.duration || 1) / playstyle.duration
    let patternDuration = 0
    for (const pattern of playstyle.patterns) {
      let repeatsPerPattern = repeatsPerChord

      const velocities = pattern.velocities

      let patternHead = playstyleHead
      const velocitiesExt: number[] = []

      do {
        const minLen = Math.min(repeatsPerPattern, 1 - patternHead)
        velocitiesExt.push(
          ...velocities.slice(
            Math.floor(velocities.length * patternHead),
            Math.floor(velocities.length * (patternHead + minLen)),
          ),
        )
        repeatsPerPattern -= minLen
        patternHead += minLen
        patternHead %= 1
      } while (repeatsPerPattern > 0)

      const barDuration = PPQ * 4
      const barAdjDuration = barDuration * (chord.duration || 1)
      const dotDuration = Math.floor(barAdjDuration / velocitiesExt.length)

      patternDuration = 0

      for (const velocityExt of velocitiesExt) {
        if (velocityExt >= 0) {
          const getEvent = (time: number, midi: number, velocity: number): Event => {
            return {
              time: time,
              velocity: velocity,
              note: midi,
              duration: barAdjDuration, // Adjust duration here
              id: chord.id,
              instrumentKey: instrumentKey,
            }
          }
          const getStrumVelocity = (stringVelocityOffsets: number[], i: number) => {
            let velocity =
              velocityExt +
              Math.sign(stringVelocityOffsets[i]) * mapRange(Math.abs(stringVelocityOffsets[i]), 0, 9, 0, 127)
            velocity = gaussianRandom(velocity, strumStd)
            velocity = trimToRange(velocity, 0, 127)
            velocity = Math.floor(velocity)
            velocity = adjustVelocity(velocity, chord.settings.velocity)

            return velocity
          }
          if (pattern.source === 128) {
            // Special code: whole chord
            for (let i = 0; i < chord.midi.length; i++) {
              const time = totalDuration + patternDuration
              const midi = chord.midi[i] + playstyle.transpose + (chord.octave || 0) * 12 + (layer?.octave || 0) * 12
              const velocity = adjustVelocity(velocityExt, chord.settings.velocity)

              events.push(getEvent(time, midi, velocity))
            }
          } else if (pattern.source === 129) {
            // Special code: Strum Down
            const stringVelocityOffsets = [
              0,
              -strumVelDecrease,
              -2 * strumVelDecrease,
              -3 * strumVelDecrease,
              -4 * strumVelDecrease,
              -5 * strumVelDecrease,
            ]
            for (let i = 0; i < 6; i++) {
              const time = totalDuration + patternDuration + i * (barDuration / 256)
              const midi = getSourceMidi(chord, i - 1, playstyle.transpose, pattern.fromBass, layer)

              if (velocityExt === 0) {
                events.push(getEvent(time, midi, velocityExt))
              } else {
                const velocity = getStrumVelocity(stringVelocityOffsets, i)

                events.push(getEvent(time, midi, velocity))
              }
            }
          } else if (pattern.source === 130) {
            // Special code: Strum Up
            const stringVelocityOffsets = [
              0,
              -strumVelDecrease,
              -2 * strumVelDecrease,
              -3 * strumVelDecrease,
              -4 * strumVelDecrease,
              -5 * strumVelDecrease,
            ]
            for (let i = 0; i < 6; i++) {
              const time = totalDuration + patternDuration + i * (barDuration / 256)
              const midi = getSourceMidi(chord, 5 - i - 1, playstyle.transpose, pattern.fromBass, layer)

              if (velocityExt === 0) {
                events.push(getEvent(time, midi, velocityExt))
              } else {
                const velocity = getStrumVelocity(stringVelocityOffsets, i)

                events.push(getEvent(time, midi, velocity))
              }
            }
          } else {
            const time = totalDuration + patternDuration
            const midi = getSourceMidi(chord, pattern.source, playstyle.transpose, pattern.fromBass, layer)
            const velocity = adjustVelocity(velocityExt, chord.settings.velocity)

            events.push(getEvent(time, midi, velocity))
          }
        }
        patternDuration += dotDuration
      }

      patternDuration = barAdjDuration
    }
    totalDuration += patternDuration
    playstyleHead += repeatsPerChord
    playstyleHead %= 1

    // Adjust durations
    for (const event of events) {
      if (event.time + event.duration > totalDuration) {
        event.duration = totalDuration - event.time
      }
    }
  }

  const eventsAdjusted = adjustEvents(events, totalDuration)

  // Convert time, duration and midi if required
  if (returnRaw) return eventsAdjusted

  return eventsAdjusted.map((event) => {
    return {
      ...event,
      velocity: normalizeVelocity(event.velocity),
      time: tileLengthToNoteDuration(getBarsFromTicks(event.time)),
      note: convertNoteToToneJS(event.note).toNote(),
      duration: tileLengthToNoteDuration(getBarsFromTicks(event.duration)),
    }
  })
}
