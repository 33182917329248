import { useDrag, useMove, usePinch, useScroll } from '@use-gesture/react'
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef } from 'react'

import style from './InteractivePianoRoll.module.scss'
import { handleClick, handleDoubleClick, handleRightClick, handleRulerClick } from './handlers/clickHandler'
import { handleTranspose12SemitonesUp, handleTranspose12SemitonesDown } from './handlers/contextMenuHandler'
import { handleDrag } from './handlers/dragHandler'
import { handleKeyDown, handleKeyUp } from './handlers/keyboardHandler'
import { handleLeftSideKeysMouseDrag } from './handlers/leftSideKeysHandler'
import { handleMouseMove, handlePointerLeave } from './handlers/mouseMoveHandler'
import { handleScrollX, handleScrollY } from './handlers/scrollHandler'
import { handleZoom, handleZoomInButton, handleZoomOutButton } from './handlers/zoomHandler'
import { useInteractivePianoRoll } from './hooks/useInteractivePianoRoll'
import { usePianoRollPlayhead } from './hooks/usePianoRollPlayhead'
import ZoomIn from './icons/zoom-in.svg'
import ZoomOut from './icons/zoom-out.svg'
import { CursorMode, PianoRollMode, PianoRollNote, PianoRollNoteType } from './types'
import { InteractivePianoRollProps } from './types'
import { hexToRgb, noteMidiToNoteName, ticksToWidth, zoomLevelToZoomFactor } from './utils/pianoRollUtils'

const InteractivePianoRoll = ({ id }: InteractivePianoRollProps) => {
  const mainContainerRef = useRef<HTMLDivElement>(null)
  const horizontalScrollRef = useRef<HTMLDivElement>(null)

  const inactiveZoneRef = useRef<HTMLDivElement>(null)

  const svgRulerGridRef = useRef<SVGSVGElement>(null)
  const rulerGridPatternRef = useRef<SVGPatternElement>(null)
  const rulerNumbersRef = useRef<HTMLDivElement>(null)
  const contentDivRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const rulerInnerContainerRef = useRef<HTMLDivElement>(null)
  const rulerLabelsRef = useRef<HTMLDivElement>(null)

  const svgGridRef = useRef<SVGSVGElement>(null)
  const gridPatternRef = useRef<SVGPatternElement>(null)

  const pianoRollRowsRef = useRef<HTMLDivElement>(null)
  const pianoRollNotesRef = useRef<HTMLDivElement>(null)
  const selectionBoxRef = useRef<HTMLDivElement>(null)

  const scrollBarXRef = useRef<HTMLDivElement>(null)
  const scrollBarYRef = useRef<HTMLDivElement>(null)
  const leftSideKeysScrollRef = useRef<HTMLDivElement>(null)
  const leftSideKeysContentRef = useRef<HTMLDivElement>(null)

  const playheadRef = useRef<HTMLDivElement>(null)
  const dragAlignerLeftRef = useRef<HTMLDivElement>(null)
  const dragAlignerRightRef = useRef<HTMLDivElement>(null)

  const pianoRollRefs = {
    horizontalScrollRef,
    pianoRollNotesRef,
    gridPatternRef,
    pianoRollRowsRef,
    svgGridRef,
    svgRulerGridRef,
    rulerGridPatternRef,
    rulerNumbersRef,
    rulerInnerContainerRef,
    rulerLabelsRef,
    contentDivRef,
    containerRef,
    selectionBoxRef,
    scrollBarXRef,
    scrollBarYRef,
    playheadRef,
    dragAlignerLeftRef,
    dragAlignerRightRef,
    leftSideKeysScrollRef,
    leftSideKeysContentRef,
    inactiveZoneRef,
  }

  const context = useInteractivePianoRoll(id)
  const playheadContext = usePianoRollPlayhead(id)

  const {
    width,
    height,
    pianoRollMode,
    samplerModeIcons,
    withLeftSideKeys,
    leftSideKeysWidth,
    leftSideKeysGap,
    leftSideHeldKeys,
    headerHeight,
    headerNumbersHeight,
    headerRulerHeight,
    rulerLabels,
    isRulerLabelsVisible,
    pianoRollNotes,
    ghostNotes,
    showNoteNames,
    isSmartScaleEnabled,
    smartScaleNotes,
    autocompleteNotes,
    isAutocompleteNotesHovered,
    tempNotes,
    renderStripesDict,
    ppq,
    ppqSnapRef,
    ppqSnapStages,
    baseGridWidth,
    baseGridHeight,
    midiRangeMin,
    midiRangeMax,
    pianoRollWidth,
    initialScale,
    initialScrollY,
    scaleBounds,
    showZoomControls,
    zoomLevelRef,
    isNoteSelected,
    selectedNoteIds,
    cursorMode,
    inactiveZoneStartTicks,
    isContextMenuOpen,
    contextMenuPosition,
    colorAutocompleteHoveredStroke,
    colorAutocompleteHoveredBackground,
    colorHeldKeyStroke,
    colorHeldKeyBackground,
    TAB_TO_ADD_STRING,
    ADD_STRING,
  } = context

  const { playheadPositionTicks } = playheadContext

  const handleKeyDownCallback = useCallback(
    (e: KeyboardEvent) => {
      handleKeyDown(e, contentDivRef, context)
    },
    [contentDivRef, context],
  )

  const handleKeyUpCallback = useCallback(
    (e: KeyboardEvent) => {
      handleKeyUp(e, contentDivRef)
    },
    [contentDivRef],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownCallback)
    return () => window.removeEventListener('keydown', handleKeyDownCallback)
  }, [handleKeyDownCallback])

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUpCallback)
    return () => window.removeEventListener('keyup', handleKeyUpCallback)
  }, [handleKeyUpCallback])

  useEffect(() => {
    if (svgGridRef.current) {
      svgGridRef.current.style.width = `${pianoRollWidth}px`
    }
    handleZoom(context, playheadContext, initialScale, 0, pianoRollRefs)
    handleScrollY(context, initialScrollY, {
      contentDivRef,
      scrollBarYRef,
      leftSideKeysScrollRef: leftSideKeysScrollRef,
    })
  }, [])

  usePinch(
    ({ offset: [scale], origin: [originX] }) => {
      handleZoom(context, playheadContext, scale, originX, pianoRollRefs)
    },
    { target: mainContainerRef, initialScale, scaleBounds, from: () => [zoomLevelRef.current, 0] },
  )

  useDrag(
    ({ event, memo, first, last, initial: [initialX, initialY], movement: [movementX, movementY] }) => {
      if (!(event instanceof MouseEvent || event instanceof PointerEvent)) return
      return handleDrag(context, event, memo, first, last, initialX, initialY, movementX, movementY, pianoRollRefs)
    },
    { target: contentDivRef, filterTaps: true },
  )
  useDrag(
    ({ event, memo, first, last, movement: [movementX, movementY] }) => {
      if (!(event instanceof MouseEvent || event instanceof PointerEvent)) return
      return handleLeftSideKeysMouseDrag(context, event, memo, first, last, movementX, movementY, pianoRollRefs)
    },
    { target: leftSideKeysScrollRef },
  )

  useMove(
    ({ xy: [x, y] }) => {
      handleMouseMove(context, x, y, pianoRollRefs)
    },
    { target: mainContainerRef },
  )

  const onPointerLeave = () => {
    handlePointerLeave(context)
  }

  useScroll(
    ({ xy: [x] }) => {
      handleScrollX(context, x, { horizontalScrollRef, scrollBarXRef, rulerInnerContainerRef, containerRef })
    },
    { target: horizontalScrollRef },
  )

  const handleVerticalScroll = (y: number) => {
    handleScrollY(context, y, { contentDivRef, scrollBarYRef, leftSideKeysScrollRef: leftSideKeysScrollRef })
  }

  useScroll(
    ({ xy: [, y] }) => {
      handleVerticalScroll(y)
    },
    { target: contentDivRef },
  )
  useScroll(
    ({ xy: [, y] }) => {
      handleVerticalScroll(y)
    },
    { target: leftSideKeysScrollRef },
  )

  const pianoRollRowsOrder = [
    'white',
    'black',
    'white',
    'black',
    'white',
    'white',
    'black',
    'white',
    'black',
    'white',
    'black',
    'white',
  ]

  const samplerRowsOrder = ['white', 'black']

  const renderPianoRollRows = () => {
    const rows: React.ReactNode[] = []
    for (let i = midiRangeMin; i < midiRangeMax; i++) {
      const key = pianoRollMode === PianoRollMode.PIANO_ROLL ? pianoRollRowsOrder[i % 12] : samplerRowsOrder[i % 2]
      rows.push(
        <div
          style={{
            height: baseGridHeight,
          }}
          key={i}
          className={`${style.pianoRollRow} ${style[`${key}Row`]}`}
        />,
      )
    }

    return rows
  }

  const getZIndexForNoteType = (note: PianoRollNote, noteType: PianoRollNoteType) => {
    // selected notes are on top of normal notes, temp notes and ghosts are below
    if (isNoteSelected(note.id)) return 3
    if (noteType === PianoRollNoteType.NORMAL) return 2
    if (noteType === PianoRollNoteType.TEMP) return 1
    if (noteType === PianoRollNoteType.GHOST || noteType === PianoRollNoteType.SMART_SCALE) return 0
    if (noteType === PianoRollNoteType.AUTOCOMPLETE) return 4
    return 0
  }

  const getBackgroundColorForNoteType = (note: PianoRollNote, noteType: PianoRollNoteType) => {
    return ['ghost', 'ghost-dashed'].includes(note.color)
      ? 'transparent'
      : `rgba(${hexToRgb(note.color)}, ${note.opacity})`
  }

  const renderPianoRollNote = (note: PianoRollNote, noteType: PianoRollNoteType) => {
    const velocityPercentage = (note.velocity / 127) * 100
    const noteWidth = ticksToWidth(
      note.endTicks - note.startTicks,
      zoomLevelToZoomFactor(zoomLevelRef.current),
      baseGridWidth,
      ppq,
    )

    const colorStyles =
      isAutocompleteNotesHovered && noteType === PianoRollNoteType.AUTOCOMPLETE
        ? {
            background: colorAutocompleteHoveredBackground,
            border: `2px solid ${colorAutocompleteHoveredStroke}`,
          }
        : {}
    return (
      <div
        key={note.id}
        style={{
          visibility: note.opacity === 0 ? 'hidden' : 'visible',
          width: noteWidth,
          zIndex: getZIndexForNoteType(note, noteType),
          height: baseGridHeight,
          backgroundColor: getBackgroundColorForNoteType(note, noteType),
          left: ticksToWidth(note.startTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq),
          bottom: note.midiNote * baseGridHeight - midiRangeMin * baseGridHeight,
          ...colorStyles,
        }}
        id={note.id}
        className={classNames(style.pianoRollNote, {
          [style.selected]: isNoteSelected(note.id),
          [style.ghost]: ['ghost', 'ghost-dashed'].includes(note.color),
          [style.ghostDashed]: note.color === 'ghost-dashed',
          [style.autocomplete]: noteType === PianoRollNoteType.AUTOCOMPLETE,
          [style.autocompleteHovered]: isAutocompleteNotesHovered && noteType === PianoRollNoteType.AUTOCOMPLETE,
        })}
      >
        {renderStripesDict[note.id] &&
          renderStripesDict[note.id].map((stripe) => (
            <div
              key={stripe.id}
              id={stripe.id}
              className={style.noteStripe}
              style={{
                left: ticksToWidth(stripe.startTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq),
                width: ticksToWidth(
                  stripe.endTicks - stripe.startTicks,
                  zoomLevelToZoomFactor(zoomLevelRef.current),
                  baseGridWidth,
                  ppq,
                ),
              }}
            ></div>
          ))}
        {noteType !== PianoRollNoteType.AUTOCOMPLETE && (
          <>
            <div style={{ height: baseGridHeight * 0.8 }} className={style.noteHandle}></div>
            <div style={{ width: `calc(${velocityPercentage}% - 4px)` }} className={style.noteVelocity}></div>
            {pianoRollMode == PianoRollMode.PIANO_ROLL && showNoteNames && (
              <div className={style.noteName + ' noteName'}>{noteMidiToNoteName(note.midiNote)}</div>
            )}
          </>
        )}
        {noteType === PianoRollNoteType.AUTOCOMPLETE && isAutocompleteNotesHovered && (
          <div className={style.autocompleteText} style={{ color: colorAutocompleteHoveredStroke }}>
            {ADD_STRING}{' '}
            {pianoRollMode == PianoRollMode.PIANO_ROLL && showNoteNames ? noteMidiToNoteName(note.midiNote) : ''}
          </div>
        )}
      </div>
    )
  }

  const renderAllPianoRollNotes = () => {
    const notesNodes: React.ReactNode[] = []

    const checkNoteInRange = (note: PianoRollNote) => {
      // Check if note is in range of the piano roll
      if (note.midiNote >= midiRangeMin && note.midiNote < midiRangeMax) {
        return true
      }
      return false
    }

    for (const note of ghostNotes) {
      if (!checkNoteInRange(note)) continue
      notesNodes.push(renderPianoRollNote(note, PianoRollNoteType.GHOST))
    }
    for (const note of tempNotes) {
      if (!checkNoteInRange(note)) continue
      notesNodes.push(renderPianoRollNote(note, PianoRollNoteType.TEMP))
    }
    for (const note of pianoRollNotes) {
      if (!checkNoteInRange(note)) continue
      notesNodes.push(renderPianoRollNote(note, PianoRollNoteType.NORMAL))
    }
    if (isSmartScaleEnabled) {
      for (const note of smartScaleNotes) {
        if (!checkNoteInRange(note)) continue
        notesNodes.push(renderPianoRollNote(note, PianoRollNoteType.SMART_SCALE))
      }
    }
    for (const note of autocompleteNotes) {
      if (!checkNoteInRange(note)) continue
      notesNodes.push(renderPianoRollNote(note, PianoRollNoteType.AUTOCOMPLETE))
    }

    return notesNodes
  }

  const renderPlayhead = () => {
    return (
      <div
        ref={playheadRef}
        style={{
          left: ticksToWidth(playheadPositionTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq),
        }}
        className={style.playhead}
      />
    )
  }

  const renderDragAligners = () => {
    return (
      <>
        <div ref={dragAlignerLeftRef} className={style.dragAligner} />
        <div ref={dragAlignerRightRef} className={style.dragAligner} />
      </>
    )
  }

  const renderSvgGrid = () => {
    return (
      <svg ref={svgGridRef} className={style.grid}>
        <defs>
          <pattern
            id='piano-roll-grid'
            ref={gridPatternRef}
            x='0'
            y='0'
            width='120'
            height='100%'
            patternUnits='userSpaceOnUse'
          >
            <rect x='0' y='0' width='1' height='100%' opacity='0.15' fill='#ffffff' />
            <rect x='30' y='0' width='1' height='100%' opacity='0.08' fill='#ffffff' />
            <rect x='60' y='0' width='1' height='100%' opacity='0.08' fill='#ffffff' />
            <rect x='90' y='0' width='1' height='100%' opacity='0.08' fill='#ffffff' />
          </pattern>
        </defs>
        <rect width='100%' height='100%' fill='url(#piano-roll-grid)' />
      </svg>
    )
  }

  const renderSvgRulerGrid = () => {
    return (
      <svg ref={svgRulerGridRef} className={style.rulerGrid} style={{ height: headerRulerHeight }}>
        <defs>
          <pattern
            id='piano-roll-ruler-grid'
            ref={rulerGridPatternRef}
            x='0'
            y='0'
            width='30'
            height='100%'
            patternUnits='userSpaceOnUse'
          >
            <rect x='0' y='0' width='1' height='100%' fill='#434851' />
          </pattern>
        </defs>
        <rect width='100%' height='100%' fill='url(#piano-roll-ruler-grid)' />
      </svg>
    )
  }

  const renderRulerNumbers = () => {
    const numberOfBars = Math.floor(pianoRollWidth / (baseGridWidth * 4)) // Calculate the number of bars based on width
    const rulerNumbers = []

    for (let i = 1; i <= numberOfBars; i++) {
      for (let beat = 1; beat <= 4; beat++) {
        const beatLabel = beat === 1 ? i : `${i}.${beat}`
        rulerNumbers.push(
          <span
            key={`${i}-${beat}`}
            className={style.rulerNumber}
            style={{
              left: ((i - 1) * 4 + (beat - 1)) * (4 * baseGridWidth * zoomLevelToZoomFactor(zoomLevelRef.current)),
              visibility: ppqSnapRef.current === ppqSnapStages[0].snap && beat !== 1 ? 'hidden' : 'visible',
            }}
          >
            {beatLabel}
          </span>,
        )
      }
    }

    return (
      <div style={{ bottom: headerNumbersHeight }} ref={rulerNumbersRef} className={style.rulerNumbers}>
        {rulerNumbers}
      </div>
    )
  }

  const renderLeftSideSamplerKey = (i: number) => {
    const colorStyles = leftSideHeldKeys.has(i)
      ? {
          background: colorHeldKeyBackground,
          borderBottom: `1px solid ${colorHeldKeyStroke}`,
        }
      : {}

    const key = samplerRowsOrder[i % 2]
    if (samplerModeIcons[i]) {
      const icon = samplerModeIcons[i]
      return (
        <div
          style={{ height: baseGridHeight, ...colorStyles }}
          key={i}
          className={classNames(style.leftSideKey, style.samplerButton, style[`${key}Sampler`], {
            [style.held]: leftSideHeldKeys.has(i),
          })}
        >
          {icon}
        </div>
      )
    }
    return <></>
  }

  const renderLeftSidePianoKey = (i: number) => {
    const colorStyles = leftSideHeldKeys.has(i)
      ? {
          background: colorHeldKeyBackground,
          borderBottom: `1px solid ${colorHeldKeyStroke}`,
        }
      : {}
    const key = pianoRollRowsOrder[i % 12]
    return (
      <div
        style={{
          height: baseGridHeight,
          ...colorStyles,
        }}
        key={i}
        className={classNames(style.leftSideKey, style[`${key}Key`], {
          [style.held]: leftSideHeldKeys.has(i),
        })}
      >
        <div className={style.noteName}>
          {i % 12 == 0 ? noteMidiToNoteName(i, true) : leftSideHeldKeys.has(i) ? noteMidiToNoteName(i, true) : ''}
        </div>
      </div>
    )
  }

  const renderLeftSideKeys = () => {
    const keys: React.ReactNode[] = []
    for (let i = midiRangeMin; i < midiRangeMax; i++) {
      if (pianoRollMode === PianoRollMode.PIANO_ROLL) {
        keys.push(renderLeftSidePianoKey(i))
      } else {
        keys.push(renderLeftSideSamplerKey(i))
      }
    }
    return keys
  }

  const renderLeftSide = () => {
    return (
      <div
        style={{ height, top: 0, paddingTop: headerHeight, width: leftSideKeysWidth }}
        className={style.leftSideKeysContainer}
        ref={leftSideKeysScrollRef}
      >
        <div
          ref={leftSideKeysContentRef}
          style={{ paddingRight: leftSideKeysGap }}
          className={style.leftSideKeysContent}
        >
          {renderLeftSideKeys()}
        </div>
      </div>
    )
  }

  const renderTopBottomBorders = () => {
    return (
      <>
        <div style={{ top: headerHeight }} className={style.topBorder} />
        <div className={style.bottomBorder} />
      </>
    )
  }

  const getCursorStyleForDiv = () => {
    if (cursorMode === CursorMode.PENCIL) return 'url(/static/interactive-pianoroll/pencil-tool.svg) 2 0, auto'
    if (cursorMode === CursorMode.BRUSH) return 'url(/static/interactive-pianoroll/brush-tool.svg) 2 0, auto'
    return 'default'
  }

  const renderRulerZoomControls = () => {
    return (
      <div style={{ top: headerHeight - 40 }} className={style.zoomControls}>
        <div
          className={style.zoomButton}
          onClick={() => {
            handleZoomInButton(context, playheadContext, pianoRollRefs)
          }}
        >
          <ZoomIn />
        </div>
        <div
          className={style.zoomButton}
          onClick={() => {
            handleZoomOutButton(context, playheadContext, pianoRollRefs)
          }}
        >
          <ZoomOut />
        </div>
      </div>
    )
  }

  const renderRulerLabels = () => {
    return (
      <div ref={rulerLabelsRef} className={style.rulerLabels}>
        {rulerLabels.map((label, index) => (
          <div
            style={{
              left: ticksToWidth(label.startTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq),
              width: ticksToWidth(
                label.endTicks - label.startTicks,
                zoomLevelToZoomFactor(zoomLevelRef.current),
                baseGridWidth,
                ppq,
              ),
            }}
            key={index}
            className={style.rulerLabel}
          >
            {label.text}
          </div>
        ))}
      </div>
    )
  }

  const renderAutocompleteTabTip = () => {
    return (
      <div style={{ top: headerHeight }} className={style.autocompleteTabTip}>
        {TAB_TO_ADD_STRING}
      </div>
    )
  }

  const renderInactiveZone = () => {
    const left = ticksToWidth(inactiveZoneStartTicks, zoomLevelToZoomFactor(zoomLevelRef.current), baseGridWidth, ppq)
    const width = pianoRollWidth - left
    return (
      <div
        ref={inactiveZoneRef}
        className={style.inactiveZone}
        style={{
          left,
          width,
        }}
      />
    )
  }

  const renderContextMenu = () => {
    return (
      <div
        className={style.contextMenu}
        style={{
          top: contextMenuPosition.top,
          left: contextMenuPosition.left,
        }}
      >
        <div
          onClick={selectedNoteIds.size === 0 ? undefined : () => handleTranspose12SemitonesUp(context)}
          className={classNames(style.contextMenuItem, {
            [style.disabled]: selectedNoteIds.size === 0,
          })}
        >
          Transpose +12 Semitones
        </div>
        <div
          onClick={selectedNoteIds.size === 0 ? undefined : () => handleTranspose12SemitonesDown(context)}
          className={classNames(style.contextMenuItem, {
            [style.disabled]: selectedNoteIds.size === 0,
          })}
        >
          Transpose -12 Semitones
        </div>
      </div>
    )
  }

  // const paddingLeftVerticalPiano = withVerticalPiano ? verticalPianoWidth + verticalPianoGap : 0
  return (
    <div
      style={{ width, height }}
      className={style.mainContainer}
      ref={mainContainerRef}
      onPointerLeave={onPointerLeave}
    >
      <div className={style.horizontalScroll} ref={horizontalScrollRef}>
        <div className={style.rulerContainer} style={{ height: headerHeight }}>
          <div
            ref={rulerInnerContainerRef}
            className={style.rulerInnerContainer}
            style={{
              left: leftSideKeysWidth,
              height: headerHeight,
              width: `calc(100% - ${leftSideKeysWidth}px)`,
            }}
            onClick={(e) => handleRulerClick(e, { containerRef }, context, playheadContext)}
          >
            {renderSvgRulerGrid()}
            {renderRulerNumbers()}
            {isRulerLabelsVisible && renderRulerLabels()}
          </div>
        </div>
        <div
          className={style.container}
          style={{
            paddingTop: headerHeight,
            paddingLeft: leftSideKeysWidth,
            cursor: getCursorStyleForDiv(),
          }}
          ref={contentDivRef}
          onDoubleClick={(e) => handleDoubleClick(e, { containerRef }, context)}
          onClick={(e) => handleClick(e, { containerRef }, context, playheadContext)}
          onContextMenu={(e) => handleRightClick(e, { containerRef, mainContainerRef }, context)}
        >
          <div className={style.content} ref={containerRef}>
            <div className={style.pianoRollRows} ref={pianoRollRowsRef}>
              {renderPianoRollRows()}
            </div>

            {renderSvgGrid()}

            <div className={style.pianoRollNotes} ref={pianoRollNotesRef}>
              {renderAllPianoRollNotes()}
            </div>

            <div className={style.selectionBox} ref={selectionBoxRef} />

            {renderPlayhead()}
            {renderDragAligners()}
            {renderInactiveZone()}
          </div>
        </div>
      </div>

      <div className={style.scrollBarX} ref={scrollBarXRef} />
      <div className={style.scrollBarY} ref={scrollBarYRef} />

      {showZoomControls && renderRulerZoomControls()}

      {autocompleteNotes.length > 0 && renderAutocompleteTabTip()}

      {renderTopBottomBorders()}

      {withLeftSideKeys && renderLeftSide()}

      {isContextMenuOpen && pianoRollMode === PianoRollMode.PIANO_ROLL && renderContextMenu()}
    </div>
  )
}

export default InteractivePianoRoll
