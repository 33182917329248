import React, { useState } from 'react'

import { updateUserAfterRegAnswersInnerRoute } from '../../../../api/user-prefs'
import WaveIcon from '../../../../assets/icons/signup/wave.svg'
import { useCurrentUser } from '../../../../context/CurrentUserContext'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import Routes from '../../../../utils/routes'
import Checkbox from '../../../common/Checkbox/Checkbox'
import IconMenuButton from '../../../common/IconMenuButton'
import Link from '../../../common/Link/Link'
import styles from './GetStartedScreen.module.scss'

type Props = {
  handleNext: () => void
}

const GetStartedScreen: React.FC<Props> = ({ handleNext }) => {
  const { currentUser, updateUserName } = useCurrentUser()
  const { getText } = useInternationalization()

  const [name, setName] = useState(currentUser?.name || '')
  const [agreeTerms, setAgreeTerms] = useState(true)
  const [emailsSubscribed, setEmailsSubscribed] = useState(true)
  const [loading, setLoading] = useState(false)

  const STRINGS = getText('WelcomeModal').GetStartedScreen || {}

  const handleUpdateName = async () => {
    if (!name) return

    await Promise.all([updateUserName(name), updateUserAfterRegAnswersInnerRoute(emailsSubscribed)])
    handleNext()
  }

  return (
    <div className={styles.container}>
      <WaveIcon className={styles.wave} />

      <div className={styles.subtitle}>{STRINGS.subtitle}</div>
      <div className={styles.title}>
        {STRINGS.title} <span>ChordChord</span>!
      </div>

      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>{STRINGS.inputLabel}</div>

        <input
          name='name'
          id='user-name-input'
          placeholder={STRINGS.inputPlaceholder}
          value={name}
          onChange={(e) => setName((e.target.value || '').slice(0, 40))}
          className={styles.inputInput}
        />
      </div>

      <div className={styles.checkboxesContainer}>
        <Checkbox
          checked={agreeTerms}
          secondary
          label={
            <span>
              {STRINGS.politicsAgreement_1}{' '}
              <Link
                href={Routes.TERMS_OF_SERVICE}
                onClick={(e: any) => e.stopPropagation()}
                target='_blank'
                rel='noopener noreferrer'
              >
                {STRINGS.politicsAgreement_2}
              </Link>{' '}
              {STRINGS.politicsAgreement_3}{' '}
              <Link
                href={Routes.PRIVACY_POLICY}
                onClick={(e: any) => e.stopPropagation()}
                target='_blank'
                rel='noopener noreferrer'
              >
                {STRINGS.politicsAgreement_4}
              </Link>
            </span>
          }
          className={styles.checkbox}
          onChange={() => setAgreeTerms(!agreeTerms)}
        />
        <Checkbox
          checked={emailsSubscribed}
          label={STRINGS.offersAgreement}
          secondary
          className={styles.checkbox}
          onChange={() => setEmailsSubscribed(!emailsSubscribed)}
        />
      </div>

      <IconMenuButton
        onClick={async () => {
          setLoading(true)
          await handleUpdateName()
          setLoading(false)
        }}
        enabled={!!name && agreeTerms && !loading}
        text={STRINGS.continueBtn}
        className={styles.continueButton}
      />
    </div>
  )
}

export default GetStartedScreen
