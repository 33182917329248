// context/InteractivePianoRollContext.tsx
import React, { createContext, useContext, useState, useRef, useMemo, useEffect } from 'react'

import {
  CursorMode,
  InteractivePianoRollContextType,
  InteractivePianoRollProviderProps,
  PianoRollMode,
  NoteUpdateParams,
  PianoRollNote,
  PianoRollNoteType,
  RulerLabel,
  SimplePianoRollNote,
  StripeSegment,
} from '../types'
import { HistoryManager } from '../utils/HistoryManager'
import { getMonoNoteOverlaps, removeOverlapsFromNotes } from '../utils/overlapHelper'
import { generateUniqueId, zoomLevelToPpqSnap } from '../utils/pianoRollUtils'

// Create a map to store multiple context instances
const InteractivePianoRollContexts: { [key: string]: React.Context<InteractivePianoRollContextType | undefined> } = {}

// Function to get or create a context for a specific ID
const getContext = (id: string) => {
  if (!InteractivePianoRollContexts[id]) {
    InteractivePianoRollContexts[id] = createContext<InteractivePianoRollContextType | undefined>(undefined)
  }
  return InteractivePianoRollContexts[id]
}

const DEFAULT_PIANO_ROLL_MODE = PianoRollMode.PIANO_ROLL
const DEFAULT_IS_MONOPHONIC = false

const DEFAULT_PPQ = 24 // Quarter notes per beat
const DEFAULT_NOTE_LENGTH_TICKS = DEFAULT_PPQ / 2

const DEFAULT_WIDTH = '100%'
const DEFAULT_HEIGHT = '100%'

const DEFAULT_LEFT_SIDE_KEYS_WIDTH = 100
const DEFAULT_LEFT_SIDE_KEYS_GAP = 16

const DEFAULT_GRID_WIDTH = 50
const DEFAULT_GRID_HEIGHT = 32
const DEFAULT_HEADER_HEIGHT = 50
const DEFAULT_HEADER_NUMBERS_HEIGHT = 44
const DEFAULT_HEADER_RULER_HEIGHT = 26
const DEFAULT_MIDI_RANGE_MIN = 24
const DEFAULT_MIDI_RANGE_MAX = 84
const DEFAULT_PIANO_ROLL_WIDTH = 10000
const DEFAULT_INITIAL_SCALE = 1
const DEFAULT_INITIAL_SCROLL_Y = DEFAULT_GRID_HEIGHT * 12 // 1 octave
const DEFAULT_SCALE_BOUNDS = { min: 0.5, max: 5 }
const DEFAULT_PPQ_SNAP_STAGES = [
  { maxZoom: 1.3, snap: DEFAULT_PPQ / 2 },
  { maxZoom: 2, snap: DEFAULT_PPQ / 4 },
  { maxZoom: 5, snap: DEFAULT_PPQ / 8 },
]

const DEFAULT_SMART_SCALE_ENABLED = true

const DEFAULT_NOTE_COLOR = '#3E72FD'
const DEFAULT_SHOW_NOTE_NAMES = true
const DEFAULT_VELOCITY = 100

const DEFAULT_NOTE_OPACITY = 0.85
const DEFAULT_TEMP_NOTE_OPACITY = 0.3

const DEFAULT_SHOW_ZOOM_CONTROLS = true
const DEFAULT_ZOOM_CONTROLS_FACTOR = 1.2

const DEFAULT_LEFT_SIDE_KEYS_VISIBLE = true

const DEFAULT_INACTIVE_ZONE_START_TICKS = 192 * 2

const DEFAULT_COLOR_AUTOCOMPLETE_HOVERED_BORDER = '#5e8eff'
const DEFAULT_COLOR_AUTOCOMPLETE_HOVERED_BACKGROUND =
  'repeating-linear-gradient(-45deg, #2b3c6f, #2b3c6f 6px, #212c4b 6px, #212c4b 12px)'

const DEFAULT_COLOR_HELD_KEY_STROKE = '#1b5ee5'
const DEFAULT_COLOR_HELD_KEY_BACKGROUND = '#1b5ee5'

const DEFAULT_TAB_TO_ADD_STRING = 'Tab to Add'
const DEFAULT_ADD_STRING = '+ Add'

// Provider component
export const InteractivePianoRollProvider = ({
  children,
  id,
  pianoRollMode = DEFAULT_PIANO_ROLL_MODE,
  isMonophonic = DEFAULT_IS_MONOPHONIC,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  initialLeftSideKeysVisible = DEFAULT_LEFT_SIDE_KEYS_VISIBLE,
  leftSideKeysWidth = DEFAULT_LEFT_SIDE_KEYS_WIDTH,
  leftSideKeysGap = DEFAULT_LEFT_SIDE_KEYS_GAP,
  ppq = DEFAULT_PPQ,
  baseGridWidth = DEFAULT_GRID_WIDTH,
  baseGridHeight = DEFAULT_GRID_HEIGHT,
  headerHeight = DEFAULT_HEADER_HEIGHT,
  headerNumbersHeight = DEFAULT_HEADER_NUMBERS_HEIGHT,
  headerRulerHeight = DEFAULT_HEADER_RULER_HEIGHT,
  initialMidiRangeMin = DEFAULT_MIDI_RANGE_MIN,
  initialMidiRangeMax = DEFAULT_MIDI_RANGE_MAX,
  pianoRollWidth = DEFAULT_PIANO_ROLL_WIDTH,
  initialScale = DEFAULT_INITIAL_SCALE,
  initialScrollY = DEFAULT_INITIAL_SCROLL_Y,
  scaleBounds = DEFAULT_SCALE_BOUNDS,
  showNoteNames = DEFAULT_SHOW_NOTE_NAMES,
  noteColor = DEFAULT_NOTE_COLOR,
  noteOpacity = DEFAULT_NOTE_OPACITY,
  noteVelocity = DEFAULT_VELOCITY,
  tempNoteOpacity = DEFAULT_TEMP_NOTE_OPACITY,
  showZoomControls = DEFAULT_SHOW_ZOOM_CONTROLS,
  zoomControlsFactor = DEFAULT_ZOOM_CONTROLS_FACTOR,
  ppqSnapStages = DEFAULT_PPQ_SNAP_STAGES,
  initialInactiveZoneStartTicks = DEFAULT_INACTIVE_ZONE_START_TICKS,
  colorAutocompleteHoveredStroke = DEFAULT_COLOR_AUTOCOMPLETE_HOVERED_BORDER,
  colorAutocompleteHoveredBackground = DEFAULT_COLOR_AUTOCOMPLETE_HOVERED_BACKGROUND,
  colorHeldKeyStroke = DEFAULT_COLOR_HELD_KEY_STROKE,
  colorHeldKeyBackground = DEFAULT_COLOR_HELD_KEY_BACKGROUND,
  TAB_TO_ADD_STRING = DEFAULT_TAB_TO_ADD_STRING,
  ADD_STRING = DEFAULT_ADD_STRING,
}: InteractivePianoRollProviderProps) => {
  const Context = getContext(id)

  const ppqSnapRef = useRef<number>(zoomLevelToPpqSnap(initialScale, ppqSnapStages))
  const zoomLevelRef = useRef<number>(initialScale) // This will be shared across helper functions
  const mousePositionRef = useRef<{ x: number; y: number }>({ x: -1, y: -1 })

  const [inactiveZoneStartTicks, setInactiveZoneStartTicks] = useState<number>(initialInactiveZoneStartTicks)

  const onNoteSoundCallbackRef = useRef<((note: PianoRollNote) => void) | null>(null)

  const onNotesUpdateCallbackRef = useRef<((notes: PianoRollNote[]) => void) | null>(null)
  const onNotesRemoveCallbackRef = useRef<((removedNoteIds: string[], notesLeft: PianoRollNote[]) => void) | null>(null)

  const onPositionUpdateCallbackRef = useRef<((ticks: number, fromRuler: boolean) => void) | null>(null)

  const [cursorMode, setCursorMode] = useState<CursorMode>(CursorMode.SELECTION)

  const [isSmartScaleEnabled, setSmartScaleEnabled] = useState<boolean>(DEFAULT_SMART_SCALE_ENABLED)

  const [rulerLabels, setRulerLabels] = useState<RulerLabel[]>([])
  const [isRulerLabelsVisible, setIsRulerLabelsVisible] = useState<boolean>(true)

  const [noteLengthTicks, setNoteLengthTicks] = useState<number>(DEFAULT_NOTE_LENGTH_TICKS)

  const [renderStripesDict, setRenderStripesDict] = useState<Record<string, StripeSegment[]>>({})

  const [pianoRollNotes, setPianoRollNotes] = useState<PianoRollNote[]>([])
  const [ghostNotes, setGhostNotes] = useState<PianoRollNote[]>([]) // Not used for now, but may be used in the future
  const [smartScaleNotes, setSmartScaleNotes] = useState<PianoRollNote[]>([]) // Used for showing all notes from the chords to guide the user
  const [autocompleteNotes, setAutocompleteNotes] = useState<PianoRollNote[]>([]) // Used for showing AI note suggestions (autocompletions)
  const [tempNotes, setTempNotes] = useState<PianoRollNote[]>([]) // Used for showing temporary notes, when for example moving the note (showing the temporary copy of it)

  const [isAutocompleteNotesHovered, setAutocompleteNotesHovered] = useState<boolean>(false)

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false)
  const [contextMenuPosition, setContextMenuPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 })

  const [selectedNoteIds, setSelectedNoteIds] = useState<Set<string>>(new Set())

  const [clipboard, setClipboard] = useState<PianoRollNote[]>([])

  const historyManager = useRef<HistoryManager>(new HistoryManager())

  const [leftSideHeldKeys, setLeftSideHeldKeys] = useState<Set<number>>(new Set())
  const [samplerModeIcons, setSamplerModeIcons] = useState<Record<string, React.ReactNode>>({})

  const [midiRangeMin, setMidiRangeMin] = useState<number>(initialMidiRangeMin)
  const [midiRangeMax, setMidiRangeMax] = useState<number>(initialMidiRangeMax)

  const contentHeight = useMemo(() => (midiRangeMax - midiRangeMin) * baseGridHeight, [midiRangeMax, midiRangeMin])

  useEffect(() => {
    captureCurrentState(pianoRollNotes, selectedNoteIds)
  }, [])

  useEffect(() => {
    console.log('pianoRollNotes', pianoRollNotes)
  }, [pianoRollNotes])

  const pianoRollNotesMap = useMemo(() => {
    return new Map(pianoRollNotes.map((note) => [note.id, note]))
  }, [pianoRollNotes])

  const tempNotesMap = useMemo(() => {
    return new Map(tempNotes.map((note) => [note.id, note]))
  }, [tempNotes])

  const onNoteSoundEvent = (callback: (note: PianoRollNote) => void) => {
    onNoteSoundCallbackRef.current = callback
  }

  const onNotesUpdateEvent = (callback: (notes: PianoRollNote[]) => void) => {
    onNotesUpdateCallbackRef.current = callback
  }

  const onNotesRemoveEvent = (callback: (removedNoteIds: string[], notesLeft: PianoRollNote[]) => void) => {
    onNotesRemoveCallbackRef.current = callback
  }

  const onPositionUpdateEvent = (callback: (ticks: number, fromRuler: boolean) => void) => {
    onPositionUpdateCallbackRef.current = callback
  }
  const captureCurrentState = (notes: PianoRollNote[], selectedNoteIds: Set<string>) => {
    historyManager.current.push({
      pianoRollNotes: notes.map((note) => {
        return {
          ...note,
        }
      }),
      selectedNoteIds: new Set(selectedNoteIds),
    })
  }

  const getOpacityForNoteType = (pianoRollNoteType: PianoRollNoteType) => {
    if (pianoRollNoteType === PianoRollNoteType.GHOST) return 1
    if (pianoRollNoteType === PianoRollNoteType.TEMP) return tempNoteOpacity
    if (pianoRollNoteType === PianoRollNoteType.AUTOCOMPLETE) return 1
    return noteOpacity
  }

  // function that sets ids, opacities, colors and velocities if they are not provided
  const simpleNotesToPianoRollNotes = (
    simpleNotes: SimplePianoRollNote[],
    pianoRollNoteType: PianoRollNoteType,
  ): PianoRollNote[] => {
    return simpleNotes
      .map((note) => ({
        ...note,
        id: generateUniqueId(),
        opacity: note.opacity ?? getOpacityForNoteType(pianoRollNoteType),
        color: note.color ?? noteColor,
        velocity: note.velocity ?? noteVelocity,
      }))
      .filter((note) => note.startTicks >= 0)
  }

  const addNote = (note: SimplePianoRollNote, params?: NoteUpdateParams): string => {
    return addNotes([note], params)[0]
  }

  const addNotes = (notes: SimplePianoRollNote[], params?: NoteUpdateParams): string[] => {
    const { saveInHistory = true, isSilent = false } = params || {}

    const newNotes = simpleNotesToPianoRollNotes(notes, PianoRollNoteType.NORMAL)

    setPianoRollNotes((prevNotes) => {
      const overlaps = getMonoNoteOverlaps(prevNotes, newNotes)
      const updatedNotes = isMonophonic
        ? removeOverlapsFromNotes([...prevNotes, ...newNotes], overlaps)
        : [...prevNotes, ...newNotes]
      if (newNotes.length === 1 && onNoteSoundCallbackRef.current) onNoteSoundCallbackRef.current(newNotes[0])
      if (saveInHistory) captureCurrentState(updatedNotes, selectedNoteIds)

      if (!isSilent) {
        if (onNotesUpdateCallbackRef.current) onNotesUpdateCallbackRef.current(updatedNotes)
      }

      return updatedNotes
    })

    return newNotes.map((note) => note.id)
  }

  const removeNote = (noteId: string, params?: NoteUpdateParams) => {
    removeNotes([noteId], params)
  }

  const removeNotes = (noteIds: string[], params?: NoteUpdateParams) => {
    const { saveInHistory = true, isSilent = false } = params || {}

    setPianoRollNotes((prevNotes) => {
      const newNotes = prevNotes.filter((note) => !noteIds.includes(note.id))
      if (saveInHistory) captureCurrentState(newNotes, selectedNoteIds)

      if (!isSilent) {
        if (onNotesRemoveCallbackRef.current) onNotesRemoveCallbackRef.current(noteIds, newNotes)
      }

      return newNotes
    })
    unselectNotes(noteIds)
  }

  const removeAllNotes = (params?: NoteUpdateParams) => {
    const { saveInHistory = true, isSilent = false } = params || {}

    setPianoRollNotes([])
    clearSelectedNoteIds()
    if (saveInHistory) captureCurrentState([], new Set())

    // TODO: add a callback to notify that all notes were removed
  }

  const updateNotes = (
    notes: { noteId: string; updatedFields: Partial<PianoRollNote> }[],
    params?: NoteUpdateParams,
  ) => {
    const { saveInHistory = true, isSilent = false } = params || {}

    setPianoRollNotes((prevNotes) => {
      const oldNotes: PianoRollNote[] = []
      const notesToUpdate: PianoRollNote[] = []
      const updatedIds: string[] = []
      for (const noteUpdate of notes) {
        const { noteId, updatedFields } = noteUpdate
        const note = prevNotes.find((note) => note.id === noteId)
        if (note) {
          notesToUpdate.push({ ...note, ...updatedFields })
          updatedIds.push(noteId)
        }
      }
      for (const note of prevNotes) {
        if (!updatedIds.includes(note.id)) {
          oldNotes.push(note)
        }
      }
      const overlaps = getMonoNoteOverlaps(oldNotes, notesToUpdate)
      const updatedNotes = isMonophonic
        ? removeOverlapsFromNotes([...oldNotes, ...notesToUpdate], overlaps)
        : [...oldNotes, ...notesToUpdate]
      if (saveInHistory) captureCurrentState(updatedNotes, selectedNoteIds)

      if (!isSilent) {
        if (onNotesUpdateCallbackRef.current) onNotesUpdateCallbackRef.current(updatedNotes)
      }

      return updatedNotes
    })
  }

  const updateNote = (noteId: string, updatedFields: Partial<PianoRollNote>, params?: NoteUpdateParams) => {
    updateNotes([{ noteId, updatedFields }], params)
  }

  const batchUpdateNotes = (noteIds: string[], updatedFields: Partial<PianoRollNote>, params?: NoteUpdateParams) => {
    const { saveInHistory = true, isSilent = false } = params || {}

    setPianoRollNotes((prevNotes) => {
      const newNotes = prevNotes.map((note) => (noteIds.includes(note.id) ? { ...note, ...updatedFields } : note))
      if (saveInHistory) captureCurrentState(newNotes, selectedNoteIds)

      if (!isSilent) {
        if (onNotesUpdateCallbackRef.current) onNotesUpdateCallbackRef.current(newNotes)
      }

      return newNotes
    })
  }

  const getNoteById = (noteId: string) => {
    return pianoRollNotesMap.get(noteId)
  }

  const getNotesByIds = (noteIds: string[]) => {
    return noteIds.map((id) => pianoRollNotesMap.get(id)).filter(Boolean) as PianoRollNote[]
  }

  const selectNote = (noteId: string) => {
    selectNotes([noteId])
  }

  const selectNotes = (noteIds: string[]) => {
    setSelectedNoteIds((prev) => {
      const newSet = new Set(prev)
      noteIds.forEach((id) => newSet.add(id))
      return newSet
    })
  }

  const unselectNote = (noteId: string) => {
    unselectNotes([noteId])
  }

  const unselectNotes = (noteIds: string[]) => {
    setSelectedNoteIds((prev) => {
      const newSet = new Set(prev)
      noteIds.forEach((id) => newSet.delete(id))
      return newSet
    })
  }

  const clearSelectedNoteIds = () => {
    setSelectedNoteIds(new Set())
  }

  const isNoteSelected = (noteId: string) => {
    return selectedNoteIds.has(noteId)
  }

  const getSelectedNotes = () => {
    return Array.from(selectedNoteIds)
      .map((id) => pianoRollNotesMap.get(id))
      .filter(Boolean) as PianoRollNote[]
  }

  const addGhostNotes = (notes: SimplePianoRollNote[]) => {
    const newGhostNotes = simpleNotesToPianoRollNotes(notes, PianoRollNoteType.GHOST)
    setGhostNotes((prevGhostNotes) => {
      return [...prevGhostNotes, ...newGhostNotes]
    })
    return newGhostNotes.map((note) => note.id)
  }

  const removeAllGhostNotes = () => {
    setGhostNotes([])
  }

  const addSmartScaleNotes = (notes: SimplePianoRollNote[]) => {
    const newSmartScaleNotes = simpleNotesToPianoRollNotes(notes, PianoRollNoteType.SMART_SCALE)
    for (const note of newSmartScaleNotes) {
      note.opacity = 0
    }
    setSmartScaleNotes((prevSmartScaleNotes) => {
      return [...prevSmartScaleNotes, ...newSmartScaleNotes]
    })
    return newSmartScaleNotes.map((note) => note.id)
  }

  const removeAllSmartScaleNotes = () => {
    setSmartScaleNotes([])
  }

  const addAutocompleteNotes = (notes: SimplePianoRollNote[]) => {
    const newAutocompleteNotes = simpleNotesToPianoRollNotes(notes, PianoRollNoteType.AUTOCOMPLETE)
    setAutocompleteNotes((prevAutocompleteNotes) => {
      return [...prevAutocompleteNotes, ...newAutocompleteNotes]
    })
    return newAutocompleteNotes.map((note) => note.id)
  }

  const removeAllAutocompleteNotes = () => {
    setAutocompleteNotes([])
  }

  const acceptAutocompleteNotes = () => {
    for (const note of autocompleteNotes) {
      note.color = noteColor
    }
    addNotes(autocompleteNotes, { saveInHistory: true })
    removeAllAutocompleteNotes()
  }

  const addTempNotes = (notes: SimplePianoRollNote[]) => {
    const newTempNotes = simpleNotesToPianoRollNotes(notes, PianoRollNoteType.TEMP)
    setTempNotes((prevTempNotes) => {
      return [...prevTempNotes, ...newTempNotes]
    })
    return newTempNotes.map((note) => note.id)
  }

  const getTempNotesByIds = (noteIds: string[]) => {
    return noteIds.map((id) => tempNotesMap.get(id)).filter(Boolean) as PianoRollNote[]
  }

  const removeAllTempNotes = () => {
    setTempNotes([])
  }

  const undo = () => {
    const previousState = historyManager.current.undo()
    if (previousState) {
      setPianoRollNotes(previousState.pianoRollNotes)
      setSelectedNoteIds(previousState.selectedNoteIds)
      setRenderStripesDict({}) // TODO: fix this when adding the TAB feature.
      // Maybe here we should also unghost the notes that were in the previous state
    }
  }

  const redo = () => {
    const nextState = historyManager.current.redo()
    if (nextState) {
      setPianoRollNotes(nextState.pianoRollNotes)
      setSelectedNoteIds(nextState.selectedNoteIds)
      setRenderStripesDict({}) // TODO: fix this when adding the TAB feature.
      // Maybe here we should also unghost the notes that were in the previous state
    }
  }

  const copySelectedNotes = () => {
    const selectedNotes = getSelectedNotes()
    setClipboard(selectedNotes)
  }

  const cutSelectedNotes = () => {
    const selectedNotes = getSelectedNotes()
    setClipboard(selectedNotes)
    removeNotes(selectedNotes.map((note) => note.id))
  }

  const pasteNotes = (): void => {
    // TODO: implement this
    /* if (clipboard.length > 0) {
      const minStartTicks = Math.min(...clipboard.map((note) => note.startTicks))
      const playheadOffset = playheadPositionTicks - minStartTicks 

      const newNotes = clipboard.map((note) => ({
        ...note,
        startTicks: note.startTicks + playheadOffset,
        endTicks: note.endTicks + playheadOffset,
      }))

      const newNoteIds = addNotes(newNotes)
      setSelectedNoteIds(new Set(newNoteIds))
     } */
  }

  return (
    <Context.Provider
      value={{
        id,
        // Modes and overall state
        pianoRollMode,
        isMonophonic,
        cursorMode,
        setCursorMode,
        withLeftSideKeys: initialLeftSideKeysVisible,

        // Active zone
        inactiveZoneStartTicks,
        setInactiveZoneStartTicks,

        // Note management
        pianoRollNotes,
        addNote,
        addNotes,
        removeNote,
        removeNotes,
        removeAllNotes,
        updateNote,
        updateNotes,
        batchUpdateNotes,
        getNotesByIds,
        getNoteById,

        // Stripe management
        renderStripesDict,
        setRenderStripesDict,

        // Selection management
        selectedNoteIds,
        selectNote,
        selectNotes,
        unselectNote,
        unselectNotes,
        clearSelectedNoteIds,
        isNoteSelected,
        setSelectedNoteIds,
        getSelectedNotes,

        // Ghost notes management
        ghostNotes,
        addGhostNotes,
        removeAllGhostNotes,

        // Smart scale notes management
        isSmartScaleEnabled,
        setSmartScaleEnabled,
        smartScaleNotes,
        setSmartScaleNotes,
        addSmartScaleNotes,
        removeAllSmartScaleNotes,

        // Autocomplete notes management
        autocompleteNotes,
        setAutocompleteNotes,
        addAutocompleteNotes,
        removeAllAutocompleteNotes,
        isAutocompleteNotesHovered,
        setAutocompleteNotesHovered,
        acceptAutocompleteNotes,

        // Context menu
        isContextMenuOpen,
        setIsContextMenuOpen,
        contextMenuPosition,
        setContextMenuPosition,

        // Temp notes management
        tempNotes,
        addTempNotes,
        getTempNotesByIds,
        removeAllTempNotes,

        // Note length
        noteLengthTicks,
        setNoteLengthTicks,

        // Configuration properties
        width,
        height,
        leftSideKeysWidth: initialLeftSideKeysVisible ? leftSideKeysWidth : 0,
        leftSideKeysGap: initialLeftSideKeysVisible ? leftSideKeysGap : 0,
        ppq,
        ppqSnapRef,
        ppqSnapStages,

        // Midi range
        midiRangeMin,
        midiRangeMax,
        setMidiRangeMin,
        setMidiRangeMax,

        // Zoom
        zoomLevelRef,

        mousePositionRef,
        baseGridWidth,
        baseGridHeight,
        contentHeight,
        headerHeight,
        headerNumbersHeight,
        headerRulerHeight,
        pianoRollWidth,
        initialScale,
        initialScrollY,
        scaleBounds,
        showNoteNames,
        noteColor,
        noteOpacity,
        noteVelocity,
        ghostNoteOpacity: tempNoteOpacity,
        showZoomControls,
        zoomControlsFactor,

        // Ruler labels
        rulerLabels,
        setRulerLabels,
        isRulerLabelsVisible,
        setIsRulerLabelsVisible,

        // Piano roll sample icons if sampler mode
        samplerModeIcons,
        setSamplerModeIcons,

        // Left side piano / sampler
        leftSideHeldKeys,
        setLeftSideHeldKeys,

        // Callbacks
        onNoteSoundCallbackRef,
        onNoteSoundEvent,
        onPositionUpdateCallbackRef,
        onPositionUpdateEvent,

        // Notes events
        onNotesUpdateCallbackRef,
        onNotesUpdateEvent,
        onNotesRemoveCallbackRef,
        onNotesRemoveEvent,

        // History management
        undo,
        redo,

        // Clipboard management
        copySelectedNotes,
        cutSelectedNotes,
        pasteNotes,

        colorAutocompleteHoveredStroke,
        colorAutocompleteHoveredBackground,
        colorHeldKeyStroke,
        colorHeldKeyBackground,

        // Strings
        TAB_TO_ADD_STRING,
        ADD_STRING,
      }}
    >
      {children}
    </Context.Provider>
  )
}

// Hook to use the Piano Roll Notes context
export const useInteractivePianoRoll = (id: string) => {
  const Context = getContext(id)
  const context = useContext(Context)

  if (!context) {
    throw new Error(`useInteractivePianoRoll must be used within an InteractivePianoRollProvider with id: ${id}`)
  }

  return context
}
