import * as Tone from 'tone'

import { Player } from './PlayerCommon'

type Metronome = Tone.Synth

class MetronomePlayer extends Player {
  metronome: Tone.Synth | null = null

  firstTimeLoad = true
  timeoutRef: any = null

  constructor(
    public onMetronomePlaying: (a: boolean) => void = () => {},
    public setActiveTick: (a: number) => void = () => {},
  ) {
    super(
      () => 0,
      () => 0,
    )

    this.onMetronomePlaying = onMetronomePlaying
    this.setActiveTick = setActiveTick
  }

  // SERVE FUNCTIONS

  initMetronome = (): Promise<Metronome> => {
    return new Promise((resolve) => {
      const metronome = new Tone.Synth({
        oscillator: {
          type: 'triangle',
        },
        envelope: {
          attack: 0.01,
          decay: 0.05,
          sustain: 0.0,
          release: 1,
        },
      })
      resolve(metronome)
    })
  }
  getTimings = (duration: string) => {
    const durationSeconds = Tone.Time(duration).toSeconds()

    return [
      { time: '0:0:0', secondary: false, durationSeconds },
      { time: '0:1:0', secondary: true, durationSeconds },
      { time: '0:2:0', secondary: true, durationSeconds },
      { time: '0:3:0', secondary: true, durationSeconds },
    ]
  }

  // MAIN FUNCTIONS

  // SETUP INSTRUMENTS
  init = (volume: Tone.Volume | null) => {
    if (this.firstTimeLoad) {
      this.initMetronome().then((value) => {
        this.metronome = value
        if (volume) {
          this.metronome.connect(new Tone.Volume(-10).connect(volume.toDestination()))
        }
      })
      this.firstTimeLoad = false
    } else {
      if (volume) {
        this.metronome?.disconnect()
        this.metronome?.connect(new Tone.Volume(-10).connect(volume.toDestination()))
      }
    }
  }
  initMetronomePart = (duration: string) => {
    const metronomeMuted = !this.part || this.part.mute
    const timings = this.getTimings(duration)

    this.part?.dispose()
    this.part = new Tone.Part((time, obj) => {
      if (Tone.Time(Tone.Transport.position).toSeconds() > obj.durationSeconds || Tone.Transport.position === '0:0:0') {
        // fix for last tick after no repeatable play
        return
      }
      try {
        if (obj.secondary) {
          this.metronome?.triggerAttackRelease('C5', '16n', time)
        } else {
          this.metronome?.triggerAttackRelease('C6', '16n', time)
        }
      } catch (e) {
        console.error(e)
      }
    }, timings)
    this.part.loop = true
    this.part.loopStart = '0:0:0'
    this.part.mute = metronomeMuted

    this.setPartDuration(duration)
  }
  updatePart = (duration: string) => {
    const timings = this.getTimings(duration)

    this.part?.clear()

    timings?.forEach((n: any) => {
      this.part?.add(n)
    })

    this.setPartDuration(duration)
  }

  // SETUP PLAYBACK
  playFromTimeSignature = (timeSignature: number, timeSignatureStrongs: number[]) => {
    this.onMetronomePlaying(true)

    this.part?.dispose()

    if (this.firstTimeLoad) {
      this.init(new Tone.Volume(-10))
    }

    const tickIndexToTime = (i: number) => `${Math.floor(i / 4)}:${i % 4}:0`

    const part = new Tone.Part(
      (time, value) => {
        try {
          this.setActiveTick(value.id)
          if (value.strong) {
            this.metronome?.triggerAttackRelease('C6', '16n', time)
          } else {
            this.metronome?.triggerAttackRelease('C5', '16n', time)
          }
        } catch (e) {
          console.error(e)
        }
      },
      Array(timeSignature)
        .fill(0)
        .map((_, i) => ({ id: i, time: tickIndexToTime(i), strong: timeSignatureStrongs.includes(i) })),
    )
    part.loop = true
    part.loopStart = '0:0:0'
    part.loopEnd = tickIndexToTime(timeSignature)
    this.part = part
    this.part?.start()
    Tone.Transport.start()
  }

  // PLAYBACK CONTROLLERS
  enableMetronome = (enabled: boolean) => {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
    }
    if (!this.part) {
      this.timeoutRef = setTimeout(() => {
        if (this.part) {
          this.part.mute = !enabled
        }
      }, 1000)
      return
    }
    this.part.mute = !enabled
  }
  pause = () => {
    this.part?.stop()
    this.onMetronomePlaying(false)
    Tone.Transport.stop()
  }
  setPartDuration = (duration: string) => {
    if (!this.part) return

    const durationSeconds = Tone.Time(duration).toSeconds()
    this.part.loopEnd = durationSeconds > Tone.Time('1:0:0').toSeconds() ? '1:0:0' : duration
  }
}

export default MetronomePlayer
