import classNames from 'classnames'
import React, { ReactNode, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'

import { useInstrumentsState } from '../../../../../context/InstrumentsStateContext'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../../hooks/useSizes'
import MobileSwipeableModal from '../../../../common/MobileSwipeableModal/MobileSwipeableModal'
import { SettingType } from '../../../LayersOfInstruments/LayersOfInstruments'
import styles from './InstrumentMenu.module.scss'

type Props = {
  settingType: SettingType
  open: boolean
  onClose: () => void

  renderHeader: () => ReactNode
  renderLeftMenu: () => ReactNode
  renderRightMenu: () => ReactNode
  renderFooter: () => ReactNode
}

const Menu: React.FC<Props> = ({
  settingType,
  open,
  onClose,
  renderHeader,
  renderLeftMenu,
  renderRightMenu,
  renderFooter,
}) => {
  const { isMobile } = useSizes()
  const { editorMode } = useInstrumentsState()

  const triggerId = `menu-hook-${settingType}-${editorMode}`
  const menuRef = useRef<HTMLDivElement>(null)

  const width = 505
  const height = 550

  const { left, top } = useMemo(() => {
    const hook = document.getElementById(triggerId)

    const bcr = hook?.getBoundingClientRect()
    const windowWidth = window.innerWidth

    if (!bcr) return { left: 0, top: 0 }

    let left = bcr.x
    let top = bcr.y - 4

    if (top < height) {
      left = bcr.x + bcr.width + 4
      top = height + 4
    }

    if (left > windowWidth - width) {
      left = windowWidth - width - 4
    }

    return { left, top }
  }, [open, settingType, triggerId])

  useClickOutsideAlerter(menuRef, onClose, triggerId)

  if (isMobile)
    return (
      <MobileSwipeableModal onClose={onClose} open={open}>
        <div className={styles.container}>
          {renderHeader()}
          {renderLeftMenu()}
          {renderRightMenu()}
        </div>
      </MobileSwipeableModal>
    )

  return open
    ? createPortal(
        <div
          ref={menuRef}
          className={classNames(styles.container, styles[editorMode])}
          style={{ left, top }}
          data-disable-ids-click-outside-hook={[clickOutsideHookIds.footer]}
        >
          {renderHeader()}
          <div className={styles.content}>
            {renderLeftMenu()}
            {renderRightMenu()}
          </div>
          {renderFooter()}
        </div>,
        document.getElementById('__next') as HTMLElement,
      )
    : null
}

export default Menu
