import { getActivePart, getChordsFromAllParts, getMelodyNotesFromAllParts } from './progUtils'
import { Prog } from './types'

export const getApiChordProg = (prog: Prog) => {
  const chords = getChordsFromAllParts(prog).map((ch, index) => ({
    ...ch,
    id: index + 1,
  }))

  const progCopy: any = {
    ...prog,
    chords,
    parts: undefined,
  }

  return progCopy
}
export const getApiMelody = (prog: Prog, currentPartId?: number) => {
  const melodyRaw = currentPartId
    ? getActivePart(prog, currentPartId)?.melody?.notes || []
    : getMelodyNotesFromAllParts(prog)

  const melody = melodyRaw.map(({ id, opacity, color, ...melodyNote }) => ({
    ...melodyNote,
    id: currentPartId || id,
  }))

  return melody
}
