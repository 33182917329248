import { ChangeEvent, MutableRefObject, useEffect } from 'react'

export const totallyDisabledClickOutsideHookAttribute = 'data-totally-disable-click-outside-hook'
export const disableIdsClickOutsideHookAttribute = 'data-disable-ids-click-outside-hook'

export const clickOutsideHookIds = {
  onboarding: 'Onboarding',
  settingsPicker: 'SettingsPicker',
  linesInput: 'LinesInput',
  assistantWrapper: 'AssistantWrapper',
  midiMenu: 'MidiMenu',
  keySelect: 'KeySelect',
  bpmSelect: 'BpmSelect',
  drumsDefaultEditor: 'DrumsDefaultEditor',
  genreDropdown: 'GenreDropdown',
  scaleSelect: 'ScaleSelect',
  paletteSelect: 'PaletteSelect',
  voicings: 'Voicings',
  footer: 'Footer',
  editMenu: 'EditMenu',
  timelineWrapper: 'TimelineWrapper',
  chordPalette: 'ChordPalette',
  // Menu -> `menu-hook-${settingType}-${editorMode}`
  // EditorPortal -> `editorPortal-hook-${type}`
}

const useClickOutsideAlerter = (
  ref: HTMLDivElement | MutableRefObject<HTMLDivElement | undefined | null> | null,
  onClose: (...args: any[]) => void,
  id?: string,
  event = 'mousedown',
  filter?: ((e: ChangeEvent<HTMLInputElement>) => boolean) | null,
  checkIfDisabled = true,
  updateTriggers = [] as any[],
) => {
  const getAttributeValue = (element: any, attributeName: string) => {
    const closestElement = element.closest(`[${attributeName}]`)
    if (!closestElement) return null

    try {
      return JSON.parse(closestElement.getAttribute(attributeName))
    } catch {
      return closestElement.getAttribute(attributeName)
    }
  }

  useEffect(() => {
    const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
      if (filter && !filter(event)) return
      onClose()
    }
    const handleClickOutside = (event: ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      if (ref?.current) {
        // @ts-ignore
        if (!ref?.current?.contains(event.target)) {
          handleClick(event)
        }
      } else {
        // @ts-ignore
        if (ref?.contains && !ref?.contains(event.target)) {
          handleClick(event)
        }
      }
    }
    const handleClickOutsideWithCheck = (event: ChangeEvent<HTMLInputElement>) => {
      const disabledIds = getAttributeValue(event.target, disableIdsClickOutsideHookAttribute) || []

      const isClickedOnTrigger = event.target.closest(`#${id}`) !== null
      const isTotallyDisabled = getAttributeValue(event.target, totallyDisabledClickOutsideHookAttribute) === true
      const isIdDisabled = id ? disabledIds.includes(id) : false

      const isBasicDisable = isClickedOnTrigger
      const isAdvancedDisabled = isTotallyDisabled || isIdDisabled

      if (isBasicDisable) return
      if (checkIfDisabled && isAdvancedDisabled) return

      handleClickOutside(event)
    }

    // @ts-ignore
    document.addEventListener(event, handleClickOutsideWithCheck)
    return () => {
      // @ts-ignore
      document.removeEventListener(event, handleClickOutsideWithCheck)
    }
  }, [ref, ...updateTriggers])
}

export default useClickOutsideAlerter
