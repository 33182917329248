import deepEqual from 'deep-equal'
import React, { LegacyRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import * as Tone from 'tone'

import {
  getChordGenreGroupsInnerRoute,
  getDrumGenreGroupsInnerRoute,
  getDrumGenreInnerRoute,
} from '../../../api/constants'
import { generateDrumGenrePattern } from '../../../api/drum-track'
import { generateMelodyPattern } from '../../../api/melody-track'
import { generateProgressionInnerRoute } from '../../../api/progresssions'
import { editProjectInnerRoute, saveDraftProjectInnerRoute, saveProjectInnerRoute } from '../../../api/projects'
import { updateFavouriteInstrumentInnerRoute } from '../../../api/user-prefs'
import { useCommonModals } from '../../../context/CommonModalsContext'
import { useHistory } from '../../../context/HistoryContext'
import { useProjectState } from '../../../context/ProjectStateContext'
import usePrevious from '../../../hooks/usePrevious'
import useSizes from '../../../hooks/useSizes'
import { drumsToPianoRollNotes } from '../../../utils/drumsUtils'
import {
  getActivePart,
  getChordByIdFromProg,
  getFirstNonEmptyPart,
  getPartIdByChordId,
  getPartLengthInBars,
  getPartOffsetInBars,
  isPartEmpty,
  isProgEmpty,
  replaceChordByIdInProg,
  updateChordsFromTimeline,
  updateProgPartDataById,
} from '../../../utils/progUtils'
import { trackMixpanelEvent_addTrack } from '../../../utils/tracking'
import {
  Chord,
  defaultProg,
  Lyrics,
  Prog,
  ProgPart,
  ProgPartPattern,
  Project,
  TGenreGroup,
  TimelineState,
  TGenre,
  ViewConfig,
  getDefaultGenre,
  TGenreShort,
  MelodyPattern,
  getDefaultPart,
  defaultLyrics,
  defaultView,
} from '../../../utils/types'
import { useInteractivePianoRoll } from '../../ai-playground/InteractivePianoRoll/hooks/useInteractivePianoRoll'
import { PianoRollNote, SimplePianoRollNote } from '../../ai-playground/InteractivePianoRoll/types'
import { drumsPianoRollId } from '../DrumsEditor/DrumsEditor'
import { randBpmFromTempo } from '../Generator/generatorUtils'
import { InstrumentType } from '../LayersOfInstruments/LayersOfInstruments'
import { melodyPianoRollId } from '../MelodyEditor/MelodyEditor'
import Player from './player/Player'
import useConstantsFetcherState from './useConstantsFetcherState'

type FetchType = {
  playerConfig: PlayerConfig
  playerConfigSetter: PlayerConfigSetter
}

export const defaultMelody = {
  notes: [],
  duration: 12,
  velocity: 127,
  tempo: 0,
  length: 4,
}
export const defaultDrums: ProgPartPattern = {
  groups: [],
  tempo: 0,
  length: 4,
}

export type RepeatType = 'none' | 'whole' | number

export const PlayerConfigStateContext = React.createContext<FetchType>({
  playerConfig: {
    instrumentLoadedRef: null,
    drumsInstrumentLoadedRef: null,
    iOSAudioContextFixRef: null,
    player: new Player(
      () => {},
      () => {},
      () => {},
    ),
    bpm: 0,
    isOnRepeat: 'none',
    isPartLooped: false,
    isMetronome: false,
    volume: 0,
    lyrics: defaultLyrics,
    view: defaultView,
    currPartDrumsOpen: false,
    tonalityKey: undefined,
    tonalityScale: undefined,
    currentPartId: 0,
    currentPart: defaultProg.parts[0],
    webMidiPlaying: [],
    isGenerateMelody: false,
    isGenerateDrums: false,
    isPartLoading: false,
    isProgLoading: false,
    isMelodyLoading: false,
    isDrumsPatternLoading: false,
    instrumentLoaded: false,
    instrumentMidiOut: {},
    melodyInstrumentLoaded: false,
    drumsInstrumentMidiOut: null,
    drumsInstrumentLoaded: false,
    isPlaying: false,
    isPreviewPlaying: false,
    activeChordIds: [],
    prevProg: null,
    prog: null,
    activeChord: null,
    playingChord: null,
    tonalityKeys: [],
    tonalityScales: [],
    requestPosition: () => ({
      time: 1,
      loop: 1,
      partDuration: 0,
      currentPart: 1,
    }),
    editChordId: {
      id: null,
      center: null,
    },
    isPlayingBeforeManualScroll: false,
    progValueRef: null,
    mutedDrumTracks: [],
    isShareEnabled: false,
    loadingTile: null,
    addChordMode: null,
    chordGroups: [],
    chordGenre: getDefaultGenre(),
    drumGroups: [],
    drumGenre: getDefaultGenre(),
    previewChordId: null,
    previewChord: null,
    editTrigger: 0,
  },
  playerConfigSetter: {
    setProg: () => {},
    setMetronome: () => {},
    setMelodyWidth: () => {},
    setDrumWidth: () => {},
    setBpm: () => {},
    setView: () => {},
    setIsOnRepeat: () => {},
    setIsProgLoading: () => {},
    setIsMelodyLoading: () => {},
    setIsDrumsPatternLoading: () => {},
    setAddChordMode: () => {},
    setVolume: () => {},
    setLyrics: () => {},
    setCurrentPartId: () => {},
    setInstrumentMidiOut: () => {},
    setDrumsInstrumentMidiOut: () => {},
    setTonalityKey: () => {},
    setTonalityScale: () => {},
    setStartAfterGenerate: () => {},
    setIsPlaying: () => {},
    setIsPreviewPlaying: () => {},
    setInstrumentLoaded: () => {},
    setMelodyInstrumentLoaded: () => {},
    setDrumsInstrumentLoaded: () => {},
    setActiveChordIds: () => {},
    setPattern: () => {},
    setMelodyPianorollNotes: () => {},
    setMelodyPattern: () => {},
    setIsGenerateMelody: () => {},
    setIsGenerateDrums: () => {},
    onPlay: () => {},
    saveProject: () => Promise.resolve(''),
    initProject: () => {},
    handleGenerateChords: () => Promise.resolve(),
    handleGenerateMelody: () => Promise.resolve(),
    handleGenerateDrums: () => Promise.resolve(),
    setEditChordId: () => {},
    setIsPlayingBeforeManualScroll: () => {},
    playControlsOnPlay: () => {},
    playControlsOnRepeatChange: () => {},
    setLoadingTile: () => {},
    handleUnselectChord: () => {},
    setMutedDrumTracks: () => {},
    setDefaultDrumPattern: () => {},
    setIsShareEnabled: () => {},
    playControlsOnReset: () => {},
    handleOpenLayersEditor: () => {},
    handleTimelineChange: () => {},
    handleShowHideMelody: () => {},
    handleShowHideDrums: () => {},
    handleSaveChordVoicings: () => {},
    handleSaveChordMidi: () => {},
    setPreviewChordId: () => {},
    setPreviewChord: () => {},
  },
})

export const PlayerConfigStateProvider = ({ children }: { children: ReactNode }) => {
  const { removeAllNotes: removeAllMelodyNotes, addNotes: addMelodyNotes } = useInteractivePianoRoll(melodyPianoRollId)
  const { removeAllNotes: removeAllDrumsNotes, addNotes: addDrumsNotes } = useInteractivePianoRoll(drumsPianoRollId)
  const { prog: historyProg, setProg: setHistoryProg, changedPartId } = useHistory()
  const projectStateConfig = useProjectState()
  const modalsConfig = useCommonModals()
  const { isMobile } = useSizes()

  // prog
  const prog = historyProg || defaultProg
  const prevProg = usePrevious(prog) as Prog

  // required to launch iOS context when the user has "Do Not Disturb" enabled
  const iOSAudioContextFixRef = useRef<HTMLAudioElement>(null)
  const progToTimingsWebWorker = useRef<Worker>()
  const isOnRepeatRef = useRef<RepeatType>('whole')
  const progValueRef = useRef<Prog>()
  const instrumentLoadedRef = useRef(false)
  const melodyInstrumentLoadedRef = useRef(false)
  const drumsInstrumentLoadedRef = useRef(false)

  // project data
  const [lyrics, setLyrics] = useState<Lyrics>(defaultLyrics)

  const [instrumentMidiOut, setInstrumentMidiOut] = useState<{ [key: string]: string }>({})
  const [drumsInstrumentMidiOut, setDrumsInstrumentMidiOut] = useState<{ [key: string]: string }>({})

  // project settings
  const { tonalityKey, setTonalityKey } = useMemo(
    () => ({
      tonalityKey: prog.key,
      setTonalityKey: (newKey: string | undefined) => newKey && setProg({ ...prog, key: newKey }),
    }),
    [prog],
  )
  const { tonalityScale, setTonalityScale } = useMemo(
    () => ({
      tonalityScale: prog.scale,
      setTonalityScale: (newScale: string | undefined) => newScale && setProg({ ...prog, scale: newScale }),
    }),
    [prog],
  )
  const { volume, setVolume } = useMemo(
    () => ({
      volume: prog.volume,
      setVolume: (newVolume: number) => setProg({ ...prog, volume: newVolume }),
    }),
    [prog],
  )
  const { bpm, setBpm } = useMemo(
    () => ({
      bpm: prog.bpm,
      setBpm: (newBpm: number) => setProg({ ...prog, bpm: newBpm }),
    }),
    [prog],
  )
  const { isMetronome, setMetronome } = useMemo(
    () => ({
      isMetronome: prog.isMetronomeEnabled,
      setMetronome: (newIsMetronomeEnabled: boolean) => setProg({ ...prog, isMetronomeEnabled: newIsMetronomeEnabled }),
    }),
    [prog],
  )
  const { isShareEnabled, setIsShareEnabled } = useMemo(
    () => ({
      isShareEnabled: prog.shareEnabled,
      setIsShareEnabled: (newShareEnabled: boolean) => setProg({ ...prog, shareEnabled: newShareEnabled }),
    }),
    [prog],
  )

  const [isOnRepeat, setIsOnRepeatState] = useState<RepeatType>('whole')
  const [isOnRepeatBeforeDrumsEditor, setIsOnRepeatBeforeDrumsEditor] = useState<RepeatType | null>(isOnRepeat)

  const [mutedDrumTracks, setMutedDrumTracks] = useState<string[]>([])

  // view settings
  const [view, setView] = useState<ViewConfig>(defaultView)
  const [editTrigger, setEditTrigger] = useState(0)
  const [webMidiPlaying, setWebMidiPlaying] = useState<Array<number>>([])
  const [activeChordIds, setActiveChordIds] = useState<number[]>([])
  const [playingChordId, setPlayingChordId] = useState<number | null>(null)
  const [addChordMode, setAddChordMode] = useState<number | null>(null)
  const [editChordId, setEditChordId] = useState<{
    id: number | null
    center: number | null
  }>({
    id: null,
    center: null,
  })
  const [previewChordId, setPreviewChordId] = useState<number | null>(null)
  const [previewChord, setPreviewChord] = useState<Chord | null>(null)

  // metadata
  const [currentPartId, setCurrentPartIdState] = useState<number>(1)
  const [startAfterGenerate, setStartAfterGenerate] = useState(false)
  const [instrumentLoaded, setInstrumentLoaded] = useState(false)
  const [melodyInstrumentLoaded, setMelodyInstrumentLoaded] = useState(true)
  const [drumsInstrumentLoaded, setDrumsInstrumentLoaded] = useState(false)

  // loading
  const [loadingTile, setLoadingTile] = useState<number | null>(null)
  const [isProgLoading, setIsProgLoading] = useState(true)
  const [isMelodyLoading, setIsMelodyLoading] = useState(false)
  const [isGenerateMelody, setIsGenerateMelody] = useState(false)
  const [isGenerateDrums, setIsGenerateDrums] = useState(false)
  const [isDrumsPatternLoading, setIsDrumsPatternLoading] = useState(false)

  // playing
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false)
  const [isPlayingBeforeManualScroll, setIsPlayingBeforeManualScroll] = useState(false)

  const player: Player = useMemo(
    () =>
      new Player(
        (value) => setIsPlaying(value),
        (id) => {
          if (id === -1) {
            setCurrentPartIdState(getFirstNonEmptyPart(progValueRef.current as Prog)?.id as number)
          } else {
            const partId = getPartIdByChordId(id)
            partId && setCurrentPartIdState(partId)
            setPlayingChordId(id)
          }
        },
        (partId) => {
          if (partId) setCurrentPartIdState(partId)
        },
        (partId) => {
          if (partId) setCurrentPartIdState(partId)
        },
        (midi, add) => setWebMidiPlaying((prev) => (add ? [...prev, midi] : prev.filter((p) => p !== midi))),
        (value) => {
          setIsPreviewPlaying(value)
        },
      ),
    [],
  )

  // other data
  const isPartLooped = typeof isOnRepeat === 'number'
  const partLoopedId = typeof isOnRepeat === 'number' ? isOnRepeat : null

  const currentPart = getActivePart(prog, currentPartId) || getDefaultPart()
  const currPartDrumsOpen = (view.drumsOpen || !!currentPart?.drums) && !!currentPart?.chords.length

  const activeChord =
    activeChordIds && activeChordIds.length === 1 ? getChordByIdFromProg(activeChordIds[0], prog) : null
  const playingChord = playingChordId ? getChordByIdFromProg(playingChordId, prog) : null

  const isPartLoading = isProgLoading || isMelodyLoading || isDrumsPatternLoading

  const [constants] = useConstantsFetcherState({
    tonalityScale: prog.scale,
    setTonalityScale,
  })

  const { data: chordGroups } = useQuery(
    ['chord-groups'],
    (): Promise<TGenreGroup[]> => getChordGenreGroupsInnerRoute(),
  )
  const chordGenre = useMemo(() => {
    return (chordGroups || [])
      .map((group) => group.genres)
      .flat()
      .find((genre) => genre.key === currentPart.generatorSettings.chordGenreKey)
  }, [chordGroups, currentPartId, currentPart.generatorSettings.chordGenreKey])
  const { data: drumGroups } = useQuery(['drum-groups'], (): Promise<TGenreGroup[]> => getDrumGenreGroupsInnerRoute())
  const { data: drumGenre, refetch: drumGenreRefetch } = useQuery(
    [drumGroups, currentPartId, currentPart.generatorSettings.drumGenreKey],
    (): Promise<TGenre> => getDrumGenreInnerRoute(currentPart.generatorSettings.drumGenreKey),
    {
      keepPreviousData: true,
    },
  )

  // data updates
  useEffect(() => {
    isOnRepeatRef.current = isOnRepeat
  }, [isOnRepeat])
  useEffect(() => {
    progValueRef.current = prog
  }, [prog])
  useEffect(() => {
    if (view.drumsOpen && !currentPart.drums) setDefaultDrumPattern(undefined, true)
  }, [view.drumsOpen])
  useEffect(() => {
    player.setBpm(bpm)
  }, [bpm])
  useEffect(() => {
    player.setVolume(volume)
  }, [volume])
  useEffect(() => {
    player.enableMetronome(isMetronome)
  }, [isMetronome])
  useEffect(() => {
    instrumentLoadedRef.current = instrumentLoaded
  }, [instrumentLoaded])
  useEffect(() => {
    melodyInstrumentLoadedRef.current = melodyInstrumentLoaded
  }, [melodyInstrumentLoaded])
  useEffect(() => {
    drumsInstrumentLoadedRef.current = drumsInstrumentLoaded
  }, [drumsInstrumentLoaded])

  // setup
  useEffect(() => {
    player.init(volume, prog)
    player.repeat(isOnRepeat !== 'none')

    return () => {
      progToTimingsWebWorker.current?.terminate()
    }
  }, [])
  useEffect(() => {
    // - start tone on first user interaction
    document.documentElement.addEventListener(
      'mousedown',
      function () {
        Tone.start()
        if (iOSAudioContextFixRef.current) {
          iOSAudioContextFixRef.current.play()
        }
      },
      { once: true },
    )
  }, [])
  useEffect(() => {
    ;(async () => {
      // - set new playback on each progression change and reset play head to init position
      if (isPartLooped) {
        const loopedPart = prog?.parts.find((p) => p.id === partLoopedId)
        const partEmpty = loopedPart && isPartEmpty(loopedPart)

        if (!loopedPart || partEmpty) {
          setIsOnRepeatState('none')
        }
      }

      await player.updatePart(prog, partLoopedId || undefined)

      setTimeout(async () => {
        if (startAfterGenerate && !isPlaying) {
          while (
            !instrumentLoadedRef.current ||
            !melodyInstrumentLoadedRef.current ||
            !drumsInstrumentLoadedRef.current
          ) {
            // wait until instruments is loaded
            await new Promise((resolve) => setTimeout(resolve, 200))
          }

          player.play()
        }
        setStartAfterGenerate(false)
      }, 0)
    })()
  }, [JSON.stringify(prog), partLoopedId, JSON.stringify(mutedDrumTracks)])

  // view
  useEffect(() => {
    if (view.drumsEditorOpen || view.layersOpen || view.melodyEditorOpen || view.editorOpen) {
      setCurrentPartId(currentPartId, 'part')
      setTimeout(() => setIsOnRepeatBeforeDrumsEditor(isOnRepeat), 500)
    } else {
      if (!isOnRepeatBeforeDrumsEditor) return

      if (typeof isOnRepeatBeforeDrumsEditor === 'number') {
        setCurrentPartId(isOnRepeatBeforeDrumsEditor, 'part')
      } else {
        setCurrentPartId(currentPartId, isOnRepeatBeforeDrumsEditor)
      }
    }
  }, [view.drumsEditorOpen, view.layersOpen, view.melodyEditorOpen, view.editorOpen])
  useEffect(() => {
    if (view.pianoOpen) {
      updateFavouriteInstrumentInnerRoute('piano')
      setView({
        ...view,
        tempFavInstrument: 'piano',
      })
    }
  }, [view.pianoOpen])
  useEffect(() => {
    if (view.guitarOpen) {
      updateFavouriteInstrumentInnerRoute('guitar')
      setView({
        ...view,
        tempFavInstrument: 'guitar',
      })
    }
  }, [view.guitarOpen])
  useEffect(() => {
    if (!editTrigger) return
    setTimeout(() => setEditTrigger(0), 150)
  }, [editTrigger])

  // logic
  useEffect(() => {
    setIsOnRepeatBeforeDrumsEditor(null)
  }, [isOnRepeat])
  useEffect(() => {
    setActiveChordIds([])
  }, [currentPartId])
  useEffect(() => {
    if (modalsConfig.pricingOpen || modalsConfig.loginOpen || modalsConfig.feedbackIsOpen) {
      if (isPlaying) {
        onPlay()
      }
    }
  }, [modalsConfig.pricingOpen, modalsConfig.loginOpen, modalsConfig.feedbackIsOpen])
  useEffect(() => {
    // - play chord one time on sounding change
    if (!activeChord) {
      return
    }
    const prevActiveChord =
      activeChordIds && activeChordIds.length === 1 ? getChordByIdFromProg(activeChordIds[0], prevProg) : null
    if (!prevActiveChord || prevActiveChord.name !== activeChord.name) {
      // first render or transposed - in both cases do not play chord
      return
    }
    if (
      prevActiveChord.octave === activeChord.octave &&
      activeChord.settings.velocity === prevActiveChord.settings.velocity &&
      JSON.stringify(activeChord.midi) === JSON.stringify(prevActiveChord.midi)
    ) {
      return
    }
    player.playChord(prog, activeChord.id)
  }, [activeChord?.octave, activeChord?.settings.velocity, activeChord?.midi])
  useEffect(() => {
    if (!prog.parts.find((p) => p.id === currentPartId)) {
      setCurrentPartId(prog.parts[0]?.id as number)
    }
  }, [prog, currentPartId])
  useEffect(() => {
    projectStateConfig.setProjectEmpty(
      !prog || !prog.parts.length || prog.parts.every((p) => !p.chords?.length && !p.drums),
    )
    if (!prog || !prevProg || projectStateConfig.openNewProject) {
      return
    }
    projectStateConfig.setConfigOrProgChanged(Math.random())
  }, [JSON.stringify(prog), volume, isMetronome, bpm, view, lyrics, projectStateConfig.openNewProject])
  useEffect(() => {
    if (!projectStateConfig.saveChangedStateTrigger || !prog || projectStateConfig.openNewProject) {
      return
    }
    ;(async () => {
      if (projectStateConfig.editedProjectId) {
        await editProjectInnerRoute(projectStateConfig.editedProjectId, prog, view, lyrics)
        projectStateConfig.setProjectSaved(true)
      } else {
        await saveDraftProjectInnerRoute(prog, view, lyrics)
        projectStateConfig.setDraftSaved(true)
      }
    })()
  }, [
    projectStateConfig.editedProjectId,
    projectStateConfig.saveChangedStateTrigger,
    projectStateConfig.openNewProject,
  ])
  useEffect(() => {
    if (!changedPartId || changedPartId === currentPartId) return
    setCurrentPartId(changedPartId)
  }, [changedPartId])

  // callbacks
  const requestPosition = useCallback(() => {
    const partLength = getPartLengthInBars(prog, currentPartId)
    if (isPartLooped) {
      return {
        time: 1 + player.getTransportPosition(),
        loop: 1,
        partDuration: partLength,
        currentPart: currentPartId,
      }
    }
    const barsOffset = getPartOffsetInBars(prog, currentPartId)
    const position = player.getTransportPosition(barsOffset)
    return {
      time: 1 + (position % partLength),
      loop: Math.ceil(position / partLength),
      partDuration: partLength,
      currentPart: currentPartId,
    }
  }, [isPartLooped, prog, currentPartId])
  const onPlay = useCallback(
    async (newProgProps?: Prog) => {
      if (Tone.context.state !== 'running') {
        if (!instrumentLoaded || !melodyInstrumentLoaded || !drumsInstrumentLoaded) return

        try {
          await Tone.start()
          console.log('Audio context resumed!')
        } catch (err) {
          console.error('Error resuming AudioContext:', err)
        }
      }

      const newProg = newProgProps || prog
      const progEmpty = isProgEmpty(newProg)
      if (progEmpty) {
        return
      }
      if (Tone.Transport.state === 'started') {
        player.pause()
        return
      }
      if (!player.chordPlayer.part) {
        return
      }
      player.play()
    },
    [isPlaying, prog, isPartLooped, instrumentLoaded, melodyInstrumentLoaded, drumsInstrumentLoaded],
  )

  // setters
  const setDrumsPianorollNotes = (notes?: SimplePianoRollNote[]) => {
    removeAllDrumsNotes()
    addDrumsNotes(notes || [], { isSilent: true })
  }
  const setPattern = (
    drumsPattern?: ProgPartPattern,
    newProgProps?: Prog,
    partId?: number,
    genreFromProps?: TGenre,
  ) => {
    const genre = genreFromProps || drumGenre
    if (!genre) return

    const curPart = getActivePart(newProgProps || prog, partId || currentPartId)
    if (drumsPattern && curPart?.drums && !curPart.drums.length && (curPart?.chords.length || 0) === 0) {
      drumsPattern.length = 4
    }

    const newDrums: ProgPartPattern =
      drumsPattern !== undefined
        ? drumsPattern
        : {
            length: curPart?.chords?.length ? null : 4,
            tempo: genre.defaultTempo,
            groups: genre.percGroups.map((group) => ({
              percTypes: group.percTypes,
              pattern: group.patterns[0],
            })),
          }

    const newProg = updateProgPartDataById(newProgProps || (prog as Prog), partId || currentPartId, { drums: newDrums })
    setProg(newProg)

    setDrumsPianorollNotes(drumsToPianoRollNotes(newDrums))
  }
  const setDrumWidth = (drumsLength?: number | null) => {
    const newProg = updateProgPartDataById(prog as Prog, currentPartId, {
      drums: currentPart.drums ? { ...currentPart.drums, length: drumsLength } : null,
    })
    setProg(newProg)
  }
  const setDefaultDrumPattern = (pattern?: ProgPartPattern, startAfterGenerate = false) => {
    setPattern(pattern || null)
    setStartAfterGenerate(startAfterGenerate)
  }
  const setMelodyPianorollNotes = (notes?: PianoRollNote[]) => {
    removeAllMelodyNotes()
    addMelodyNotes(notes || [], { isSilent: true })
  }
  const setMelodyPattern = (
    melodyPattern?: MelodyPattern,
    newProgProps?: Prog,
    partId?: number,
    genreFromProps?: TGenre,
  ) => {
    const genre = genreFromProps || drumGenre
    if (!genre) return

    const curPart = getActivePart(newProgProps || prog, partId || currentPartId)
    if (melodyPattern && curPart?.melody && !curPart.melody.length && (curPart?.chords.length || 0) === 0) {
      melodyPattern.length = 4
    }

    const newMelody: MelodyPattern =
      melodyPattern !== undefined
        ? melodyPattern
        : {
            ...defaultMelody,
            tempo: genre.defaultTempo,
            length: curPart?.chords?.length ? null : 4,
          }

    const newProg = updateProgPartDataById(newProgProps || (prog as Prog), partId || currentPartId, {
      melody: newMelody,
    })
    setProg(newProg)

    setMelodyPianorollNotes(newMelody?.notes)
  }
  const setMelodyWidth = (melodyLength?: number | null) => {
    const newProg = updateProgPartDataById(prog as Prog, currentPartId, {
      melody: currentPart.melody ? { ...currentPart.melody, length: melodyLength } : null,
    })
    setProg(newProg)
  }

  // save
  const saveProject = async (name: string) => {
    if (!prog) return

    const project = await saveProjectInnerRoute({ ...prog, name }, view, lyrics)
    return project._id
  }

  // generators - service
  const updatePartParamsGenerated = (newProg: Prog, partName?: string, partId?: number) => {
    return updateProgPartDataById(newProg, partId as number, {
      draft: false,
      name: partName || currentPart.name,
    })
  }

  // generators - main
  const handleGenerateDrums = async (
    progFromProp: Prog | null,
    genre: string,
    sameTempo: boolean,
    tempo: string | number,
  ) => {
    const progFromPropAsProg = progFromProp || prog
    const newBpm = sameTempo ? bpm : randBpmFromTempo(tempo, genre)

    player.pause()
    player.setTime(partLoopedId ? 0 : getPartOffsetInBars(progFromPropAsProg, currentPartId))

    const generatedDrums = await generateDrumGenrePattern(genre)
    const newDrums = { ...generatedDrums, length: currentPart.chords.length ? null : 4 }

    setPattern(newDrums, { ...progFromPropAsProg, bpm: newBpm })

    setStartAfterGenerate(true)
  }
  const handleGenerateMelody = async (progFromProp?: Prog | null) => {
    const progFromPropAsProg = progFromProp || prog

    player.pause()
    player.setTime(partLoopedId ? 0 : getPartOffsetInBars(progFromPropAsProg, currentPartId))

    const generatedMelodyNotes = await generateMelodyPattern(prog, tonalityKey, tonalityScale)
    const newMelody = {
      ...defaultMelody,
      ...currentPart.melody,
      notes: generatedMelodyNotes,
      length: currentPart.chords.length ? null : 4,
    }

    setMelodyPattern(newMelody, progFromPropAsProg)
    setStartAfterGenerate(true)
  }
  const handleGenerateChords = async (
    progFromProp: Prog,
    genreFromProps?: string,
    sameTempo?: boolean,
    tempo?: string | number,
    scale?: string,
  ) => {
    const { data } = await generateProgressionInnerRoute({
      genre: genreFromProps || chordGenre?.key,
      tonalityKey,
      tonalityScale: scale || tonalityScale,
      prog,
      currentPartId,
    })
    const newBpm = sameTempo ? bpm : randBpmFromTempo(tempo || '', chordGenre?.key || '')

    let partId = currentPartId
    let part = currentPart
    let newProg = projectStateConfig.initiateProgFromData(
      progFromProp as Prog,
      data.chords,
      data.scale,
      data.key,
      currentPartId,
      currentPart?.drums || null,
    )
    // it means that it is first part
    if (!partId) {
      partId = newProg.parts[0].id
      part = newProg.parts[0]
    }
    // @ts-ignore
    newProg = updatePartParamsGenerated(newProg, '', partId)
    // @ts-ignore
    newProg = updateProgPartDataById(newProg, partId, {
      generatorSettings: {
        chordGenreKey: genreFromProps || chordGenre?.key || 'hip-hop',
        drumGenreKey: part.generatorSettings.drumGenreKey,
        emptyGenre: false,
      },
    })
    if (
      newProg.name !== progValueRef.current?.name &&
      progFromProp.componentKey === prog.componentKey &&
      progValueRef.current?.name
    ) {
      // name could have been updated just before generator clicked
      newProg.name = progValueRef.current?.name || ''
    }

    setActiveChordIds([])
    setCurrentPartId(partId, undefined, newProg)
    setProg({ ...newProg, bpm: newBpm, scale: scale || tonalityScale })

    if (view && isMobile) {
      view.lyricsOpen = false
    }
    setStartAfterGenerate(true)
  }

  // controls
  const setCurrentPartId = (
    partId?: number,
    loopRaw?: 'none' | 'whole' | 'part',
    progFromProps?: Prog,
    setTime = true,
  ) => {
    const loopInRepeatType = loopRaw === 'part' ? partId : loopRaw
    const loop = loopInRepeatType || (typeof isOnRepeat === 'number' ? partId : isOnRepeat)

    const isPartNew = partId && partId !== currentPartId
    const isLoopNew = loop && loop !== isOnRepeat
    const isProgNew = progFromProps && JSON.stringify(progFromProps) !== JSON.stringify(prog)

    if (isPartNew || isLoopNew || isProgNew) setIsOnRepeat(loop || isOnRepeat, partId, progFromProps, setTime)
    if (isPartNew) setCurrentPartIdState(partId)
  }
  const setIsOnRepeat = (newValueRaw: RepeatType, newPartId = currentPartId, progFromProps = prog, setTime = true) => {
    const getValueData = (valueRaw: RepeatType, alternativePartId: number, prog: Prog) => {
      const value = valueRaw
      const partLoopedSolo = typeof value === 'number'

      const partId = partLoopedSolo ? value : alternativePartId
      const part = getActivePart(prog, partLoopedSolo ? value : partId)

      const partEmpty = part ? isPartEmpty(part) : true
      const partLoops = part ? part.loops : 1
      const partLooped = partLoops > 1
      const partLength = part ? getPartLengthInBars(prog, partLoopedSolo ? value : partId) : 1

      const partOffsetInBars = part ? getPartOffsetInBars(prog, part.id) : 0
      const partTimeInBars = player.getTransportPositionBars() % (partOffsetInBars || Infinity)

      return {
        value,
        partLooped,
        partLoopedSolo,
        partId,
        part,
        partEmpty,
        partLoops,
        partLength,
        partOffsetInBars,
        partTimeInBars,
      }
    }

    const {
      value: prevValue,
      partLoopedSolo: prevPartLoopedSolo,
      part: prevPart,
      partLoops: prevPartLoops,
      partTimeInBars: prevPartTimeInBarsRaw,
    } = getValueData(isOnRepeat, currentPartId, prog)
    const {
      value: newValue,
      partLooped: newPartLooped,
      partLoopedSolo: newPartLoopedSolo,
      part: newPart,
      partEmpty: newPartEmpty,
      partLoops: newPartLoops,
      partLength: newPartLength,
      partOffsetInBars: newPartOffsetInBars,
    } = getValueData(newValueRaw, newPartId, progFromProps)

    let prevPartTimeInBars = prevPartTimeInBarsRaw

    const isSamePart = prevPart && newPart && prevPart.id === newPart.id
    const wasSamePartLoopedSolo = prevPartLoopedSolo && newPart && prevValue === newPart.id

    // if loops decreased, need to reduce time
    if (isSamePart && prevPartLoops > newPartLoops) {
      const difference = prevPartLoops - newPartLoops

      if (prevPartTimeInBars < newPartLength) return
      prevPartTimeInBars -= difference * newPartLength
    }

    const newTimeInProg = newPartOffsetInBars + prevPartTimeInBars
    const newTimeInProgReset = 0
    const newTimeInPart = prevPartTimeInBars
    const newTimeInPartReset = newPartOffsetInBars

    let newIsOnRepeat = newValue
    let newTime = 0

    // newIsOnRepeat: if new loop value is 'loop # part solo' and this '# part' is empty => set loop value to 'whole prog'
    // newIsOnRepeat: else => set loop value to 'loop # part solo'
    // newTime: set to 0
    if (newPartLoopedSolo) {
      newIsOnRepeat = newPartEmpty ? 'whole' : newValue
      newTime = isSamePart ? newTimeInPart : newTimeInProgReset
    }

    // newIsOnRepeat: set to newValueRaw
    // newTime: if new loop value is NOT 'loop # part solo' and previous loop value was 'loop # part solo' (same part) => set time to be in same position but relative to whole prog
    if (!newPartLoopedSolo) {
      newIsOnRepeat = newValue
      newTime = wasSamePartLoopedSolo || (isSamePart && newPartLooped) ? newTimeInProg : newTimeInPartReset
    }

    if (newPartEmpty) player.pause()

    setIsOnRepeatState(newIsOnRepeat)
    player.repeat(newIsOnRepeat !== 'none')
    if (setTime) player.setTime(newTime)
  }
  const playControlsOnPlay = () => {
    onPlay()
  }
  const playControlsOnReset = () => {
    if (!isPartLooped) {
      setCurrentPartId(getFirstNonEmptyPart(prog)?.id || 1)
    }
    player.reset()
  }
  const playControlsOnRepeatChange = () => {
    let newOnRepeat
    if (isOnRepeat === 'none') {
      newOnRepeat = 'whole'
    } else if (isOnRepeat === 'whole' && (getActivePart(prog, currentPartId)?.chords?.length || 0) > 0) {
      newOnRepeat = 'part'
    } else {
      newOnRepeat = 'none'
    }
    setCurrentPartId(currentPartId, newOnRepeat as any)
  }

  // other
  const handleUnselectChord = (e: React.MouseEvent<HTMLDivElement>) => {
    // @ts-ignore
    if (e.target?.getAttribute('data-unselect-chord') !== 'true') return

    setActiveChordIds([])
  }
  const handleOpenLayersEditor = (layersOpen: InstrumentType | null) => {
    setView((v) => ({ ...v, editorOpen: false, layersOpen, drumsEditorOpen: false }))
  }
  const setProg = (prog: Prog | null, openNewPart = false) => {
    if (!prog) return

    const newPartId = Math.max(...prog.parts.map((part) => part.id))
    let newActivePartId = (openNewPart ? newPartId : currentPartId) || 1

    if (!prog?.parts.some((p) => p.id === newActivePartId)) {
      newActivePartId = prog?.parts[0]?.id as number
    }

    setCurrentPartId(newActivePartId, undefined, prog, false)
    setHistoryProg(prog)
  }
  const handleTimelineChange = async (s: TimelineState) => {
    if (isProgEmpty(prog)) {
      return
    }
    const newProg = updateChordsFromTimeline(s, prog as Prog, currentPartId)
    if (deepEqual(newProg, prog)) {
      return
    }
    const currPart = newProg.parts.find((p) => p.id === currentPartId)
    if (currPart?.chords?.length === 0) {
      const updatedProg = updateProgPartDataById(newProg, currentPartId, {
        drums: currentPart.drums ? { ...currentPart.drums, length: 4 } : null,
      })
      setProg(updatedProg)

      return
    }
    setProg(newProg)
  }
  const handleShowHideMelody = () => {
    const melodyShown = !currentPart.melodyShown
    const updates: any = { melodyShown }

    if (!melodyShown) {
      updates.melody = null
    }

    const newProg = updateProgPartDataById(prog, currentPartId, updates)
    setProg(newProg)

    if (melodyShown) trackMixpanelEvent_addTrack('melody')
  }
  const handleShowHideDrums = () => {
    const drumsShown = !currentPart.drumsShown
    const updates: any = { drumsShown }

    if (!drumsShown) {
      updates.drums = null
    }

    const newProg = updateProgPartDataById(prog, currentPartId, updates)
    setProg(newProg)

    if (drumsShown) trackMixpanelEvent_addTrack('drums')
  }
  const handleSaveChordVoicings = (value: Array<{ midi: number[]; name: string }>, midi?: number[]) => {
    if (!activeChord) return

    setProg(
      replaceChordByIdInProg(
        activeChord.id,
        prog,
        {
          ...activeChord,
          voicings: value,
          midi: midi || activeChord.midi,
        },
        currentPartId,
      ),
    )
  }
  const handleSaveChordMidi = (midi: number[]) => {
    if (!activeChord) return

    if (deepEqual(midi, activeChord.midi)) {
      player.playChord(prog, activeChord.id)
    } else {
      setProg(replaceChordByIdInProg(activeChord.id, prog, { ...activeChord, midi }, currentPartId))
    }
  }

  return (
    <PlayerConfigStateContext.Provider
      value={{
        playerConfig: {
          ...constants,
          iOSAudioContextFixRef,
          player,
          bpm,
          lyrics,
          view,
          isOnRepeat,
          isPartLooped,
          volume,
          tonalityKey,
          tonalityScale,
          isPlaying,
          isPreviewPlaying,
          activeChordIds,
          prevProg,
          prog,
          addChordMode,
          isMetronome,
          playingChord,
          currPartDrumsOpen,
          activeChord,
          isPartLoading,
          isProgLoading,
          isMelodyLoading,
          isDrumsPatternLoading,
          instrumentLoaded,
          instrumentMidiOut,
          melodyInstrumentLoaded,
          drumsInstrumentMidiOut,
          drumsInstrumentLoaded,
          isGenerateMelody,
          isGenerateDrums,
          webMidiPlaying,
          currentPartId,
          currentPart,
          requestPosition,
          instrumentLoadedRef,
          drumsInstrumentLoadedRef,
          editChordId,
          isPlayingBeforeManualScroll,
          progValueRef,
          mutedDrumTracks,
          isShareEnabled,
          loadingTile,
          chordGroups: chordGroups || [],
          chordGenre: chordGenre || getDefaultGenre(),
          drumGroups: drumGroups || [],
          drumGenre: drumGenre || getDefaultGenre(),
          previewChordId,
          previewChord,
          editTrigger,
        },
        playerConfigSetter: {
          setProg,
          setIsProgLoading,
          setIsMelodyLoading,
          setIsDrumsPatternLoading,
          setView: (newViewRaw = {}) => {
            const isHugeOpen = newViewRaw.drumsOpen || newViewRaw.drumsEditorOpen || newViewRaw.melodyEditorOpen
            const isBigOpen = newViewRaw.layersOpen || newViewRaw.editorOpen || newViewRaw.chordEditMode
            const isReset = Object.keys(newViewRaw).length === 0

            const newView = {
              ...(isBigOpen || isHugeOpen || isReset ? defaultView : view),
              ...(isHugeOpen || isReset
                ? { pianoOpen: false, guitarOpen: false, notationOpen: false }
                : { pianoOpen: view.pianoOpen, guitarOpen: view.guitarOpen, notationOpen: view.notationOpen }),
              ...newViewRaw,
            }

            const isSmallOpen = newView.pianoOpen || newView.guitarOpen || newView.notationOpen

            if (isSmallOpen) {
              newView.drumsOpen = false
              newView.drumsEditorOpen = false
            }
            if (isSmallOpen && !isMobile) {
              newView.melodyEditorOpen = false
            }

            const isChordsOpenOld = view.editorOpen
            const isChordsOpenNew = newView.editorOpen

            const chordEditorModeOld = view.chordEditMode
            const chordEditorModeNew = newView.chordEditMode

            const isMelodyOpenOld = view.melodyEditorOpen
            const isMelodyOpenNew = newView.melodyEditorOpen

            const isDrumOpenOld = view.drumsOpen && view.drumsEditorOpen
            const isDrumOpenNew = newView.drumsOpen && newView.drumsEditorOpen

            const isLayersOpenOld = view.layersOpen
            const isLayersOpenNew = newView.layersOpen

            if (
              (isChordsOpenOld && isChordsOpenNew && chordEditorModeOld === chordEditorModeNew) ||
              (isMelodyOpenOld && isMelodyOpenNew) ||
              (isDrumOpenOld && isDrumOpenNew) ||
              (isLayersOpenOld && isLayersOpenNew && isLayersOpenOld === isLayersOpenNew)
            ) {
              setEditTrigger(Math.random())
            }

            setView(newView)
          },
          setLyrics,
          setBpm,
          setIsOnRepeat,
          setVolume,
          setTonalityKey,
          setTonalityScale,
          setIsPlaying,
          setIsPreviewPlaying,
          setActiveChordIds,
          setInstrumentLoaded,
          setInstrumentMidiOut,
          setMelodyInstrumentLoaded,
          setDrumsInstrumentMidiOut,
          setPattern,
          setDrumsInstrumentLoaded,
          setMetronome,
          setIsGenerateMelody,
          setIsGenerateDrums,
          setCurrentPartId,
          setMelodyPianorollNotes,
          setMelodyPattern,
          setMelodyWidth,
          setDrumWidth,
          setStartAfterGenerate,
          onPlay,
          saveProject,
          initProject: (project: Project, fromPreview?: boolean) => {
            const { id, prog, lyrics, view } = project

            setProg(prog)
            setLyrics(lyrics || defaultLyrics)
            setView(view || defaultView)

            setCurrentPartId(prog.parts[0]?.id)

            projectStateConfig.setIsProjectSetuped(true)
            if (id && !fromPreview) {
              projectStateConfig.setEditedProjectId(id)
            }
          },
          handleGenerateChords,
          handleGenerateMelody,
          handleGenerateDrums,
          setEditChordId,
          setIsPlayingBeforeManualScroll,
          playControlsOnPlay,
          playControlsOnRepeatChange,
          setLoadingTile,
          handleUnselectChord,
          setMutedDrumTracks,
          setDefaultDrumPattern,
          setIsShareEnabled,
          playControlsOnReset,
          setAddChordMode,
          handleOpenLayersEditor,
          handleTimelineChange,
          handleShowHideMelody,
          handleShowHideDrums,
          handleSaveChordVoicings,
          handleSaveChordMidi,
          setPreviewChordId,
          setPreviewChord,
        },
      }}
    >
      {children}
    </PlayerConfigStateContext.Provider>
  )
}

export const usePlayerConfigState = () => React.useContext<FetchType>(PlayerConfigStateContext)

export type PlayerConfig = {
  iOSAudioContextFixRef: LegacyRef<HTMLAudioElement>
  player: Player
  bpm: number
  isOnRepeat: RepeatType
  isPartLooped: boolean
  isMetronome: boolean
  volume: number
  currPartDrumsOpen: boolean
  view: ViewConfig
  tonalityKey?: string
  tonalityScale?: string
  currentPartId: number
  addChordMode: number | null
  currentPart: ProgPart
  webMidiPlaying: Array<number>
  isGenerateMelody: boolean
  isGenerateDrums: boolean
  isPartLoading: boolean
  isProgLoading: boolean
  isMelodyLoading: boolean
  isDrumsPatternLoading: boolean
  instrumentLoaded: boolean
  instrumentMidiOut: { [key: string]: string }
  melodyInstrumentLoaded: boolean
  drumsInstrumentMidiOut: any
  drumsInstrumentLoaded: boolean
  isPlaying: boolean
  isPreviewPlaying: boolean
  activeChordIds: number[]
  prevProg: Prog | null
  prog: Prog | null
  activeChord?: Chord | null
  playingChord?: Chord | null
  tonalityKeys: Array<{ key: string }>
  tonalityScales: Array<{ name: string; scale: string }>
  lyrics: Lyrics
  requestPosition: () => {
    time: number
    loop: number
    partDuration: number
    currentPart: number
  }
  instrumentLoadedRef: any
  drumsInstrumentLoadedRef: any
  editChordId: {
    id: number | null
    center: number | null
  }
  isPlayingBeforeManualScroll: boolean
  progValueRef: any
  mutedDrumTracks: string[]
  isShareEnabled: boolean
  loadingTile: null | number
  chordGroups: TGenreGroup[]
  chordGenre: TGenreShort
  drumGroups: TGenreGroup[]
  drumGenre: TGenre
  previewChordId: number | null
  previewChord: Chord | null
  editTrigger: number
}

export type PlayerConfigSetter = {
  setProg: (a: Prog | null, openNewPart?: boolean) => void
  setMetronome: (a: boolean) => void
  setBpm: (a: number) => void
  setIsOnRepeat: (a: RepeatType) => void
  setIsProgLoading: (a: boolean) => void
  setIsMelodyLoading: (a: boolean) => void
  setIsDrumsPatternLoading: (a: boolean) => void
  setVolume: (a: number) => void
  setCurrentPartId: (a?: number, loop?: 'none' | 'whole' | 'part', progFromProps?: Prog) => void
  setTonalityKey: (a: string) => void
  setTonalityScale: (a: string) => void
  setView: (a?: Partial<ViewConfig>) => void
  setIsPlaying: (a: boolean) => void
  setIsPreviewPlaying: (a: boolean) => void
  setInstrumentLoaded: (a: boolean) => void
  setMelodyInstrumentLoaded: (a: boolean) => void
  setInstrumentMidiOut: (a: { [key: string]: string }) => void
  setDrumsInstrumentMidiOut: (a: any) => void
  setDrumsInstrumentLoaded: (a: boolean) => void
  setAddChordMode: (a: number | null) => void
  setActiveChordIds: (a: number[] | ((v: number[]) => number[])) => void
  setMelodyWidth: (a?: number) => void
  setDrumWidth: (a?: number) => void
  setStartAfterGenerate: (a: boolean) => void
  setPattern: (pattern?: ProgPartPattern, newProg?: Prog, partId?: number, styleFromProps?: TGenre) => void
  setMelodyPianorollNotes: (notes?: PianoRollNote[]) => void
  setMelodyPattern: (pattern?: MelodyPattern, newProg?: Prog, partId?: number, styleFromProps?: TGenre) => void
  setIsGenerateMelody: (a: boolean) => void
  setIsGenerateDrums: (a: boolean) => void
  onPlay: (prog?: Prog) => void
  saveProject: (name: string) => Promise<string>
  setLyrics: (lyrics: Lyrics) => void
  initProject: (p: Project, fromPreview?: boolean) => void
  handleGenerateDrums: (prog: Prog | null, genre: string, sameTempo: boolean, tempo: string | number) => Promise<void>
  handleGenerateMelody: (prog?: Prog | null) => Promise<void>
  handleGenerateChords: (
    prog: Prog,
    genre?: string,
    sameTempo?: boolean,
    tempo?: string | number,
    scale?: string,
  ) => Promise<void>
  setEditChordId: (v: { id: number | null; center: number | null }) => void
  setIsPlayingBeforeManualScroll: (v: boolean) => void
  playControlsOnPlay: () => void
  playControlsOnRepeatChange: () => void
  setLoadingTile: (a: number | null) => void
  handleUnselectChord: (e: React.MouseEvent<HTMLDivElement>) => void
  setMutedDrumTracks: (v: string[] | ((v: string[]) => string[])) => void
  setDefaultDrumPattern: (pattern?: ProgPartPattern) => void
  setIsShareEnabled: (v: boolean) => void
  playControlsOnReset: () => void
  handleOpenLayersEditor: (v: InstrumentType | null) => void
  handleTimelineChange: (v: TimelineState) => void
  handleShowHideMelody: () => void
  handleShowHideDrums: () => void
  handleSaveChordVoicings: (value: Array<{ midi: number[]; name: string }>, midi?: number[]) => void
  handleSaveChordMidi: (midi: number[]) => void
  setPreviewChordId: (v: number | null) => void
  setPreviewChord: (v: Chord | null) => void
}
