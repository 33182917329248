import axios from 'axios'
import * as Tone from 'tone'
import * as wm from 'webmidi'

import { getPlaystyleInnerRoute } from '../../../../api/constants'
import {
  convertNoteToToneJS,
  denormalizeVolume,
  getPartDuration,
  getPartTracksStatus,
  normalizeVelocity,
  normalizeVolume,
  parseSampleBuffers,
  sumTwoBarsBeatsSixteenthsNoTone,
  tileLengthToNoteDuration,
} from '../../../../utils/audio/audioUtils'
import { S3_URL } from '../../../../utils/constants'
import { TICKS_PER_BAR } from '../../../../utils/drumsUtils'
import {
  getInstrumentKey,
  getInstrumentKeyFromLayerKey,
  getPartIdFromInstrumentKey,
} from '../../../../utils/instrumentsUtils'
import { melodyToTempo } from '../../../../utils/melodyUtils'
import { getActivePart, getPartsForEachLoopArr } from '../../../../utils/progUtils'
import { sleep } from '../../../../utils/stringUtils'
import { InstrumentLayer, MelodyPattern, Prog } from '../../../../utils/types'
import { PianoRollNote } from '../../../ai-playground/InteractivePianoRoll/types'
import { getMidiPort } from '../../TimelineWrapper/InstrumentMenu/constants'
import { TSampler, TSamplerData, TSamplers } from './Layers'
import MidiOutputPlayer from './MidiOutputPlayer'
import { Player } from './PlayerCommon'

class MelodyPlayer extends Player {
  samplers: TSamplers = {}

  synthData: TSamplerData = {}

  getMidiOutput: (port: string, key: string) => MidiOutputPlayer

  constructor(getMidiOutput: (port: string, key: string) => any, equalizerVolume: number) {
    super(normalizeVolume, denormalizeVolume, equalizerVolume)

    this.getMidiOutput = getMidiOutput
  }

  // SERVE FUNCTIONS

  getNotesFromMidi = (prog: Prog, loopedPart?: number) => {
    const parts = getPartsForEachLoopArr(prog, loopedPart)
    const allNotes: Array<any> = []
    let totalDuration = '0:0:0'

    for (const part of parts) {
      if (!part) continue

      const { isChordsEmpty, isMelodyEmpty } = getPartTracksStatus(part)

      const partId = isChordsEmpty && !isMelodyEmpty ? part.id : undefined

      const { duration, melodyDuration } = getPartDuration(part)
      const { allNotes: notes, totalDuration: dur } = this.convertPattern(
        melodyToTempo(part.melody),
        melodyDuration,
        totalDuration,
        duration,
      )
      const instrumentKeys = part.melodyLayers.map((layer, index) => getInstrumentKey(layer.instrument, index, part.id))

      allNotes.push({ time: totalDuration, partId }) // timing to change part id
      allNotes.push(...notes.map((n) => ({ ...n, partId, instrumentKeys })))

      totalDuration = sumTwoBarsBeatsSixteenthsNoTone(totalDuration, dur)
    }

    return { allNotes, duration: totalDuration }
  }
  convertPattern = (melody: MelodyPattern, melodyDuration: string, offset = '0:0:0', partDuration = '') => {
    const allNotes: Array<any> = []

    let totalDuration = '0:0:0'

    melody?.notes?.forEach((n) => {
      const noteTime = tileLengthToNoteDuration(n.startTicks / TICKS_PER_BAR)
      const noteDuration = tileLengthToNoteDuration((n.endTicks - n.startTicks) / TICKS_PER_BAR)

      const noteTimeWithOffset = sumTwoBarsBeatsSixteenthsNoTone(offset, noteTime)
      const melodyDurationWithOffset = sumTwoBarsBeatsSixteenthsNoTone(offset, melodyDuration)

      if (Tone.Time(noteTimeWithOffset).toSeconds() >= Tone.Time(melodyDurationWithOffset).toSeconds()) return

      allNotes.push({
        ...n,
        note: convertNoteToToneJS(n.midiNote).toNote(),
        time: noteTimeWithOffset,
        midi: n.midiNote,
        duration: noteDuration,
        track: n.midiNote.toString(),
        velocity: normalizeVelocity(n.velocity),
        instrumentKeys: [],
      })
    })

    totalDuration = sumTwoBarsBeatsSixteenthsNoTone(totalDuration, partDuration || melodyDuration)
    return { totalDuration, allNotes }
  }
  updateSamplers = (samplers: TSamplers, func: any) => {
    Object.keys(samplers).map((samplerKey) => func(samplers[samplerKey]))
  }
  generateSamplerData = async (
    layers: InstrumentLayer[],
    customConfig: any = null,
    initialData: TSamplerData = {},
    prog?: Prog | null,
  ) => {
    const data: TSamplerData = initialData
    const loader: { [key: string]: boolean } = {}

    const { dataToRemove, dataToSetup } = this.defineLayerToRemoveToSetup(data, { arr: layers })
    const layersToSetup = layers.filter((l) => dataToSetup.includes(l.instrument.key))

    dataToRemove.forEach((key) => {
      data[key].buffer.dispose()
      delete data[key]
    })

    for (const layer of layersToSetup) {
      this.setShouldResetup(!!prog)

      const key = layer.instrument.key
      loader[key] = false

      axios.get(`${S3_URL}/${layer.instrument.path}instrument.json`).then((configData) => {
        const config = {
          ...configData.data,
          ...(customConfig || {}),
          octaveShift: layer.octave,
          volume: this.normalizeVolume(layer.muted ? 0 : layer.volume),
        }

        const instrumentData = this.instrumentsData.find((iD) => iD.key === getInstrumentKeyFromLayerKey(key))
        if (instrumentData) {
          config.octaveShift += instrumentData.config.octaveShift || 0

          const layerVolumeEqualizer = (instrumentData.config.volume || 10) / 10
          const volume = this.normalizeVolume(this.denormalizeVolume(config.volume) * layerVolumeEqualizer)

          config.volume = volume
          this.layersVolumeEqualizer[key] = layerVolumeEqualizer
        }

        new Tone.Buffer(`${S3_URL}/${layer.instrument.path}instrument.mp3`, async (buffer) => {
          setTimeout(() => {
            data[key] = { config, buffer }
            loader[key] = true
          }, 100)
        })
      })
    }

    if (prog) {
      const { playstylesToSetup, playstylesToRemove } = this.definePlaystylesToRemoveToSetup(this.playstyles, layers)

      playstylesToRemove.forEach((key) => delete this.playstyles[key])

      for (const playstyle of playstylesToSetup) {
        this.shouldResetup = true
        loader[playstyle] = false

        getPlaystyleInnerRoute(playstyle).then((res) => {
          this.playstyles[playstyle] = res
          loader[playstyle] = true
        })
      }
    }

    do {
      await sleep(200)
    } while (Object.values(loader).some((v) => v === false))

    // START: setup parts volume
    if (prog) {
      const oldPartIds = Object.keys(this.partsVolume).map((id) => +id)
      const newPartIds = Array.from(new Set(Object.keys(data).map((key) => getPartIdFromInstrumentKey(key))))

      oldPartIds.filter((id) => id && !newPartIds.includes(id)).forEach((id) => delete this.partsVolume[id])
      newPartIds
        .filter((id) => id && !oldPartIds.includes(id))
        .forEach((id) => {
          const part = getActivePart(prog, id)

          if (part && this.progVolume) {
            this.partsVolume[id] = this.initVolume(part.melodyMuted ? 0 : part.melodyVolume, this.progVolume)
          }
        })
    }
    // END

    return data
  }
  triggerAttackRelease = (
    samplers: TSamplers,
    instrumentKeys: string[],
    note: string,
    duration: any,
    time?: any,
    velocity?: any,
  ) => {
    Object.entries(samplers)
      .filter(([key]) => instrumentKeys.includes(key))
      .forEach(([key, sampler]) => {
        sampler?.triggerAttackRelease(note, duration, time, velocity)
      })
  }

  // MAIN FUNCTIONS

  // SETUP INSTRUMENTS
  init = async (prog: Prog, volume: Tone.Volume = new Tone.Volume(normalizeVolume(100))) => {
    this.progVolume = volume

    prog.parts.forEach((part) => {
      if (this.progVolume) {
        this.partsVolume[part.id]?.dispose()
        this.partsVolume[part.id] = this.initVolume(part.melodyMuted ? 0 : part.melodyVolume, this.progVolume)
      }
    })
  }
  setupSynth = async (prog: Prog | null, melodyLayers: InstrumentLayer[]) => {
    const prevSynthData = JSON.parse(JSON.stringify(this.synthData))

    const data: TSamplerData = await this.generateSamplerData(melodyLayers, undefined, this.synthData, prog)

    const oldOctaves = Object.keys(prevSynthData)
      .sort()
      .map((key) => prevSynthData[key].config.octaveShift)
      .join('-')
    const newOctaves = Object.keys(data)
      .sort()
      .map((key) => data[key].config.octaveShift)
      .join('')
    if (
      oldOctaves === newOctaves &&
      JSON.stringify(Object.keys(prevSynthData).sort()) === JSON.stringify(Object.keys(data).sort())
    )
      return this.setShouldResetup(true, false)

    this.synthData = data

    this.createSynth(this.samplers, data)
    this.setupChordLayersVolume(data)
    this.setupSamplersVolume(this.samplers)

    return true
  }
  createSynth = (samplers: TSamplers, data: TSamplerData, instrumentMidiOut: { [key: string]: string } = {}) => {
    const { dataToRemove, dataToSetup } = this.defineLayerToRemoveToSetup(samplers, { obj: data }, instrumentMidiOut)
    const samplersToSetup = Object.entries(data).filter(([key]) => dataToSetup.includes(key))
    dataToRemove.forEach((key) => {
      samplers[key]?.releaseAll()
      samplers[key]?.disconnect()
      samplers[key]?.dispose()
      delete samplers[key]
    })

    samplersToSetup.map(([key, { config, buffer }]) => {
      if (key in instrumentMidiOut && instrumentMidiOut[key]) {
        wm.WebMidi.enable().then(() => {
          setTimeout(() => {
            samplers[key] = this.getMidiOutput(getMidiPort(instrumentMidiOut[key]), key)
          }, 100)
        })
      } else {
        const sampleBuffers = parseSampleBuffers(config, buffer)

        samplers[key] = new Tone.Sampler(sampleBuffers, {
          attack: config.envelope.attack,
          release: config.envelope.release,
        })
        // @ts-ignore
        samplers[key].config = { octaveShift: config.octaveShift }
      }
    })
  }

  // SETUP PLAYBACK
  setupProgPlayback = (prog: Prog, loopedPartId?: number) => {
    const { allNotes, duration } = this.getNotesFromMidi(prog, loopedPartId)

    this.dispose()
    this.part = new Tone.Part((time, obj) => {
      if (obj.partId) this.handleChange(obj.partId)
      if (!obj.note) return
      this.triggerAttackRelease(this.samplers, obj.instrumentKeys, obj.note, obj.duration, time, obj.velocity)
    }, allNotes)

    this.part.loopStart = '0:0:0'
    this.part.loopEnd = duration
  }
  prepareExportPlay = (prog: Prog, loops: number, layerKey?: string) => {
    const offlineSynthSamplers: TSamplers = {}
    const volumeRefs: Tone.Volume[] = []

    return {
      onPlay: () => {
        const { allNotes, duration } = this.getNotesFromMidi(prog)

        this.createSynth(offlineSynthSamplers, this.synthData)
        const part = new Tone.Part((time, obj) => {
          if (!obj.note) return

          this.triggerAttackRelease(
            offlineSynthSamplers,
            layerKey ? [layerKey] : obj.instrumentKeys,
            obj.note,
            obj.duration,
            time,
            obj.velocity,
          )
        }, allNotes)
        part.loop = loops
        part.loopStart = '0:0:0'
        part.loopEnd = duration
        part.start()

        Object.keys(offlineSynthSamplers).forEach((key) => {
          const synthKey = layerKey || key

          const { volume, refs } = this.initExportVolume(synthKey)
          volumeRefs.push(...refs)

          if (volume) offlineSynthSamplers[key]?.connect(volume)
        })
      },
      onFinish: () => {
        this.updateSamplers(offlineSynthSamplers, (s: TSampler) => s?.dispose())

        volumeRefs.forEach((volumeRef) => {
          volumeRef.disconnect()
          volumeRef.dispose()
        })
      },
    }
  }
  updatePart = async (prog: Prog, loopedPartId?: number) => {
    const { allNotes } = this.getNotesFromMidi(prog, loopedPartId)

    this.part?.clear()
    allNotes?.forEach((n: any) => {
      this.part?.add(n)
    })
  }

  // PLAYING
  playNote = (pianoRollNote: PianoRollNote, partId: number) => {
    const instrumentKeys = Object.keys(this.samplers).filter((key) => key.endsWith(`-${partId}`))
    const note = convertNoteToToneJS(pianoRollNote.midiNote).toNote()
    const velocity = normalizeVelocity(pianoRollNote.velocity)

    this.triggerAttackRelease(this.samplers, instrumentKeys, note, '0:0:1', undefined, velocity)
  }
}

export default MelodyPlayer
