import { Chord, Instrument } from '../utils/types'
import api from './index'

export const getChordTypes = () => {
  return api.get(`${process.env.API_URL}/chord/types`)
}

export const getGenresInnerRoute = async () => {
  const data = await api.get('/api/genres')
  return data.data
}

export const getDiatonicChords = ({
  key,
  octave,
  scaleType,
  diatonicChordTypes,
}: {
  key: string
  octave: string
  scaleType: string
  diatonicChordTypes: string[]
}) => {
  return api.get(`${process.env.API_URL}/chord/diatonic`, { params: { key, octave, scaleType, diatonicChordTypes } })
}

export const getSecondaryChords = ({
  key,
  octave,
  scaleType,
  diatonicChordTypes,
}: {
  key: string
  octave: string
  scaleType: string
  diatonicChordTypes: string[]
}) => {
  return api.get(`${process.env.API_URL}/chord/secondary`, { params: { key, octave, scaleType, diatonicChordTypes } })
}

export const getChordGenreGroupsInnerRoute = async () => {
  const data = await api.get('/api/genres/chord/by-group')
  return data.data
}
export const getDrumGenreGroupsInnerRoute = async () => {
  const data = await api.get('/api/genres/drum/by-group')
  return data.data
}
export const getDrumGenreInnerRoute = async (drumGenreKey: string) => {
  const data = await api.get('/api/genres/drum', { params: { drumGenreKey } })
  return data.data
}

export const getTonalityKeys = async (scale?: string | string[]) => {
  const data = await api.get(`${process.env.API_URL}/scale/start-notes`, {
    params: { scale },
  })
  return data.data
}

export const getTonalityKeysInnerRoute = (scale: string) => async () => {
  const data = await api.get('/api/tonality-keys', { params: { scale } })
  return data.data
}

export const getTonalityScalesInnerRoute = async () => {
  const data = await api.get('/api/tonality-scales')
  return data.data
}

export const getChordTypesInnerRoute = async () => {
  const data = await api.get('/api/chord-types')
  return data.data
}

export const getDiatonicChordsInnerRoute = async ({
  key,
  octave,
  scale,
  diatonicChordTypes,
}: {
  key: string
  octave: string
  scale: string
  diatonicChordTypes: string[]
}) => {
  const data = await api.get('/api/chord-types/diatonic', { params: { key, octave, scale, diatonicChordTypes } })
  return [
    ...data.data[diatonicChordTypes[0]],
    ...data.data[diatonicChordTypes[1]],
    ...data.data[diatonicChordTypes[2]],
  ].map((ch: any) => ({
    ...ch,
    settings: {
      velocity: 100,
    },
  })) as Chord[]
}

export const getSecondaryChordsInnerRoute = async ({
  key,
  octave,
  scale,
  diatonicChordTypes,
}: {
  key: string
  octave: string
  scale: string
  diatonicChordTypes: string[]
}) => {
  const data = await api.get('/api/chord-types/secondary', { params: { key, octave, scale, diatonicChordTypes } })
  return Object.values(data.data)
    .flat()
    .map((ch: any) => ({
      ...ch,
      settings: {
        velocity: 100,
      },
    })) as Chord[]
}

export const getDrumsPatterns = () => {
  return api.get(`${process.env.S3_URL}/drum-patterns-meta.json`)
}

export const getInstrumentsInnerRoute = async () => {
  const data = await api.get('/api/instruments')
  return Object.keys(data.data)
    .map((name: string) => ({ name, ...data.data[name] }))
    .filter((instrument) => instrument.published)
}

export const getPlaystylesInnerRoute = async () => {
  const data = await api.get('/api/playstyles')
  return data.data
}

export const getPlaystyleInnerRoute = async (id: string) => {
  const data = await api.get(`/api/playstyles/${id}`)
  return data.data
}

export const getInstrumentsCanvasInnerRoute = (isTest?: boolean) => async () => {
  const response = await api.get('/api/instruments/canvas', { params: { isTest } })
  return response.data as Instrument[]
}

export const getDrumsInnerRoute = async () => {
  const data = await api.get('/api/drums')
  return Object.keys(data.data).map((name: string) => ({
    name,
    ...data.data[name],
    key: data.data[name].key.replace(/^\//, ''),
  }))
}
