import { InteractivePianoRollContextType } from '../types'

const handleTranspose12SemitonesUp = (context: InteractivePianoRollContextType) => {
  const { setIsContextMenuOpen, selectedNoteIds, updateNotes, getNotesByIds } = context
  setIsContextMenuOpen(false)

  const notes = getNotesByIds(Array.from(selectedNoteIds))
  updateNotes(notes.map((note) => ({ noteId: note.id, updatedFields: { midiNote: note.midiNote + 12 } })))
}

const handleTranspose12SemitonesDown = (context: InteractivePianoRollContextType) => {
  const { setIsContextMenuOpen, selectedNoteIds, updateNotes, getNotesByIds } = context
  setIsContextMenuOpen(false)

  const notes = getNotesByIds(Array.from(selectedNoteIds))
  updateNotes(notes.map((note) => ({ noteId: note.id, updatedFields: { midiNote: note.midiNote - 12 } })))
}

export { handleTranspose12SemitonesUp, handleTranspose12SemitonesDown }
