// utils/pianoRollUtils.ts
import { PianoRollNote } from '../types'

// Helper function to generate unique string-based IDs
export const generateUniqueId = () => `note-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`

export const zoomLevelToZoomFactor = (zoomLevel: number) => {
  return zoomLevel / 2
}

export const zoomLevelToPpqSnap = (zoomLevel: number, ppqSnapStages: { maxZoom: number; snap: number }[]): number => {
  const stage = ppqSnapStages.find((stage) => zoomLevel <= stage.maxZoom)
  return stage?.snap || ppqSnapStages[ppqSnapStages.length - 1].snap
}

export const ticksToWidth = (ticks: number, zoomFactor: number, baseGridWidth: number, PPQ: number) => {
  const beats = ticks / PPQ
  const sixteenths = beats * 4
  return sixteenths * baseGridWidth * zoomFactor
}

export const widthToTicks = (width: number, zoomFactor: number, baseGridWidth: number, PPQ: number) => {
  const sixteenths = width / (baseGridWidth * zoomFactor)
  const beats = sixteenths / 4
  return Math.floor(beats * PPQ)
}

export const pianoRollToJson = (pianoRollNotes: PianoRollNote[]) => {
  return pianoRollNotes.map((note) => ({
    midiNote: note.midiNote,
    startTicks: note.startTicks,
    endTicks: note.endTicks,
    velocity: note.velocity,
  }))
}

export const hexToRgb = (hex: string): string => {
  const bigint = parseInt(hex.replace('#', ''), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return `${r}, ${g}, ${b}`
}

const MIDI_NOTE_NAMES = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']

export const noteMidiToNoteName = (midiNote: number, withNumber = false) => {
  const noteName = MIDI_NOTE_NAMES[midiNote % 12]
  return withNumber ? `${noteName}${Math.floor(midiNote / 12)}` : noteName
}
