import React, { FC, useEffect, useMemo, useState } from 'react'

import InfoIcon from '../../../assets/icons/question-stroke.svg'
import useSizes from '../../../hooks/useSizes'
import { trackMixpanelEvent_openMelodyPianoRoll } from '../../../utils/tracking'
import InteractivePianoRoll from '../../ai-playground/InteractivePianoRoll/InteractivePianoRoll'
import { useInteractivePianoRoll } from '../../ai-playground/InteractivePianoRoll/hooks/useInteractivePianoRoll'
import { PianoRollNote } from '../../ai-playground/InteractivePianoRoll/types'
import Switch from '../../common/Switch'
import Tooltip from '../../common/Tooltip/Tooltip'
import { INSTRUMENT_TYPES } from '../LayersOfInstruments/LayersOfInstruments'
import EditorPianoRollSetup from '../common/EditorPianoRollSetup/EditorPianoRollSetup'
import CursorSetting from '../common/EditorSettings/CursorSetting'
import DurationSetting from '../common/EditorSettings/DurationSetting'
import InstrumentSetting from '../common/EditorSettings/InstrumentSetting'
import StretchSetting from '../common/EditorSettings/StretchSetting'
import VelocitySetting from '../common/EditorSettings/VelocitySetting'
import VolumeSetting from '../common/EditorSettings/VolumeSetting'
import styles from './MelodyEditor.module.scss'

type Props = object

const STRINGS = {
  showChords: 'Show Chords',
  showChordsTooltip: 'Highlight the notes of your chord progression in the Piano Roll.',
}

export const melodyPianoRollId = 'melody-piano-roll-id'

const MelodyEditor: FC<Props> = () => {
  const { isMobile } = useSizes()
  const {
    selectedNoteIds,
    cursorMode,
    noteLengthTicks,
    isSmartScaleEnabled,
    isRulerLabelsVisible,
    setIsRulerLabelsVisible,
    setCursorMode,
    setNoteLengthTicks,
    setSmartScaleEnabled,
  } = useInteractivePianoRoll(melodyPianoRollId)

  const [velocity, setVelocity] = useState(0)
  const [_, setSelectedNotes] = useState<PianoRollNote[]>([])

  const notesSelected = useMemo(() => Array.from(selectedNoteIds).length, [selectedNoteIds])
  const noSelectedNotes = !notesSelected

  useEffect(() => {
    trackMixpanelEvent_openMelodyPianoRoll()
  }, [])

  const renderShowChordsSetting = () => {
    return (
      <div className={styles.showChordsContainer}>
        <div className={styles.showChordsInnerContainer}>
          <span>{STRINGS.showChords}</span>

          <Switch
            value={isSmartScaleEnabled && isRulerLabelsVisible}
            onChange={() => {
              setSmartScaleEnabled((v) => {
                const newV = !v

                setIsRulerLabelsVisible(newV)
                return newV
              })
            }}
            className={styles.showChordsSwitch}
            markerClassName={styles.showChordsSwitchMarker}
            activeClassName={styles.showChordsSwitchActive}
          />
        </div>

        <InfoIcon className={styles.showChordsInfo} data-tooltip-id='show-chords-tooltip' />
        <Tooltip id='show-chords-tooltip'>
          <>{STRINGS.showChordsTooltip}</>
        </Tooltip>
      </div>
    )
  }

  if (isMobile) {
    return (
      <div className={styles.container}>
        <div className={styles.settings}>
          <VolumeSetting mode={INSTRUMENT_TYPES.MELODY} />

          <InstrumentSetting mode={INSTRUMENT_TYPES.MELODY} hideArrows small />
        </div>
      </div>
    )
  }
  return (
    <>
      <EditorPianoRollSetup
        mode={INSTRUMENT_TYPES.MELODY}
        setVelocity={setVelocity}
        setSelectedNotes={setSelectedNotes}
      />

      <div className={styles.container}>
        <div className={styles.settings}>
          {renderShowChordsSetting()}

          <InstrumentSetting mode={INSTRUMENT_TYPES.MELODY} hideArrows small />

          <CursorSetting cursorMode={cursorMode} setCursorMode={setCursorMode} />

          <DurationSetting noteDuration={noteLengthTicks} setNoteDuration={setNoteLengthTicks} />

          <VelocitySetting
            velocity={velocity}
            setVelocity={setVelocity}
            setSelectedNotes={setSelectedNotes}
            mode={INSTRUMENT_TYPES.MELODY}
            disabled={noSelectedNotes}
          />

          <StretchSetting mode={INSTRUMENT_TYPES.MELODY} disabled={noSelectedNotes} />
        </div>

        <div className={styles.divider} />

        <div className={styles.content}>
          {/* <div className={styles.contentHeader}>
            <div />
          </div> */}

          <div className={styles.contentTabContent}>
            <InteractivePianoRoll id={melodyPianoRollId} />
          </div>
        </div>
      </div>
    </>
  )
}

export default MelodyEditor
