import { UniqueIdentifier } from '@dnd-kit/core'

export const getElementFromTree = (
  e: any,
  selector: (e: Element | HTMLElement | null | undefined) => boolean,
): Element | HTMLElement | null | undefined => {
  let tileElement: Element | HTMLElement | null | undefined = e.target
  let counter = 0
  while (!selector(tileElement) && counter < 10) {
    tileElement = tileElement?.parentElement
    if (!tileElement) {
      return null
    }
    counter += 1
  }
  return counter === 10 ? null : tileElement
}

export const getTimelineWidthChanges = (
  tiles: UniqueIdentifier[],
  index: number,
  prevChanges: { [key: UniqueIdentifier]: number | null },
  delta: number | null,
  resizeSide: 'left' | 'right' | null,
) => {
  if (!delta) return {}

  const res = {
    ...prevChanges,
    [tiles[index]]: delta,
    ...(resizeSide
      ? {
          [tiles[index + (resizeSide === 'left' ? -1 : 1)]]: -delta,
        }
      : {}),
  }

  Object.keys(res).forEach((key: any) => {
    if (Math.abs(res[key] || 0) !== Math.abs(delta)) delete res[key]
  })

  return res
}
