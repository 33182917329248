import classNames from 'classnames'
import React from 'react'
import { createPortal } from 'react-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import styles from './Tooltip.module.scss'

type Props = {
  id: string
  text?: string
  className?: string
  place?: 'top' | 'right' | 'bottom' | 'left'
  primary?: boolean
  children?: JSX.Element
  delayShow?: number
  delayHide?: number
  clickable?: boolean
  openOnClick?: boolean
  isOpen?: boolean
  noArrow?: boolean
  closeOnEsc?: boolean
  onOpen?: (v: boolean) => void
}

const Tooltip: React.FC<Props> = ({
  delayShow,
  delayHide,
  id,
  primary,
  className,
  children,
  text,
  place = 'top',
  clickable,
  openOnClick,
  isOpen,
  noArrow,
  closeOnEsc,
  onOpen = () => {},
}) => {
  if (!process.browser) {
    return null
  }
  return (
    <>
      {createPortal(
        <ReactTooltip
          delayShow={delayShow || 350}
          delayHide={delayHide}
          id={id}
          place={place}
          content={text}
          clickable={clickable}
          openOnClick={openOnClick}
          isOpen={isOpen}
          noArrow={noArrow}
          closeOnEsc={closeOnEsc}
          className={classNames(styles.container, className, {
            [styles.primary]: primary,
          })}
          setIsOpen={onOpen}
        >
          {children || null}
        </ReactTooltip>,
        document.getElementById('__next') as HTMLElement,
      )}
    </>
  )
}

export default Tooltip
