import api from '.'

// Define a type for the notes if not already defined
type CompressedPianoRollNote = {
  midiNote: number
  startTicks: number
  endTicks: number
  velocity: number
}

type CompressedDrumRollNote = {
  type: string
  startTicks: number
  endTicks: number
  velocity: number
}

export const inferenceInnerRoute = async (model: string, bpm: number, pianoRollNotes: any, params: any) => {
  const data = await api.post('/api/ai-playground/inference', {
    pianoRollNotes,
    bpm,
    model,
    params,
  })
  return data.data
}

export const melodyAutocompleteInnerRoute = async (
  pianoRollNotes: any,
  prog: any,
  tonalityKey: string,
  tonalityScale: string,
  genre: string,
  signal: AbortSignal,
) => {
  const simplePianoRollNotes = pianoRollNotes.map((note: CompressedPianoRollNote) => ({
    midiNote: note.midiNote,
    startTicks: note.startTicks,
    endTicks: note.endTicks,
    velocity: note.velocity,
  }))

  try {
    const data = await api.post(
      '/api/ai-playground/melody-autocomplete',
      {
        pianoRollNotes: simplePianoRollNotes,
        prog,
        tonalityKey,
        tonalityScale,
        genre,
      },
      { signal },
    )
    return data.data
  } catch (error) {
    console.log('kinda aborted on frontend')
  }
}


export const drumsAutocompleteInnerRoute = async (
  drumRollNotes: any,
  drumGenreKey: string,
  signal: AbortSignal,
) => {
  const simpleDrumRollNotes = drumRollNotes.map((note: CompressedDrumRollNote) => ({
    type: note.type,
    startTicks: note.startTicks,
    endTicks: note.endTicks,
    velocity: note.velocity,
  }))

  try {
    const data = await api.post(
      '/api/ai-playground/drums-autocomplete',
      {
        drumRollNotes: simpleDrumRollNotes,
        drumGenreKey,
      },
      { signal },
    )
    return data.data
  } catch (error) {
    console.log('kinda aborted on frontend')
  }
}

export const melodyInnerRoute = async (chords: any) => {
  const data = await api.post('/api/ai-playground/melody', {
    prog: JSON.stringify({ name: 'test', key: 'A', scale: 'minor', chords }),
  })
  return data.data
}

export const modelsInnerRoute = async () => {
  const data = await api.get('/api/ai-playground/models')
  return data.data
}

export const inferenceOuter = (model: string, bpm: number, pianoRollNotes: any, params: any) => {
  return api.post(`${process.env.API_URL}/ai/inference`, {
    pianoRollNotes,
    model,
    bpm,
    params,
  })
}

export const melodyOuter = (prog?: string | string[]) => {
  return api.post(`${process.env.API_URL}/melody-track/melody/generate`, {
    prog,
  })
}

export const melodyAutocompleteOuter = (
  pianoRollNotes: any,
  prog: any,
  tonalityKey: string,
  tonalityScale: string,
  genre: string,
  signal: AbortSignal,
) => {
  return api.post(
    `${process.env.API_URL}/melody-track/melody/autocomplete`,
    {
      pianoRollNotes,
      prog,
      tonalityKey,
      tonalityScale,
      genre,
    },
    { signal },
  )
}

export const drumsAutocompleteOuter = (
  drumRollNotes: any,
  drumGenreKey: string,
  signal: AbortSignal,
) => {
  return api.post(
    `${process.env.API_URL}/drum-track/drums/autocomplete`,
    { drumRollNotes, drumGenreKey },
    { signal },
  )
}

export const modelsOuter = () => {
  return api.get(`${process.env.API_URL}/ai/models`)
}
