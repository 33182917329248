import classNames from 'classnames'
import React, { FC, useState } from 'react'

import ArrowIcon from '../../../../assets/icons/arrow.svg'
import RandomPickerIcon from '../../../../assets/icons/random-picker.svg'
import { useInstrumentsState } from '../../../../context/InstrumentsStateContext'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import useSizes from '../../../../hooks/useSizes'
import { trackMixpanelEvent_clickRandomInstrument } from '../../../../utils/tracking'
import Tooltip from '../../../common/Tooltip/Tooltip'
import {
  INSTRUMENT_TYPES,
  InstrumentType,
  SETTINGS_TYPES,
  SettingType,
} from '../../LayersOfInstruments/LayersOfInstruments'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './EditorSettings.module.scss'
import InstrumentMenu from './InstrumentMenu/InstrumentMenu'
import PlaystyleMenu from './InstrumentMenu/PlaystyleMenu'

type Props = {
  settingType?: SettingType
  mode?: InstrumentType
  hideArrows?: boolean
  small?: boolean
  className?: string
}

const InstrumentSetting: FC<Props> = ({
  settingType = SETTINGS_TYPES.INSTRUMENT,
  mode = INSTRUMENT_TYPES.CHORDS,
  hideArrows,
  small,
  className,
}) => {
  const { text } = useInternationalization()
  const { isMobile, isTablet } = useSizes()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { activeLayer, navigator, editorMode, handlePickRandom } = useInstrumentsState()

  const [menuOpen, setMenuOpen] = useState<SettingType | null>(null)
  const [isRandomPickerLoading, setIsRandomPickerLoading] = useState(false)

  const isInstrument = settingType === SETTINGS_TYPES.INSTRUMENT
  const isChordMode = editorMode === INSTRUMENT_TYPES.CHORDS
  const isMelodyMode = editorMode === INSTRUMENT_TYPES.MELODY
  const isDrumsMode = editorMode === INSTRUMENT_TYPES.DRUMS

  const isMenuOpen = settingType === menuOpen
  const isLoading = (() => {
    if (isChordMode) return !playerConfig.instrumentLoaded
    if (isMelodyMode) return !playerConfig.melodyInstrumentLoaded
    if (isDrumsMode) return !playerConfig.drumsInstrumentLoaded
    return false
  })()
  const value = isInstrument ? activeLayer?.instrument.name : activeLayer?.playstyle.name

  const STRINGS = text.footer?.instrumentsEditor

  const handlePrevClick = () => {
    if (isLoading || isRandomPickerLoading) return

    if (isChordMode) {
      playerConfigSetter.setInstrumentLoaded(false)
    }
    if (isMelodyMode) {
      playerConfigSetter.setMelodyInstrumentLoaded(false)
    }
    if (isDrumsMode) {
      playerConfigSetter.setDrumsInstrumentLoaded(false)
    }

    if (isChordMode && isInstrument) navigator.prevInstrument()
    if (isChordMode && !isInstrument) navigator.prevPlaystyle()
    if (isDrumsMode) navigator.prevDrums()
    if (isMelodyMode) navigator.prevMelody()
  }
  const handleNextClick = () => {
    if (isLoading || isRandomPickerLoading) return

    if (isChordMode) {
      playerConfigSetter.setInstrumentLoaded(false)
    }
    if (isMelodyMode) {
      playerConfigSetter.setMelodyInstrumentLoaded(false)
    }
    if (isDrumsMode) {
      playerConfigSetter.setDrumsInstrumentLoaded(false)
    }

    if (isChordMode && isInstrument) navigator.nextInstrument()
    if (isChordMode && !isInstrument) navigator.nextPlaystyle()
    if (isDrumsMode && isInstrument) navigator.nextDrums()
    if (isMelodyMode) navigator.nextMelody()
  }
  const handleOpenMenu = () => {
    setMenuOpen(settingType === menuOpen ? null : settingType)
  }

  const getTitle = () => {
    if (!isInstrument) return STRINGS.playstyle.triggerBtn
    if (isChordMode || isMelodyMode) return STRINGS.instruments.triggerBtn
    return STRINGS.instruments.triggerBtnDrums
  }

  const renderRandomPicker = () => {
    const tooltipId = 'random-picker-tooltip'
    const tooltipText = (() => {
      if (isChordMode) return STRINGS.randomPickerTooltipChords
      if (isMelodyMode) return STRINGS.randomPickerTooltipMelody
      if (isDrumsMode) return STRINGS.randomPickerTooltipDrums
      return ''
    })()

    const handlePick = async () => {
      if (isLoading || isRandomPickerLoading) return

      setIsRandomPickerLoading(true)
      setTimeout(() => setIsRandomPickerLoading(false), 500)

      handlePickRandom()

      // @ts-ignore
      await trackMixpanelEvent_clickRandomInstrument(mode.toLowerCase())
    }

    if (!isInstrument || isMobile) return null
    return (
      <>
        <div
          className={classNames(styles.randomPicker, { [styles.loading]: isRandomPickerLoading })}
          onClick={handlePick}
          data-tooltip-id={tooltipId}
        >
          <RandomPickerIcon />
        </div>
        <Tooltip id={tooltipId} text={tooltipText} place='top' />
      </>
    )
  }

  if (!isChordMode && !isInstrument) return null
  if (mode !== editorMode) return null
  return (
    <div className={classNames(styles.instrumentSetting, className, { [styles.instrumentSettingSmall]: small })}>
      {renderRandomPicker()}

      <div className={styles.instrumentSettingContent}>
        <div className={styles.instrumentSettingTitle}>{getTitle()}</div>

        <div
          className={classNames(styles.instrumentSettingSwitcher, { [styles.loading]: isLoading })}
          id={`menu-hook-${settingType}-${mode}`}
        >
          <div className={styles.currentsSettingValue} onClick={handleOpenMenu}>
            {value}
          </div>

          {(isTablet && !isMobile) || hideArrows ? null : (
            <div className={styles.btns}>
              <div className={classNames(styles.btn, styles.left)} onClick={handlePrevClick}>
                <ArrowIcon />
              </div>

              <div className={classNames(styles.btn, styles.right)} onClick={handleNextClick}>
                <ArrowIcon />
              </div>
            </div>
          )}
        </div>
      </div>

      {settingType === SETTINGS_TYPES.INSTRUMENT ? (
        <InstrumentMenu open={isMenuOpen} onClose={() => setMenuOpen(null)} />
      ) : (
        <PlaystyleMenu open={isMenuOpen} onClose={() => setMenuOpen(null)} />
      )}
    </div>
  )
}

export default InstrumentSetting
