import React, { FC, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'

import { useInstrumentsState } from '../../../../context/InstrumentsStateContext'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../hooks/useSizes'
import { getInstrumentKey } from '../../../../utils/instrumentsUtils'
import MobileSwipeableModal from '../../../common/MobileSwipeableModal/MobileSwipeableModal'
import MidiOutputMenu from '../../TimelineWrapper/InstrumentMenu/MidiOutputMenu/MidiOutputMenu'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './MidiMenu.module.scss'

type Props = {
  open: boolean
  onClose: () => void
}

const MidiMenu: FC<Props> = ({ open, onClose }) => {
  const { isMobile } = useSizes()
  const { outputs, isChordMode, activeLayer, activeLayerId } = useInstrumentsState()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()

  const { instrumentMidiOut, drumsInstrumentMidiOut, currentPart } = playerConfig
  const { setInstrumentMidiOut, setDrumsInstrumentMidiOut } = playerConfigSetter

  const menuRef = useRef<HTMLDivElement>(null)

  useClickOutsideAlerter(menuRef, onClose, clickOutsideHookIds.midiMenu)

  const { right, top } = useMemo(() => {
    const hook = document.getElementById('midi-menu-hook')

    const bcr = hook?.getBoundingClientRect()

    const right = window.innerWidth - (bcr?.x || 0) - (bcr?.width || 0)
    const top = bcr?.y || 0

    return { right, top }
  }, [open])

  const renderContent = () => {
    const chordInstrumentKey = activeLayer
      ? getInstrumentKey(activeLayer.instrument, activeLayerId, currentPart.id)
      : ''
    const drumInstrumentKey = activeLayer ? getInstrumentKey(activeLayer.instrument, activeLayerId, currentPart.id) : ''

    const port = activeLayer
      ? isChordMode
        ? instrumentMidiOut[chordInstrumentKey]
        : drumsInstrumentMidiOut[drumInstrumentKey]
      : null

    const setPort = (port: string) => {
      if (!activeLayer) return

      if (isChordMode) {
        setInstrumentMidiOut({
          ...instrumentMidiOut,
          [chordInstrumentKey]: port,
        })
      } else {
        setDrumsInstrumentMidiOut({
          ...drumsInstrumentMidiOut,
          [drumInstrumentKey]: port,
        })
      }
    }

    return <MidiOutputMenu outputs={outputs} onClose={onClose} port={port} setPort={setPort} />
  }

  if (isMobile)
    return (
      <MobileSwipeableModal onClose={onClose} open={open}>
        <div className={styles.container}>{renderContent()}</div>
      </MobileSwipeableModal>
    )

  return open
    ? createPortal(
        <div className={styles.container} ref={menuRef} style={{ right, top }}>
          {renderContent()}
        </div>,
        document.getElementById('__next') as HTMLElement,
      )
    : null
}

export default MidiMenu
