import classNames from 'classnames'
import React from 'react'

import ArrowIcon from '../../../assets/icons/simple-arrow.svg'
import styles from './SmallRangeSelector.module.scss'

type Props = {
  value: number
  min?: number
  max?: number
  onChange: (a: number) => void
  step: number
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  controlClassName?: string
  className?: string
  formatValue?: (val: number) => string | JSX.Element
}

const SmallRangeSelector: React.FC<Props> = ({
  value,
  min,
  max,
  onChange,
  step,
  leftIcon,
  rightIcon,
  controlClassName,
  className,
  formatValue,
}) => {
  return (
    <div className={styles.container}>
      <button
        className={classNames(controlClassName, styles.leftButton)}
        data-disabled={min === value}
        onClick={() => min !== value && onChange(value - step)}
      >
        {leftIcon || <ArrowIcon />}
      </button>
      <div className={classNames(className, styles.value)}>
        {formatValue ? formatValue(value) : (value > 0 ? '+' : '') + value}
      </div>
      <button
        className={classNames(controlClassName, styles.rightButton)}
        data-disabled={max === value}
        onClick={() => max !== value && onChange(value + step)}
      >
        {rightIcon || <ArrowIcon />}
      </button>
    </div>
  )
}

export default SmallRangeSelector
