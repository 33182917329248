import React, { FC } from 'react'

import NewVolumeControls from '../../Footer/NewVolumeControls'
import { TTab } from './EditorTabs'
import styles from './EditorTabs.module.scss'

type Props = {
  tab: TTab
  index: number

  isVolumeTabEnabled?: boolean
  onVolumeTabCallback: (newVolume: number, tab: TTab, tabIndex: number) => void
  onAfterVolumeTabCallback: (newVolume: number, tab: TTab, tabIndex: number) => void
}

const EditorTabVolumeControl: FC<Props> = ({
  tab,
  index,
  isVolumeTabEnabled = true,
  onVolumeTabCallback,
  onAfterVolumeTabCallback,
}) => {
  const { isMuted = false, color = 'white', volume = 0 } = tab

  if (!isVolumeTabEnabled) return null
  return (
    <NewVolumeControls
      data-block-swipe='true'
      color={isMuted ? 'gray' : (color as any)}
      volume={volume}
      onVolumeChange={(volume) => onVolumeTabCallback(volume, tab, index)}
      onAfterVolumeChange={(volume) => onAfterVolumeTabCallback(volume, tab, index)}
      mode='thin-enlarged'
      hideControls
      className={styles.volumeControl}
    />
  )
}

export default EditorTabVolumeControl
