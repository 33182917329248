import classNames from 'classnames'
import React from 'react'

import styles from './Switch.module.scss'

type Props = {
  className?: string
  activeClassName?: string
  markerClassName?: string
  disabled?: boolean
  onChange: () => void
  value: boolean
}

const Switch: React.FC<Props> = ({ value, onChange, disabled, className, markerClassName, activeClassName }) => {
  return (
    <div
      className={classNames(
        className,
        styles.switchContainer,
        { [styles.disabled]: disabled },
        { [styles.true]: value },
        { [activeClassName || '']: value },
      )}
      onClick={onChange}
    >
      <div data-bg-active={value}>
        <div data-marker-active={value} className={classNames(styles.switchMarker, markerClassName)}></div>
      </div>
    </div>
  )
}

export default Switch
