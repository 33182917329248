import classNames from 'classnames'
import React, { FC } from 'react'

import DrumsLabelClapsIcon from '../../../../assets/icons/drummer/claps.svg'
import DrumsLabelHihatsIcon from '../../../../assets/icons/drummer/hihats.svg'
import DrumsLabelKicksIcon from '../../../../assets/icons/drummer/kicks.svg'
import DrumsLabelOpenhapsIcon from '../../../../assets/icons/drummer/openhaps.svg'
import DrumsLabelPercsIcon from '../../../../assets/icons/drummer/percs.svg'
import DrumsLabelSnaresIcon from '../../../../assets/icons/drummer/snares.svg'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import { isInstrumentActive } from './DrumsPattern'
import styles from './DrumsPattern.module.scss'

type Props = {
  type: string
}

const DrumsInstrument: FC<Props> = ({ type }) => {
  const {
    playerConfig: {
      currentPart: { drums },
    },
    playerConfigSetter: { setPattern },
  } = usePlayerConfigState()

  const isActive = isInstrumentActive(drums, type)

  if (!drums) return null

  const renderIcon = () => {
    if (type === 'percs') return <DrumsLabelPercsIcon />
    if (type === 'hihats') return <DrumsLabelHihatsIcon />
    if (type === 'openhats') return <DrumsLabelOpenhapsIcon />
    if (type === 'kicks') return <DrumsLabelKicksIcon />
    if (type === 'snares') return <DrumsLabelSnaresIcon />
    if (type === 'claps') return <DrumsLabelClapsIcon />
    return null
  }

  const handleInsturmentClick = () => {
    const newGroups = JSON.parse(JSON.stringify(drums.groups))
    newGroups.forEach((group: any, gI: number) => {
      group.percTypes.forEach((insturment: any, iI: number) => {
        if (insturment.type === type) {
          newGroups[gI].percTypes[iI].active = !insturment.active
        }
      })
    })

    setPattern({ ...drums, groups: newGroups })
  }

  return (
    <div
      key={type}
      className={classNames(styles.instrument, { [styles.active]: isActive })}
      onClick={handleInsturmentClick}
    >
      {renderIcon()}
    </div>
  )
}

export default DrumsInstrument
