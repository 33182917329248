import classNames from 'classnames'
import React, { FC, useState } from 'react'

import ArrowIcon from '../../../assets/icons/arrow.svg'
import MidiSettings from '../../../assets/icons/genres.svg'
import { useInstrumentsState } from '../../../context/InstrumentsStateContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import useSizes from '../../../hooks/useSizes'
import SmallRangeSelector from '../../common/SmallRangeSelector/SmallRangeSelector'
import { INSTRUMENT_CATEGORY } from '../TimelineWrapper/InstrumentMenu/constants'
import InstrumentSetting from '../common/EditorSettings/InstrumentSetting'
import styles from './LayersOfInstruments.module.scss'
import LayersTabs, { LayersMuteDeleteControll, LayersVolumeControll } from './LayersTabs'
import MidiMenu from './MidiMenu/MidiMenu'

type Props = object

export enum INSTRUMENT_TYPES {
  CHORDS = 'CHORDS',
  MELODY = 'MELODY',
  DRUMS = 'DRUMS',
}

export type InstrumentType = keyof typeof INSTRUMENT_TYPES

export type TabType = keyof typeof INSTRUMENT_CATEGORY

export enum SETTINGS_TYPES {
  INSTRUMENT = 'INSTRUMENT',
  ARPEGGIO = 'ARPEGGIO',
}

export type SettingType = keyof typeof SETTINGS_TYPES

const LayersOfInstruments: FC<Props> = () => {
  const { text } = useInternationalization()
  const { isMobile, isTablet } = useSizes()
  const { layers, activeLayer, activeLayerId, isDrumMode, updateLayerById } = useInstrumentsState()

  const [midiMenuOpen, setMidiMenuOpen] = useState(false)

  const STRINGS = text.footer.instrumentsEditor

  if (!activeLayer || !layers.length) return null

  const renderMobileSettings = () => {
    if (!isMobile) return null
    return (
      <div className={styles.mobileVolumeControls}>
        <div className={classNames(styles.instrumentSetting, styles.instrumentSettingAdaptive)}>
          <div className={styles.instrumentSettingTitle}>{STRINGS.volume}</div>

          <LayersVolumeControll />
        </div>

        <LayersMuteDeleteControll />
      </div>
    )
  }
  const renderOctaveSetting = () => {
    const value = activeLayer.octave
    if (isDrumMode) return null
    return (
      <div className={classNames(styles.octaveSetting, styles.instrumentSetting, styles.instrumentSettingAdaptive)}>
        <div className={styles.instrumentSettingTitle}>{STRINGS.octave}</div>
        <div className={styles.instrumentSettingSwitcher}>
          <div className={styles.btns}>
            <SmallRangeSelector
              min={-2}
              max={2}
              value={value || 0}
              step={1}
              className={styles.octaveContent}
              controlClassName={styles.octaveArrows}
              leftIcon={
                <div className={classNames(styles.btn, styles.left)}>
                  <ArrowIcon />
                </div>
              }
              rightIcon={
                <div className={classNames(styles.btn, styles.right)}>
                  <ArrowIcon />
                </div>
              }
              onChange={(value) => {
                updateLayerById(
                  activeLayerId,
                  { ...activeLayer, octave: value },
                  isDrumMode ? INSTRUMENT_TYPES.DRUMS : INSTRUMENT_TYPES.CHORDS,
                )
              }}
            />
          </div>
        </div>
      </div>
    )
  }
  const renderMidiOutput = () => {
    if (isTablet || isDrumMode) return null
    return (
      <>
        <div
          className={styles.midiSettings}
          id={'midi-menu-hook'}
          onClick={() => {
            setMidiMenuOpen(true)
          }}
        >
          <MidiSettings />
          {STRINGS.midi.triggerBtn}
        </div>
        <MidiMenu open={midiMenuOpen} onClose={() => setMidiMenuOpen(false)} />
      </>
    )
  }

  return (
    <div className={styles.top}>
      <LayersTabs />

      <div className={styles.instrumentSettings}>
        <div className={styles.instrumentSettingsInner}>
          {renderMobileSettings()}

          <InstrumentSetting
            settingType={SETTINGS_TYPES.INSTRUMENT}
            mode={INSTRUMENT_TYPES.CHORDS}
            className={styles.instrumentSettingAdaptive}
          />
          <InstrumentSetting
            settingType={SETTINGS_TYPES.ARPEGGIO}
            mode={INSTRUMENT_TYPES.CHORDS}
            className={styles.instrumentSettingAdaptive}
          />

          {renderOctaveSetting()}

          {renderMidiOutput()}
        </div>
      </div>
    </div>
  )
}

export default LayersOfInstruments
