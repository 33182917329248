import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useSwipeable } from 'react-swipeable'
import { Transition, TransitionStatus } from 'react-transition-group'

import useClickOutsideAlerter from '../../../hooks/clickOutsideAlerterHook'
import { getElementFromTree } from '../../../utils/timelineUtils'
import stylesCanvas from '../../canvas/ChordCanvas.module.scss'
import { InstrumentType } from '../../editor/LayersOfInstruments/LayersOfInstruments'
import IconButton from '../IconButton'
import styles from './MobileSwipeableModal.module.scss'

type Props = {
  onClose: () => void
  open: boolean
  children: any
  openHeight?: number
  className?: string
  controlsClassName?: string
  whiteMode?: boolean
  disabled?: boolean
  fullHeight?: boolean
  contentSwipable?: boolean
  blockingModal?: boolean
  lowPriority?: boolean
  headerColor?: string
  title?: string
  type?: InstrumentType | undefined
  canvasMode?: boolean
  includeNoise?: boolean
  maxOpenHeight?: number
  getHeight?: () => number | undefined
  hideControls?: boolean
}

const MobileSwipeableModal: React.FC<Props> = ({
  open,
  onClose,
  headerColor,
  whiteMode,
  openHeight,
  className,
  controlsClassName,
  children,
  disabled,
  fullHeight,
  contentSwipable = false,
  blockingModal = false,
  lowPriority,
  title,
  type,
  canvasMode = false,
  maxOpenHeight = 659,
  getHeight = () => 0,
  hideControls,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [countedHeight, setCountedHeight] = useState(0)
  const [prevOpen, setPrevOpen] = useState(open)
  const blockContentSwipe = useRef<boolean>(null)
  const [localPosition, setLocalPosition] = useState<number | null>(null)
  const [parentContainer, setParentContainer] = useState<HTMLDivElement | null>(null)
  useEffect(() => {
    setTimeout(() => {
      setPrevOpen(open)
    }, 400)
  }, [open])
  useEffect(() => {
    const el = document.createElement('div')
    setParentContainer(el)
    if (lowPriority) {
      document.body.insertBefore(el, document.body.firstChild)
    } else {
      document.body.appendChild(el)
    }
    return () => {
      document.body.removeChild(el)
    }
  }, [])
  const handleUpdateHeight = () => {
    if (!getHeight()) {
      setTimeout(handleUpdateHeight, 50)
    } else {
      setCountedHeight((getHeight() || 0) + 32 + (title && type ? 62 : 0))
    }
  }
  useEffect(() => {
    if (open) {
      handleUpdateHeight()
    } else {
      setCountedHeight(0)
    }
  }, [open, children])
  useClickOutsideAlerter(containerRef, onClose) // TODO: clickOutsideHookIds
  const handlers = useSwipeable({
    onSwipedDown: () => {
      onClose()
      setLocalPosition(null)
    },
    onSwiping: (e) => {
      setLocalPosition(e.deltaY)
    },
    onSwiped: () => {
      setLocalPosition(null)
    },
  })
  const contentHandlers = useSwipeable({
    onSwipedDown: (e) => {
      if (blockContentSwipe.current) {
        return
      }
      if (getElementFromTree(e.event, (e) => !!e?.getAttribute('data-block-swipe'))) {
        return
      }
      onClose()
      setLocalPosition(null)
    },
    onSwipeStart: (e) => {
      const modalHeight = typeof height === 'string' ? window.innerHeight - 50 : height
      const topSpace = window.innerHeight - modalHeight
      const touchFromModalTop = e.initial[1] - topSpace
      const availableArea = Math.min(200, modalHeight * 0.4)
      // @ts-ignore
      blockContentSwipe.current = touchFromModalTop > availableArea
    },
    onSwiping: (e) => {
      if (blockContentSwipe.current) {
        return
      }
      if (getElementFromTree(e.event, (e) => !!e?.getAttribute('data-block-swipe'))) {
        return
      }
      setLocalPosition(Math.max(0, e.deltaY))
    },
    onSwiped: () => {
      setLocalPosition(null)
    },
  })
  const height =
    !openHeight && !getHeight() && !fullHeight
      ? 'calc(100% - 52px)'
      : fullHeight
      ? '100%'
      : openHeight || Math.min(maxOpenHeight, countedHeight)
  const movingHeight =
    !openHeight && !getHeight() && !fullHeight
      ? `calc(100% - 52px - ${localPosition || 0}px)`
      : fullHeight
      ? `calc(100% - ${localPosition || 0}px)`
      : (height as number) - (localPosition || 0)
  const renderBody = (status: TransitionStatus) => (
    <>
      {open && <div className={styles.shadow} />}
      {open && (
        <div
          {...(disabled ? {} : handlers)}
          className={classNames(styles.mobileSwiper)}
          style={{
            bottom: localPosition ? movingHeight : status === 'entered' ? height : 0,
          }}
        />
      )}
      <div
        // @ts-ignore
        ref={disabled ? undefined : containerRef}
        data-full={fullHeight}
        className={classNames(
          styles.mobileModal,
          { [styles.drums]: type === 'DRUMS' },
          { [styles.chords]: type === 'CHORDS' },
          { 'center-modal': blockingModal && (open || prevOpen) },
          { [styles.canvasMode]: canvasMode },
          {
            [stylesCanvas.noise]: canvasMode,
          },
          controlsClassName,
        )}
        style={{
          height: localPosition ? movingHeight : status === 'entered' ? height : 0,
          transition: localPosition ? undefined : 'height 200ms ease-out',
        }}
      >
        {!hideControls ? (
          <div
            data-full={fullHeight}
            className={classNames(styles.mobileControl, {
              [styles.whiteMode]: whiteMode,
            })}
          >
            <div
              style={{
                background: headerColor,
              }}
            >
              {!disabled && <div />}
            </div>
          </div>
        ) : null}

        <div
          className={classNames(className, styles.content, {
            [styles.contentSwipable]: contentSwipable,
          })}
          {...(disabled || blockingModal ? {} : contentHandlers)}
        >
          {title && type && (
            <div className={styles.modalHead}>
              <IconButton className={styles.modalTitleIcon} icon={`portal-button-${type}`} />
              <div className={styles.modalTitle}>{title}</div>
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  )
  return (
    <Transition in={open} timeout={100} nodeRef={containerRef} unmountOnExit>
      {(status) => <>{parentContainer ? createPortal(renderBody(status), parentContainer) : null}</>}
    </Transition>
  )
}

export default MobileSwipeableModal
